import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// Modules
import { LoginBranchPageComponent } from './others/pages/login-branch-page/login-branch-page.component';
import { LoginPageComponent } from './others/pages/login-page/login-page.component';
import { PasswordPageComponent } from './others/pages/password-page/password-page.component';
import { ChangePasswordModalComponent } from './others/components/change-password-modal/change-password-modal.component';
import { PasswordFailedPageComponent } from './others/pages/password-failed-page/password-failed-page.component';
import { PasswordSuccessPageComponent } from './others/pages/password-success-page/password-success-page.component';
import { NotFoundPageComponent } from './others/pages/not-found-page/not-found-page.component';
import { ForgotPasswordPageComponent } from './others/pages/forgot-password-page/forgot-password-page.component';
import { BusinessPartnersPageComponent } from './business-partners/pages/business-partners-page/business-partners-page.component';
import { BusinessPartnersAddEditFormComponent } from './business-partners/components/business-partners-add-edit-form/business-partners-add-edit-form.component';
import { BusinessPartnersSinglePageComponent } from './business-partners/pages/business-partners-single-page/business-partners-single-page.component';
import { BranchesAddEditFormComponent } from './business-partners/components/branches-add-edit-form/branches-add-edit-form.component';
import { MainBranchMainInfoComponent } from './business-partners/components/branches/main-branch-main-info/main-branch-main-info.component';
import { MainBranchDefaultAccountComponent } from './business-partners/components/branches/main-branch-default-account/main-branch-default-account.component';
import { MainBranchAddressComponent } from './business-partners/components/branches/main-branch-address/main-branch-address.component';
import { AgmCoreModule } from '@agm/core';
import { QRCodeModule } from 'angularx-qrcode';
import { MainBranchAboutComponent } from './business-partners/components/branches/main-branch-about/main-branch-about.component';
import { MainBranchFeatureAccessComponent } from './business-partners/components/branches/main-branch-feature-access/main-branch-feature-access.component';
import { MainBranchOperationHoursComponent } from './business-partners/components/branches/main-branch-operation-hours/main-branch-operation-hours.component';
import { MainBranchBillingComponent } from './business-partners/components/branches/main-branch-billing/main-branch-billing.component';
import { MainBranchDistributorComponent } from './business-partners/components/branches/main-branch-distributor/main-branch-distributor.component';
import { BranchesSinglePageComponent } from './business-partners/pages/branches-single-page/branches-single-page.component';
import { CustomerAppPageComponent } from './settings/pages/customer-app-page/customer-app-page.component';
import { CustomerAppSingleComponent } from './settings/pages/customer-app-single/customer-app-single.component';
import { CustomerAppAddFormComponent } from './settings/components/customer-app-add-form/customer-app-add-form.component';
import { CustomerListPageComponent } from './customers/pages/customer-list-page/customer-list-page.component';
import { SuspendUsersModalComponent } from './customers/components/suspend-users-modal/suspend-users-modal.component';
import { CustomerSinglePageComponent } from './customers/pages/customer-single-page/customer-single-page.component';
import { AccountsAddEditFormComponent } from './settings/components/accounts-add-edit-form/accounts-add-edit-form.component';
import { AccountsListingComponent } from './settings/components/accounts-listing/accounts-listing.component';
import { AccountsPageComponent } from './settings/pages/accounts-page/accounts-page.component';
import { AccountsSinglePageComponent } from './settings/pages/accounts-single-page/accounts-single-page.component';
import { IndustryAddEditFormComponent } from './settings/components/industry-add-edit-form/industry-add-edit-form.component';
import { IndustryListingComponent } from './settings/components/industry-listing/industry-listing.component';
import { IndustryPageComponent } from './settings/pages/industry-page/industry-page.component';
import { IndustySinglePageComponent } from './settings/pages/industy-single-page/industy-single-page.component';
import { DistributorPageComponent } from './distributor/pages/distributor-page/distributor-page.component';
import { DistributorSinglePageComponent } from './distributor/pages/distributor-single-page/distributor-single-page.component';
import { DistributorAddEditFormComponent } from './distributor/components/distributor-add-edit-form/distributor-add-edit-form.component';
import { DistributorAccountPageComponent } from './distributor/pages/distributor-account-page/distributor-account-page.component';
import { DeviceMonitoringPageComponent } from './device-monitoring/pages/device-monitoring-page/device-monitoring-page.component';
import { DeviceAddEditFormComponent } from './device-monitoring/components/device-add-edit-form/device-add-edit-form.component';
import { DeviceSinglePageComponent } from './device-monitoring/pages/device-single-page/device-single-page.component';
import { ViewDeviceModalComponent } from './device-monitoring/components/view-device-modal/view-device-modal.component';
import { NewsPageComponent } from './announcement/pages/news-page/news-page.component';
import { NewsSinglePageComponent } from './announcement/pages/news-single-page/news-single-page.component';
import { NewsAddEditFormComponent } from './announcement/components/news-add-edit-form/news-add-edit-form.component';
import { MessagesPageComponent } from './announcement/pages/messages-page/messages-page.component';
import { MessagesSinglePageComponent } from './announcement/pages/messages-single-page/messages-single-page.component';
import { MessagesAddEditFormComponent } from './announcement/components/messages-add-edit-form/messages-add-edit-form.component';
import { ManagerListPageComponent } from './managers/pages/manager-list-page/manager-list-page.component';
import { ManagerSinglePageComponent } from './managers/pages/manager-single-page/manager-single-page.component';
import { ManagerAddEditFormComponent } from './managers/components/manager-add-edit-form/manager-add-edit-form.component';
import { AssignManagerModalComponent } from './managers/components/assign-manager-modal/assign-manager-modal.component';
import { DashboardMainComponent } from './dashboard/components/dashboard-main/dashboard-main.component';
import { DashboardPageComponent } from './dashboard/pages/dashboard-page/dashboard-page.component';
import { CustomerPasswordPageComponent } from './others/pages/customer-password-page/customer-password-page.component';
import { BannerViewComponent } from './settings/components/banner-view/banner-view.component';
import { CustomerBannerComponent } from './settings/components/customer-banner/customer-banner.component';
import { CustomerDiscoverComponent } from './settings/components/customer-discover/customer-discover.component';
import { DiscoverAddEditFormComponent } from './settings/components/discover-add-edit-form/discover-add-edit-form.component';
import { LogsPageComponent } from './activity-logs/pages/logs-page/logs-page.component';
import { LandingPageComponent } from './business-portal/pages/landing-page/landing-page.component';
import { ReportsPageComponent } from './reports/pages/reports-page/reports-page.component';
import { ReportGenerateModalComponent } from './reports/components/report-generate-modal/report-generate-modal.component';
import { ReportsListingComponent } from './reports/components/reports-listing/reports-listing.component';

// Main Modules in Modules
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';

// Additional Modules
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts-x';
import { DragulaModule } from 'ng2-dragula';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { LogoutPageComponent } from './business-portal/pages/logout-page/logout-page.component';
import { DistributorAddEditFormV2Component } from './distributor/components/distributor-add-edit-form-v2/distributor-add-edit-form-v2.component';
import { UserRolePageComponent } from './settings/pages/user-role-page/user-role-page.component';
import { UserRoleListingComponent } from './settings/components/user-role-listing/user-role-listing.component';
import { UserRoleAddEditFormComponent } from './settings/components/user-role-add-edit-form/user-role-add-edit-form.component';
import { CustomBrandPageComponent } from './settings/pages/custom-brand-page/custom-brand-page.component';
import { CustomBrandListingComponent } from './settings/components/custom-brand-listing/custom-brand-listing.component';
import { UserRoleViewModalComponent } from './settings/components/user-role-view-modal/user-role-view-modal.component';
import { TemplateSinglePageComponent } from './settings/pages/template-single-page/template-single-page.component';
import { TemplateAddPageComponent } from './settings/pages/template-add-page/template-add-page.component';
import { TemplateNameColorFormComponent } from './settings/components/custom-branding/template-name-color-form/template-name-color-form.component';
import { TemplateOptionsFormComponent } from './settings/components/custom-branding/template-options-form/template-options-form.component';
import { TemplateLoginPageFormComponent } from './settings/components/custom-branding/template-login-page-form/template-login-page-form.component';
import { TemplateSidenavLogoFormComponent } from './settings/components/custom-branding/template-sidenav-logo-form/template-sidenav-logo-form.component';
import { TemplateFaviconFormComponent } from './settings/components/custom-branding/template-favicon-form/template-favicon-form.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { TemplatePreviewComponent } from './settings/components/custom-branding/template-preview/template-preview.component';
import { MainBranchCustomBrandingComponent } from './business-partners/components/branches/main-branch-custom-branding/main-branch-custom-branding.component';

@NgModule({
  declarations: [
    LoginBranchPageComponent,
    LoginPageComponent,
    PasswordPageComponent,
    ChangePasswordModalComponent,
    PasswordFailedPageComponent,
    PasswordSuccessPageComponent,
    NotFoundPageComponent,
    BusinessPartnersPageComponent,
    ForgotPasswordPageComponent,
    BusinessPartnersAddEditFormComponent,
    BusinessPartnersSinglePageComponent,
    BranchesAddEditFormComponent,
    MainBranchMainInfoComponent,
    MainBranchDefaultAccountComponent,
    MainBranchAddressComponent,
    MainBranchAboutComponent,
    MainBranchFeatureAccessComponent,
    MainBranchOperationHoursComponent,
    MainBranchBillingComponent,
    MainBranchDistributorComponent,
    BranchesSinglePageComponent,
    CustomerAppPageComponent,
    CustomerAppSingleComponent,
    CustomerAppAddFormComponent,
    CustomerListPageComponent,
    SuspendUsersModalComponent,
    CustomerSinglePageComponent,
    AccountsAddEditFormComponent,
    AccountsListingComponent,
    AccountsPageComponent,
    AccountsSinglePageComponent,
    IndustryAddEditFormComponent,
    IndustryListingComponent,
    IndustryPageComponent,
    IndustySinglePageComponent,
    DistributorPageComponent,
    DistributorSinglePageComponent,
    DistributorAddEditFormComponent,
    DistributorAccountPageComponent,
    DeviceMonitoringPageComponent,
    DeviceAddEditFormComponent,
    DeviceSinglePageComponent,
    ViewDeviceModalComponent,
    ManagerListPageComponent,
    ManagerSinglePageComponent,
    ManagerAddEditFormComponent,
    AssignManagerModalComponent,
    DashboardMainComponent,
    DashboardPageComponent,
    CustomerPasswordPageComponent,
    NewsPageComponent,
    NewsSinglePageComponent,
    NewsAddEditFormComponent,
    MessagesPageComponent,
    MessagesSinglePageComponent,
    MessagesAddEditFormComponent,
    ReportsPageComponent,
    ReportsListingComponent,
    ReportGenerateModalComponent,
    BannerViewComponent,
    CustomerBannerComponent,
    CustomerDiscoverComponent,
    DiscoverAddEditFormComponent,
    LogsPageComponent,
    LandingPageComponent,
    LogoutPageComponent,
    DistributorAddEditFormV2Component,
    UserRolePageComponent,
    UserRoleListingComponent,
    UserRoleAddEditFormComponent,
    CustomBrandPageComponent,
    CustomBrandListingComponent,
    UserRoleViewModalComponent,
    TemplateSinglePageComponent,
    TemplateAddPageComponent,
    TemplateNameColorFormComponent,
    TemplateOptionsFormComponent,
    TemplateLoginPageFormComponent,
    TemplateSidenavLogoFormComponent,
    TemplateFaviconFormComponent,
    TemplatePreviewComponent,
    MainBranchCustomBrandingComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule.forRoot(),
    ChartsModule,
    RouterModule,
    NgbModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    FormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCEcYYZ5FPrsIiD42WN5wXBzts_CNUj_OA'
    }),
    QRCodeModule,
    DragulaModule.forRoot(),
    AngularEditorModule,
    InfiniteScrollModule,
    AutocompleteLibModule,
    ColorPickerModule
  ],
  entryComponents: [
    ChangePasswordModalComponent,
    CustomerAppSingleComponent,
    CustomerAppAddFormComponent,
    SuspendUsersModalComponent,
    BusinessPartnersAddEditFormComponent,
    DeviceAddEditFormComponent,
    ReportGenerateModalComponent,
    ManagerAddEditFormComponent,
    AssignManagerModalComponent,
    ViewDeviceModalComponent,
    AccountsAddEditFormComponent,
    IndustryAddEditFormComponent,
    DistributorAddEditFormComponent,
    BannerViewComponent,
    DiscoverAddEditFormComponent,
    UserRoleAddEditFormComponent,
    UserRoleViewModalComponent
  ]
})
export class ModulesModule {}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { BusinessService } from 'src/app/core/services/business.service';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';
import { AccountsAddEditFormComponent } from 'src/app/modules/settings/components/accounts-add-edit-form/accounts-add-edit-form.component';
import { ViewDeviceModalComponent } from 'src/app/modules/device-monitoring/components/view-device-modal/view-device-modal.component';
import { UserRolePermissionsComponent } from 'src/app/shared/components/user-role-permissions/user-role-permissions.component';

@Component({
  selector: 'app-distributor-single-page',
  templateUrl: './distributor-single-page.component.html',
  styleUrls: ['./distributor-single-page.component.scss']
})
export class DistributorSinglePageComponent extends UserRolePermissionsComponent implements OnInit, OnDestroy {
  distributorData: any;
  imgUrl: any;
  imgAvatar: any;
  imgBanner: any;
  imgBranch: any;
  imgMascot: any;
  dataLists: Array<any>;
  dataLists1: Array<any>;
  distributorId: string;
  distributorList = [{name: 'Device List', type: 'distributor-device'}, {name: 'Branches', type: 'distributor-branches'}];
  selected: number;
  selectedList: any;

  constructor(
    businessService: BusinessService,
    private httpAdminService: HttpWebAdminService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal
  ) {
    super(businessService)
  }

  getUrlParams() {
    this.subscriptions.add(this.activatedRoute.params.subscribe(params => {
      this.distributorId = params['distributorId'];
      console.log('this.distributorId', this.distributorId);
      this.getDistributorData();
    }));
  }

  getDistributorData() {
    this.httpAdminService.get$(`distributor/${this.distributorId}`).subscribe(
      data => {
        console.log('distributor data', data);
        this.distributorData = data;
        this.imgUrl = this.distributorData.avatarUrl ? this.distributorData.avatarUrl : './assets/images/koala.jpg';
      }, error => {
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Error:</b> Something went wrong.`
        });
      }
    );
  }

  getDataList() {
    this.subscriptions.add(this.businessService.dataLists$.subscribe(
      data => {
        console.log('distributor accounts list', data);
        if (data) {
          this.dataLists = data.data;
        }
      }, error => {
        console.log('error', error);
      }
    ));
  }

  checkDataChange() {
    this.subscriptions.add(this.businessService.dataChange$.subscribe(
      res => {
        if (res) {
          this.getDistributorData();
          this.getDataList();
        }
      }
    ));
  }

  checkAlert() {
    this.subscriptions.add(this.businessService.alert$.subscribe(
      res => {
        if (res) {
          if (!res.message.includes(`<b>Error:</b> Something went wrong.`)) {
            this.getDistributorData();
          }
        }
      }
    ));
  }

  viewDetails($event) {
    console.log($event);
    this.router.navigate([`/distributors/dist/${this.distributorId}/accounts/${$event._id}`]);
  }

  viewDevice($event) {
    console.log($event);
    const modalRef = this.modalService.open(ViewDeviceModalComponent, {
      centered: true,
      size: 'sm'
    });
    modalRef.componentInstance.title = 'View Device';
    modalRef.componentInstance.deviceId = $event._id;
  }

  editDistributor() {
    console.log('this.distributorId', `/distributors/dist/${this.distributorId}/edit`);
    this.router.navigate([`/distributors/dist/${this.distributorId}/edit`]);
  }
  suspendDistributor() {
    const modalRef = this.modalService.open(ModalConfirmComponent, {
      centered: true
    });
    modalRef.componentInstance.type = 'suspend-distributor';
    modalRef.componentInstance.adminTitle = 'Suspend Distributor';
    modalRef.componentInstance.bodyText = `Are you sure you want to suspend ${this.distributorData.name}?`;
    modalRef.componentInstance.primaryButton = 'Confirm';
    modalRef.componentInstance.distributorData = this.distributorData;
  }

  unsuspendDistributor() {
    this.httpAdminService.patch$(`/distributor/${this.distributorData._id}/status`, {isSuspended: false}).subscribe(
      data => {
        this.businessService.publishAlert$({
          type: 'green-dark-01',
          icon: 'fas fa-check',
          message: `<b>${this.distributorData.name}</b> has been Unsuspended!`
        });
        this.getDistributorData();
      }, error => {
        console.log('error');
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: error.error.source
        });
      }
    );
  }

  addAccount() {
    const modalRef = this.modalService.open(AccountsAddEditFormComponent, {
      centered: true
    });
    modalRef.componentInstance.title = 'Add Account';
    modalRef.componentInstance.type = 'distributor-add-account';
    modalRef.componentInstance.distributorData = this.distributorData;
  }

  getAccountData() {
    this.getAccountPromise();
    this.accountPromise
    .then((data) => {      
      console.log('promise account', data);
      this.setAccountItems(data);
      // Insert all functions related to Account Data
      // ----- End ----- //
    })
    .catch((error) => {
      console.log('promise err', error);
    });
  }

  ngOnInit() {
    // Needed in User Role
    this.getAccountData();
    // --------- //
    this.selected = 0;
    this.selectedList = 'distributor-device';
    this.getUrlParams();
    this.checkDataChange();
    this.checkAlert();
    this.getDataList();
  }
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

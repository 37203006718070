import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, FormArray, FormGroup } from '@angular/forms';
import { NgbModal, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { BusinessService } from 'src/app/core/services/business.service';
import { DateTimeValidatorService } from 'src/app/shared/validators/date-time.validator.service';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';

@Component({
  selector: 'app-messages-add-edit-form',
  templateUrl: './messages-add-edit-form.component.html',
  styleUrls: ['./messages-add-edit-form.component.scss']
})
export class MessagesAddEditFormComponent implements OnInit, OnDestroy {
  title: string;
  subTitle: string;
  messagesData: any;
  messagesId: string;
  recipientsList = [ 'All Customer Emails', 'All Business Account Emails', 'Customer App'];
  time: any;
  meridian = true;
  timeIndex: number;
  timeType: string;
  showDateTime: boolean;
  hoursDirty: boolean;
  defaultTime: number;
  messagesForm: FormGroup;
  selectedRecipient: Array<number>;
  submitting: boolean;
  minDate: any;
  timeValid: boolean;
  paramList: Array<any>;
  errorMsg: string;
  scheduledAtErrorMsg: string;
  subscriptions = new Subscription();

  constructor(
    private httpAdminService: HttpWebAdminService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private businessService: BusinessService,
    private calendar: NgbCalendar
  ) { }

  recipientsControls = this.recipientsList.map(c => false);

  createForm() {
    this.messagesForm = this.fb.group({
      status: [],
      title: ['', [Validators.required, Validators.maxLength(120)]],
      content: ['', Validators.required],
      recipients: this.fb.array(this.recipientsControls),
      isScheduled: [false],
      dateSchedule: [],
      timeSchedule: []
    });
  }

  get f() { return this.messagesForm.controls; }
  get recipients() { return this.messagesForm.get('recipients') as FormArray; }

  getUrlParams() {
    this.subscriptions.add(this.activatedRoute.params.subscribe(params => {
      this.messagesId = params['messagesId'];
      this.getMessageData();
    }));
  }

  getMessageData() {
    this.httpAdminService.get$(`notification/messages/${this.messagesId}`).subscribe(
      data => {
        console.log('messages data', data);
        if (data) {
          this.messagesData = data;
          if (this.messagesData) {
            this.patchValue();
          }
        }
      }, error => {
        console.log('error', error);
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Error:</b> Something went wrong.`
        });
      }
    );
  }

  goToMessagePage() {
    this.router.navigate([`/announcements/messages/view/${this.messagesData._id}`]);
  }

  patchValue() {
    this.messagesForm.patchValue({
      title: this.messagesData.title,
      content: this.messagesData.content,
      recipients: this.patchRecipients(),
      isScheduled: this.messagesData.scheduledAt > 0 ? true : false,
      dateSchedule: this.messagesData.scheduledAt > 0 ? this.businessService.jsonDate(this.messagesData.scheduledAt) : undefined,
      timeSchedule: this.messagesData.scheduledAt > 0 ? this.messagesData.scheduledAt : undefined
    });
  }

  patchRecipients() {
    const recipients = this.recipientsList.map((element, index) => {
      return this.messagesData.recipients.includes(index) ? true : false;
    });
    console.log('recipients', recipients);
    return recipients;
  }

  changeTime($event, timePicker, timeValue, type) {
    $event.srcElement.blur();
    this.modalService.open(timePicker, {
      centered: true,
      size: 'sm',
      windowClass: 'width-200'
    });
    this.time = this.businessService.jsonTime(timeValue);
    this.timeType = type;
    this.hoursDirty = true;
  }

  setTime(time, type) {
    if (time) {
      const transformedTime = this.businessService.parseTime(time);
      this.f[type].setValue(transformedTime);
    }
  }

  toggleMeridian() {
    this.meridian = !this.meridian;
  }

  checkIsScheduled($event) {
    console.log($event);
    if ($event) {
      this.checkTimeValid();
      this.showDateTime = true;
      this.f['dateSchedule'].setValidators([Validators.required]);
      this.f['timeSchedule'].setValidators([Validators.required]);
      this.f['dateSchedule'].updateValueAndValidity();
      this.f['timeSchedule'].updateValueAndValidity();
    } else {
      this.showDateTime = false;
      this.timeValid = true;
      this.f['dateSchedule'].clearValidators();
      this.f['timeSchedule'].clearValidators();
      this.f['dateSchedule'].updateValueAndValidity();
      this.f['timeSchedule'].updateValueAndValidity();
    }
  }

  convertTimeDate(date, time) {
    const convertTime = this.businessService.jsonTime(time);
    if (date) {
      const convertMillis = (new Date(`${date.month}/${date.day}/${date.year} ${convertTime.hour}:${convertTime.minute}:${convertTime.second}`).getTime());
      console.log('convertMillis', `${date.month}/${date.day}/${date.year} ${convertTime.hour}:${convertTime.minute}:${convertTime.second}`, convertMillis);
      return convertMillis;
    }
  }

  checkTimeValid() {
    const time = this.convertTimeDate((this.f['dateSchedule'].value), this.f['timeSchedule'].value);
    console.log('time and date', this.f['dateSchedule'].value, this.f['timeSchedule'].value);
    const date = new Date();
    console.log('time - todayTime', time, date.getTime(), time - date.getTime());
    if (time > date.getTime()) {
      console.log('is valid time');
      this.timeValid = true;
    } else {
      console.log('not valid time');
      this.timeValid = false;
    }
    console.log(this.f['dateSchedule']);
    console.log(this.f['timeSchedule']);
  }

  addRecipient() {
    this.selectedRecipient = this.recipients.getRawValue().map((element, index) => element ? index : null
    ).filter(element => element !== null);
    console.log('selectedRecipient', this.selectedRecipient);
  }

  cancel() {
    if (this.messagesData) {
      this.router.navigate([`/announcements/messages/view/${this.messagesId}`]);
    } else {
      this.router.navigate([`/announcements/messages`]);
    }
  }

  canDeactivate() {
    console.log('canDeactivate');
    if (this.messagesForm.dirty) {
      const modalRef = this.modalService.open(ModalConfirmComponent, {
        centered: true
      });
      modalRef.componentInstance.title = 'Leave Page';
      modalRef.componentInstance.type = 'leave-page';
      return this.businessService.navigateAwaySelection$;
    } else {
      return true;
    }
  }

  submitForm(f, stat) {
    console.log(f.value);
    this.submitting = true;
    f.disable();
    const { dateSchedule, timeSchedule, status, recipients, isScheduled, ...form } = f.value;
    const body = {
      ...form,
      status: stat,
      recipients: this.selectedRecipient ? this.selectedRecipient : [],
      scheduledAt: this.f['isScheduled'].value === true ? this.convertTimeDate(this.f['dateSchedule'].value, this.f['timeSchedule'].value) : 0
    };
    console.log('body', body);
    if (this.messagesData) {
      // if edit
      this.httpAdminService.patch$(`notification/messages/${this.messagesId}`, body).subscribe(
        data => {
          console.log('edited', data);
          // if Saved as Draft
          if (stat === 0) {
            this.businessService.publishAlert$({
              type: 'green-dark-01',
              icon: 'fas fa-check',
              message: `<b>Draft Saved!</b> Your draft has been saved.`
            });
          // if Send
          } else {
            this.businessService.publishAlert$({
              type: 'green-dark-01',
              icon: 'fas fa-check',
              message: `<b>Message Sent!</b> Your message has been sent.`
            });
          }
          this.submitting = false;
          f.enable();
          f.reset();
          this.router.navigate([`/announcements/messages/view/${data._id}`]);
        }, error => {
          console.log('error', error);
          this.showError(error);
          this.submitting = false;
          f.enable();
        }
      );
    } else {
      // if new
      this.httpAdminService.post$(`notification/messages`, body).subscribe(
        data => {
          console.log('submitted', data);
          // if Saved as Draft
          if (stat === 0) {
            this.businessService.publishAlert$({
              type: 'green-dark-01',
              icon: 'fas fa-check',
              message: `<b>Draft Saved!</b> Your draft has been saved.`
            });
          // if Send
          } else {
            this.businessService.publishAlert$({
              type: 'green-dark-01',
              icon: 'fas fa-check',
              message: `<b>Message Sent!</b> Your message has been sent.`
            });
          }
          this.submitting = false;
          f.enable();
          f.reset();
          this.router.navigate([`/announcements/messages/view/${data._id}`]);
        }, error => {
          console.log('error', error);
          this.showError(error);
          this.submitting = false;
          f.enable();
        }
      );
    }
  }

  showError(error) {
    this.paramList = error.error.errors.filter(element => element.param).map(element2 => element2.param);
    console.log('this.paramList', this.paramList);
    if (this.paramList) {
      if (this.paramList.includes('scheduledAt')) {
        this.scheduledAtErrorMsg = 'Entered time is less than the current time.';
      } else {
        this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
      }
    }
    this.businessService.publishAlert$({
      type: 'red-dark-01',
      icon: 'fas fa-ban',
      message: this.errorMsg
    });
  }

  clearBackendError(input) {
    if (input.dirty) {
      this.scheduledAtErrorMsg = null;
      this.errorMsg = null;
    }
  }

  ngOnInit() {
    if (this.router.url.includes('edit')) {
      this.getUrlParams();
      console.log('should edit');
      this.title = 'Edit Message';
      this.subTitle = 'Edit personalized messages for business and consumers';
    } else {
      this.title = 'Add Message';
      this.subTitle = 'Add personalized messages for business and consumers';
    }
    const date = new Date();
    const m = date.getMonth();
    const d = date.getDay();
    const y = date.getFullYear();
    this.defaultTime = date.setTime(date.getTime() + (60 * 60 * 1000));
    this.minDate = this.calendar.getToday();
    console.log('date today', this.calendar.getToday());
    this.createForm();
    this.timeValid = true;
    this.hoursDirty = false;
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { HttpService } from 'src/app/core/http/http.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';
import { BusinessService } from 'src/app/core/services/business.service';
import { environment } from 'src/environments/environment';
import { SocketService } from 'src/app/core/http/socket.service';
import { first, map } from 'rxjs/operators';
import { UserRolePermissionsComponent } from 'src/app/shared/components/user-role-permissions/user-role-permissions.component';

@Component({
  selector: 'app-branches-single-page',
  templateUrl: './branches-single-page.component.html',
  styleUrls: ['./branches-single-page.component.scss']
})
export class BranchesSinglePageComponent extends UserRolePermissionsComponent implements OnInit, OnDestroy {
  partnerId: string;
  branchId: string;
  branchData: any;
  queueSettingsData: any;
  imgUrl: string;
  lineTitle: string;
  lineSubtitle: string;
  serviceType: number;
  queueLineChart: any;
  errorMsg: string;
  queueWalkin: number;
  queueOnline: number;
  queueCounts: any;
  totalQueues = 0;
  smsValue = 0;
  smsCounts: any;
  avatarUrl: any;
  devicesList: any;
  businessPortalUrl = environment.businessPortalUrl;
  completed: number;
  removed: number;
  cancelled: number;
  distributorData: string;
  newQueueUpdate: BehaviorSubject<any>;
  newSMSUpdate: BehaviorSubject<any>;
  overallQueueCounts: number;
  // Default Account
  defaultAccountData: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpAdminService: HttpWebAdminService,
    private httpService: HttpService,
    private router: Router,
    private modalService: NgbModal,
    businessService: BusinessService,
    public wsService: SocketService
  ) {
    super(businessService)
  }

  getUrlParams() {
    this.subscriptions.add(this.activatedRoute.params.subscribe(async params => {
      this.partnerId = params['partnerId'];
      this.branchId = params['branchId'];
      if (this.branchId) {
        this.defaultAccountData = await this.getDefaultAccount();
        console.log('default accountssssss', this.defaultAccountData);

        this.getBranchData();
        this.getQueueSettings();
        this.getQueueCounts();
        this.getSmsCounts();
        this.getOverallQueueCounts();
        this.checkNewQueueCounts();
        this.checkNewSMSCounts();
        this.getAverage();
      }
    }));
  }

  getBranchData() {
    this.httpAdminService.get$(`branches/${this.branchId}`).subscribe(
      data => {
        console.log('branch data', data);
        if (data) {
          this.branchData = data;
          this.imgUrl = this.branchData.avatarUrl ? this.branchData.avatarUrl : './assets/images/koala.jpg';
          this.getDistributorData(data.distributorId);
        }
      }, error => {
        console.log('error', error);
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Error:</b> Something went wrong.`
        });
      }
    );
  }

  getQueueSettings() {
    this.httpAdminService.get$(`/branches/${this.branchId}/queue-settings`).subscribe(
      data => {
        console.log('queue settings', data);
        this.queueSettingsData = data;
      }
    );
  }

  async getDefaultAccount() {

    const defaultAccountData = await this.httpService
      .get$(`business-portal/accounts/web-admin/default-account?branchId=${this.branchId}`)
      .pipe(first())
      .toPromise();

    return defaultAccountData
    // this.httpAdminService.get$(`business-portal/accounts/web-admin/default-account?branchId=${this.branchId}`).toPromise().then(
    //   data => {
    //     if (data) {
    //       console.log('default accountssss', data)
    //       return data.data;
    //     }
    //   }, error => {
    //     console.log('error', error);
    //   }
    // );


    // this.httpAdminService.get$(`business-portal/accounts/web-admin/default-account?branchId=${this.branchId}`).subscribe(
    //   data => {
    //     if (data) {
    //       console.log('default account', data);
    //       this.defaultAccountData = data.data;
    //     }
    //   }, error => {
    //     console.log('error', error);
    //   }
    // );
  }

  checkNewQueueCounts() {
    console.log('branch id', this.branchId);
    this.newQueueUpdate = this.wsService
    .connect(`UPDATE_DASHBOARD_QUEUE_COUNTS_${this.branchId}`)
    .pipe(
      map((response: any): any => {
        return response;
      })
    ) as BehaviorSubject<any>;
    this.subscriptions.add(this.newQueueUpdate.subscribe(msg => {
      console.log('update queue counts', msg);
      // call get functions
      this.getQueueCounts();
      this.getBranchData();
      this.getOverallQueueCounts();
    }));
  }

  checkNewSMSCounts() {
    console.log('branch id', this.branchId);
    this.newSMSUpdate = this.wsService
    .connect(`NEW_SMS_${this.branchId}`)
    .pipe(
      map((response: any): any => {
        return response;
      })
    ) as BehaviorSubject<any>;
    this.subscriptions.add(this.newSMSUpdate.subscribe(msg => {
      console.log('update queue counts', msg);
      // call get functions
      this.getSmsCounts();
    }));
  }

  getLineChart(filter) {
    console.log('filter', filter);
    let param;
    if (filter === 1) {
      param = `/queues/${this.branchId}/dashboard/info/weekly-queue-counts?week=0`;
    } else if (filter === 2) {
      param = `/queues/${this.branchId}/dashboard/info/weekly-queue-counts?week=-1`;
    } else if (filter === 3) {
      param = `/queues/${this.branchId}/dashboard/info/month-queue-counts`;
    } else {
      param = `/queues/${this.branchId}/dashboard/info/month-queue-counts?month=${filter}`;
    }
    this.httpAdminService.get$(param).subscribe(data => {
      console.log('Line chart', data);
      this.queueLineChart = data;
    }, error => {
      // this.errorMsg = error;
      this.businessService.publishAlert$({
        type: 'red-dark-01',
        icon: 'fas fa-ban',
        message: `<b>Error:</b> Something went wrong.`
      });
    });
  }

  getServicesType($event) {
    console.log($event);
    this.serviceType = $event;
    this.getLineChart(this.serviceType);
  }

  getQueueCounts() {
    this.httpAdminService.get$(`queues/${this.branchId}/dashboard/info/queue-counts-today`).subscribe(data => {
      console.log('queue counts', data);
      this.queueCounts = data;
      this.totalQueues = data.total;
    }, error => {
      // this.errorMsg = error;
      this.businessService.publishAlert$({
        type: 'red-dark-01',
        icon: 'fas fa-ban',
        message: `<b>Error:</b> Something went wrong.`
      });
    });
  }

  getAverage() {
    this.httpAdminService.get$(`queues/${this.branchId}/dashboard/info/queue-counts-avg`).subscribe(data => {
      console.log('average counts', data);
      this.completed = Math.round(data.completed);
      this.removed = Math.round(data.removed);
      this.cancelled = Math.round(data.cancelled);
    }, error => {
      // this.errorMsg = error;
      this.businessService.publishAlert$({
        type: 'red-dark-01',
        icon: 'fas fa-ban',
        message: `<b>Error:</b> Something went wrong.`
      });
    });
  }

  getOverallQueueCounts() {
    this.httpAdminService.get$(`queues/${this.branchId}/dashboard/info/queue-counts`).subscribe(data => {
      console.log('overall queue counts', data);
      this.overallQueueCounts = data.completed + data.removed + data.cancelled + data.ongoing;
    }, error => {
      // this.errorMsg = error;
      console.log('error', error);
      this.businessService.publishAlert$({
        type: 'red-dark-01',
        icon: 'fas fa-ban',
        message: `<b>Error:</b> Something went wrong.`
      });
    });
  }

  getSmsCounts() {
    this.httpAdminService.get$(`mailer/${this.branchId}/sms/month-sms-count`).subscribe(data => {
      console.log('sms counts', data);
      this.smsCounts = data;
      this.smsValue = data.costEquivalent * data.successSms;
    }, error => {
      // this.errorMsg = error;
      // this.businessService.publishAlert$({
      //   type: 'red-dark-01',
      //   icon: 'fas fa-ban',
      //   message: `<b>Error:</b> Something went wrong.`
      // });
    });
  }

  suspendBranch() {
    const modalRef = this.modalService.open(ModalConfirmComponent, {
      centered: true
    });
    modalRef.componentInstance.type = 'suspend-admin';
    modalRef.componentInstance.adminTitle = 'Suspend Branch';
    modalRef.componentInstance.bodyText = `Are you sure you want to suspend <br>
    ${this.branchData.partnerName} ${this.branchData.branchName}?`;
    modalRef.componentInstance.primaryButton = 'Confirm';
    modalRef.componentInstance.branchId = this.branchData._id;
  }

  unsuspendBranch() {
    this.httpAdminService.patch$(`branches/${this.branchId}/suspend-status`, {suspendStatus: false}).subscribe(
      data => {
        this.businessService.publishAlert$({
          type: 'green-dark-01',
          icon: 'fas fa-check',
          message: `<b>${data.branchName}</b> has been Unsuspended!`
        });
        this.getBranchData();
      }, error => {
        console.log('error');
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: error.error.source
        });
      }
    );
  }

  checkAlert() {
    this.subscriptions.add(this.businessService.alert$.subscribe(res => {
      if (res) {
        if (!res.message.includes(`<b>Error:</b> Something went wrong.`)) {
          this.getBranchData();
        }
      }
    }));
  }

  goToBusinessPortal() {
    console.log('this.branchDatazzz', this.branchData.settings.businessPortalCustomDomain);
    const JWT = localStorage.getItem('JWT_TOKEN');
    const REFRESH = localStorage.getItem('REFRESH_TOKEN');
    const accountId = localStorage.getItem('accountId');
    localStorage.setItem('JWT_TOKEN', JWT);
    localStorage.setItem('REFRESH_TOKEN', REFRESH);
    localStorage.setItem('branchId', this.branchId);
    localStorage.setItem('accountId', accountId);
    if (this.branchData.settings.businessPortalCustomDomain) {
      window.open(`${this.branchData.settings.businessPortalCustomDomain}/web-admin?accountId=${accountId}&branchId=${this.branchId}&jwt=${JWT}&ref=${REFRESH}`, '_self');
    } else {
      window.open(`${this.businessPortalUrl}/web-admin?accountId=${accountId}&branchId=${this.branchId}&jwt=${JWT}&ref=${REFRESH}`, '_self');
    }
  }

  getDevicesList() {
    this.httpAdminService.get$(`devices`).subscribe(
      data => {
        console.log('devices list', data);
        this.devicesList = data.data;
      }, error => {
        console.log('error', error);
        // this.businessService.publishAlert$({
        //   type: 'red-dark-01',
        //   icon: 'fas fa-ban',
        //   message: `<b>Error:</b> Something went wrong.`
        // });
      }
    );
  }

  getDeviceImage(id) {
    if (this.devicesList) {
      const avatar = this.devicesList.find(element => element._id === id);
      if (avatar) {
        return avatar.avatarUrl ? avatar.avatarUrl : './assets/images/koala.jpg';
      } else {
        return './assets/images/koala.jpg';
      }
    }
  }

  getDeviceName(id) {
    if (this.devicesList) {
      const name = this.devicesList.find(element => element._id === id);
      if (name) {
        return name.refId ? name.refId : '—';
      } else {
        return  '—';
      }
    }
  }

  saveAsImage(parent) {
    // fetches base 64 date from image
    const parentElement = parent.el.nativeElement.querySelector('img').src;

    // converts base 64 encoded image to blobData
    const blobData = this.businessService.dataURItoBlob(parentElement);

    // saves as image
    if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE
      window.navigator.msSaveOrOpenBlob(blobData, 'Qrcode');
    } else { // chrome
      const blob = new Blob([blobData], { type: 'image/png' });
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Qrcode';
      link.click();
    }
  }

  getDistributorData(distributorId) {
    this.httpAdminService.get$(`distributor/${distributorId}`).subscribe(
      data => {
        console.log('distributor data', data);
        this.distributorData = data;
      }, error => {
        console.log('error', error);
        // this.businessService.publishAlert$({
        //   type: 'red-dark-01',
        //   icon: 'fas fa-ban',
        //   message: `<b>Error:</b> Something went wrong.`
        // });
      }
    );
  }

  formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  getAccountData() {
    this.getAccountPromise();
    this.accountPromise
    .then((data) => {      
      console.log('promise account', data);
      this.setAccountItems(data);
      // Insert all functions related to Account Data
      // ----- End ----- //
    })
    .catch((error) => {
      console.log('promise err', error);
    });
  }

  ngOnInit() {
     // Needed in User Role
     this.getAccountData();
     // --------- //
    this.getUrlParams();
    this.checkAlert();
    this.getDevicesList();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs';
import * as Rx from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket;
  test;
  time;

  constructor() { }

  connect(event): Rx.Subject<MessageEvent> {
    if (!this.socket) {
      this.socket = io(`${environment.socketAPI}`, {
        path: environment.socketPath
      });
    }

    const observable = new Observable(observer2 => {
      this.test = observer2;
      this.socket.on('connect', () => {
        console.log('Socket IO connected');
      });
      this.socket.on(event, (data) => {
        observer2.next(data);
        console.log('new service socket', data);
      });
      this.socket.on('disconnect', () => {
        console.log('Socket IO disconnected');
        this.time = setTimeout(() => this.socket.connect(), 500);
      });
      return observable;
    });

    const observer = {
      next: (data: object) => {
        this.socket.emit('message', JSON.stringify(data));
      }
    };

    return Rx.Subject.create(observer, observable);
  }
}

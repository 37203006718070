import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterData'
})
export class FilterDataPipe implements PipeTransform {

  transform(items: any[], value: string, label: string) {
    if (!items) {
      return [];
    }
    if (!value) {
      return items;
    }
    if (value === '' || value === null) {
      return [];
    }

    // Reports Filter
    if (label === 'reportName') {
      return items.filter(it => {
        if (!it.reportName) {
          const status = it.status.toString().includes(value);
          const mobileNo = it.mobileNo.toString().includes(value);
          const fullName = it.fullName.toLowerCase().includes(value.toLowerCase());
          return ( status + fullName + mobileNo);
        } else {
          const reportName = it.reportName.toLowerCase().includes(value.toLowerCase());
          return ( reportName );
        }
      });
    // Custom Branding Settings
    } else if (label === 'brandTemplate') {
      return items.filter(e => (e['name'].toLowerCase()).indexOf(value.toLowerCase()) > -1);
    }
  }

}

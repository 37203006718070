import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective } from '@angular/forms';
import { BusinessService } from 'src/app/core/services/business.service';
import { CropPhotoComponent } from 'src/app/indie/crop-photo/crop-photo.component';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-main-branch-default-account',
  templateUrl: './main-branch-default-account.component.html',
  styleUrls: ['./main-branch-default-account.component.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }]
})
export class MainBranchDefaultAccountComponent implements OnInit {
  @Input() branchData: any;
  @Input() defaultAccountData: any;
  imgUrl3: any;
  selectedAvatar3: any;
  formDirty: boolean;
  avatarError: boolean;
  croppedImg: any;
  fileName: string;
  fileType: string;
  subscriptions = new Subscription();

  constructor(
    private fb: FormBuilder,
    private parent: FormGroupDirective,
    private modalService: NgbModal,
    private businessService: BusinessService
  ) { }

  branchDefaultAccount = this.fb.group({
    firstName: [null, [Validators.required, Validators.pattern(/^[A-Za-z- ñÑ.]*$/)]],
    lastName: [null, [Validators.required, Validators.pattern(/^[A-Za-z- ñÑ.]*$/)]],
    email: [null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9\_\-\.]+\@[a-zA-Z0-9]{1,100}(\.[a-zA-Z0-9]{1,7}){1,3}$/)]]
  });

  get f() {return this.branchDefaultAccount.controls; }

  onAvatarChange($event) {
    this.selectedAvatar3 = $event.target.files[0] as File;
    if (['image/png', 'image/jpeg'].includes($event.target.files[0].type)) {
      this.openCropModal($event, 'account-avatar');
    } else {
      this.avatarError = true;
      this.businessService.publishEnableEdit$(false);
    }

  }

  checkCroppedAvatar() {
    this.subscriptions.add(this.businessService.croppedAccountPhoto$.subscribe(res => {
      this.businessService.selectedAccountAvatar$ = res.image;
      console.log('this.croppedImg', this.businessService.selectedAccountAvatar$);
      this.imgUrl3 = res.image;
      this.businessService.fileName3$ = res.fileName;
      this.businessService.fileType3$ = res.fileType;
      this.formDirty = true;
      this.businessService.publishEnableEdit$(true);
      this.avatarError = false;
    }));
  }

  openCropModal(avatar, type) {
    const modalRef = this.modalService.open(CropPhotoComponent, {
      centered: true,
      windowClass: `modal-${type}`
    });
    modalRef.componentInstance.imageFile = avatar;
    modalRef.componentInstance.title = 'Select Default Account';
    modalRef.componentInstance.cropType = type;
  }

  patchValue() {
    this.imgUrl3 = this.defaultAccountData.avatarUrl ? this.defaultAccountData.avatarUrl : './assets/images/koala.jpg';
    this.selectedAvatar3 = this.imgUrl3;
    this.branchDefaultAccount.patchValue({
      firstName: this.defaultAccountData.firstName,
      lastName: this.defaultAccountData.lastName,
      email: this.defaultAccountData.email.value
    });
  }

  ngOnInit() {
    this.checkCroppedAvatar();
    if (this.branchData) {
      this.patchValue();
    } else {
      this.imgUrl3 = './assets/images/koala.jpg';
    }
    this.parent.form.addControl(
      'branchDefaultAccount', this.branchDefaultAccount
    );
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BusinessService } from 'src/app/core/services/business.service';

@Component({
  selector: 'app-user-role-permissions',
  templateUrl: './user-role-permissions.component.html',
  styleUrls: ['./user-role-permissions.component.scss']
})
export class UserRolePermissionsComponent implements OnInit, OnDestroy {
  accountPromise: Promise<any>;
  accountId: string;
  accountData: any;
  permission: any;
  roleLevel: any;
  subscriptions = new Subscription();

  constructor(
    public businessService: BusinessService
  ) { }

  getAccountPromise() {
    console.log('get promiseeee', this.businessService.accountData$);
    this.accountPromise = new Promise(async (resolve, reject) => {
      if (this.businessService.accountData$) {
        console.log('promise accountData', this.businessService.accountData$);
        resolve(this.businessService.accountData$);
      } else {
        this.subscriptions.add(this.businessService.loggedInAccount$.subscribe(res => {
          if (res) {
            console.log('promise loggedInAccount', res);
            resolve(res);
          }
        }));
      }
    });
  }

  setAccountItems(res): any {
    this.accountId = res.accountId;
    this.accountData = res.accountData;
    this.permission = res.permission;
    this.roleLevel = res.roleLevel;
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

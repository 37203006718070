import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BusinessService } from 'src/app/core/services/business.service';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';

@Component({
  selector: 'app-industy-single-page',
  templateUrl: './industy-single-page.component.html',
  styleUrls: ['./industy-single-page.component.scss']
})
export class IndustySinglePageComponent implements OnInit, OnDestroy {
  industryData: any;
  imgUrl: any;
  imgAvatar: any;
  imgBanner: any;
  imgBranch: any;
  imgMascot: any;
  dataLists: Array<any>;
  industryId: string;
  subscriptions = new Subscription();

  constructor(
    private businessService: BusinessService,
    private httpAdminService: HttpWebAdminService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal
  ) { }

  getUrlParams() {
    this.subscriptions.add(this.activatedRoute.params.subscribe(params => {
      this.industryId = params['industryId'];
      console.log('this.industryId', this.industryId);
      this.getIndustryData();
    }));
  }

  getIndustryData() {
    this.httpAdminService.get$(`industry/${this.industryId}`).subscribe(
      data => {
        console.log('industry data', data);
        this.industryData = data.data;
        this.imgUrl = this.industryData.iconUrl ? this.industryData.iconUrl : './assets/images/koala.jpg';
        this.imgBanner = this.industryData.profileBannerUrl ? this.industryData.profileBannerUrl : './assets/images/container_img.png';
        this.imgBranch = this.industryData.branchImageUrl ? this.industryData.branchImageUrl : './assets/images/container_img.png';
        this.imgMascot = this.industryData.mascotImageUrl ? this.industryData.mascotImageUrl : './assets/images/container_img.png';
        this.businessService.industryData$ = this.industryData;
      }, error => {
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Error:</b> Something went wrong.`
        });
      }
    );
  }

  removeIndustry() {
    const modalRef = this.modalService.open(ModalConfirmComponent, {
      centered: true
    });
    modalRef.componentInstance.type = 'remove-industry';
    modalRef.componentInstance.adminTitle = 'Remove Industry';
    modalRef.componentInstance.bodyText = `Are you sure you want to remove ${this.industryData.name}?`;
    modalRef.componentInstance.primaryButton = 'Confirm';
    modalRef.componentInstance.industryData = this.industryData;
  }

  editIndustry(id) {
    this.router.navigate([`/settings/industry-setting/industry/${id}/edit`]);
  }

  checkAlert() {
    this.subscriptions.add(this.businessService.alert$.subscribe(
      res => {
        if (res) {
          if (!res.message.includes(`<b>Error:</b> Something went wrong.`)) {
            this.getIndustryData();
          }
        }
      }
    ));
  }

  ngOnInit() {
    this.getUrlParams();
    this.checkAlert();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

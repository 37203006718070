import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent implements OnInit {
  dateToday: any;
  now: any;

  constructor(
    private modalService: NgbModal
  ) {
    setInterval(() => {
      this.now = Date.now();
    }, 1);
   }

  ngOnInit() {
    this.dateToday = Date.now();
  }

}

import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { BusinessService } from 'src/app/core/services/business.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CropPhotoComponent } from 'src/app/indie/crop-photo/crop-photo.component';
import { HttpService } from 'src/app/core/http/http.service';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';

@Component({
  selector: 'app-distributor-add-edit-form-v2',
  templateUrl: './distributor-add-edit-form-v2.component.html',
  styleUrls: ['./distributor-add-edit-form-v2.component.scss']
})
export class DistributorAddEditFormV2Component implements OnInit, OnDestroy {
  title: string;
  imgUrl: any;
  logoUrl: any;
  branchId: string;
  submitting: boolean;
  errorMsg: string;
  distributorId: string;
  distributorData: any;
  selectedAvatar: File;
  selectedLogo: File;
  logoFileName: string;
  enableSubmit: boolean;
  openLeaveModal: boolean;
  croppedImg: any;
  croppedImg2: any;
  fileName: string;
  fileType: string;
  formDirty: boolean;
  avatarError: boolean;
  logoError: boolean;
  subscriptions = new Subscription();
  statusCodeList: Array<any>;
  distributorErrorMsg: string;

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private businessService: BusinessService,
    private httpService: HttpService,
    private httpAdminService: HttpWebAdminService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  distributorForm = this.fb.group({
    name: ['', Validators.required],
    contactPerson: ['', Validators.required],
    email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9\_\-\.]+\@[a-zA-Z0-9]{1,12}(\.[a-zA-Z0-9]{1,7}){1,3}$/)]],
    mobileNo:  ['(+63) 9', [Validators.required, Validators.minLength(7)]],
    websiteUrl: ['', Validators.required]
  });

  get f() {return this.distributorForm.controls; }

  getUrlParams() {
    this.subscriptions.add(this.activatedRoute.params.subscribe(params => {
      this.distributorId = params['distributorId'];
      this.getDistributorData();
    }));
  }

  getDistributorData() {
    this.httpAdminService.get$(`distributor/${this.distributorId}`).subscribe(
      data => {
        console.log('distributor data', data);
        this.distributorData = data;
        if (this.distributorData) {
          this.patchValue();
          this.title = 'Edit Distributor';
          this.imgUrl = this.distributorData.avatarUrl ? this.distributorData.avatarUrl : './assets/images/koala.jpg';
          this.logoUrl = this.distributorData.brandLogoUrl ? this.distributorData.brandLogoUrl : '';
        }
      }, error => {
        console.log('error', error);
      }
    );
  }

  goToDistributorSingle() {
    this.router.navigate([`/distributors/${this.distributorData._id}`]);
  }

  onMobileKeyUp(contact) {
    const defaultVal = '(+63) 9';
    if (contact.value.length <= 3 ||
      contact.value.length === '' ||
      contact.value.substring(0, 7) !== defaultVal) {
      contact.setValue(defaultVal);
    }
  }

  patchMobile(contact) {
    const number1 = contact.substring(0, 3);
    const number2 = contact.substring(3, 6);
    const number3 = contact.substring(6, 11);
    return `(+63) ${number1} - ${number2} - ${number3}`;
  }

  transformMobile(contact) {
    return contact.slice(6, 22).replace(/\D/g, '');
  }

  onAvatarChange($event) {
    this.formDirty = true;
    this.selectedAvatar = $event.target.files[0] as File;
    if (['image/png', 'image/jpeg'].includes($event.target.files[0].type)) {
      this.openCropModal($event, 'avatar-circle', 'Select Distributor Logo');
    } else {
      this.avatarError = true;
    }
  }

  onLogoChange($event) {
    this.formDirty = true;
    this.logoFileName = $event.target.files[0].name;
    this.selectedLogo = $event.target.files[0] as File;
    if (['image/png'].includes($event.target.files[0].type)) {
      this.openCropModal($event, 'logo-rectangle', 'Upload Powered By');
    } else {
      this.logoError = true;
    }
  }

  checkCroppedAvatar() {
    this.subscriptions.add(this.businessService.croppedPhoto$.subscribe(res => {
      this.croppedImg = res.image;
      console.log('this.croppedImg', this.croppedImg);
      this.imgUrl = res.image;
      this.fileName = res.fileName;
      this.fileType = res.fileType;
      this.enableSubmit = true;
      this.avatarError = false;
    }));
  }

  checkCroppedLogo() {
    this.subscriptions.add(this.businessService.croppedBanner$.subscribe(res => {
      this.croppedImg2 = res.image;
      console.log('this.croppedImg', this.croppedImg2);
      this.logoUrl = res.image;
      this.fileName = res.fileName;
      this.fileType = res.fileType;
      this.enableSubmit = true;
      this.logoError = false;
    }));
  }

  openCropModal(avatar, type, title) {
    const modalRef = this.modalService.open(CropPhotoComponent, {
      centered: true,
      windowClass: `modal-${type}`
    });
    modalRef.componentInstance.imageFile = avatar;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.cropType = type;
    console.log('image type', avatar.target.files[0].type);
  }

  resetForm() {
    this.subscriptions.add(this.businessService.resetForm$.subscribe(res => {
      if (res === true) {
        this.distributorForm.reset();
        this.distributorForm.get('contactNo').patchValue('(+63)');
      }
    }));
  }

  patchValue() {
    this.distributorForm.patchValue({
      name: this.distributorData.name,
      contactPerson: this.distributorData.contactPerson,
      email: this.distributorData.email,
      mobileNo: this.distributorData.mobileNo ? this.patchMobile(this.distributorData.mobileNo) : '(+63)',
      websiteUrl: this.distributorData.websiteUrl
    });
    this.logoFileName = this.distributorData.brandLogoUrl;
  }

  cancel() {
    if (this.distributorId) {
      this.router.navigate([`/distributors/${this.distributorId}`]);
    } else {
      this.router.navigate(['/distributors/all']);
    }
  }

  canDeactivate() {
    console.log('canDeactivate');
    if (this.distributorForm.dirty || this.formDirty) {
      const modalRef = this.modalService.open(ModalConfirmComponent, {
        centered: true
      });
      modalRef.componentInstance.title = 'Leave Page';
      modalRef.componentInstance.type = 'leave-page';
      return this.businessService.navigateAwaySelection$;
    } else {
      return true;
    }
  }

  submitForm(f) {
    const body = new FormData();
    console.log(this.croppedImg, this.croppedImg);
    if (this.croppedImg) {
      body.append('avatar', new File([new Blob([this.businessService.dataURItoBlob(this.croppedImg)], {type: this.fileType})], this.fileName, {type: this.fileType}));
      console.log('avatar', new File([new Blob([this.businessService.dataURItoBlob(this.croppedImg)], {type: this.fileType})], this.fileName, {type: this.fileType}));
    }
    if (this.croppedImg2) {
      body.append('logo', new File([new Blob([this.businessService.dataURItoBlob(this.croppedImg2)], {type: this.fileType})], this.fileName, {type: this.fileType}));
      console.log('logo', new File([new Blob([this.businessService.dataURItoBlob(this.croppedImg2)], {type: this.fileType})], this.fileName, {type: this.fileType}));
    }
    console.log('mobile', f.get('mobileNo').value);
    if (f.get('mobileNo').value !== '(+63)') {
      body.append('mobileNo', this.transformMobile(f.get('mobileNo').value));
    }
    for (const key in f.getRawValue()) {
      if (f.getRawValue().hasOwnProperty(key) && key !== 'mobileNo') {
        body.append(key, f.getRawValue()[key]);
        console.log(key, f.getRawValue()[key]);
      }
    }
    this.submitting = true;
    f.disable();
    if (this.distributorData) {
      // if edit
      this.httpService.patch$(`distributor/${this.distributorData._id}`, body).subscribe(
        data => {
          console.log('edited distributor', data);
          this.businessService.publishAlert$({
            type: 'green-dark-01',
            icon: 'fas fa-check',
            message: `<b>Success!</b> ${data.name} has been updated.`
          });
          this.submitting = false;
          f.enable();
          f.reset();
          this.formDirty = false;
          this.router.navigate([`/distributors/${this.distributorId}`]);
        }, error => {
          console.log('error', error);
          this.showError(error);
          this.submitting = false;
          f.enable();
        }
      );
    } else {
      // if new
      this.httpService.post$(`distributor`, body).subscribe(
        data => {
          console.log('added distributor', data);
          this.businessService.publishAlert$({
            type: 'green-dark-01',
            icon: 'fas fa-check',
            message: `<b>New Distributor Added!</b> ${data.name} has been added to the list.`
          });
          this.businessService.publishDataChange$(data);
          this.submitting = false;
          f.enable();
          f.reset();
          this.formDirty = false;
          this.router.navigate([`/distributors/all`]);
        }, error => {
          console.log('error', error);
          this.showError(error);
          this.submitting = false;
          f.enable();
        }
      );
    }
  }

  showError(error) {
    this.statusCodeList = error.error.errors.filter(element => element.msg.statusCode).map(element2 => element2.msg.statusCode);
    console.log('this.statusCodeList', this.statusCodeList);
     if (this.statusCodeList.includes(32451)) {
      this.distributorErrorMsg = 'Distributor already exist.';
    } else {
      this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
      this.businessService.publishAlert$({
        type: 'red-dark-01',
        icon: 'fas fa-ban',
        message: this.errorMsg
      });
    }
  }

  clearBackendError(input) {
    if (input.dirty) {
      this.distributorErrorMsg = null;
      this.errorMsg = null;
    }
  }

  ngOnInit() {
    this.title = 'Add Distributor';
    this.imgUrl = './assets/images/koala.jpg';
    this.logoUrl = '';
    this.logoFileName = '';
    this.checkCroppedAvatar();
    this.checkCroppedLogo();
    this.branchId = localStorage.getItem('branchId');
    this.resetForm();
    this.getUrlParams();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, ControlContainer, FormGroupDirective, Validators } from '@angular/forms';
import * as provinceL from '../../../../../shared/data/province.json';
import * as cityL from '../../../../../shared/data/city.json';

@Component({
  selector: 'app-main-branch-address',
  templateUrl: './main-branch-address.component.html',
  styleUrls: ['./main-branch-address.component.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }]
})
export class MainBranchAddressComponent implements OnInit {
  @Input() branchData: any;
  lat: number;
  lng: number;
  cityL = cityL.RECORDS;
  provinceL = provinceL.RECORDS;
  provinceList: Array<any>;
  cityList: Array<any>;
  cityFiltered: Array<any>;

  constructor(
    private fb: FormBuilder,
    private parent: FormGroupDirective
  ) { }

  address = this.fb.group({
    street: [''],
    province: [null],
    city: [null],
    zipcode: [''],
    longitude: [0],
    latitude: [0]
  });

  get f() { return this.address.controls; }

  patchValue() {
    if (this.branchData.address.province) {
      this.updateProvince(this.branchData.address.province);
    }
    this.address.patchValue({
      street: this.branchData.address.street ? this.branchData.address.street : '',
      province: this.branchData.address.province ? this.branchData.address.province : 'null',
      city: this.branchData.address.city ? this.branchData.address.city : 'null',
      zipcode: this.branchData.address.zipcode ? this.branchData.address.zipcode : '',
      latitude: this.branchData.settings.location.coordinates[1] ? this.branchData.settings.location.coordinates[1] : 0,
      longitude: this.branchData.settings.location.coordinates[0] ? this.branchData.settings.location.coordinates[0] : 0
    });
    this.lat = this.branchData.settings.location.coordinates[1] ? this.branchData.settings.location.coordinates[1] : 0;
    this.lng = this.branchData.settings.location.coordinates[0] ? this.branchData.settings.location.coordinates[0] : 0;
  }

  changeZipCode($event) {
    console.log($event);
    if ($event) {
      if ($event.length > 0) {
        this.f['zipcode'].setValidators([Validators.maxLength(4)]);
        this.f['zipcode'].updateValueAndValidity();
      } else {
        this.f['zipcode'].clearValidators();
        this.f['zipcode'].updateValueAndValidity();
      }
    }
  }

  capitalize(name) {
    return name.toLowerCase().replace(/(^|\s)\S/g,  (firstLetter) => firstLetter.toUpperCase());
  }

  upperCase(name) {
    return name.toUpperCase();
  }

  sortProvince() {
    console.log('province', this.provinceL);
    if (this.provinceL) {
      this.provinceList = this.provinceL.sort((a, b) => {
        return (a.name.localeCompare(b.name));
      }).reduce((acc, element) => {
        if (element.key.includes('MM')) {
          return [element, ...acc];
        }
        return [...acc, element];
      }, []);
    }
  }

  changeProvince($event) {
    console.log($event);
    const selectedProvince = this.provinceList.find(element => element.name === $event);
    if ($event === 'null') {
      this.cityFiltered = [];
      this.address.controls.city.patchValue(null);
      this.f['city'].clearValidators();
      this.f['city'].updateValueAndValidity();
    } else {
      if (selectedProvince) {
        this.cityFiltered = this.cityL.filter(element => {
          return selectedProvince.key === element.province;
        }).sort((a, b) => a.province.localeCompare(b.province));
        this.address.controls.city.patchValue(null);
        this.f['city'].setValidators([Validators.required]);
        this.f['city'].updateValueAndValidity();
      }
    }
  }

  updateProvince($event) {
    console.log($event);
    const selectedProvince = this.provinceList.find(element => element.name === $event);
    if ($event === 'null') {
      this.cityFiltered = [];
      this.address.controls.city.patchValue(null);
      this.f['city'].clearValidators();
      this.f['city'].updateValueAndValidity();
    } else {
      if (selectedProvince) {
        this.cityFiltered = this.cityL.filter(element => {
          return selectedProvince.key === element.province;
        }).sort((a, b) => a.province.localeCompare(b.province));
        console.log('change province', this.address.controls.city.value);
        this.address.controls.city.patchValue(null);
        this.f['city'].setValidators([Validators.required]);
        this.f['city'].updateValueAndValidity();
      }
    }
  }

  changeLongitude($event) {
    console.log($event);
    this.lng = $event;
  }

  changeLatitude($event) {
    console.log($event);
    this.lat = $event;
  }

  ngOnInit() {
    this.parent.form.addControl(
      'address', this.address
    );
    this.sortProvince();
    if (this.branchData) {
      this.patchValue();
    }
  }

}

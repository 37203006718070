import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-password-failed-page',
  templateUrl: './password-failed-page.component.html',
  styleUrls: ['./password-failed-page.component.scss']
})
export class PasswordFailedPageComponent implements OnInit, OnDestroy {
  type: string;
  title: string;
  subscriptions = new Subscription();

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  getUrlParams() {
    this.subscriptions.add(this.activatedRoute.params.subscribe(params => {
      this.type = params['type'];
      this.getType();
    }));
  }

  getType() {
    switch (this.type) {
      case 'FORGOT_PASSWORD' : {
        console.log('forgot password');
        this.title = 'Reset Link Expired';
      }
      break;
      case 'CUSTOMER_PASSWORD' : {
        console.log('customer password');
        this.title = 'Reset Link Expired';
      }
      break;
      case 'VERIFY' : {
        console.log('verify');
        this.title = 'Verification Link Expired';
      }
      break;
      case 'VERIFIED' : {
        console.log('verified');
        this.title = 'Verification Link Expired';
      }
      break;
      case 'CUSTOMER_VERIFY' : {
        console.log('customer verified');
        this.title = 'Verification Link Expired';
      }
      break;
    }
  }

  ngOnInit() {
    this.getUrlParams();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

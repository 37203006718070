import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { BusinessService } from './core/services/business.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'web-admin-v3';
  isPermanentCollapse: boolean;
  isCollapse: boolean;
  subscriptions = new Subscription();

  constructor(
    private businessService: BusinessService,
    public router: Router
  ) {}

  isLoggedIn() {
    if (localStorage.getItem('REFRESH_TOKEN')) {
      return true; 
    }
  }

  ngOnInit() {
    this.subscriptions.add(this.businessService.permanentSideNav$.subscribe(res => {
      this.isPermanentCollapse = res;
    }));
    this.subscriptions.add(this.businessService.sideNav$.subscribe(res => {
      this.isCollapse = res;
    }));
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      } else {
        console.log('changed route', evt instanceof NavigationEnd);
      }
      window.scrollTo(0, 0);
    });
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}

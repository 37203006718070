import { Component, OnInit, OnDestroy } from '@angular/core';
import { BusinessService } from 'src/app/core/services/business.service';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';
import { UserRolePermissionsComponent } from 'src/app/shared/components/user-role-permissions/user-role-permissions.component';
@Component({
  selector: 'app-customer-single-page',
  templateUrl: './customer-single-page.component.html',
  styleUrls: ['./customer-single-page.component.scss']
})
export class CustomerSinglePageComponent extends UserRolePermissionsComponent implements OnInit, OnDestroy {
  customerData: any;
  imgUrl: any;
  imgAvatar: any;
  imgBanner: any;
  imgBranch: any;
  imgMascot: any;
  dataLists: Array<any>;
  customerId: string;
  selected: number;
  selectedList: any;

  constructor(
    businessService: BusinessService,
    private httpAdminService: HttpWebAdminService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal
  ) {
    super(businessService)
  }

  getUrlParams() {
    this.subscriptions.add(this.activatedRoute.params.subscribe(params => {
      this.customerId = params['customerId'];
      console.log('this.customerId', this.customerId);
      this.getCustomerData();
    }));
  }

  getDataList() {
    this.subscriptions.add(this.businessService.dataLists$.subscribe(
      data => {
        console.log('distributor list', data);
        if (data) {
          this.dataLists = data.data;
        }
      }, error => {
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Error:</b> Something went wrong.`
        });
      }
    ));
  }

  getCustomerData() {
    this.httpAdminService.get$(`customer/user/${this.customerId}`).subscribe(
      data => {
        console.log('customer data', data);
        this.customerData = data;
        this.imgUrl = this.customerData.avatarUrl ? this.customerData.avatarUrl : './assets/images/koala.jpg';
      }, error => {
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Error:</b> Something went wrong.`
        });
      }
    );
  }

  resetPassword() {
    const modalRef = this.modalService.open(ModalConfirmComponent, {
      centered: true
    });
    modalRef.componentInstance.type = 'reset-password-customer';
    modalRef.componentInstance.adminTitle = 'Reset Password Customer';
    modalRef.componentInstance.primaryButton = 'Confirm';
    modalRef.componentInstance.customerData = this.customerData;
    if (this.customerData.email.value) {
      modalRef.componentInstance.bodyText = `A reset password link will be sent to <br>
    <b>${this.customerData.email.value}</b>`;
    } else {
      modalRef.componentInstance.bodyText = `A reset password link will be sent to <br>
    <b>(+63)${this.customerData.mobileNo.value}</b>`;
    }
  }

  suspendCustomer() {
    const modalRef = this.modalService.open(ModalConfirmComponent, {
      centered: true
    });
    modalRef.componentInstance.type = 'suspend-customer';
    modalRef.componentInstance.adminTitle = 'Suspend Customer';
    modalRef.componentInstance.bodyText = `Are you sure you want to suspend ${this.customerData.fullName}?`;
    modalRef.componentInstance.primaryButton = 'Confirm';
    modalRef.componentInstance.customerData = this.customerData;
  }

  unsuspendCustomer() {
    this.httpAdminService.patch$(`customer/user/unsuspend`, {accountIds: [this.customerData._id]}).subscribe(
      data => {
        this.businessService.publishAlert$({
          type: 'green-dark-01',
          icon: 'fas fa-check',
          message: `<b>${this.customerData.fullName}</b> has been Unsuspended!`
        });
        this.getCustomerData();
      }, error => {
        console.log('error');
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: error.error.source
        });
      }
    );
  }

  checkDataChange() {
    this.subscriptions.add(this.businessService.dataChange$.subscribe(res => {
      if (res) {
        this.getCustomerData();
      }
    }));
  }

  checkAlert() {
    this.subscriptions.add(this.businessService.alert$.subscribe(
      res => {
        if (res) {
          if (!res.message.includes(`<b>Error:</b> Something went wrong.`)) {
            this.getCustomerData();
          }
        }
      }
    ));
  }

  getAccountData() {
    this.getAccountPromise();
    this.accountPromise
    .then((data) => {      
      console.log('promise account', data);
      this.setAccountItems(data);
      // Insert all functions related to Account Data
      // ----- End ----- //
    })
    .catch((error) => {
      console.log('promise err', error);
    });
  }

  ngOnInit() {
    // Needed in User Role
    this.getAccountData();
    // --------- //
    this.getDataList();
    this.checkDataChange();
    this.getUrlParams();
    this.getCustomerData();
    this.checkAlert();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

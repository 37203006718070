import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';
import { UserRolePermissionsComponent } from 'src/app/shared/components/user-role-permissions/user-role-permissions.component';
import { BusinessService } from 'src/app/core/services/business.service';

@Component({
  selector: 'app-template-single-page',
  templateUrl: './template-single-page.component.html',
  styleUrls: ['./template-single-page.component.scss']
})
export class TemplateSinglePageComponent extends UserRolePermissionsComponent implements OnInit, OnDestroy {
  templateData: any;
  templateId: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private httpAdminService: HttpWebAdminService,
    private modalService: NgbModal,
    businessService: BusinessService
  ) {
    super(businessService)
  }

  getParams() {
    this.subscriptions.add(this.route.params.subscribe(
      params => {
        this.templateId = params['templateId'];
        console.log('template id', this.templateId);
        this.getTemplateData();
      }
    ));
  }

  editTemplate() {
    this.router.navigate([`/settings/custom-brand-setting/edit/${this.templateId}`]);
  }

  getTemplateData() {
    this.httpAdminService.get$(`custom-brand-templates/${this.templateId}`).subscribe(
      data => {
        console.log('template data', data);
        this.templateData = data.result;
      }, error => {
        console.log('error', error);
      }
    );
  }

  removeTemplate() {
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(ModalConfirmComponent, {
      centered: true
    });
    modalRef.componentInstance.templateData = this.templateData;
    modalRef.componentInstance.adminTitle = 'Remove Template';
    modalRef.componentInstance.type = 'remove-template';
    modalRef.componentInstance.bodyText = `Are you sure you want to remove this template?`;
    modalRef.componentInstance.primaryButton = 'Confirm';
  }

  getAccountData() {
    this.getAccountPromise();
    this.accountPromise
    .then((data) => {      
      console.log('promise account', data);
      this.setAccountItems(data);
      // Insert all functions related to Account Data
      // ----- End ----- //
    })
    .catch((error) => {
      console.log('promise err', error);
    });
  }

  ngOnInit() {
    // Needed in User Role
    this.getAccountData();
    // --------- //
    this.getParams();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

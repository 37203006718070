import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BusinessService } from 'src/app/core/services/business.service';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';

@Component({
  selector: 'app-template-add-page',
  templateUrl: './template-add-page.component.html',
  styleUrls: ['./template-add-page.component.scss']
})
export class TemplateAddPageComponent implements OnInit {
  templateForm: FormGroup;
  templateData: any;
  templateId: string;
  addEditButton: string;
  submitting: boolean;
  enableEdit: boolean;
  subscriptions = new Subscription();

  constructor(
    private fb: FormBuilder,
    private businessService: BusinessService,
    private httpAdminService: HttpWebAdminService,
    private route: ActivatedRoute,
    public router: Router,
    private modalService: NgbModal
  ) { }

  createForm() {
    this.templateForm = this.fb.group({});
  }

  getParams() {
    this.subscriptions.add(this.route.params.subscribe(
      params => {
        this.templateId = params['templateId'];
        console.log('template id', this.templateId);
        this.getTemplateData();
      }
    ));
  }

  getTemplateData() {
    this.httpAdminService.get$(`custom-brand-templates/${this.templateId}`).subscribe(
      data => {
        console.log('template data', data);
        this.templateData = data.result;
      }, error => {
        console.log('error', error);
      }
    );
  }

  submitForm(f) {
    this.submitting = true;
    const body = new FormData();
    const { nameColor, loginImage, favicon, sidenavLogo, ...form } = f.value;
    const newForm = {
      ...form,
      name: nameColor.name,
      colorTheme: {
        primary: nameColor.primary,
        secondary: nameColor.secondary,
        primaryText: nameColor.primaryText,
        secondaryText: nameColor.secondaryText
      }
    };
    console.log('submitForm', newForm);
    // Selected Login Image
    if (this.businessService.selectedLoginImage$) {
      console.log('this.businessService.selectedLoginImage$', this.businessService.selectedLoginImage$);
      body.append('loginPageImage', new File([new Blob([this.businessService.dataURItoBlob(this.businessService.selectedLoginImage$.image)], {type: this.businessService.selectedLoginImage$.fileType})], this.businessService.selectedLoginImage$.fileName, {type: this.businessService.selectedLoginImage$.fileType}));
    }
    // Selected Sidenav Logo
    if (this.businessService.selectedSidenavLogo$) {
      console.log('this.businessService.selectedSidenavLogo$', this.businessService.selectedSidenavLogo$);
      body.append('sideNavigationImage', new File([new Blob([this.businessService.dataURItoBlob(this.businessService.selectedSidenavLogo$.image)], {type: this.businessService.selectedSidenavLogo$.fileType})], this.businessService.selectedSidenavLogo$.fileName, {type: this.businessService.selectedSidenavLogo$.fileType}));
    }
     // Selected Favicon
     if (this.businessService.selectedFavicon$) {
      console.log('this.businessService.selectedFavicon$', this.businessService.selectedSidenavLogo$);
      body.append('faviconImage', this.businessService.selectedFavicon$);
    }
    body.append('data', JSON.stringify(newForm));
    console.log('stringify', JSON.stringify(newForm));
    // new
    if (!this.templateData) {
      this.httpAdminService.post$(`custom-brand-templates`, body).subscribe(
        data => {
          console.log('added new template', data);
          this.businessService.publishAlert$({
            type: 'green-dark-01',
            icon: 'fas fa-check',
            message: `<b>Success!</b> — ${data.result.name} has been added.`
          });
          this.businessService.publishDataChange$(data);
          this.submitting = false;
          f.enable();
          f.reset();
          this.enableEdit = false;
          this.router.navigate([`/settings/custom-brand-setting/custom-list`]);
        }, error => {
          console.log('error', error);
          this.submitting = false;
          this.businessService.publishAlert$({
            type: 'red-dark-01',
            icon: 'fas fa-ban',
            message: `<b>Error:</b> Something went wrong.`
          });
        }
      );
    // edit
    } else {
      this.httpAdminService.patch$(`custom-brand-templates/${this.templateData._id}`, body).subscribe(
        data => {
          console.log('edited template', data);
          this.businessService.publishAlert$({
            type: 'green-dark-01',
            icon: 'fas fa-check',
            message: `<b>Success!</b> — ${data.result.name} has been updated.`
          });
          this.businessService.publishDataChange$(data);
          this.submitting = false;
          f.enable();
          f.reset();
          this.enableEdit = false;
          this.router.navigate([`/settings/custom-brand-setting/custom-list`]);
        }, error => {
          console.log('error', error);
          this.submitting = false;
          this.businessService.publishAlert$({
            type: 'red-dark-01',
            icon: 'fas fa-ban',
            message: `<b>Error:</b> Something went wrong.`
          });
        }
      );
    }
  }

  cancel() {
    this.router.navigate([`/settings/custom-brand-setting/custom-list`]);
  }

  canDeactivate() {
    console.log('canDeactivate');
    if (this.templateForm.dirty || this.enableEdit) {
      const modalRef = this.modalService.open(ModalConfirmComponent, {
        centered: true
      });
      modalRef.componentInstance.title = 'Leave Page';
      modalRef.componentInstance.type = 'leave-page';
      return this.businessService.navigateAwaySelection$;
    } else {
      return true;
    }
  }

  checkEnableEdit() {
    console.log('checkEnableEdit');
    this.subscriptions.add(this.businessService.enableEdit$.subscribe(
      res => {
        this.enableEdit = res;
      }
    ));
  }

  ngOnInit() {
    this.enableEdit = true;
    this.checkEnableEdit();
    if (this.router.url.includes('edit')) {
      this.addEditButton = 'Edit Template';
      this.getParams();
    } else {
      this.addEditButton = 'Add Template';
    }
    this.createForm();
  }

}

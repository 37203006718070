import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective, FormBuilder, FormArray } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { BusinessService } from 'src/app/core/services/business.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-main-branch-operation-hours',
  templateUrl: './main-branch-operation-hours.component.html',
  styleUrls: ['./main-branch-operation-hours.component.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }],
  providers: [DatePipe]
})
export class MainBranchOperationHoursComponent implements OnInit {
  @Input() branchData: any;
  days = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  not24hours: boolean;
  selectedOne: boolean;
  selectedDays: Array<string>;
  time: any;
  meridian = true;
  timeIndex: number;
  timeType: string;

  constructor(
    private fb: FormBuilder,
    private parent: FormGroupDirective,
    private businessService: BusinessService,
    private modalService: NgbModal
  ) { }

  branchHours = this.fb.group({
    time: [null],
    isWeeklyOpened: [false],
    selectedAll: [true],
    operationHours: this.fb.array(
      this.createDays()
    )
  });

  get f() { return this.branchHours.controls; }
  get operationHours(): any { return this.branchHours.get('operationHours') as FormArray; }

  createDays() {
    const date = new Date();
    const m = date.getMonth();
    const d = date.getDay();
    const y = date.getFullYear();
    console.log(`Date.now()`, new Date(y, m, d, 9, 0, 0));
    const form = [];
    for (let i = 0; i <= 6; i++) {
      form.push(this.fb.group({
        openingTime: [new Date(y, m, d, 9, 0, 0).getTime()],
        closingTime: [new Date(y, m, d, 18, 0, 0).getTime()],
        // openingTime: [0],
        // closingTime: [0],
        enabled: [true],
        isWholeDay: [false],
        day: i,
        _id: undefined
      }));
    }
    return form;
  }

  patchValue() {
    console.log(this.branchHours.controls.isWeeklyOpened);
    this.branchHours.patchValue({
      isWeeklyOpened: this.branchData.settings.isWeeklyOpened
    });
    this.changeEveryday(this.branchData.settings.isWeeklyOpened);
    this.branchData.settings.operationHours.forEach((element, i) => {
      this.operationHours.controls[i].controls.openingTime.patchValue(element.openingTime);
      this.operationHours.controls[i].controls.closingTime.patchValue(element.closingTime);
      this.operationHours.controls[i].controls.enabled.patchValue(element.enabled);
      this.operationHours.controls[i].controls.isWholeDay.patchValue(element.isWholeDay);
      this.operationHours.controls[i].controls._id.patchValue(element._id);
      this.checkIfSelected(element.enabled, i);
      this.change24hours(element.isWholeDay, i);
    });
  }

  checkIfSelected($event, i) {
    // enable disable Opening, Closing Time and 24 hours
    if ($event === false) {
      this.operationHours.controls[i].controls['openingTime'].disable();
      this.operationHours.controls[i].controls['closingTime'].disable();
      this.operationHours.controls[i].controls['isWholeDay'].disable();
    } else {
      this.operationHours.controls[i].controls['openingTime'].enable();
      this.operationHours.controls[i].controls['closingTime'].enable();
      this.operationHours.controls[i].controls['isWholeDay'].enable();
    }
    const selected = this.operationHours.controls.find(element1 => {
      return element1.value === true;
    });
    if (selected) {
      selected.value ? this.selectedOne = true : this.selectedOne = false;
    }
    this.addSelected();
    this.operationHours.controls.length === 0 ? this.selectedOne = false : this.selectedOne = true;
    console.log($event, i);
  }

  selectAll(selectedAll) {
    console.log('selectedAll', selectedAll);
    if (selectedAll === true) {
      if ((this.operationHours.controls.length < this.days.length) && this.operationHours.controls.length !== 0) {
        console.log('yes');
        this.operationHours.controls.forEach(element1 => {
          element1.controls['enabled'].setValue(false);
        });
        this.f['selectedAll'].setValue(false);
        this.selectedOne = false;
      } else {
        this.operationHours.controls.forEach(element1 => {
          element1.controls['enabled'].setValue(true);
        });
        this.selectedOne = false;
      }
    } else {
      this.operationHours.controls.forEach(element2 => {
        element2.controls['enabled'].setValue(false);
      });
      this.selectedOne = false;
    }
  }

  addSelected() {
    this.selectedDays = this.operationHours.getRawValue()
      .map((element4, index) => element4.isEnabled === true ? index : null)
      .filter(element5 => element5 !== null);
    console.log('selectedDays', this.selectedDays);
  }

  changeEveryday($event) {
    $event === true ? this.not24hours = false : this.not24hours = true;
  }

  change24hours($event, i) {
    console.log($event, i);
    if ($event === true) {
      this.operationHours.controls[i].controls.openingTime.disable();
      this.operationHours.controls[i].controls.closingTime.disable();
    } else {
      this.operationHours.controls[i].controls.openingTime.enable();
      this.operationHours.controls[i].controls.closingTime.enable();
    }
  }

  changeTime($event, timePicker, timeValue, index, type) {
    console.log(timeValue, index, type);
    $event.srcElement.blur();
    this.modalService.open(timePicker, {
      centered: true,
      size: 'sm',
      windowClass: 'width-200'
    });
    this.timeIndex = index;
    this.f['time'].setValue(this.businessService.jsonTime(timeValue));
    console.log('time', this.f['time'].value);
    this.timeType = type;
  }

  setTime(time, type) {
    const transformedTime = this.businessService.parseTime(time);
    this.operationHours.controls[this.timeIndex].controls[type].setValue(transformedTime);
  }

  toggleMeridian() {
    this.meridian = !this.meridian;
  }

  ngOnInit() {
    this.not24hours = true;
    if (this.branchData) {
      this.patchValue();
    }
    this.parent.form.addControl(
      'branchHours', this.branchHours
    );
  }

}

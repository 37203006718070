import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessService } from 'src/app/core/services/business.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';
import { UserRolePermissionsComponent } from 'src/app/shared/components/user-role-permissions/user-role-permissions.component';

@Component({
  selector: 'app-messages-single-page',
  templateUrl: './messages-single-page.component.html',
  styleUrls: ['./messages-single-page.component.scss']
})
export class MessagesSinglePageComponent extends UserRolePermissionsComponent implements OnInit, OnDestroy {
  messagesData: any;
  messagesId: string;
  submitting: boolean;

  constructor(
    private httpAdminService: HttpWebAdminService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    businessService: BusinessService,
    private modalService: NgbModal
  ) {
    super(businessService)
  }

  getUrlParams() {
    this.subscriptions.add(this.activatedRoute.params.subscribe(params => {
      this.messagesId = params['messagesId'];
      this.getMessageData();
    }));
  }

  getMessageData() {
    this.httpAdminService.get$(`notification/messages/${this.messagesId}`).subscribe(
      data => {
        console.log('messages data', data);
        if (data) {
          this.messagesData = data;
        }
      }, error => {
        console.log('error', error);
        this.router.navigate([`/announcements/messages`]);
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Error:</b> Something went wrong.`
        });
      }
    );
  }

  editMessage() {
    this.router.navigate([`announcements/messages/${this.messagesData._id}/edit`]);
  }

  discardMessage() {
    const modalRef = this.modalService.open(ModalConfirmComponent, {
      centered: true
    });
    modalRef.componentInstance.type = 'suspend-admin';
    modalRef.componentInstance.adminTitle = 'Discard Draft';
    modalRef.componentInstance.bodyText = `Are you sure you want to discard this draft?
    You can't undo this action.`;
    modalRef.componentInstance.primaryButton = 'Confirm';
    modalRef.componentInstance.messageId = this.messagesData._id;
  }

  sendNow() {
    this.submitting = true;
    this.httpAdminService.patch$(`notification/messages/send/${this.messagesData._id}`, '').subscribe(
      data => {
        console.log('sent draft', data);
        this.modalService.dismissAll();
        this.businessService.publishAlert$({
          type: 'green-dark-01',
          icon: 'fas fa-check',
          message: `<b>Sent</b> “${data.title}” has been sent`
        });
        this.router.navigate([`/announcements/messages`]);
        this.submitting = false;
      }, error => {
        console.log('error', error);
        const errorsList = error.error.errors.map(element => {
          return element.param;
        });
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `Invalid ${errorsList.toString().split(',').join(', ')}`
        });
        this.submitting = false;
      }
    );
  }

  getAccountData() {
    this.getAccountPromise();
    this.accountPromise
    .then((data) => {      
      console.log('promise account', data);
      this.setAccountItems(data);
      // Insert all functions related to Account Data
      // ----- End ----- //
    })
    .catch((error) => {
      console.log('promise err', error);
    });
  }

  ngOnInit() {
    // Needed in User Role
    this.getAccountData();
    // --------- //
    this.getUrlParams();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

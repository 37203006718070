import { Component, OnInit, HostListener, OnDestroy, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';
import { Subscription } from 'rxjs';
import { BusinessService } from 'src/app/core/services/business.service';
import { HttpService } from 'src/app/core/http/http.service';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';

@Component({
  selector: 'app-view-device-modal',
  templateUrl: './view-device-modal.component.html',
  styleUrls: ['./view-device-modal.component.scss']
})
export class ViewDeviceModalComponent implements OnInit {
  title: any;
  deviceId: any;
  deviceData: any;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private businessService: BusinessService,
    private httpService: HttpService,
    private httpAdminService: HttpWebAdminService
  ) { }

  getDeviceData() {
    this.httpAdminService.get$(`devices/${this.deviceId}`).subscribe(
      data => {
        console.log('device data', data);
        this.deviceData = data;
      }, error => {
        console.log('error', error);
      }
    );
  }

  unassignedDevice() {
    this.activeModal.dismiss();
    const modalRef = this.modalService.open(ModalConfirmComponent, {
      centered: true
    });
    modalRef.componentInstance.type = 'unassigned-device';
    modalRef.componentInstance.adminTitle = 'Unassigned Device';
    modalRef.componentInstance.bodyText = `Are you sure you want to unassign ${this.deviceData.refId}?`;
    modalRef.componentInstance.primaryButton = 'Confirm';
    modalRef.componentInstance.deviceData = this.deviceData;
  }

  ngOnInit() {
    this.getDeviceData();
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Crop Photo
import { FormsModule } from '@angular/forms';
import { CropPhotoComponent } from './crop-photo/crop-photo.component';
import {
  LyThemeModule,
  LY_THEME
} from '@alyle/ui';

import { LyResizingCroppingImageModule } from '@alyle/ui/resizing-cropping-images';
import { LyButtonModule } from '@alyle/ui/button';
import { LyIconModule } from '@alyle/ui/icon';
import { LyTypographyModule } from '@alyle/ui/typography';

/** Import theme */
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';

@NgModule({
  declarations: [
    CropPhotoComponent
  ],
  imports: [
    CommonModule,
    LyThemeModule.setTheme('minima-dark'), // or minima-light
    LyResizingCroppingImageModule,
    LyButtonModule,
    LyIconModule,
    LyTypographyModule,
    FormsModule
  ],
  exports: [
    CropPhotoComponent
  ],
  providers: [
    { provide: LY_THEME, useClass: MinimaLight, multi: true },
    { provide: LY_THEME, useClass: MinimaDark, multi: true }
  ],
  entryComponents: [
    CropPhotoComponent
  ]
})
export class IndieModule {
  static forRoot() {
    return {
        ngModule: IndieModule,
        providers: [],
    };
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';
import { CropPhotoComponent } from 'src/app/indie/crop-photo/crop-photo.component';
import { BusinessService } from 'src/app/core/services/business.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-business-partners-add-edit-form',
  templateUrl: './business-partners-add-edit-form.component.html',
  styleUrls: ['./business-partners-add-edit-form.component.scss']
})
export class BusinessPartnersAddEditFormComponent implements OnInit {
  title: string;
  industryLists: Array<any>;
  categoryLists: Array<any>;
  imgUrl: any;
  partnerData: any;
  selectedAvatar: any;
  submitting: boolean;
  errorMsg: string;
  partnerErrorMsg: string;
  enableSubmit: boolean;
  avatarError: boolean;
  croppedImg: any;
  fileName: string;
  fileType: string;
  statusCodeList: Array<any>;
  subscriptions = new Subscription();

  constructor(
    private fb: FormBuilder,
    private httpAdminService: HttpWebAdminService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private businessService: BusinessService
  ) { }

  partnerForm = this.fb.group({
    name: [null, Validators.required],
    industryId: [null, Validators.required],
    categoryId: [null, Validators.required]
  });

  get f() {return this.partnerForm.controls; }

  getIndustryList() {
    this.httpAdminService.get$(`industry`).subscribe(
      data => {
        console.log('industry list', data);
        this.industryLists = data.data;
        if (this.partnerData) {
          this.patchValue();
          this.imgUrl = this.partnerData.avatarUrl ? this.partnerData.avatarUrl : './assets/images/koala.jpg';
        } else {
          this.imgUrl = './assets/images/koala.jpg';
        }
      }, error => {
        console.log('error', error);
        // this.errorMsg = `<b>Error:</b> Something went wrong.`;
      }
    );
  }

  onAvatarChange($event) {
    this.selectedAvatar = $event.target.files[0] as File;
    if (['image/png', 'image/jpeg'].includes($event.target.files[0].type)) {
      this.openCropModal($event, 'avatar-circle');
    } else {
      this.avatarError = true;
    }
  }

  checkCroppedAvatar() {
    this.subscriptions.add(this.businessService.croppedPhoto$.subscribe(res => {
      this.croppedImg = res.image;
      console.log('this.croppedImg', this.croppedImg);
      this.imgUrl = res.image;
      this.fileName = res.fileName;
      this.fileType = res.fileType;
      this.enableSubmit = true;
      this.avatarError = false;
    }));
  }

  openCropModal(avatar, type) {
    const modalRef = this.modalService.open(CropPhotoComponent, {
      centered: true,
      windowClass: `modal-${type}`
    });
    modalRef.componentInstance.imageFile = avatar;
    modalRef.componentInstance.title = 'Select Business Logo';
    modalRef.componentInstance.cropType = type;
  }

  changeIndustry($event) {
    console.log($event);
    const filtered = this.industryLists.find(element => {
      return element.id === $event;
    });
    this.categoryLists = filtered.categoryList;
    console.log('categoryLists', this.categoryLists);
    this.f['categoryId'].patchValue(this.categoryLists[0]._id);
  }

  patchValue() {
    this.changeIndustry(this.partnerData.industryId);
    this.partnerForm.patchValue({
      name: this.partnerData.name,
      industryId: this.partnerData.industryId,
      categoryId: this.partnerData.categoryId
    });
  }

  closeModal() {

  }

  submitForm(f) {
    const body = new FormData();
    if (this.croppedImg) {
      body.append('avatar', new File([new Blob([this.businessService.dataURItoBlob(this.croppedImg)], {type: this.fileType})], this.fileName, {type: this.fileType}));
      console.log('avatar', new File([new Blob([this.businessService.dataURItoBlob(this.croppedImg)], {type: this.fileType})], this.fileName, {type: this.fileType}));
    }
    for (const key in f.getRawValue()) {
      if (f.getRawValue().hasOwnProperty(key)) {
        body.append(key, f.getRawValue()[key]);
        console.log(key, f.getRawValue()[key]);
      }
    }
    console.log('body', body);
    this.submitting = true;
    f.disable();
    if (this.partnerData) {
      // if edit
      this.httpAdminService.patch$(`partners/${this.partnerData._id}`, body).subscribe(
        data => {
          this.activeModal.dismiss();
          this.businessService.publishAlert$({
            type: 'green-dark-01',
            icon: 'fas fa-check',
            message: `<b>Success!</b> — ${data.data.name} has been updated.`
          });
          this.submitting = false;
          f.enable();
        }, error => {
          console.log('error', error);
          this.showError(error);
          this.submitting = false;
          f.enable();
        }
      );
    } else {
      // if add
      this.httpAdminService.post$(`partners`, body).subscribe(
        data => {
          console.log('added', data);
          this.activeModal.dismiss();
          const modalRef = this.modalService.open(ModalConfirmComponent, {
            centered: true
          });
          modalRef.componentInstance.type = 'success-admin';
          modalRef.componentInstance.title = `New Business Partner Added!`;
          modalRef.componentInstance.bodyText = `${data.data.name} has been added to the business partners list. <br>
          Add a branch now or skip.`;
          modalRef.componentInstance.primaryButton = 'Add Branch Now';
          modalRef.componentInstance.partnerId = data.data._id;
          this.businessService.publishDataChange$(data);
          this.submitting = false;
          f.enable();
        }, error => {
          console.log('error', error);
          this.showError(error);
          this.submitting = false;
          f.enable();
        }
      );
    }
  }

  showError(error) {
    this.statusCodeList = error.error.errors.filter(element => element.msg.statusCode).map(element2 => element2.msg.statusCode);
    console.log('this.statusCodeList', this.statusCodeList);
    if (this.statusCodeList.includes(13020)) {
      this.partnerErrorMsg = 'Business Partner already exist.';
    } else {
      this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
    }
  }

  clearBackendError(input) {
    if (input.dirty) {
      this.partnerErrorMsg = null;
      this.errorMsg = null;
    }
  }

  ngOnInit() {
    this.checkCroppedAvatar();
    this.getIndustryList();
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective, FormBuilder } from '@angular/forms';
import { BusinessService } from 'src/app/core/services/business.service';

@Component({
  selector: 'app-template-options-form',
  templateUrl: './template-options-form.component.html',
  styleUrls: ['./template-options-form.component.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }]
})
export class TemplateOptionsFormComponent implements OnInit {
  @Input() templateData: any;

  constructor(
    private fb: FormBuilder,
    private parent: FormGroupDirective,
    private businessService: BusinessService
  ) { }

  get f() { return this.distributorForm.controls; }

  distributorForm = this.fb.group({
    status: [false],
    showOnLoginPage: [false],
    showOnTVDisplay: [false],
    allowCustomEmailDetails: [false]
  });

  patchValue() {
    
    this.distributorForm.patchValue({
      status: this.templateData.distributor.status,
      showOnLoginPage: this.templateData.distributor.showOnLoginPage,
      showOnTVDisplay: this.templateData.distributor.showOnTVDisplay,
      allowCustomEmailDetails: this.templateData.distributor.allowCustomEmailDetails
    });
    this.getDistributor(this.templateData.distributor.status);
    this.businessService.publishDistributorLogin$(this.templateData.distributor.showOnLoginPage);
    this.businessService.publishDistributorTV$(this.templateData.distributor.showOnTVDisplay);
    this.businessService.publishDistributorData$(this.templateData.distributor.status);
  }

  getDistributor($event) {
    this.businessService.publishDistributorData$($event);
    if ($event === true) {
      this.f['showOnLoginPage'].enable();
      this.f['showOnTVDisplay'].enable();
      this.f['allowCustomEmailDetails'].enable();
    } else {
      this.f['showOnLoginPage'].disable();
      this.f['showOnTVDisplay'].disable();
      this.f['allowCustomEmailDetails'].disable();
    }
  }

  changeLogin($event){
    this.businessService.publishDistributorLogin$($event);
  }

  changeTV($event){
    this.businessService.publishDistributorTV$($event);
  }

  ngOnInit() {
    this.parent.form.addControl(
      'distributor', this.distributorForm
    );
    if (this.templateData) {
      this.patchValue();
    } else {
      this.getDistributor(false);
    }
  }

}

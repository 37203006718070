import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessService } from 'src/app/core/services/business.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';
import { UserRolePermissionsComponent } from 'src/app/shared/components/user-role-permissions/user-role-permissions.component';

@Component({
  selector: 'app-news-single-page',
  templateUrl: './news-single-page.component.html',
  styleUrls: ['./news-single-page.component.scss']
})
export class NewsSinglePageComponent extends UserRolePermissionsComponent implements OnInit, OnDestroy {
  newsData: any;
  newsId: string;
  submitting: boolean;
  @ViewChild('iframe', {static: true}) iframe: ElementRef;
  iframeHeight: any;
  partnersList: Array<any>;

  constructor(
    private httpAdminService: HttpWebAdminService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    businessService: BusinessService,
    private modalService: NgbModal
  ) {
    super(businessService)
  }

  getUrlParams() {
    this.subscriptions.add(this.activatedRoute.params.subscribe(params => {
      this.newsId = params['newsId'];
      this.getNewsData();
    }));
  }

  getNewsData() {
    this.httpAdminService.get$(`notification/news/${this.newsId}`).subscribe(
      data => {
        console.log('news data', data);
        if (data) {
          this.newsData = data;
        }
      }, error => {
        console.log('error', error);
        this.router.navigate([`/announcements/news`]);
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Error:</b> Something went wrong.`
        });
      }
    );
  }

  getPartnersList() {
    this.httpAdminService.get$(`partners`).subscribe(
      data => {
        console.log('partners list', data);
        if (data) {
          this.partnersList = data.data;
        }
      }, error => {
        console.log('error', error);
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Error:</b> Something went wrong.`
        });
      }
    );
  }

  getPartnerName(id) {
    let name;
    if (this.partnersList) {
      this.partnersList.forEach(element => {
        if (element.id === id) {
          name = element.name;
        }
      });
    }
    return name;
  }

  getPartnerAvatar(id) {
    let avatar;
    if (this.partnersList) {
      this.partnersList.forEach(element => {
        if (element.id === id) {
          avatar = element.avatarUrl ? element.avatarUrl : './assets/images/koala.jpg';
        }
      });
    }
    return avatar;
  }

  deleteNews() {
    const modalRef = this.modalService.open(ModalConfirmComponent, {
      centered: true
    });
    modalRef.componentInstance.type = 'suspend-admin';
    modalRef.componentInstance.adminTitle = 'Delete News';
    modalRef.componentInstance.bodyText = `Are you sure you want to delete this news?
    You can't undo this action.`;
    modalRef.componentInstance.primaryButton = 'Confirm';
    modalRef.componentInstance.newsId = this.newsData._id;
  }

  sendNow() {

  }

  editNews() {
    this.router.navigate([`announcements/news/${this.newsData._id}/edit`]);
  }

  getAccountData() {
    this.getAccountPromise();
    this.accountPromise
    .then((data) => {      
      console.log('promise account', data);
      this.setAccountItems(data);
      // Insert all functions related to Account Data
      // ----- End ----- //
    })
    .catch((error) => {
      console.log('promise err', error);
    });
  }

  ngOnInit() {
    // Needed in User Role
    this.getAccountData();
    // --------- //
    this.getUrlParams();
    this.getPartnersList();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const HOST = "https://devapi.kyoo.com"
export const environment = {
  production: false,
  urlAPI: 'https://devapi.kyoo.com/api/v3/web-admin', // 'https://devapi.kyoo.com/api/v3/business-portal',
  webAdminAPI: 'https://devapi.kyoo.com/api/v3/web-admin',
  businessPortalUrl: 'https://dev-biz.kyoo.com', // 'http://10.5.36.138:5300'
  customerAppAPI: 'https://devapi.kyoo.com/api/v3/customer-app',
  socketAPI: 'https://devapi.kyoo.com',
  socketPath: '/api/v3/socket.io'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

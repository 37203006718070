import { Component, OnInit, OnDestroy } from '@angular/core';
@Component({
  selector: 'app-accounts-page',
  templateUrl: './accounts-page.component.html',
  styleUrls: ['./accounts-page.component.scss']
})
export class AccountsPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {  }

}

import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { BusinessService } from 'src/app/core/services/business.service';

@Component({
  selector: 'app-logout-page',
  templateUrl: './logout-page.component.html',
  styleUrls: ['./logout-page.component.scss']
})
export class LogoutPageComponent implements OnInit {
  jwt: string;
  ref: string;
  accountId: string;
  subscriptions = new Subscription();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private businessService: BusinessService
  ) { }

  getUrlParams() {
    this.subscriptions.add(this.activatedRoute.queryParams.subscribe(params => {
      this.jwt = params['jwt'];
      this.ref = params['ref'];
      this.accountId = params['accountId'];
      if (this.accountId && this.jwt && this.ref) {
        console.log('jwt', this.accountId, this.jwt, this.ref);
        localStorage.setItem('JWT_TOKEN', this.jwt);
        localStorage.setItem('REFRESH_TOKEN', this.ref);
        localStorage.setItem('accountId', this.accountId);
        this.logout();
      }
    }));
  }

  logout() {
    console.log('confirm logout');
    this.authService.logout(this.accountId).subscribe(
      res => {
      if (res.success === true) {
        this.router.navigate(['/login']);
        this.businessService.accountData$ = null;
        console.log('success logout');
      }
    }, error => {
      console.log('error', error);
    });
  }

  ngOnInit() {
    this.getUrlParams();
  }

}

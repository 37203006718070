import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { BusinessService } from './business.service';

@Injectable({
  providedIn: 'root'
})
export class QueueService {
  counter: number;
  submitting: boolean;

  constructor(
    private httpService: HttpService,
    private businessService: BusinessService
  ) { }

  serveQueue(queue) {
    return this.httpService.patch$(`queues/${queue.branchId}/${queue.queueGroupId}/${queue._id}/serve`, '').subscribe(
      data => {
        console.log('served', data);
        console.log('moved', queue);
        this.businessService.publishAlert$({
          type: 'green-dark-01',
          icon: 'fas fa-check',
          message: `<b>Served!</b> — ${queue.queueNo} has been transferred to the current serving list.`
        });
      }, error => {
        console.log('error', error);
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Error!</b> — ${queue.queueNo} cannot be serve. ${error.error.source} Please try again.`
        });
      }
    );
  }

  returnQueue(queue) {
    return this.httpService.patch$(`queues/${queue.branchId}/${queue.queueGroupId}/${queue._id}/return`, '').subscribe(
      data => {
        console.log('return', data);
        this.businessService.publishAlert$({
          type: 'secondary',
          icon: 'fas fa-sync-alt',
          message: `<b>Returned!</b> — ${queue.queueNo} has been returned to the on-going queue list.`
        });
        this.businessService.publishConfirmReturnQueue$(data);
      }, error => {
        console.log('error', error);
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Error!</b> — ${queue.queueNo} cannot be returned. Please try again.`
        });
      }
    );
  }

  removeQueue(queue) {
    this.httpService.patch$(`queues/${queue.branchId}/${queue.queueGroupId}/${queue._id}/remove`, '').subscribe(
      data => {
        console.log('done', data);
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Remove!</b> — ${queue.queueNo} has been removed`
        });
      }, error => {
        console.log('error', error);
        console.log('error', error);
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Error!</b> — ${queue.queueNo} cannot be removed. Please try again.`
        });
      }
    );
  }

  doneQueue(queue, body) {
    this.httpService.patch$(`queues/${queue.branchId}/${queue.queueGroupId}/${queue._id}/done`, body).subscribe(
      data => {
        console.log('done', data);
        this.businessService.publishAlert$({
          type: 'green-dark-01',
          icon: 'fas fa-check',
          message: `<b>Done!</b> — ${queue.queueNo} has been completed.`
        });
      }, error => {
        console.log('error', error);
        console.log('error', error);
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Error!</b> — ${queue.queueNo} cannot be completed. Please try again.`
        });
      }
    );
  }

  skipQueue(queue) {
    return this.httpService.patch$(`queues/${queue.branchId}/${queue.queueGroupId}/${queue._id}/skip`, '').subscribe(
      data => {
        console.log('return', data);
        this.businessService.publishAlert$({
          type: 'secondary',
          icon: 'fas fa-sync-alt',
          message: `<b>Skipped!</b> — ${queue.queueNo} has been skipped.`
        });
      }, error => {
        console.log('error', error);
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Error!</b> — ${queue.queueNo} cannot be skipped. Please try again.`
        });
      }
    );
  }

}

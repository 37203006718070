import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { BusinessService } from 'src/app/core/services/business.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-branches-add-edit-form',
  templateUrl: './branches-add-edit-form.component.html',
  styleUrls: ['./branches-add-edit-form.component.scss']
})
export class BranchesAddEditFormComponent implements OnInit, OnDestroy {
  branchData: any;
  branchId: string;
  partnerId: string;
  title: string;
  branchForm: FormGroup;
  partnerData: any;
  imgUrl: string;
  submitting: boolean;
  enableEdit: boolean;
  errorMsg: string;
  statusCodeList: Array<any>;
  defaultAccountData: any;
  subscriptions = new Subscription();

  constructor(
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private fb: FormBuilder,
    private httpAdminService: HttpWebAdminService,
    private businessService: BusinessService,
    private modalService: NgbModal
  ) { }

  getUrlParams() {
    this.subscriptions.add(this.activatedRoute.params.subscribe(async params => {
      this.branchId = params['branchId'];
      this.partnerId = params['partnerId'];
      if (this.partnerId) {
        this.getPartnerData();
      }
      if (this.branchId) {
        this.getBranchData();
        this.defaultAccountData = await this.getDefaultAccount();
      }
    }));
  }

  getPartnerData() {
    this.httpAdminService.get$(`partners/${this.partnerId}`).subscribe(
      data => {
        console.log('partner data', data);
        this.partnerData = data.data;
        this.imgUrl = this.partnerData.avatarUrl ? this.partnerData.avatarUrl : './assets/images/koala.jpg';
      }, error => {
        console.log('error', error);
      }
    );
  }

  getBranchData() {
    this.httpAdminService.get$(`branches/${this.branchId}`).subscribe(
      data => {
        if (data) {
          console.log('branch data', data);
          this.branchData = data;
        }
      }, error => {
        console.log('error', error);
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Error:</b> Something went wrong.`
        });
      }
    );
  }

  async getDefaultAccount() {

    const defaultAccountData = await this.httpAdminService
      .get$(`business-portal/accounts/web-admin/default-account?branchId=${this.branchId}`)
      .pipe(first())
      .toPromise();

    return defaultAccountData

    // this.httpAdminService.get$(`business-portal/accounts/web-admin/default-account?branchId=${this.branchId}`).subscribe(
    //   data => {
    //     if (data) {
    //       console.log('default account', data);
    //       this.defaultAccountData = data.data;
    //     }
    //   }, error => {
    //     console.log('error', error);
    //   }
    // );
  }

  transformMobile(mobile) {
    return mobile.replace(/\D/g, '').slice(0, 10);
  }

  canDeactivate() {
    console.log('canDeactivate');
    if (this.branchForm.dirty || this.enableEdit) {
      const modalRef = this.modalService.open(ModalConfirmComponent, {
        centered: true
      });
      modalRef.componentInstance.title = 'Leave Page';
      modalRef.componentInstance.type = 'leave-page';
      return this.businessService.navigateAwaySelection$;
    } else {
      return true;
    }
  }

  checkEnableEdit() {
    console.log('checkEnableEdit');
    this.subscriptions.add(this.businessService.enableEdit$.subscribe(
      res => {
        console.log('enable edit', res);
        this.enableEdit = res;
      }
    ));
  }

  assignedDevices(f, returnData) {
    console.log('rawwwwww', f.value);
    const body = new FormData();
    const { distributor } = f.getRawValue();
    const form = {
      branchId: this.branchId ? this.branchId : returnData._id,
      devicesId: distributor.devices.filter(element => (element.deviceId)).map(element => {
        return element.deviceId;
      })
    };
    console.log('submitted devices', form, distributor, form.devicesId);
    this.httpAdminService.patch$('devices/assign-branch', form).subscribe(
      data => {
        console.log('edited device', data);
        this.submitting = false;
        f.enable();
        f.reset();
        this.enableEdit = false;
        // this.router.navigate([`/business-partners/${this.partnerId}`]);
      }, error => {
        console.log('error', error);
        this.submitting = false;
        f.enable();
      }
    );
  }

  capitalize(name) {
    return name.toLowerCase().replace(/(^|\s)\S/g,  (firstLetter) => firstLetter.toUpperCase());
  }

  submitForm(f) {
    console.log('raw value', f.getRawValue());
    const body = new FormData();
    const { about, address, billing, branchDefaultAccount, branchHours, branchMain, distributor, feature, customBrandTemplate } = f.getRawValue();
    const newContacts = branchMain.contactNumbers.map(element => {
      return { ...element, number: element.type === 'mobile' ? this.transformMobile(element.number) : element.number };
    });
    const newSocials = [];
    if (about.company) {
      newSocials.push({ type: 'company' , url: about.company});
    }
    if (about.facebook) {
      newSocials.push({ type: 'facebook' , url: about.facebook});
    }
    if (about.instagram) {
      newSocials.push({ type: 'instagram' , url: about.instagram});
    }
    const newFeature = [];
    // Procedure
    if (feature.enableKyooID && feature.enableProcedure) {
      newFeature.push(1);
    }
    // Kyoo ID
    if (feature.enableKyooID) {
      newFeature.push(5);
    }
    // feedback
    if (feature.enableFeedback) {
      newFeature.push(6);
    }
    // appointment
    if (feature.enableAppointment) {
      newFeature.push(7);
    }
    const formValue = {
      branchName: branchMain.branchName,
      branchId: branchMain.branchIdText,
      email: branchMain.branchEmail,
      country: branchMain.country,
      contactNumbers: newContacts,
      address: {
        street: (address.street === 'null' || address.street === null  || address.street === '' ) ? '' : address.street,
        province: (address.province === 'null' || address.province === null  || address.province === '' ) ? '' : address.province,
        city: (address.city === 'null' || address.city === null  || address.city === '') ? '' : address.city,
        zipcode: (address.zipcode === 'null' || address.zipcode === null  || address.zipcode === '' ) ? '' : address.zipcode,
      },
      coordinates: {
        lat: address.latitude ? address.latitude : 0,
        lng: address.longitude ? address.longitude : 0
      },
      about: about.desc,
      socialLinks: newSocials,
      featuredAccess: {
        account: {
          status: feature.maxAccount,
          max: feature.maxAccountNo ? feature.maxAccountNo : 1
        },
        queueGroup: {
          status: feature.maxQueueGroup,
          max: feature.maxQueueGroupNo ? feature.maxQueueGroupNo : 1
        },
        smsModule: {
          status: feature.enableSMS
        }
      },
      enableBatchIssuanceOfQueue: feature.enableBatchIssuanceOfQueue,
      enableMultipleDoneRemove: feature.enableMultipleDoneRemove,
      operationHours: branchHours.operationHours,
      isWeeklyOpened: branchHours.isWeeklyOpened,
      billing: {
        planType: billing.plan ? Number(billing.plan) : 1,
        amountRate: billing.subsRate ? Number(billing.subsRate) : 1,
        SMSRate: billing.smsRate ? Number(billing.smsRate) : 1,
        lockInPeriod: billing.period ? Number(billing.period) : 3
      },
      distributorId: distributor.assignedDistributor === 'null' || distributor.assignedDistributor === null ? '' : distributor.assignedDistributor,
      customBrandTemplate: {
        status: customBrandTemplate.status,
        templateId: customBrandTemplate.templateId
      },
      userRoleStatus: feature.enableUserRole,
      customizationClientFeatures: newFeature
    };

    let formdata;
    formdata = {
      ...formValue,
      account: {
        firstName: branchDefaultAccount.firstName ? this.capitalize(branchDefaultAccount.firstName) : '',
        lastName: branchDefaultAccount.lastName ? this.capitalize(branchDefaultAccount.lastName) : '',
        email: branchDefaultAccount.email
      }
    };
    if (this.businessService.selectedAccountAvatar$) {
      body.append('accountAvatar', new File([new Blob([this.businessService.dataURItoBlob(this.businessService.selectedAccountAvatar$)], {type: this.businessService.fileType3$})], this.businessService.fileName3$, {type: this.businessService.fileType3$}));
    }
    if (this.businessService.selectedAvatar$) {
      body.append('avatar', new File([new Blob([this.businessService.dataURItoBlob(this.businessService.selectedAvatar$)], {type: this.businessService.fileType$})], this.businessService.fileName$, {type: this.businessService.fileType$}));
    }
    if (this.businessService.selectedCoverPhoto$) {
      body.append('banner', new File([new Blob([this.businessService.dataURItoBlob(this.businessService.selectedCoverPhoto$)], {type: this.businessService.fileType2$})], this.businessService.fileName2$, {type: this.businessService.fileType2$}));
    }

    body.append('data', JSON.stringify(formdata));
    console.log('avatar', this.businessService.selectedAvatar$);
    console.log('accountAvatar', this.businessService.selectedAccountAvatar$);
    console.log('banner', this.businessService.selectedCoverPhoto$);
    console.log(formdata);
    console.log('data', JSON.stringify(formdata));
    this.sendData(body, f);
    this.submitting = true;
    f.disable();
  }

  sendData(formdata, f) {
    console.log('form value', f.getRawValue());
    if (!this.branchData) {
       // if new
      this.httpAdminService.post$(`branches/${this.partnerId}`, formdata).subscribe(
        data => {
          console.log('added', data);
          this.assignedDevices(f, data);
          const modalRef = this.modalService.open(ModalConfirmComponent, {
            centered: true
          });
          modalRef.componentInstance.type = 'success-admin';
          modalRef.componentInstance.title = `New Branch Added!`;
          modalRef.componentInstance.successType = 'add-branch';
          modalRef.componentInstance.bodyText = `<b>${data.data.data.branchName}</b> has been added to the list. An email verification has been sent to <b>${data.data.account.email.value}</b>`;
          modalRef.componentInstance.primaryButton = 'Okay, got it';
          modalRef.componentInstance.partnerId = this.partnerId;
          this.submitting = false;
          f.enable();
          f.reset();
          this.enableEdit = false;
          this.router.navigate([`/business-partners/${this.partnerId}`]);
        }, error => {
          console.log('error', error);
          // const errorsList = error.error.errors.map(element => {
          //   return element.param;
          this.submitting = false;
          this.showError(error);
          f.enable();
        }
      );
    } else {
       // if edit
      this.httpAdminService.patch$(`branches/${this.branchId}`, formdata).subscribe(
        data => {
          console.log('edited', data);
          this.assignedDevices(f, data);
          this.businessService.publishAlert$({
            type: 'green-dark-01',
            icon: 'fas fa-check',
            message: `<b>Success!</b> — ${data.branchName} has been updated.`
          });
          this.submitting = false;
          f.enable();
          f.reset();
          this.enableEdit = false;
          this.router.navigate([`/business-partners/branch/${this.partnerId}/${this.branchId}`]);
        }, error => {
          console.log('error', error);
          
          this.submitting = false;
          console.log('should stop submitting', this.submitting);
          f.enable();
          this.enableEdit = false;
          this.showError(error);          
        }
      );
    }
  }

  showError(error) {
    if (error.error.errors) {
      this.statusCodeList = error.error.errors.filter(element => element.msg.statusCode).map(element2 => element2.msg.statusCode);
      console.log('this.statusCodeList', this.statusCodeList);
      // Publish StatusCodes
      this.businessService.publishStatusCodeList$(this.statusCodeList);
    }
    this.businessService.publishAlert$({
      type: 'red-dark-01',
      icon: 'fas fa-ban',
      message: `<b>Something went wrong.</b> Please try again.`
    });
  }

  createForm() {
    this.branchForm = this.fb.group({});
  }

  ngOnInit() {
    this.enableEdit = true;
    this.checkEnableEdit();
    this.getUrlParams();
    if (this.router.url.includes('edit')) {
      this.title = 'Edit Branch';
    } else {
      this.title = 'Add Branch';
          }
    this.createForm();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
      this.businessService.publishEnableEdit$(false);
    }
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/core/http/http.service';
import { Subscription } from 'rxjs';
import { BusinessService } from 'src/app/core/services/business.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, OnDestroy {
  jwt: string;
  ref: string;
  branchId: string;
  branchData: any;
  accountId: string;
  subscriptions = new Subscription();

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private router: Router,
    private businessService: BusinessService
  ) { }

  getUrlParams() {
    this.subscriptions.add(this.activatedRoute.queryParams.subscribe(params => {
      this.jwt = params['jwt'];
      this.ref = params['ref'];
      this.branchId = params['branchId'];
      this.accountId = params['accountId'];
      if (this.accountId && this.branchId && this.jwt && this.ref) {
        console.log('jwt', this.accountId, this.branchId, this.jwt, this.ref);
        localStorage.setItem('JWT_TOKEN', this.jwt);
        localStorage.setItem('REFRESH_TOKEN', this.ref);
        localStorage.setItem('accountId', this.accountId);
        this.getBranchDetails();
      }
    }));
  }

  getBranchDetails() {
    this.httpService.get$(`branches/${this.branchId}`).subscribe(
      data => {
        console.log('branch details', data);
        this.branchData = data;
        setTimeout(() => {
          this.router.navigate([`/business-partners/branch/${this.branchData.partnerId}/${this.branchId}`]);
        }, 500);
      }, error => {
        console.log('error', error);
        // this.businessService.publishAlert$({
        //   type: 'red-dark-01',
        //   icon: 'fas fa-ban',
        //   message: `<b>Error:</b> Something went wrong.`
        // });
      }
    );
  }

  ngOnInit() {
    this.getUrlParams();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, retry, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpWebAdminService {
urlAPI = environment.webAdminAPI;
customerAppApi = environment.customerAppAPI;
private branchId = localStorage.getItem('branchId');

  constructor( private http: HttpClient ) { }

  private prepareHeader(headers: HttpHeaders | null): any {
    headers = headers || new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return {
      headers
    };
  }

  get$(param: string, headers?: HttpHeaders | null): Observable<any> {
    const expandedHeaders = this.prepareHeader(headers);
    return this.http.get(this.urlAPI + '/' + param, expandedHeaders)
    .pipe(
      retry(1),
      map(response => response)
    );
  }

  post$(param: string, data: any): Observable<any> {
    return this.http.post(this.urlAPI + '/' + param, data)
    .pipe(
      retry(1),
      map(response => response)
    );
  }

  put$(param: string, data: any): Observable<any> {
    return this.http.put(this.urlAPI + '/' + param, data)
    .pipe(
      retry(1),
      map(response => response)
    );
  }

  patch$(param: string, data: any): Observable<any> {
    return this.http.patch(this.urlAPI + '/' + param, data)
    .pipe(
      retry(1),
      map(response => response)
    );
  }

  delete$(param: string): Observable<any> {
    return this.http.delete(this.urlAPI + '/' + param)
    .pipe(
      retry(1),
      map(response => response)
    );
  }

  get2$(param: string, headers?: HttpHeaders | null): Observable<any> {
    const expandedHeaders = this.prepareHeader(headers);
    return this.http.get(`${this.urlAPI}/authenticate/${this.branchId}/${param}`, expandedHeaders)
    .pipe(
      retry(1),
      map(response => response)
    );
  }

  post2$(param: string, data: any, headers?: HttpHeaders | null): Observable<any> {
    const expandedHeaders = this.prepareHeader(headers);
    return this.http.post(`${this.urlAPI}/authenticate/${this.branchId}/${param}`, data, expandedHeaders)
    .pipe(
      retry(1),
      map(response => response)
    );
  }

  put2$(param: string, data: any, headers?: HttpHeaders | null): Observable<any> {
    const expandedHeaders = this.prepareHeader(headers);
    return this.http.put(this.urlAPI + '/' + param, data, expandedHeaders)
    .pipe(
      retry(1),
      map(response => response)
    );
  }

  patch2$(param: string, data: any, headers?: HttpHeaders | null): Observable<any> {
    const expandedHeaders = this.prepareHeader(headers);
    return this.http.patch(`${this.urlAPI}/authenticate/${this.branchId}/${param}`, data, expandedHeaders)
    .pipe(
      retry(1),
      map(response => response)
    );
  }

  patch3$(param: string, data: any): Observable<any> {
    return this.http.patch(this.customerAppApi + '/' + param, data)
    .pipe(
      retry(1),
      map(response => response)
    );
  }

  get3$(param: string, headers?: HttpHeaders | null): Observable<any> {
    const expandedHeaders = this.prepareHeader(headers);
    return this.http.get(this.customerAppApi + '/' + param, expandedHeaders)
    .pipe(
      retry(1),
      map(response => response)
    );
  }

  get4$(param: string, headers?: HttpHeaders | null): Observable<any> {
    const expandedHeaders = this.prepareHeader(headers);
    return this.http.get(param, expandedHeaders)
    .pipe(
      retry(1),
      map(response => response)
    );
  }

}


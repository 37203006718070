import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { BusinessService } from 'src/app/core/services/business.service';

@Component({
  selector: 'app-chart-line',
  templateUrl: './chart-line.component.html',
  styleUrls: ['./chart-line.component.scss'],
  providers: [DatePipe]
})
export class ChartLineComponent implements OnInit, AfterViewInit, OnDestroy {
  lineChartData: ChartDataSets[];
  lineLegends: any;
  lineLabels: any;
  lineChartColors: any;
  selectedChartLine: number;
  lineChartOptions: any;
  info1: string;
  info2: string;
  info3: string;
  color1: string;
  color2: string;
  color3: string;
  infoArray1: Array<number>;
  infoArray2: Array<number>;
  infoArray3: Array<number>;
  infoDates: Array<string>;
  selectedFilter: string;
  @Input() lineData: any;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() lineType: string;
  @Input() feedType: any;
  @Input() filterList: any;
  @Output() valueChange = new EventEmitter();
  @ViewChild('myCanvas',  {static: false}) canvas: ElementRef;
  subscriptions = new Subscription();

  constructor(
    private datePipe: DatePipe,
    private businessService: BusinessService
    ) { }

  getLineData() {
    this.subscriptions.add(this.businessService.chartLists$.subscribe(data => {
      console.log('queues count', data);
      if (data) {
        this.lineData = data;
        this.getValues();
        this.getDateOfWeek();
        this.getChartValue();
      }
    }));
  }

  getChartValue() {
    this.lineChartData = [
      {
        data: this.infoArray1,
        label: this.info1,
        fill: false,
      },
      {
        data: this.infoArray2,
        label: this.info2,
        fill: false
      },
      {
        data: this.infoArray3,
        label: this.info3,
        fill: false
      }
    ];
    this.lineLegends = [this.info1, this.info2, this.info3];
    this.lineChartColors = [
      { borderColor: this.color1, backgroundColor: this.color1 },
      { borderColor: this.color2, backgroundColor: this.color2 },
      { borderColor: this.color3, backgroundColor: this.color3 }
    ];
    this.lineChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {position: 'bottom'},
      scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true,
                precision: 0
            }
        }]
    }
    };
  }

  getDateOfWeek() {
    const curr = new Date();
    this.lineLabels = [];
    for (let i = 0; i <= 6; i++) {
      const first = curr.getDate() - curr.getDay() + i;
      const day = new Date(curr.setDate(first));
      this.lineLabels.push(this.datePipe.transform(day, 'MMM d'));
    }
  }

  filterChartChange() {
    this.valueChange.emit(this.selectedChartLine);
  }

  getLineInfo() {
    switch (this.lineType) {
      case 'totalQueues' : {
        this.title = 'Total Queues';
        this.color1 = '#FE8F02';
        this.color2 = '#425A79';
        this.color3 = '#92B7E1';
        this.info1 = 'Completed';
        this.info2 = 'Removed';
        this.info3 = 'Cancelled';
      }
      break;
    }
  }

  getValues() {
    switch (this.lineType) {
      case 'totalQueues' : {
        this.infoArray1 = [];
        this.infoArray2 = [];
        this.infoArray3 = [];
        this.infoDates = [];
        const lastDay = this.lineData[this.lineData.length - 1];
        console.log('lastElement', lastDay);
        if (this.lineData.length > 0) {
          this.lineData.forEach(element => {
            this.infoArray1.push(element.completed);
            this.infoArray2.push(element.removed);
            this.infoArray3.push(element.cancelledByCustomer);
            if (this.selectedFilter) {
              if (this.selectedFilter.includes('week')) {
                this.infoDates.push(this.datePipe.transform(element.date, 'MMM d'));
              } else {
                this.infoDates.push(this.datePipe.transform(element.date, 'd'));
              }
            }
          });
        }        
        if (this.selectedFilter) {
          if (this.selectedFilter.includes('week')) {
            if (this.lineData.length < 7) {
              console.log('lineData length', 7 - this.lineData.length);
              let endDay = lastDay.date;
              const oneDay = 86400000;
              for (let i = 0; i < (7 - this.lineData.length); i++) {
                console.log(i);
                endDay = endDay + oneDay;
                this.infoArray1.push(0);
                this.infoArray2.push(0);
                this.infoArray3.push(0);
                  if (this.selectedFilter) {
                  if (this.selectedFilter.includes('week')) {
                    this.infoDates.push(this.datePipe.transform(endDay, 'MMM d'));
                  }
                }
              }
            }
          }
        }
        console.log('this.infoArrays', this.infoArray1, this.infoArray2, this.infoArray3);
      }
      break;
    }
  }

  formatDate(date: any) {
    const monthList = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = parseInt(date.substring(5, 7), 10);
    const day = date.substring(8, 10);
    console.log('month', monthList[month], day);
    return monthList[month] + ' ' + day;
  }

  getSelectedFilter($event) {
    console.log('selected filter', $event);
    this.selectedFilter = $event;
  }

  ngOnInit() {
    this.getLineData();
    this.getDateOfWeek();
    this.getChartValue();
    this.getLineInfo();
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

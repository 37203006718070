import {Directive, ElementRef, HostListener, Renderer2, Input, AfterViewChecked} from '@angular/core';

@Directive({
  selector: '[appInputActive]'
})

export class InputActiveDirective implements AfterViewChecked {
  public el: ElementRef = null;
  public elLabel: ElementRef = null;
  @Input() public input: any;

  constructor(
  el: ElementRef,
  public renderer: Renderer2) {
      this.el = el;
  }

  @HostListener('focus', ['$event']) onClick() { this.initComponent(); }
  @HostListener('blur', ['$event']) onBlur() { this.checkValue(); }

  private initComponent(): void {
    const inputP = this.el.nativeElement.parentNode;
    if (inputP) {
      this.elLabel = inputP.querySelector('label');
      if (this.elLabel) {
        if (this.elLabel !== null || this.elLabel !== undefined || this.el.nativeElement.className.includes('except')) {
          this.renderer.addClass(this.elLabel, 'active');
        }
      }
    }
  }

  private checkValue(): void {
    if (this.elLabel) {
      let value = '';
      if (this.elLabel !== null || this.elLabel !== undefined) {
        value = this.el.nativeElement.value;
        if (value === '') {
          this.renderer.removeClass(this.elLabel, 'active');
        } else {
          this.renderer.addClass(this.elLabel, 'active');
        }
      }
    }
  }

  ngAfterViewChecked() {
    this.initComponent();
    this.checkValue();
  }
}

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BusinessService } from 'src/app/core/services/business.service';

@Component({
  selector: 'app-template-preview',
  templateUrl: './template-preview.component.html',
  styleUrls: ['./template-preview.component.scss']
})
export class TemplatePreviewComponent implements OnInit, OnDestroy {
  @Input() templateData: any;
  primaryColor: string;
  secondaryColor: string;
  primaryText: string;
  secondaryText: string;
  sideNavLogo: string;
  loginImage: string;
  favicon: string;
  tabActive: number;
  defaultPrimary = '#FE8F02';
  defaultSecondary = '#212121';
  defaultPrimaryText = '#FFFFFF';
  defaultsecondaryText = '#FFFFFF';
  defaultSidenav = 'assets/images/img_kyoo_bizportal.png';
  defaultLoginImg = 'assets/images/img_kyoo_food.png';
  defaultFavicon = 'assets/images/default-favicon.png';
  subscriptions = new Subscription();
  distributorLogin: boolean;
  distributorTV: boolean;
  distributorData: boolean;

  constructor(
    private businessService: BusinessService
  ) { }

  view(id) {
    this.tabActive = id;
  }

  checkPrimary() {
    this.businessService.primaryColor$.subscribe(res => {
      console.log('checkPrimary', res);
      this.primaryColor = res ? res : this.defaultPrimary;
    });
  }

  checkSecondary() {
    this.businessService.secondaryColor$.subscribe(res => {
      this.secondaryColor = res ? res : this.defaultSecondary;
    });
  }

  checkPrimaryText() {
    this.businessService.primaryText$.subscribe(res => {
      this.primaryText = res ? res : this.defaultPrimaryText;
    });
  }

  checkSecondaryText() {
    this.businessService.secondaryText$.subscribe(res => {
      this.secondaryText = res ? res : this.defaultsecondaryText;
    });
  }

  checkSideNavLogo() {
    this.businessService.croppedSideNavLogo$.subscribe(res => {
      this.sideNavLogo = res.image ? res.image : this.defaultSidenav;
    });
  }

  checkLoginImage() {
    this.businessService.croppedLoginImage$.subscribe(res => {
      this.loginImage = res.image ? res.image : this.defaultLoginImg;
    });
  }

  checkFavicon() {
    this.businessService.croppedFavicon$.subscribe(res => {
      console.log('check favicon', res);
      this.favicon = res ? res : this.defaultFavicon;
    });
  }

  checkDistributorData() {
    this.businessService.distributorData$.subscribe(res => {
      console.log('check distributor data', res);
      this.distributorData = res ? res : false;
    });
  }

  checkDistributorLogin() {
    this.businessService.distributorLogin$.subscribe(res => {
      console.log('check distributor Login', res);
      this.distributorLogin = res ? res : false;
    });
  }

  checkDistributorTV() {
    this.businessService.distributorTV$.subscribe(res => {
      console.log('check distributor TV', res);
      this.distributorTV = res ? res : false;
    });
  }

  ngOnInit() {
    this.primaryColor =  this.defaultPrimary;
    this.secondaryColor = this.defaultSecondary;
    this.secondaryText = this.defaultsecondaryText;
    this.sideNavLogo = this.defaultSidenav;
    this.loginImage = this.defaultLoginImg;
    this.favicon = this.defaultFavicon;
    if (this.templateData) {
      this.primaryColor = this.templateData.colorTheme.primary ? this.templateData.colorTheme.primary : this.defaultPrimary;
      this.secondaryColor = this.templateData.colorTheme.secondary ? this.templateData.colorTheme.secondary : this.defaultSecondary;
      this.primaryText = this.templateData.colorTheme.primaryText ? this.templateData.colorTheme.primaryText : this.defaultPrimaryText;
      this.secondaryText = this.templateData.colorTheme.secondaryText ? this.templateData.colorTheme.secondaryText : this.defaultsecondaryText;
      this.sideNavLogo = this.templateData.sideNavigationImage ? this.templateData.sideNavigationImage : this.defaultSidenav;
      this.loginImage = this.templateData.loginPageImage ? this.templateData.loginPageImage : this.defaultLoginImg;
      this.favicon = this.templateData.faviconImage ? this.templateData.faviconImage : this.defaultLoginImg;
      this.distributorData = this.templateData.distributor.status ? this.templateData.distributor.status : false;
      this.distributorLogin = this.templateData.distributor.showOnLoginPage ? this.templateData.distributor.showOnLoginPage : false;
      this.distributorTV = this.templateData.distributor.showOnTVDisplay ? this.templateData.distributor.showOnTVDisplay : false;
    }
    this.checkPrimary();
    this.checkSecondary();
    this.checkPrimaryText();
    this.checkSecondaryText();
    this.checkSideNavLogo();
    this.checkLoginImage();
    this.checkFavicon();
    this.checkDistributorData();
    this.checkDistributorLogin();
    this.checkDistributorTV();
    this.tabActive = 1;
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { BusinessService } from 'src/app/core/services/business.service';
import { HttpService } from 'src/app/core/http/http.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import * as fs from 'file-saver';
import { AngularCsv } from 'angular7-csv';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';

declare const ExcelJS: any;

@Component({
  selector: 'app-report-generate-modal',
  templateUrl: './report-generate-modal.component.html',
  styleUrls: ['./report-generate-modal.component.scss']
})
export class ReportGenerateModalComponent implements OnInit {
  type: any;
  title: string;
  filter: any;
  filter1: any;
  filter2: any;

  dateTypes = ['Show All', 'Today', 'Yesterday', 'Last 7 Days', 'Last 30 Days', 'This Month', 'Last Month', 'This Year', 'Custom'];
  selected: number;
  dateFrom = null;
  dateTo = null;
  dateDisplay: boolean;
  inputFrom: any;
  inputTo: any;
  filterCategory: any;
  filterCategory2: any;
  selectedFilter: string;
  selectedFilterName: string;
  selectedFilter2: string;
  selectedFilterName2: string;
  selectedFilterLists: Array<any>;
  branchId: string;
  showAll = 'show-all';
  distributorLists: any;

  selectedDates;
  selectedDistributor = 'specific';
  selectedDates1;
  selectedDistributor1: any;
  selectedType = 'branch';
  selectedStatus = "show-all";

  accountsList = [];
  queueGroupList = [];
  smsRecords = [];

  allMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  current = new Date().getMonth();
  monthPassed: any;
  dateYear = new Date().getFullYear();
  yearLists = [2020];

  d = [];
  bNames = [];
  errorMsg: any;
  now: any;

  distributors: any;

  constructor(
    private businessService: BusinessService,
    public activeModal: NgbActiveModal,
    private httpAdminService: HttpWebAdminService,
    private httpService: HttpService
    ) {
      setInterval(() => {
        this.now = Date.now();
      }, 1);
    }

  changeDateFilter(i) {
    const today = new Date();
    this.selected = i;
    console.log(this.selected);
    switch(i) {
      // Show All
      case 0: {
        this.dateFrom = null;
        this.dateTo = null;
      }
      break;
      // Today
     case 1: {
      this.dateFrom = this.jsonDate(today);
      this.dateTo = this.jsonDate(today);
     }
     break;
     // Yesterday
      case 2: {
        this.dateFrom = this.jsonDate(today.setDate(today.getDate() - 1));
        this.dateTo = this.dateFrom;
      }
      break;
      // Last 7 Days
      case 3: {
        this.dateTo = this.jsonDate(today);
        this.dateFrom = this.jsonDate(today.setDate(today.getDate() - 6));
      }
      break;
      // Last 30 Days
      case 4: {
        this.dateTo = this.jsonDate(today);
        this.dateFrom = this.jsonDate(today.setDate(today.getDate() - 29));
      }
      break;
      // This Month
      case 5: {
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        this.dateTo = this.jsonDate(lastDay);
        this.dateFrom = this.jsonDate(firstDay);
      }
      break;
      // Last Month
      case 6: {
        const lastMonthFirstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const lastMonthLastDay = new Date(today.getFullYear(), today.getMonth(), 0);
        this.dateTo = this.jsonDate(lastMonthLastDay);
        this.dateFrom = this.jsonDate(lastMonthFirstDay);
      }
      break;
       // This Year
       case 7: {
        const thisYearFirstDay = new Date(today.getFullYear(), 0);
        const thisYearLastDay = new Date(today.getFullYear(), 11, 31);
        this.dateTo = this.jsonDate(thisYearLastDay);
        this.dateFrom = this.jsonDate(thisYearFirstDay);
      }
      break;
    }
  }

  checkDateFromChange($event) {
    console.log('change', $event);
    this.selected = 8;
    const day = String($event.day).padStart(2, '0');
    const month = String($event.month).padStart(2, '0');
    const year = $event.year;
    this.inputFrom = `${month}/${day}/${year}`;
  }

  checkDateToChange($event) {
    console.log('change', $event);
    this.selected = 8;
    const day = String($event.day).padStart(2, '0');
    const month = String($event.month).padStart(2, '0');
    const year = $event.year;
    this.inputTo = `${month}/${day}/${year}`;
  }

  jsonDate(date) {
    return this.businessService.jsonDate(date);
  }

  getDistributorList() {
    this.httpAdminService.get$(`distributor`).subscribe(
      data => {
        this.distributorLists = data.data;
        this.selectedDistributor1 = this.distributorLists[0]._id;
        console.log('distributor list', this.distributorLists);
      }, error => {
        console.log('error', error);
      }
    );
  }

  getAccountList() {
    let param = null;
    console.log('selectedType & selectedStatus', this.selectedType, this.selectedStatus);
    if (this.selectedType === 'account') {
      param = `/reports/account-list?status=${this.selectedStatus}`;
    } else if (this.selectedType === 'branch') {
      param = `/branches/reports/branch-list?status=${this.selectedStatus}`;
    } else if (this.selectedType === 'customer') {
      param = `/customer/reports/account-list?status=${this.selectedStatus}`;
    } else if (this.selectedType === 'device') {
      param = `/distributor/reports/device-list?status=${this.selectedStatus}`;
    } else {
      param = `/distributor/reports/list?status=${this.selectedStatus}`;
    }
    if (param !== null) {
      this.httpService.get$(param).subscribe(
        data => {
          console.log('Accounts list', data);
          this.accountsList = data;
          this.generateModule(this.accountsList)
          console.log('this.accountsList', this.accountsList);
        }, error => {
          this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
          console.log('error', error);
        }
      );
    }
  }

  getSmsRecords() {
    let param;
    if (this.selectedDates === 'month') {
      param = `/distributor/reports/sms?filterType=${this.selectedDates}&distributorId=${this.selectedDistributor1}&month=${this.selectedDates1}`;
    } else {
      param = `/distributor/reports/sms?filterType=${this.selectedDates}&distributorId=${this.selectedDistributor1}&year=${this.selectedDates1}`;
    }
    this.httpService.get$(param).subscribe(
      data => {
        console.log('SMS list', data);
        this.smsRecords = data;
        if (data.filterType === 'month'){
          this.generateMonths(this.smsRecords);
        } else {
          this.generateYear(this.smsRecords );
        }
        console.log('this.smsRecords', this.smsRecords);
        // this.csvClickSms(this.smsRecords);
      }, error => {
        console.log('error', error);
        this.errorMsg = `System can't generate report. No branch found in the distributor selected.`
        this.smsRecords = [];
        // this.csvClickSms(this.smsRecords);
        console.log('this.smsRecords', this.smsRecords);
      }
    );
  }

  parseDate(date) {
    return this.businessService.parseDate(date);
  }

  applyDateFilter() {
    console.log(this.parseDate(this.dateFrom), this.parseDate(this.dateTo));
    this.dateDisplay = false;
    // this.getSearchResult();
  }

  generate() {
    const today = new Date();
    switch (this.type) {
      case 'sms-report': {
        console.log('this is sms-report');
        this.getSmsRecords();
        console.log('sms-report', this.selectedDates, this.selectedDates1, this.selectedDistributor1);
      }
      break;
      case 'accounts-report': {
        console.log('this is accounts-report');
        this.getAccountList();
      }
      break;
    }
  }
  formatDate(date) {
    const monthNames = ['January', 'February', 'March', 'April', 'May','June','July', 'August', 'September', 'October', 'November', 'December'];
    const d = new Date(date);
    return `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
}

  checkMonths(){
    this.monthPassed = this.allMonths.filter((val, index) => index <= this.current)

    console.log('monthPassed', this.monthPassed);
  }

  checkYear() {
     if (this.dateYear !== 2020) {
      this.yearLists.push(this.dateYear);
      console.log('this.yearLists', this.yearLists);
    }
  }

  checkFilter() {
    if (this.selectedDates === 'month') {
      this.selectedDates1 = this.current;
    }
    if (this.selectedDates === 'year') {
      this.selectedDates1 = this.dateYear;
    }
  }

  clearBackendError() {
    this.errorMsg = null;
  }

  generateMonths(allData) {
    // Excel Title, Header, Data
    const monthTitle = this.allMonths.find((element, index) =>
      index === allData.month );
    const title = `SMS Report - ${allData.distributor.name} for the Month of ${monthTitle}`;
    const header = ['January', 'Date', 'SMS Sent', 'SMS Failed'];

    // Create workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('SMS report');

  // Add Row and formatting
      const titleRow = worksheet.addRow([title]);
      titleRow.font = { name: 'Arial', family: 4, size: 14 };
      const subTitleRow = worksheet.addRow(['Reported Generated By: ' + allData.actionBy.name]);
      const subTitleRow2 = worksheet.addRow(['Date Generated: ' + this.formatDate(this.now)]);

  // Blank Row
      worksheet.addRow([]);

  // Add Header Row
      const headerRow = worksheet.addRow(header);

  // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
        cell.font = { name: 'Arial', bold: true};
    cell.alignment = { vertical: 'middle', horizontal: 'center' };
  });

  // Add Data and Conditional Formatting
    allData.branchSmsReports.forEach(d => {
      d.smsReports.forEach(sms => {
        console.log('sms', sms);
        const arraySms = [sms.branchName, this.formatDate(sms.date), sms.sent, sms.failed]
       const row = worksheet.addRow(arraySms);
       const smsSuccess = row.getCell(3);
       const smsFailed = row.getCell(4);
       smsSuccess.alignment = { vertical: 'middle', horizontal: 'center' };
       smsFailed.alignment = { vertical: 'middle', horizontal: 'center' };
      });
      worksheet.addRow([]);
    }
  );
      worksheet.getColumn(1).width = 30;
      worksheet.getColumn(2).width = 30;
      worksheet.getColumn(3).width = 25;
      worksheet.getColumn(4).width = 25;
      worksheet.addRow([]);

  // Generate Excel File with given name
      workbook.xlsx.writeBuffer().then((data: any) => {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    fs.saveAs(blob, 'SMS Report per Distributor_MonthGenerated.xlsx');
  });
}

generateYear(allData){
console.log('alldata YEAR', allData.branchSmsReports[0].smsReports.length);
const title = `SMS Report - ${allData.distributor.name} for the Year ${allData.year}`;
if (allData.branchSmsReports[0].smsReports.length === 1) {
  var header = ['', 'January', ''];
  var header2 = ['Branch Name', 'SMS Sent', 'SMS Failed'];
} else if (allData.branchSmsReports[0].smsReports.length === 2) {
  var header = ['', 'January', '', 'February', ''];
  var header2 = ['Branch Name', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed'];
} else if (allData.branchSmsReports[0].smsReports.length === 3) {
  var header = ['', 'January', '', 'February', '', 'March', ''];
  var header2 = ['Branch Name', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed'];
} else if (allData.branchSmsReports[0].smsReports.length === 4) {
  var header = ['', 'January', '', 'February', '', 'March', '', 'April', ''];
  var header2 = ['Branch Name', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed'];
} else if (allData.branchSmsReports[0].smsReports.length === 5) {
  var header = ['', 'January', '', 'February', '', 'March', '', 'April', '', 'May', ''];
  var header2 = ['Branch Name', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed'];
} else if (allData.branchSmsReports[0].smsReports.length === 6) {
  var header = ['', 'January', '', 'February', '', 'March', '', 'April', '', 'May', '', 'June', ''];
  var header2 = ['Branch Name', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed'];
} else if (allData.branchSmsReports[0].smsReports.length === 7) {
  var header = ['', 'January', '', 'February', '', 'March', '', 'April', '', 'May', '', 'June', '', 'July', ''];
  var header2 = ['Branch Name', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed'];
} else if (allData.branchSmsReports[0].smsReports.length === 8) {
  var header = ['', 'January', '', 'February', '', 'March', '', 'April', '', 'May', '', 'June', '', 'July', '', 'August', ''];
  var header2 = ['Branch Name', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed'];
} else if (allData.branchSmsReports[0].smsReports.length === 9) {
  var header = ['', 'January', '', 'February', '', 'March', '', 'April', '', 'May', '', 'June', '', 'July', '', 'August', '', 'September', ''];
  var header2 = ['Branch Name', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed'];
} else if (allData.branchSmsReports[0].smsReports.length === 10) {
  var header = ['', 'January', '', 'February', '', 'March', '', 'April', '', 'May', '', 'June', '', 'July', '', 'August', '', 'September', '', 'October', ''];
  var header2 = ['Branch Name', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed'];
} else if (allData.branchSmsReports[0].smsReports.length === 11) {
  var header = ['', 'January', '', 'February', '', 'March', '', 'April', '', 'May', '', 'June', '', 'July', '', 'August', '', 'September', '', 'October', '', 'November', ''];
  var header2 = ['Branch Name', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed'];
} else {
  var header = ['', 'January', '', 'February','', 'March','', 'April', '', 'May', '', 'June','', 'July','', 'August','', 'September','', 'October','', 'November','', 'December'];
  var header2 = ['Branch Name', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed','SMS Sent', 'SMS Failed','SMS Sent', 'SMS Failed','SMS Sent', 'SMS Failed','SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed', 'SMS Sent', 'SMS Failed'];
}


// Create workbook and worksheet
const workbook = new ExcelJS.Workbook();
const worksheet = workbook.addWorksheet('SMS report');

// Add Row and formatting
  const titleRow = worksheet.addRow([title]);
  titleRow.font = { name: 'Arial', family: 4, size: 14 };
  const subTitleRow = worksheet.addRow(['Reported Generated By: ' + allData.actionBy.name]);
  const subTitleRow2 = worksheet.addRow(['Date Generated: ' + this.formatDate(this.now)]);

// Blank Row
  worksheet.addRow([]);

// Add Header Row
  const headerRow = worksheet.addRow(header);
  const headerRow2 = worksheet.addRow(header2);

// Cell Style : Fill and Border
  headerRow.eachCell((cell, number) => {
    cell.font = { name: 'Arial', bold: true};
  cell.alignment = { vertical: 'middle', horizontal: 'center' };
  });
  headerRow2.eachCell((cell, number) => {
    cell.font = { name: 'Arial', bold: true};
  cell.alignment = { vertical: 'middle', horizontal: 'center' };
  });
  if (allData.branchSmsReports[0].smsReports.length === 1) {
    worksheet.mergeCells('B5:C5');
    allData.branchSmsReports.forEach((d, index) => {
      console.log('d', d.smsReports[0], index);
      const arraySms = [d.branchName, d.smsReports[0].sent, d.smsReports[0].failed]
     const row = worksheet.addRow(arraySms);
     const smsCell1 = row.getCell(2);
     const smsCell2 = row.getCell(3);
     smsCell1.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell2.alignment = { vertical: 'middle', horizontal: 'center' };
    }
  );
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
  } else if (allData.branchSmsReports[0].smsReports.length === 2) {
    worksheet.mergeCells('B5:C5');
    worksheet.mergeCells('D5:E5');
    allData.branchSmsReports.forEach((d, index) => {
      console.log('d', d.smsReports[0], index);
      const arraySms = [d.branchName, d.smsReports[0].sent, d.smsReports[0].failed, d.smsReports[1].sent, d.smsReports[1].failed]
     const row = worksheet.addRow(arraySms);
     const smsCell1 = row.getCell(2);
     const smsCell2 = row.getCell(3);
     const smsCell3 = row.getCell(4);
     const smsCell4 = row.getCell(5);
     smsCell1.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell2.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell3.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell4.alignment = { vertical: 'middle', horizontal: 'center' };
    }
  );
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
  } else if (allData.branchSmsReports[0].smsReports.length === 3) {
    worksheet.mergeCells('B5:C5');
    worksheet.mergeCells('D5:E5');
    worksheet.mergeCells('F5:G5');
    allData.branchSmsReports.forEach((d, index) => {
      console.log('d', d.smsReports[0], index);
      const arraySms = [d.branchName, d.smsReports[0].sent, d.smsReports[0].failed, d.smsReports[1].sent, d.smsReports[1].failed, d.smsReports[2].sent, d.smsReports[2].failed]
     const row = worksheet.addRow(arraySms);
     const smsCell1 = row.getCell(2);
     const smsCell2 = row.getCell(3);
     const smsCell3 = row.getCell(4);
     const smsCell4 = row.getCell(5);
     const smsCell5 = row.getCell(6);
     const smsCell6 = row.getCell(7);
     smsCell1.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell2.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell3.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell4.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell5.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell6.alignment = { vertical: 'middle', horizontal: 'center' };
    }
  );
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
  } else if (allData.branchSmsReports[0].smsReports.length === 4) {
    worksheet.mergeCells('B5:C5');
    worksheet.mergeCells('D5:E5');
    worksheet.mergeCells('F5:G5');
    worksheet.mergeCells('H5:I5');
    allData.branchSmsReports.forEach((d, index) => {
      console.log('d', d.smsReports[0], index);
      const arraySms = [d.branchName, d.smsReports[0].sent, d.smsReports[0].failed, d.smsReports[1].sent, d.smsReports[1].failed, d.smsReports[2].sent, d.smsReports[2].failed, d.smsReports[3].sent, d.smsReports[3].failed]
     const row = worksheet.addRow(arraySms);
     const smsCell1 = row.getCell(2);
     const smsCell2 = row.getCell(3);
     const smsCell3 = row.getCell(4);
     const smsCell4 = row.getCell(5);
     const smsCell5 = row.getCell(6);
     const smsCell6 = row.getCell(7);
     const smsCell7 = row.getCell(8);
     const smsCell8 = row.getCell(9);
     smsCell1.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell2.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell3.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell4.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell5.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell6.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell7.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell8.alignment = { vertical: 'middle', horizontal: 'center' };
    }
  );
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;
  } else if (allData.branchSmsReports[0].smsReports.length === 5) {
    worksheet.mergeCells('B5:C5');
    worksheet.mergeCells('D5:E5');
    worksheet.mergeCells('F5:G5');
    worksheet.mergeCells('H5:I5');
    worksheet.mergeCells('J5:K5');
    allData.branchSmsReports.forEach((d, index) => {
      console.log('d', d.smsReports[0], index);
      const arraySms = [d.branchName, d.smsReports[0].sent, d.smsReports[0].failed, d.smsReports[1].sent, d.smsReports[1].failed, d.smsReports[2].sent, d.smsReports[2].failed, d.smsReports[3].sent, d.smsReports[3].failed, d.smsReports[4].sent, d.smsReports[4].failed]
     const row = worksheet.addRow(arraySms);
     const smsCell1 = row.getCell(2);
     const smsCell2 = row.getCell(3);
     const smsCell3 = row.getCell(4);
     const smsCell4 = row.getCell(5);
     const smsCell5 = row.getCell(6);
     const smsCell6 = row.getCell(7);
     const smsCell7 = row.getCell(8);
     const smsCell8 = row.getCell(9);
     const smsCell9 = row.getCell(10);
     const smsCell10 = row.getCell(11);
     smsCell1.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell2.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell3.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell4.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell5.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell6.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell7.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell8.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell9.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell10.alignment = { vertical: 'middle', horizontal: 'center' };
    }
  );
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 20;
    worksheet.getColumn(11).width = 20;
  } else if (allData.branchSmsReports[0].smsReports.length === 6) {
    worksheet.mergeCells('B5:C5');
    worksheet.mergeCells('D5:E5');
    worksheet.mergeCells('F5:G5');
    worksheet.mergeCells('H5:I5');
    worksheet.mergeCells('J5:K5');
    worksheet.mergeCells('L5:M5');
    allData.branchSmsReports.forEach((d, index) => {
      console.log('d', d.smsReports[0], index);
      const arraySms = [d.branchName, d.smsReports[0].sent, d.smsReports[0].failed, d.smsReports[1].sent, d.smsReports[1].failed, d.smsReports[2].sent, d.smsReports[2].failed, d.smsReports[3].sent, d.smsReports[3].failed, d.smsReports[4].sent, d.smsReports[4].failed, d.smsReports[5].sent, d.smsReports[5].failed]
     const row = worksheet.addRow(arraySms);
     const smsCell1 = row.getCell(2);
     const smsCell2 = row.getCell(3);
     const smsCell3 = row.getCell(4);
     const smsCell4 = row.getCell(5);
     const smsCell5 = row.getCell(6);
     const smsCell6 = row.getCell(7);
     const smsCell7 = row.getCell(8);
     const smsCell8 = row.getCell(9);
     const smsCell9 = row.getCell(10);
     const smsCell10 = row.getCell(11);
     const smsCell11 = row.getCell(12);
     const smsCell12 = row.getCell(13);
     smsCell1.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell2.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell3.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell4.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell5.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell6.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell7.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell8.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell9.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell10.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell11.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell12.alignment = { vertical: 'middle', horizontal: 'center' };
    }
  );
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 20;
    worksheet.getColumn(11).width = 20;
    worksheet.getColumn(12).width = 20;
    worksheet.getColumn(13).width = 20;
  } else if (allData.branchSmsReports[0].smsReports.length === 7) {
    worksheet.mergeCells('B5:C5');
    worksheet.mergeCells('D5:E5');
    worksheet.mergeCells('F5:G5');
    worksheet.mergeCells('H5:I5');
    worksheet.mergeCells('J5:K5');
    worksheet.mergeCells('L5:M5');
    worksheet.mergeCells('N5:O5');
    allData.branchSmsReports.forEach((d, index) => {
      console.log('d', d.smsReports[0], index);
      const arraySms = [d.branchName, d.smsReports[0].sent, d.smsReports[0].failed, d.smsReports[1].sent, d.smsReports[1].failed, d.smsReports[2].sent, d.smsReports[2].failed, d.smsReports[3].sent, d.smsReports[3].failed, d.smsReports[4].sent, d.smsReports[4].failed, d.smsReports[5].sent, d.smsReports[5].failed, d.smsReports[6].sent, d.smsReports[6].failed]
     const row = worksheet.addRow(arraySms);
     const smsCell1 = row.getCell(2);
     const smsCell2 = row.getCell(3);
     const smsCell3 = row.getCell(4);
     const smsCell4 = row.getCell(5);
     const smsCell5 = row.getCell(6);
     const smsCell6 = row.getCell(7);
     const smsCell7 = row.getCell(8);
     const smsCell8 = row.getCell(9);
     const smsCell9 = row.getCell(10);
     const smsCell10 = row.getCell(11);
     const smsCell11 = row.getCell(12);
     const smsCell12 = row.getCell(13);
     const smsCell13 = row.getCell(14);
     const smsCell14 = row.getCell(15);
     smsCell1.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell2.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell3.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell4.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell5.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell6.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell7.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell8.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell9.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell10.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell11.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell12.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell13.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell14.alignment = { vertical: 'middle', horizontal: 'center' };
    }
  );
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 20;
    worksheet.getColumn(11).width = 20;
    worksheet.getColumn(12).width = 20;
    worksheet.getColumn(13).width = 20;
    worksheet.getColumn(14).width = 20;
    worksheet.getColumn(15).width = 20;
  } else if (allData.branchSmsReports[0].smsReports.length === 8) {
    worksheet.mergeCells('B5:C5');
    worksheet.mergeCells('D5:E5');
    worksheet.mergeCells('F5:G5');
    worksheet.mergeCells('H5:I5');
    worksheet.mergeCells('J5:K5');
    worksheet.mergeCells('L5:M5');
    worksheet.mergeCells('N5:O5');
    worksheet.mergeCells('P5:Q5');
    allData.branchSmsReports.forEach((d, index) => {
      console.log('d', d.smsReports[0], index);
      const arraySms = [d.branchName, d.smsReports[0].sent, d.smsReports[0].failed, d.smsReports[1].sent, d.smsReports[1].failed, d.smsReports[2].sent, d.smsReports[2].failed, d.smsReports[3].sent, d.smsReports[3].failed, d.smsReports[4].sent, d.smsReports[4].failed, d.smsReports[5].sent, d.smsReports[5].failed, d.smsReports[6].sent, d.smsReports[6].failed, d.smsReports[7].sent, d.smsReports[7].failed]
     const row = worksheet.addRow(arraySms);
     const smsCell1 = row.getCell(2);
     const smsCell2 = row.getCell(3);
     const smsCell3 = row.getCell(4);
     const smsCell4 = row.getCell(5);
     const smsCell5 = row.getCell(6);
     const smsCell6 = row.getCell(7);
     const smsCell7 = row.getCell(8);
     const smsCell8 = row.getCell(9);
     const smsCell9 = row.getCell(10);
     const smsCell10 = row.getCell(11);
     const smsCell11 = row.getCell(12);
     const smsCell12 = row.getCell(13);
     const smsCell13 = row.getCell(14);
     const smsCell14 = row.getCell(15);
     const smsCell15 = row.getCell(16);
     const smsCell16 = row.getCell(17);
     smsCell1.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell2.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell3.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell4.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell5.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell6.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell7.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell8.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell9.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell10.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell11.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell12.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell13.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell14.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell15.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell16.alignment = { vertical: 'middle', horizontal: 'center' };
    }
  );
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 20;
    worksheet.getColumn(11).width = 20;
    worksheet.getColumn(12).width = 20;
    worksheet.getColumn(13).width = 20;
    worksheet.getColumn(14).width = 20;
    worksheet.getColumn(15).width = 20;
    worksheet.getColumn(16).width = 20;
    worksheet.getColumn(17).width = 20;
  } else if (allData.branchSmsReports[0].smsReports.length === 9) {
    worksheet.mergeCells('B5:C5');
    worksheet.mergeCells('D5:E5');
    worksheet.mergeCells('F5:G5');
    worksheet.mergeCells('H5:I5');
    worksheet.mergeCells('J5:K5');
    worksheet.mergeCells('L5:M5');
    worksheet.mergeCells('N5:O5');
    worksheet.mergeCells('P5:Q5');
    worksheet.mergeCells('R5:S5');
    allData.branchSmsReports.forEach((d, index) => {
      console.log('d', d.smsReports[0], index);
      const arraySms = [d.branchName, d.smsReports[0].sent, d.smsReports[0].failed, d.smsReports[1].sent, d.smsReports[1].failed, d.smsReports[2].sent, d.smsReports[2].failed, d.smsReports[3].sent, d.smsReports[3].failed, d.smsReports[4].sent, d.smsReports[4].failed, d.smsReports[5].sent, d.smsReports[5].failed, d.smsReports[6].sent, d.smsReports[6].failed, d.smsReports[7].sent, d.smsReports[7].failed, d.smsReports[8].sent, d.smsReports[8].failed]
     const row = worksheet.addRow(arraySms);
     const smsCell1 = row.getCell(2);
     const smsCell2 = row.getCell(3);
     const smsCell3 = row.getCell(4);
     const smsCell4 = row.getCell(5);
     const smsCell5 = row.getCell(6);
     const smsCell6 = row.getCell(7);
     const smsCell7 = row.getCell(8);
     const smsCell8 = row.getCell(9);
     const smsCell9 = row.getCell(10);
     const smsCell10 = row.getCell(11);
     const smsCell11 = row.getCell(12);
     const smsCell12 = row.getCell(13);
     const smsCell13 = row.getCell(14);
     const smsCell14 = row.getCell(15);
     const smsCell15 = row.getCell(16);
     const smsCell16 = row.getCell(17);
     const smsCell17 = row.getCell(18);
     const smsCell18 = row.getCell(19);
     smsCell1.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell2.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell3.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell4.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell5.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell6.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell7.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell8.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell9.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell10.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell11.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell12.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell13.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell14.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell15.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell16.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell17.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell18.alignment = { vertical: 'middle', horizontal: 'center' };
    }
  );
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 20;
    worksheet.getColumn(11).width = 20;
    worksheet.getColumn(12).width = 20;
    worksheet.getColumn(13).width = 20;
    worksheet.getColumn(14).width = 20;
    worksheet.getColumn(15).width = 20;
    worksheet.getColumn(16).width = 20;
    worksheet.getColumn(17).width = 20;
    worksheet.getColumn(18).width = 20;
    worksheet.getColumn(19).width = 20;
  } else if (allData.branchSmsReports[0].smsReports.length === 10) {
    worksheet.mergeCells('B5:C5');
    worksheet.mergeCells('D5:E5');
    worksheet.mergeCells('F5:G5');
    worksheet.mergeCells('H5:I5');
    worksheet.mergeCells('J5:K5');
    worksheet.mergeCells('L5:M5');
    worksheet.mergeCells('N5:O5');
    worksheet.mergeCells('P5:Q5');
    worksheet.mergeCells('R5:S5');
    worksheet.mergeCells('T5:U5');
    allData.branchSmsReports.forEach((d, index) => {
      console.log('d', d.smsReports[0], index);
      const arraySms = [d.branchName, d.smsReports[0].sent, d.smsReports[0].failed, d.smsReports[1].sent, d.smsReports[1].failed, d.smsReports[2].sent, d.smsReports[2].failed, d.smsReports[3].sent, d.smsReports[3].failed, d.smsReports[4].sent, d.smsReports[4].failed, d.smsReports[5].sent, d.smsReports[5].failed, d.smsReports[6].sent, d.smsReports[6].failed, d.smsReports[7].sent, d.smsReports[7].failed, d.smsReports[8].sent, d.smsReports[8].failed, d.smsReports[9].sent, d.smsReports[9].failed]
     const row = worksheet.addRow(arraySms);
     const smsCell1 = row.getCell(2);
     const smsCell2 = row.getCell(3);
     const smsCell3 = row.getCell(4);
     const smsCell4 = row.getCell(5);
     const smsCell5 = row.getCell(6);
     const smsCell6 = row.getCell(7);
     const smsCell7 = row.getCell(8);
     const smsCell8 = row.getCell(9);
     const smsCell9 = row.getCell(10);
     const smsCell10 = row.getCell(11);
     const smsCell11 = row.getCell(12);
     const smsCell12 = row.getCell(13);
     const smsCell13 = row.getCell(14);
     const smsCell14 = row.getCell(15);
     const smsCell15 = row.getCell(16);
     const smsCell16 = row.getCell(17);
     const smsCell17 = row.getCell(18);
     const smsCell18 = row.getCell(19);
     const smsCell19 = row.getCell(20);
     const smsCell20 = row.getCell(21);
     smsCell1.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell2.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell3.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell4.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell5.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell6.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell7.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell8.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell9.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell10.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell11.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell12.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell13.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell14.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell15.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell16.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell17.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell18.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell19.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell20.alignment = { vertical: 'middle', horizontal: 'center' };
    }
  );
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 20;
    worksheet.getColumn(11).width = 20;
    worksheet.getColumn(12).width = 20;
    worksheet.getColumn(13).width = 20;
    worksheet.getColumn(14).width = 20;
    worksheet.getColumn(15).width = 20;
    worksheet.getColumn(16).width = 20;
    worksheet.getColumn(17).width = 20;
    worksheet.getColumn(18).width = 20;
    worksheet.getColumn(19).width = 20;
    worksheet.getColumn(20).width = 20;
    worksheet.getColumn(21).width = 20;
  } else if (allData.branchSmsReports[0].smsReports.length === 11) {
    worksheet.mergeCells('B5:C5');
    worksheet.mergeCells('D5:E5');
    worksheet.mergeCells('F5:G5');
    worksheet.mergeCells('H5:I5');
    worksheet.mergeCells('J5:K5');
    worksheet.mergeCells('L5:M5');
    worksheet.mergeCells('N5:O5');
    worksheet.mergeCells('P5:Q5');
    worksheet.mergeCells('R5:S5');
    worksheet.mergeCells('T5:U5');
    worksheet.mergeCells('V5:W5');
    allData.branchSmsReports.forEach((d, index) => {
      console.log('d', d.smsReports[0], index);
      const arraySms = [d.branchName, d.smsReports[0].sent, d.smsReports[0].failed, d.smsReports[1].sent, d.smsReports[1].failed, d.smsReports[2].sent, d.smsReports[2].failed, d.smsReports[3].sent, d.smsReports[3].failed, d.smsReports[4].sent, d.smsReports[4].failed, d.smsReports[5].sent, d.smsReports[5].failed, d.smsReports[6].sent, d.smsReports[6].failed, d.smsReports[7].sent, d.smsReports[7].failed, d.smsReports[8].sent, d.smsReports[8].failed, d.smsReports[9].sent, d.smsReports[9].failed, d.smsReports[10].sent, d.smsReports[10].failed]
     const row = worksheet.addRow(arraySms);
     const smsCell1 = row.getCell(2);
     const smsCell2 = row.getCell(3);
     const smsCell3 = row.getCell(4);
     const smsCell4 = row.getCell(5);
     const smsCell5 = row.getCell(6);
     const smsCell6 = row.getCell(7);
     const smsCell7 = row.getCell(8);
     const smsCell8 = row.getCell(9);
     const smsCell9 = row.getCell(10);
     const smsCell10 = row.getCell(11);
     const smsCell11 = row.getCell(12);
     const smsCell12 = row.getCell(13);
     const smsCell13 = row.getCell(14);
     const smsCell14 = row.getCell(15);
     const smsCell15 = row.getCell(16);
     const smsCell16 = row.getCell(17);
     const smsCell17 = row.getCell(18);
     const smsCell18 = row.getCell(19);
     const smsCell19 = row.getCell(20);
     const smsCell20 = row.getCell(21);
     const smsCell21 = row.getCell(22);
     const smsCell22 = row.getCell(23);
     smsCell1.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell2.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell3.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell4.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell5.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell6.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell7.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell8.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell9.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell10.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell11.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell12.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell13.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell14.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell15.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell16.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell17.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell18.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell19.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell20.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell21.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell22.alignment = { vertical: 'middle', horizontal: 'center' };
    }
  );
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 20;
    worksheet.getColumn(11).width = 20;
    worksheet.getColumn(12).width = 20;
    worksheet.getColumn(13).width = 20;
    worksheet.getColumn(14).width = 20;
    worksheet.getColumn(15).width = 20;
    worksheet.getColumn(16).width = 20;
    worksheet.getColumn(17).width = 20;
    worksheet.getColumn(18).width = 20;
    worksheet.getColumn(19).width = 20;
    worksheet.getColumn(20).width = 20;
    worksheet.getColumn(21).width = 20;
    worksheet.getColumn(22).width = 20;
    worksheet.getColumn(23).width = 20;
  } else {
    worksheet.mergeCells('B5:C5');
    worksheet.mergeCells('D5:E5');
    worksheet.mergeCells('F5:G5');
    worksheet.mergeCells('H5:I5');
    worksheet.mergeCells('J5:K5');
    worksheet.mergeCells('L5:M5');
    worksheet.mergeCells('N5:O5');
    worksheet.mergeCells('P5:Q5');
    worksheet.mergeCells('R5:S5');
    worksheet.mergeCells('T5:U5');
    worksheet.mergeCells('V5:W5');
    worksheet.mergeCells('X5:Y5');
    allData.branchSmsReports.forEach((d, index) => {
      console.log('d', d.smsReports[0], index);
      const arraySms = [d.branchName, d.smsReports[0].sent, d.smsReports[0].failed, d.smsReports[1].sent, d.smsReports[1].failed, d.smsReports[2].sent, d.smsReports[2].failed, d.smsReports[3].sent, d.smsReports[3].failed, d.smsReports[4].sent, d.smsReports[4].failed, d.smsReports[5].sent, d.smsReports[5].failed, d.smsReports[6].sent, d.smsReports[6].failed, d.smsReports[7].sent, d.smsReports[7].failed, d.smsReports[8].sent, d.smsReports[8].failed, d.smsReports[9].sent, d.smsReports[9].failed, d.smsReports[10].sent, d.smsReports[10].failed, d.smsReports[11].sent, d.smsReports[11].failed]
     const row = worksheet.addRow(arraySms);
     const smsCell1 = row.getCell(2);
     const smsCell2 = row.getCell(3);
     const smsCell3 = row.getCell(4);
     const smsCell4 = row.getCell(5);
     const smsCell5 = row.getCell(6);
     const smsCell6 = row.getCell(7);
     const smsCell7 = row.getCell(8);
     const smsCell8 = row.getCell(9);
     const smsCell9 = row.getCell(10);
     const smsCell10 = row.getCell(11);
     const smsCell11 = row.getCell(12);
     const smsCell12 = row.getCell(13);
     const smsCell13 = row.getCell(14);
     const smsCell14 = row.getCell(15);
     const smsCell15 = row.getCell(16);
     const smsCell16 = row.getCell(17);
     const smsCell17 = row.getCell(18);
     const smsCell18 = row.getCell(19);
     const smsCell19 = row.getCell(20);
     const smsCell20 = row.getCell(21);
     const smsCell21 = row.getCell(22);
     const smsCell22 = row.getCell(23);
     const smsCell23 = row.getCell(24);
     const smsCell24 = row.getCell(25);
     smsCell1.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell2.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell3.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell4.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell5.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell6.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell7.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell8.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell9.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell10.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell11.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell12.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell13.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell14.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell15.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell16.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell17.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell18.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell19.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell20.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell21.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell22.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell23.alignment = { vertical: 'middle', horizontal: 'center' };
     smsCell24.alignment = { vertical: 'middle', horizontal: 'center' };
    }
  );
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 20;
    worksheet.getColumn(11).width = 20;
    worksheet.getColumn(12).width = 20;
    worksheet.getColumn(13).width = 20;
    worksheet.getColumn(14).width = 20;
    worksheet.getColumn(15).width = 20;
    worksheet.getColumn(16).width = 20;
    worksheet.getColumn(17).width = 20;
    worksheet.getColumn(18).width = 20;
    worksheet.getColumn(19).width = 20;
    worksheet.getColumn(20).width = 20;
    worksheet.getColumn(21).width = 20;
    worksheet.getColumn(22).width = 20;
    worksheet.getColumn(23).width = 20;
    worksheet.getColumn(24).width = 20;
    worksheet.getColumn(25).width = 20;
  }
  // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data: any) => {
  const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
  fs.saveAs(blob, 'SMS Report per Distributor_YearGenerated.xlsx');
  }).catch(error => console.log('errorrrr', error));

  }

  generateModule(allData) {
    // Excel Title, Header, Data
    console.log('allData', allData.data);
    if (allData.reportType === 'branch-list') {
      var title = `Branch List`;
      var header = ['Branch Name', 'Branch Id', 'Total Queues', 'Consumed SMS Today', 'Status', 'Date Added', 'Added by'];
    } else if (allData.reportType === 'customer-list') {
      var title = `Customer List`;
      var header = ['Name', 'Mobile Number', 'Last Login', 'Total Queues', 'Status'];
    } else if (allData.reportType === 'device-list') {
      var title = `Device List`;
      var header = ['Device Id', 'Model', 'Manufacturer', 'Status', 'Distributor', 'Date Added', 'Added by'];
    } else {
      var title = `Distributor List`;
      var header = ['Distributor Name', 'Contact Person', 'Contact Number', 'No. of Clients', 'Status'];
    }

    // Create workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Account report');

    // Add Row and formatting
      const titleRow = worksheet.addRow([title]);
      titleRow.font = { name: 'Arial', family: 4, size: 14 };
      const subTitleRow = worksheet.addRow(['Reported Generated By: ' + allData.actionBy.firstName + allData.actionBy.lastName]);
      const subTitleRow2 = worksheet.addRow(['Date Generated: ' + this.formatDate(this.now)]);

    // Blank Row
      worksheet.addRow([]);

    // Add Header Row
      const headerRow = worksheet.addRow(header);

    // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
        cell.font = { name: 'Arial', bold: true};
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
      });

    // Add Data and Conditional Formatting
      // branch
    if(allData.reportType === 'branch-list') {
      allData.data.forEach(d => {
        if (d.isSuspended) {
          var suspend = 'Suspended'
        } else {
          var suspend = 'Active'
        }
        const fullName = d.addedBy.firstName + d.addedBy.lastName;
        const arraySms = [d.branchName, d.branchId, d.totalQueues, d.smsCountToday.totalValue, suspend, this.formatDate(d.createdAt), fullName];
        const row = worksheet.addRow(arraySms);
        const number1 = row.getCell(3);
        const number2 = row.getCell(4);
        const number3 = row.getCell(5);
        number1.alignment = { vertical: 'middle', horizontal: 'center' };
        number2.alignment = { vertical: 'middle', horizontal: 'center' };
        number3.alignment = { vertical: 'middle', horizontal: 'center' };
      }
    );
      // customer
    } else if (allData.reportType === 'customer-list') {
      allData.data.forEach(d => {
        if (d.isSuspended) {
          var suspend = 'Suspended'
        } else {
          var suspend = 'Active'
        }
        const arraySms = [d.fullName, d.mobileNo.value, this.formatDate(d.lastSignedIn), d.totalQueuesCompleted, suspend];
        const row = worksheet.addRow(arraySms);
        const number2 = row.getCell(4);
        const number3 = row.getCell(5);
        number2.alignment = { vertical: 'middle', horizontal: 'center' };
        number3.alignment = { vertical: 'middle', horizontal: 'center' };
      }
    );
      // device
    } else if (allData.reportType === 'device-list') {
      allData.data.forEach(d => {
        if (d.isSuspended && d.distributor !== null) {
          var suspend = 'Suspended'
        } else {
          var suspend = 'Active'
        }
        const fullName = d.addedBy.firstName + d.addedBy.lastName;
        const arraySms = [d.refId, d.modelType, d.manufacturer, suspend, d.distributor ? d.distributor.name : 'No Distributor', this.formatDate(d.createdAt), fullName];
        const row = worksheet.addRow(arraySms);
        // const number1 = row.getCell(3);
        const number2 = row.getCell(4);
        // number1.alignment = { vertical: 'middle', horizontal: 'center' };
        number2.alignment = { vertical: 'middle', horizontal: 'center' };
      }
    );
      // distributor
    } else {
      allData.data.forEach(d => {
        if (d.isSuspended) {
          var suspend = 'Suspended'
        } else {
          var suspend = 'Active'
        }
        const arraySms = [d.name, d.contactPerson, d.mobileNo, d.businessBranches.length, suspend];
        const row = worksheet.addRow(arraySms);
        const number2 = row.getCell(4);
        const number3 = row.getCell(5);
        number2.alignment = { vertical: 'middle', horizontal: 'center' };
        number3.alignment = { vertical: 'middle', horizontal: 'center' };
      }
    );
    }

    // width
      if (allData.reportType === 'branch-list' || allData.reportType === 'device-list') {
        worksheet.getColumn(1).width = 30;
        worksheet.getColumn(2).width = 30;
        worksheet.getColumn(3).width = 30;
        worksheet.getColumn(4).width = 30;
        worksheet.getColumn(5).width = 30;
        worksheet.getColumn(6).width = 30;
        worksheet.getColumn(7).width = 30;
      } else {
        worksheet.getColumn(1).width = 30;
        worksheet.getColumn(2).width = 30;
        worksheet.getColumn(3).width = 30;
        worksheet.getColumn(4).width = 30;
        worksheet.getColumn(5).width = 30;
      }
  // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data: any) => {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    fs.saveAs(blob, 'Account Report.xlsx');
    });
}

  ngOnInit() {
    this.selectedDates = 'month';
    this.selectedDates1 = this.current;
    console.log('this.dateYear',this.dateYear);
    this.branchId = localStorage.getItem('branchId');
    this.checkMonths();
    this.checkYear();
    this.getDistributorList();
    this.selected = 0;
    this.selectedFilter = '';
    this.selectedFilter2 = '';
    console.log('this.type', this.type);
    console.log('this.filter', this.filter);
  }

}

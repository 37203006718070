import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, FormArray, ControlContainer, FormGroupDirective } from '@angular/forms';
import { HttpService } from 'src/app/core/http/http.service';
import { BusinessService } from 'src/app/core/services/business.service';
import { CropPhotoComponent } from 'src/app/indie/crop-photo/crop-photo.component';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-main-branch-main-info',
  templateUrl: './main-branch-main-info.component.html',
  styleUrls: ['./main-branch-main-info.component.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }]
})
export class MainBranchMainInfoComponent implements OnInit, OnDestroy {
  @Input() branchData: any;
  imgUrl: any;
  imgUrl2: any;
  industryList: Array<any>;
  categoryList: Array<any>;
  errorMsg: string;
  formDirty: boolean;
  submitting: boolean;
  selectedAvatar: any;
  selectedCoverPhoto: any;
  disabledRemove: boolean;
  countryList = ['Philippines (+63)'];
  avatarError: boolean;
  bannerError: boolean;
  subscriptions = new Subscription();

  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    private modalService: NgbModal,
    private businessService: BusinessService,
    private parent: FormGroupDirective
  ) { }

  branchMain = this.fb.group({
    branchName: [null, Validators.required],
    branchIdText: [null, Validators.required],
    branchEmail: [null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9\_\-\.]+\@[a-zA-Z0-9]{1,100}(\.[a-zA-Z0-9]{1,7}){1,3}$/)]],
    country: [{value: 'Philippines (+63)', disabled: true}, Validators.required],
    contactNumbers: this.fb.array([
      this.fb.group({
        type: ['mobile'],
        number: [null, [Validators.required, Validators.minLength(12), Validators.pattern(/^9/)]],
        isPrimary: true
      })
    ])
  });

  get f() { return this.branchMain.controls; }
  get contactNumbers(): any { return this.branchMain.get('contactNumbers') as FormArray; }

  patchValue() {
    console.log('patchValue');
    this.imgUrl = this.branchData.avatarUrl ? this.branchData.avatarUrl : './assets/images/koala.jpg';
    this.selectedAvatar = this.imgUrl;
    this.imgUrl2 = this.branchData.bannerUrl ? this.branchData.bannerUrl : './assets/images/cover-photo.png';
    this.selectedCoverPhoto = this.imgUrl2;
    this.branchMain.patchValue({
      branchName: this.branchData.branchName,
      branchIdText: this.branchData.branchId,
      branchEmail: this.branchData.email,
      country: this.branchData.country ? this.branchData.country : 'Philippines (+63)'
    });
    this.branchData.contacts.forEach((element, index) => {
      this.contactNumbers.push(
        this.fb.group({
          type: element.type ? element.type : 'mobile',
          number: element.type === 'mobile' ? this.patchMobile(element.number) : element.number,
          isPrimary: element.isPrimary
        })
      );
    });
    this.contactNumbers.removeAt(0);
    this.disableRemove();
    this.contactNumbers.value.forEach((element, index) => {
      this.changeContact(index);
    });
  }

  patchMobile(mobile) {
    const number1 = mobile.substring(0, 3);
    const number2 = mobile.substring(3, 6);
    const number3 = mobile.substring(6, 11);
    return `${number1}-${number2}-${number3}`;
  }

  transformMobile(mobile) {
    return mobile.replace(/\D/g, '');
  }

  onAvatarChange($event) {
    this.selectedAvatar = $event.target.files[0] as File;
    if (['image/png', 'image/jpeg'].includes($event.target.files[0].type)) {
      this.openCropModal($event, 'avatar-circle', 'Select Branch Logo');
    } else {
      this.avatarError = true;
      this.businessService.publishEnableEdit$(false);
    }
  }

  onCoverPhotoChange($event) {
    this.selectedCoverPhoto = $event.target.files[0] as File;
    if (['image/png', 'image/jpeg'].includes($event.target.files[0].type)) {
      this.openCropModal($event, 'cover-banner', 'Select Branch Banner');
    } else {
      this.bannerError = true;
      this.businessService.publishEnableEdit$(false);
    }
  }

  checkCroppedAvatar() {
    this.subscriptions.add(this.businessService.croppedPhoto$.subscribe(res => {
      this.businessService.selectedAvatar$ = res.image;
      console.log('this.croppedImg', this.businessService.selectedAvatar$);
      this.imgUrl = res.image;
      this.businessService.fileName$ = res.fileName;
      this.businessService.fileType$ = res.fileType;
      this.formDirty = true;
      this.businessService.publishEnableEdit$(true);
      this.avatarError = false;
    }));
  }

  checkCroppedBanner() {
    this.subscriptions.add(this.businessService.croppedBanner$.subscribe(res => {
      this.businessService.selectedCoverPhoto$ = res.image;
      console.log('this.croppedImg2', this.businessService.selectedCoverPhoto$);
      this.imgUrl2 = res.image;
      this.businessService.fileName2$ = res.fileName;
      this.businessService.fileType2$ = res.fileType;
      this.formDirty = true;
      this.businessService.publishEnableEdit$(true);
      this.bannerError = false;
    }));
  }

  openCropModal(avatar, type, title) {
    const modalRef = this.modalService.open(CropPhotoComponent, {
      centered: true,
      windowClass: `modal-${type}`
    });
    modalRef.componentInstance.imageFile = avatar;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.cropType = type;
  }

   dataURItoBlob(dataURI) {
    return this.businessService.dataURItoBlob(dataURI);
  }

  // Contact Number
  changeContact(i) {
    console.log('changeContact', this.contactNumbers.controls[i].controls['type'].value);
    if (this.contactNumbers.controls[i].controls['type'].value) {
      if (this.contactNumbers.controls[i].controls['type'].value === 'mobile') {
        this.contactNumbers.controls[i].controls['number'].setValidators([Validators.required, Validators.minLength(12), Validators.pattern(/^9/)]);
        this.contactNumbers.controls[i].controls['number'].updateValueAndValidity();
      } else {
        this.contactNumbers.controls[i].controls['number'].clearValidators();
        this.contactNumbers.controls[i].controls['number'].setValidators([Validators.required, Validators.minLength(7)]);
        this.contactNumbers.controls[i].controls['number'].updateValueAndValidity();
      }
    }
  }

  changePrimary(i) {
    this.contactNumbers.controls.forEach((element, index) => {
      console.log('element', index, element.controls['isPrimary'].value);
      if (i !== index) {
        element.controls['isPrimary'].setValue(false);
      } else {
        element.controls['isPrimary'].setValue(true);
      }
    });
  }

  addContact() {
    this.contactNumbers.push(
      this.fb.group({
        type: [ 'mobile'],
        number: [null, [Validators.required, Validators.minLength(12), Validators.pattern(/^9/)]],
        isPrimary: false
      })
    );
    this.disableRemove();
  }

  disableRemove() {
    console.log('contactNumbers.length', this.contactNumbers.length);
    if (this.contactNumbers.length <= 1) {
      this.disabledRemove = true;
    } else {
      this.disabledRemove = false;
    }
  }

  deleteContact(i) {
    this.contactNumbers.removeAt(i);
    this.disableRemove();
    console.log('contactNumbers.length', this.contactNumbers);
    if (this.contactNumbers.length <= 1) {
     this.contactNumbers.controls[0].controls['isPrimary'].setValue(true);
    }
    this.businessService.publishEnableEdit$(true);
  }

  checkStatusCodeList() {
    this.subscriptions.add(this.businessService.statusCodeList$.subscribe(res => {
      if (res.includes(10106)) {
        this.errorMsg = 'Branch ID already exist.';
      }
    }));
  }

  clearBackendError(input) {
    if (input.dirty) {
      this.errorMsg = null;
    }
  }

  ngOnInit() {
    this.checkCroppedAvatar();
    this.checkCroppedBanner();
    if (this.branchData) {
      this.patchValue();
    } else {
      this.disableRemove();
      this.imgUrl = './assets/images/koala.jpg';
      this.imgUrl2 = './assets/images/cover-photo.png';
    }
    console.log('this.parent.form', this.parent.form);
    this.parent.form.addControl(
      'branchMain', this.branchMain
    );
    this.checkStatusCodeList();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BusinessService } from 'src/app/core/services/business.service';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerAppAddFormComponent } from '../../components/customer-app-add-form/customer-app-add-form.component';
import { DragulaService } from 'ng2-dragula';
import { BannerViewComponent } from '../../components/banner-view/banner-view.component';
import { UserRolePermissionsComponent } from 'src/app/shared/components/user-role-permissions/user-role-permissions.component';

@Component({
  selector: 'app-customer-banner',
  templateUrl: './customer-banner.component.html',
  styleUrls: ['./customer-banner.component.scss']
})
export class CustomerBannerComponent extends UserRolePermissionsComponent implements OnInit, OnDestroy {
  bannersList: Array<any>;
  formDirty: boolean;
  submitting: boolean;
  editedArray: Array<any>;
  addedList: any;
  successUpload: number;

  constructor(
    businessService: BusinessService,
    private httpAdminService: HttpWebAdminService,
    private modalService: NgbModal,
    private dragulaService: DragulaService
  ) {
    super(businessService)
  }

  getBannersList() {
    this.httpAdminService.get$(`partners/banner`).subscribe(
      data => {
        console.log('customer app banners', data);
        this.bannersList = data.data;
      }, error => {
        console.log('error', error);
      }
    );
  }

  checkIfBannerAdd() {
    this.subscriptions.add(this.businessService.bannersList$.subscribe(res => {
      console.log('checkIfBannerAdd', res);
      console.log('is edited', res.banner._id);
      if (this.editedArray) {
        if (!this.editedArray.includes(res.banner._id)) {
          this.editedArray.push(res.banner._id);
        }
      }
      console.log('editedArray', this.editedArray);
      this.formDirty = true;
      this.businessService.publishBannerDiscover$(true);
      if (res.i !== undefined) {
        this.bannersList.splice(res.i, 1, res.banner);
      } else {
        this.bannersList.unshift(res.banner);
      }
      console.log('banners list', this.bannersList);
    }));
  }

  addModified() {
    this.bannersList = this.bannersList.map(element => {
      return {...element, isModified: (this.editedArray.includes(element._id) && element._id !== '') || this.addedList ? true : false };
    });
    console.log('new set', this.bannersList);
    this.addedList = this.bannersList.find(element => element._id === '');
    console.log('added list', this.addedList);
  }

  checkDrag() {
    this.subscriptions.add(this.dragulaService.dropModel('bannerModel')
      .subscribe(({ sourceIndex, targetIndex, sourceModel, targetModel, target, item }) => {
        this.formDirty = true;
        this.businessService.publishBannerDiscover$(true);
        this.bannersList = targetModel;
        console.log('array', targetModel);
        targetModel.forEach((element, index) => {
          console.log(element.sortNo, index);
          if (element.sortNo !== index) {
            if (this.editedArray) {
              if (!this.editedArray.includes(targetModel[index]._id)) {
                this.editedArray.push(targetModel[index]._id);
              }
            }
          }
        });
        console.log('editedArray', this.editedArray);
        console.log('new', this.bannersList);
      })
    );
  }

  checkDataChange() {
    this.subscriptions.add(this.businessService.alert$.subscribe(res => {
      if (res) {
        if (!res.message.includes(`<b>Error:</b> Something went wrong.`)) {
          this.getBannersList();
          this.checkDrag();
        }
      }
    }));
  }

  checkRemoveBanner() {
    this.subscriptions.add(this.businessService.removeBanner$.subscribe(res => {
      console.log('checkRemoveBanner', res);
      this.bannersList.splice(res, 1);
      this.formDirty = true;
      this.businessService.publishBannerDiscover$(true);
    }));
  }

  viewBanner(banner, index) {
    const modalRef = this.modalService.open(BannerViewComponent, {
      centered: true
    });
    modalRef.componentInstance.bannerData = banner;
    modalRef.componentInstance.index = index;
  }

  addBanner() {
    const modalRef = this.modalService.open(CustomerAppAddFormComponent, {
      centered: true
    });
    modalRef.componentInstance.title = 'Add Banner';
    this.formDirty = true;
    this.businessService.publishBannerDiscover$(true);
  }

  saveBanners() {
    this.addModified();
    this.bannersList.forEach((element, index) => {
      this.saveSingleBanner(element, index);
    });
  }

  saveSingleBanner(banner, sortIndex) {
    this.submitting = true;
    const {sortNo, isModified, ...form} = banner;
    const newForm = {
      sortNo: sortIndex,
      isModified: (this.editedArray.includes(banner._id) && banner._id !== '') || this.addedList ? true : false,
      ...form };
    const body = new FormData();
    for (const key in newForm) {
      if (newForm.hasOwnProperty(key)) {
        body.append(key, newForm[key]);
        console.log(key, newForm[key]);
      }
    }
    if (banner.fileName) {
      if (!banner.fileName.includes('http')) {
        body.append('bannerImage', new File([new Blob([this.businessService.dataURItoBlob(banner.bannerImage)], {type: banner.fileType})], banner.fileName, {type: banner.fileType}));
        console.log('bannerImage', new File([new Blob([this.businessService.dataURItoBlob(banner.bannerImage)], {type: banner.fileType})], banner.fileName, {type: banner.fileType}));
      }
    }
    this.httpAdminService.post$(`partners/banner`, body).subscribe(
      data => {
        console.log('submitted', data);
        this.successUpload ++;
        console.log('banners and success', this.bannersList.length, this.successUpload);
        if (this.bannersList.length === this.successUpload) {
          console.log('must refresh');
          this.businessService.publishAlert$({
            type: 'green-dark-01',
            icon: 'fas fa-check',
            message: `<b>Success!</b> Banner list has been updated.`
          });
          this.getBannersList();
          this.formDirty = false;
          this.businessService.publishBannerDiscover$(false);
          this.submitting = false;
          this.editedArray = [];
          this.successUpload = 0;
        }
      }, error => {
        console.log('error', error);
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Something went wrong.</b> Please try again.`
        });
        this.formDirty = false;
        this.businessService.publishBannerDiscover$(false);
        this.submitting = false;
      }
    );
  }

  getAccountData() {
    this.getAccountPromise();
    this.accountPromise
    .then((data) => {      
      console.log('promise account', data);
      this.setAccountItems(data);
      // Insert all functions related to Account Data
      // ----- End ----- //
    })
    .catch((error) => {
      console.log('promise err', error);
    });
  }

  ngOnInit() {
     // Needed in User Role
     this.getAccountData();
     // --------- //
    this.getBannersList();
    this.checkDrag();
    this.checkIfBannerAdd();
    this.checkDataChange();
    this.checkRemoveBanner();
    this.editedArray = [];
    this.successUpload = 0;
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

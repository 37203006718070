import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpService } from 'src/app/core/http/http.service';
import { ConfirmPasswordValidator } from 'src/app/shared/validators/confirm-password.validator.service';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';

@Component({
  selector: 'app-customer-password-page',
  templateUrl: './customer-password-page.component.html',
  styleUrls: ['./customer-password-page.component.scss']
})
export class CustomerPasswordPageComponent implements OnInit, OnDestroy {

  branchId: string;
  errorMsg: string;
  passwordType: any;
  show: boolean;
  passwordType2: any;
  show2: boolean;
  inputFocus: any;
  isMatch1: boolean;
  isMatch2: boolean;
  isMatch3: boolean;
  token: string;
  type: any;
  submitting: boolean;
  setPasswordToken: string;
  subscriptions = new Subscription();

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private httpAdminService: HttpWebAdminService,
    private router: Router
  ) { }

  passwordForm = this.fb.group({
    setPasswordToken: [''],
    password: ['', [Validators.pattern(/(?=^.{6,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/)]],
    confirmPwd: ['', Validators.required]
  }, {
    validator: ConfirmPasswordValidator.MatchPassword
  });

  get f() { return this.passwordForm.controls; }

  showHidePw() {
    this.show = !this.show;
    this.show ? this.passwordType = 'text' : this.passwordType = 'password';
    console.log(this.passwordType);
  }

  showHidePw2() {
    this.show2 = !this.show2;
    this.show2 ? this.passwordType2 = 'text' : this.passwordType2 = 'password';
    console.log(this.passwordType);
  }

  checkPwPattern(newPwd) {
    console.log(newPwd);
    // At least 6 characters
    newPwd.match(/(?=^.{6,}$)/) ? this.isMatch1 = true : this.isMatch1 = false;
    newPwd.match(/(?=.*[A-Z])/) ? this.isMatch2 = true : this.isMatch2 = false;
    newPwd.match(/(?=.*[0-9])/) ? this.isMatch3 = true : this.isMatch3 = false;
  }

  getUrlParams() {
    this.subscriptions.add(this.activatedRoute.queryParams.subscribe(params => {
      this.token =  params['token'];
      this.type = params['type'];
      this.branchId = params['branchId'];
    }));
  }

  validateToken() {
      // if verify
        this.httpAdminService.get3$(`auth/verify-email/${this.token}`).subscribe(data => {
          console.log('data', data);
          this.router.navigate([`/password-success/${this.type}`]);
        }, error => {
          console.log('error', error);
          this.router.navigate([`/password-failed/${this.type}`]);
        });
  }

  submitForm(f) {
    f.disable();
      this.submitting = true;
      console.log('Customer App reset');
      const {password} = f.value;
      const body = { newPassword: password};
      console.log('type and form', this.type, body);
      this.httpAdminService.patch3$(`auth/forgot-password/change-password/${this.token}`, body).subscribe(data => {
        f.enable();
        console.log('data', data);
        this.router.navigate([`password-success/CUSTOMER_SUCCESS`]);
        this.submitting = false;
      }, error => {
        f.enable();
        console.log('error', error);
        this.errorMsg = error.error.source;
        this.submitting = false;
      });
  }

  ngOnInit() {
    this.getUrlParams();
    console.log('this.type', this.type);
    this.passwordType = 'password';
    this.passwordType2 = 'password';
    if (this.type === 'CUSTOMER_VERIFY') {
      this.validateToken();
    }
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BusinessService } from 'src/app/core/services/business.service';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormArray } from '@angular/forms';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';
import { CropPhotoComponent } from 'src/app/indie/crop-photo/crop-photo.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-industry-add-edit-form',
  templateUrl: './industry-add-edit-form.component.html',
  styleUrls: ['./industry-add-edit-form.component.scss']
})
export class IndustryAddEditFormComponent implements OnInit, OnDestroy  {
  @ViewChild('mydiv', { static: false }) mydiv: ElementRef;
  title: string;
  @Input() branchData: any;
  imgAvatar: any;
  imgBanner: any;
  imgBranch: any;
  imgMascot: any;
  selectedAvatar: any;
  selectedBanner: any;
  selectedBranch: any;
  selectedMascot: any;
  formDirty: boolean;
  imageDirty1: boolean;
  imageDirty2: boolean;
  category = [null];
  industryData: any;
  submitting: boolean;
  errorMsg: string;
  enableSubmit: boolean;
  enableEdit: boolean;
  subscriptions = new Subscription();

  croppedImg: any;
  croppedImg2: any;
  croppedImg3: any;
  croppedImg4: any;
  fileName: string;
  fileName2: string;
  fileName3: string;
  fileName4: string;
  fileType: string;
  fileType2: string;
  fileType3: string;
  fileType4: string;

  checkImage1: any;
  checkImage2: any;

  bannerFileName: string;
  bannerFileName2: string;
  bannerFileName3: string;

  avatarError: boolean;
  bannerError: boolean;
  mascottError: boolean;
  branchError: boolean;
  statusCodeList: Array<any>;
  categoryErrorMsg: string;
  duplicateCat: string;
  dupObject = {};
  dupRes = [];
  res = [];
  catArray = [];
  test1: any;
  test2: any;
  newArr = [];

  constructor(
    public router: Router,
    private httpAdminService: HttpWebAdminService,
    private businessService: BusinessService,
    private fb: FormBuilder,
    private modalService: NgbModal
  ) { }

  industryForm = this.fb.group({
    industryName: [null, Validators.required],
    shortName: [null, [Validators.maxLength(15), Validators.required]],
    categoryList: this.fb.array([null])
  });

  get categoryList(): any { return this.industryForm.get('categoryList') as FormArray; }

  get f() {return this.industryForm.controls; }

  onAvatarChange($event) {
    this.selectedAvatar = $event.target.files[0] as File;
    if (['image/png', 'image/jpeg'].includes($event.target.files[0].type)) {
      this.openCropModal($event, 'avatar-circle', 'Select Industry Logo');
    } else {
      this.avatarError = true;
    }
  }
  onBannerChange($event) {
    console.log('banner');
    this.bannerFileName = $event.target.files[0].name;
    this.selectedBanner = $event.target.files[0] as File;
    if (['image/png', 'image/PNG', 'image/jpeg'].includes($event.target.files[0].type)) {
      this.openCropModal($event, 'cover-banner', 'Select Industry Background');
    } else {
      this.bannerError = true;
    }
  }
  onBranchChange($event) {
    console.log('branch');
    this.bannerFileName2 = $event.target.files[0].name;
    this.selectedBranch = $event.target.files[0] as File;
    if (['image/png', 'image/jpeg'].includes($event.target.files[0].type)) {
      this.openCropModal($event, 'login-image', 'Select Industry Photo');
    } else {
      this.branchError = true;
    }
  }
  onMascotChange($event) {
    console.log('Mascot');
    this.bannerFileName3 = $event.target.files[0].name;
    this.selectedMascot = $event.target.files[0] as File;
    if (['image/png', 'image/jpeg'].includes($event.target.files[0].type)) {
      this.openCropModal($event, 'cover-mascot', 'Select Industry Mascot');
    } else {
      this.mascottError = true;
    }
  }

  checkIfDirty() {
    this.imageDirty1 = true;
  }
  checkIfDirty2() {
    this.imageDirty2 = true;
  }
  checkCroppedAvatar() {
    this.subscriptions.add(this.businessService.croppedPhoto$.subscribe(res => {
      console.log('res imagess', res);
      this.croppedImg = res.image;
      console.log('this.croppedImg', this.croppedImg);
      this.imgAvatar = res.image;
      this.fileName = res.fileName;
      this.fileType = res.fileType;
      this.enableSubmit = true;
      this.avatarError = false;
    }));
  }

  checkCroppedBanner() {
    this.subscriptions.add(this.businessService.croppedBanner$.subscribe(res => {
      this.croppedImg2 = res.image;
      this.checkImage1 = res.image;
      console.log('this.croppedImg2', this.croppedImg2);
      this.imgBanner = res.image;
      this.fileName2 = res.fileName;
      this.fileType2 = res.fileType;
      this.enableSubmit = true;
      this.bannerError = false;
      this.imageDirty1 = false;
    }));
  }
  checkCroppedBranch() {
    this.subscriptions.add(this.businessService.croppedLoginImage$.subscribe(res => {
      this.croppedImg3 = res.image;
      console.log('this.croppedImg3', this.croppedImg3);
      this.imgBranch = res.image;
      this.fileName3 = res.fileName;
      this.fileType3 = res.fileType;
      this.enableSubmit = true;
      this.branchError = false;
    }));
  }
  checkCroppedMascot() {
    this.subscriptions.add(this.businessService.croppedMascot$.subscribe(res => {
      this.croppedImg4 = res.image;
      this.checkImage2 = res.image;
      console.log('this.croppedImg4', this.croppedImg4);
      this.imgMascot = res.image;
      this.fileName4 = res.fileName;
      this.fileType4 = res.fileType;
      this.enableSubmit = true;
      this.mascottError = false;
      this.imageDirty2 = false;
    }));
  }

  openCropModal(avatar, type, title) {
    const modalRef = this.modalService.open(CropPhotoComponent, {
      centered: true,
      windowClass: `modal-${type}`
    });
    modalRef.componentInstance.imageFile = avatar;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.cropType = type;
    console.log('image type', avatar.target.files[0].type);
  }

  backToIndustry() {
    this.router.navigate(['/settings/industry-setting/industry']);
    this.industryData = [];
  }

  backToIndustrySingle() {
    this.router.navigate([`/settings/industry-setting/industry/${this.industryData._id}`]);
  }

  delete(index) {
    this.categoryList.removeAt(index);
    this.formDirty = true;
    this.enableSubmit = true;
  }

  addNewTag() {
      this.categoryList.push(this.fb.control(null));
      this.formDirty = true;
  }
  submitForm(f) {
    const body = new FormData();
    body.append('name', f.getRawValue().industryName);
    body.append('shortName', f.getRawValue().shortName);
    if (this.croppedImg) {
      body.append('icon', new File([new Blob([this.businessService.dataURItoBlob(this.croppedImg)], {type: this.fileType})], this.fileName, {type: this.fileType}));
    console.log('avatar', new File([new Blob([this.businessService.dataURItoBlob(this.croppedImg)], {type: this.fileType})], this.fileName, {type: this.fileType}));
    } else {
      body.append('icon', this.imgAvatar);
    }
    if (this.croppedImg2) {
      body.append('profileBanner', new File([new Blob([this.businessService.dataURItoBlob(this.croppedImg2)], {type: this.fileType2})], this.fileName2, {type: this.fileType2}));
    console.log('profileBanner', new File([new Blob([this.businessService.dataURItoBlob(this.croppedImg2)], {type: this.fileType2})], this.fileName2, {type: this.fileType2}));
    } else {
      console.log('this.imgBanner', this.imgBanner);
      body.append('profileBanner', this.imgBanner);
    }
    if (this.croppedImg3) {
      body.append('branchImage', new File([new Blob([this.businessService.dataURItoBlob(this.croppedImg3)], {type: this.fileType3})], this.fileName3, {type: this.fileType3}));
    console.log('branchImage', new File([new Blob([this.businessService.dataURItoBlob(this.croppedImg3)], {type: this.fileType3})], this.fileName3, {type: this.fileType3}));
    } else {
      body.append('branchImage', this.imgBranch);
    }
    if (this.croppedImg4) {
      body.append('mascotImage', new File([new Blob([this.businessService.dataURItoBlob(this.croppedImg4)], {type: this.fileType4})], this.fileName4, {type: this.fileType4}));
    console.log('mascotImage', new File([new Blob([this.businessService.dataURItoBlob(this.croppedImg4)], {type: this.fileType4})], this.fileName4, {type: this.fileType4}));
    } else {
      console.log('this.imgMascot', this.imgMascot);
      body.append('mascotImage', this.imgMascot);
    }
    this.submitting = true;
    f.disable();
    if (this.industryData) {
    const cats = f.getRawValue().categoryList;
    const newCats = cats.map((categoryName, i) => {
      console.log('categoryName', categoryName);
      const category = this.catArray.find((category1, index) => index === i);
      return category ? {name: categoryName, counts: category.counts, createdAt: category.createdAt, _id: category._id} : categoryName;
    });
    console.log('newCats: ', newCats);
    body.append('categoryList', JSON.stringify(newCats));
    console.log('body', body);
      // if edit
      this.httpAdminService.patch$(`industry/${this.industryData._id}`, body).subscribe(
        data => {
          console.log('data added', data);
          this.businessService.publishAlert$({
            type: 'green-dark-01',
            icon: 'fas fa-check',
            message: `<b>Success!</b> — ${data.data.name} has been updated.`
          });
          this.submitting = false;
          this.formDirty = false;
          f.enable();
          f.reset();
          this.router.navigate(['/settings/industry-setting/industry']);
        }, error => {
          console.log('error', error);
          this.showError(error);
          this.submitting = false;
          f.enable();
        }
      );
    } else {
      // if add
      console.log('f.getRawValue().categoryList', f.getRawValue().categoryList);
      body.append('categoryList', JSON.stringify(f.getRawValue().categoryList));
      console.log('body', body);
      this.httpAdminService.post$(`industry`, body).subscribe(
        data => {
          console.log('data added', data);
          this.businessService.publishAlert$({
            type: 'green-dark-01',
            icon: 'fas fa-check',
            message: `<b>New Industry Added!</b> — ${data.data.name} has been added.`
          });
          this.submitting = false;
          this.formDirty = false;
          f.enable();
          f.reset();
          this.router.navigate(['/settings/industry-setting/industry']);
        }, error => {
          console.log('body error', body);
          console.log('error', error);
          this.showError(error);
          this.submitting = false;
          f.enable();
        }
      );
    }
  }

  showError(error) {
    this.statusCodeList = error.error.errors.filter(element => element.msg.statusCode).map(element2 => element2.msg.statusCode);
    console.log('this.statusCodeList', this.statusCodeList);
     if (this.statusCodeList.includes(13532)) {
      this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
      this.businessService.publishAlert$({
        type: 'red-dark-01',
        icon: 'fas fa-ban',
        message: this.errorMsg
      });
    } else if (this.statusCodeList.includes(13533)) {
      this.duplicateCat = `Category Name already exist.`;
      if (typeof error.error.errors[0].value[0] === 'string') {
        error.error.errors[0].value.forEach(item => {
          console.log('itemsss111', item);
          if (!this.dupObject[item]) {
            this.dupObject[item] = 0;
            this.dupObject[item] += 1;
          }
        });
        for (const prop in this.dupObject) {
           if (this.dupObject[prop] >= 2) {
               this.dupRes.push(prop);
           }
        }
      } else {
        error.error.errors[0].value.forEach(item => {
          console.log('itemsss222', item.name);
          if (!this.dupObject[item.name]) {
            this.dupObject[item.name] = 0;
          this.dupObject[item.name] += 1;
          }
        });
        for (const prop in this.dupObject) {
           if (this.dupObject[prop] >= 2) {
              this.dupRes.push(prop);
           }
        }
      }
    } else {
      this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
      this.businessService.publishAlert$({
        type: 'red-dark-01',
        icon: 'fas fa-ban',
        message: this.errorMsg
      });
    }
  }

  clearBackendError(input) {
    if (input.dirty) {
      this.dupRes = [];
      this.categoryErrorMsg = null;
      this.duplicateCat = null;
      this.errorMsg = null;
    }
  }

  patchValue() {
    console.log('industry data', this.industryData);
    this.industryForm = this.fb.group({
      industryName: [null, Validators.required],
      shortName: [null, [Validators.maxLength(15), Validators.required]],
      categoryList: this.fb.array([])
    });
    this.industryForm.patchValue({
      industryName: this.industryData.name,
      shortName: this.industryData.shortName
    });
    this.industryData.categoryList.forEach(cat => {
      console.log('catssss', cat);
      this.categoryList.push(this.fb.control(cat.name));
      this.catArray.push(cat);
    });
    console.log('categoryListsss',  this.categoryList);
    this.bannerFileName = this.industryData.profileBannerUrl;
    this.bannerFileName2 = this.industryData.branchImageUrl;
    this.bannerFileName3 = this.industryData.mascotImageUrl;
  }

  canDeactivate() {
    console.log('canDeactivate');
    if (this.industryForm.dirty || this.formDirty) {
      const modalRef = this.modalService.open(ModalConfirmComponent, {
        centered: true
      });
      modalRef.componentInstance.title = 'Leave Page';
      modalRef.componentInstance.type = 'leave-page';
      return this.businessService.navigateAwaySelection$;
    } else {
      return true;
    }
  }

  checkEnableEdit() {
    console.log('checkEnableEdit');
    this.subscriptions.add(this.businessService.enableEdit$.subscribe(
      res => {
        console.log('enable edit', res);
        this.enableEdit = res;
      }
    ));
  }

  ngOnInit() {
    this.bannerFileName = '';
    this.bannerFileName2 = '';
    this.bannerFileName3 = '';
    this.checkCroppedAvatar();
    this.checkCroppedBanner();
    this.checkCroppedBranch();
    this.checkCroppedMascot();
    console.log('this.categoryList', this.categoryList.value);
    this.checkEnableEdit();
    if (['/settings/industry-setting/industry/add'].includes(this.router.url)) {
      this.title = 'Add Industry';
      this.businessService.industryData$ = null;
    } else {
      this.title = 'Edit Industry';
      this.industryData = this.businessService.industryData$;
    }
    if (this.industryData) {
      this.patchValue();
      this.imgAvatar = this.industryData.iconUrl ? this.industryData.iconUrl : './assets/images/koala.jpg';
      this.imgBanner = this.industryData.profileBannerUrl ? this.industryData.profileBannerUrl : './assets/images/container_img.png';
      this.checkImage1 = this.industryData.profileBannerUrl;
      this.imgBranch = this.industryData.branchImageUrl ? this.industryData.branchImageUrl : './assets/images/container_img.png';
      this.imgMascot = this.industryData.mascotImageUrl ? this.industryData.mascotImageUrl : './assets/images/container_img.png';
      this.checkImage2 = this.industryData.mascotImageUrl;
    } else {
      this.imgAvatar = './assets/images/koala.jpg';
      this.imgBanner = './assets/images/container_img.png';
      this.imgBranch = './assets/images/container_img.png';
      this.imgMascot = './assets/images/container_img.png';
    }
  }

  ngOnDestroy() {
    if (this.businessService.industryData$) {
      this.businessService.industryData$ = null;
      this.businessService.publishEnableEdit$(false);
    }
  }

}

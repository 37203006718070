import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { HttpService } from 'src/app/core/http/http.service';

@Component({
  selector: 'app-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.scss']
})
export class ForgotPasswordPageComponent implements OnInit {
  passwordType: any;
  show: boolean;
  errorMsg: string;
  errorUnverified: boolean;
  returnUrl: string;
  subscriptions = new Subscription();
  submitting: boolean;
  verificationSent: boolean;
  errorEmail: boolean;
  errorSapId: boolean;
  isForgotPassword: boolean;
  background: string;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private modalService: NgbModal
  ) { }

  forgotPasswordForm = this.fb.group({
    identifier:  ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9\_\-\.]+\@[a-zA-Z0-9]{1,12}(\.[a-zA-Z0-9]{1,7}){1,3}$/)]]
  });

  get g(): any { return this.forgotPasswordForm.controls; }

  forgotPassword(f: any) {
    this.submitting = true;
    f.disable();
    const body = f.value;
    this.httpService.post$('auth/forgot-password', body).subscribe(
      data => {
        console.log('forgot password success');
        this.verificationSent = true;
        this.submitting = false;
        f.enable();
      }, error => {
        this.errorMsg = error.error.source;
        this.submitting = false;
        f.enable();
      }
    );
  }

  get f() { return this.forgotPasswordForm.controls; }

  ngOnInit() {
    this.passwordType = 'password';
    this.background = './assets/images/img_kyoo_food.png';
  }

}

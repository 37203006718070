import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BusinessService } from 'src/app/core/services/business.service';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountsAddEditFormComponent } from '../../components/accounts-add-edit-form/accounts-add-edit-form.component';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';

@Component({
  selector: 'app-accounts-single-page',
  templateUrl: './accounts-single-page.component.html',
  styleUrls: ['./accounts-single-page.component.scss']
})
export class AccountsSinglePageComponent implements OnInit, OnDestroy {
  accountData: any;
  imgUrl: any;
  imgAvatar: any;
  imgBanner: any;
  imgBranch: any;
  imgMascot: any;
  dataLists: Array<any>;
  accountId: string;
  myId: string;
  subscriptions = new Subscription();
  // Needed in User Role
  myAccountData: any;
  permission: any;

  constructor(
    private businessService: BusinessService,
    private httpAdminService: HttpWebAdminService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal
  ) { }

  getUrlParams() {
    this.subscriptions.add(this.activatedRoute.params.subscribe(params => {
      this.accountId = params['accountId'];
      console.log('this.industryId', this.accountId);
      this.getAccountData();
    }));
  }

  getAccountData() {
    this.httpAdminService.get$(`accounts/${this.accountId}`).subscribe(
      data => {
        console.log('accounts data', data);
        this.accountData = data;
        this.imgUrl = this.accountData.avatarUrl ? this.accountData.avatarUrl : './assets/images/koala.jpg';
      }, error => {
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Error:</b> Something went wrong.`
        });
      }
    );
  }

  getDataList() {
    this.subscriptions.add(this.businessService.dataLists$.subscribe(
      data => {
        console.log('activity logs', data);
        if (data) {
          this.dataLists = data.data;
        }
      }, error => {
        console.log('error', error);
      }
    ));
  }

  editAccount() {
    const modalRef = this.modalService.open(AccountsAddEditFormComponent, {
      centered: true
    });
    modalRef.componentInstance.title = 'Edit Account';
    modalRef.componentInstance.accountData = this.accountData;
  }

  checkDataChange() {
    this.subscriptions.add(this.businessService.dataChange$.subscribe(
      res => {
        if (res) {
          this.getAccountData();
        }
      }
    ));
  }

  checkAlert() {
    this.subscriptions.add(this.businessService.alert$.subscribe(
      res => {
        if (res) {
          this.getAccountData();
        }
      }
    ));
  }

  suspendAccount() {
    const modalRef = this.modalService.open(ModalConfirmComponent, {
      centered: true
    });
    modalRef.componentInstance.type = 'suspend-admin';
    modalRef.componentInstance.adminTitle = 'Suspend Account';
    modalRef.componentInstance.bodyText = `Are you sure you want to suspend ${this.accountData.firstName} ${this.accountData.firstName}?`;
    modalRef.componentInstance.primaryButton = 'Confirm';
    modalRef.componentInstance.accountData = this.accountData;
  }

  unsuspendAccount() {
    this.httpAdminService.patch$(`accounts/status/${this.accountData._id}`, {isSuspended: false}).subscribe(
      data => {
        this.businessService.publishAlert$({
          type: 'green-dark-01',
          icon: 'fas fa-check',
          message: `<b>${this.accountData.firstName} ${this.accountData.lastName}</b> has been Unsuspended!`
        });
        this.getAccountData();
      }, error => {
        console.log('error');
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: error.error.source
        });
      }
    );
  }

  getAccountData2() {
    this.httpAdminService.get$(`accounts/${this.myId}`).subscribe(
      data => {
        console.log('accounts data', data);
        this.myAccountData = data;
        this.permission = data.roleGroup;
      }, error => {
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Error:</b> Something went wrong.`
        });
      }
    );
  }

  ngOnInit() {
    this.myId = localStorage.getItem('accountId');
    this.getUrlParams();
    this.getDataList();
    this.checkDataChange();
    this.checkAlert();
    // Needed in User Role
    this.getAccountData2();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

import { Component, OnInit, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/core/http/http.service';
import { Subscription, BehaviorSubject, pipe } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessService } from 'src/app/core/services/business.service';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { UserRoleAddEditFormComponent } from '../user-role-add-edit-form/user-role-add-edit-form.component';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';

@Component({
  selector: 'app-user-role-view-modal',
  templateUrl: './user-role-view-modal.component.html',
  styleUrls: ['./user-role-view-modal.component.scss']
})
export class UserRoleViewModalComponent implements OnInit {

  errorMsg: string;
  modulesData: any;
  title: string;
  partnerName = localStorage.getItem('partnerName');
  branchName = localStorage.getItem('branchName');
  submitting: boolean;
  type: any;
  branchId: any;
  totalQueues: any;
  filteredModuleList: Array<any>;
  moduleList: Array<any>;
  collapsed: any = [];
  selectedOne: boolean;
  checkAll: boolean;
  newForm: any;
  subscriptions = new Subscription();
  totalModules = [];
  totalPermission = [];
  modulesWithMinus = [];
  filtModule: any;
  modulesArray: Array<string>;
  modulesNameArray: Array<string>;
  modifiedModulesData: any;
  accountsList: Array<any>;

  constructor(
    private businessService: BusinessService,
    public modalService: NgbModal,
    public httpService: HttpService,
    private httpAdminService: HttpWebAdminService,
    public activeModal: NgbActiveModal
  ) { }

  getAccountsList() {
    this.httpAdminService.get$(`accounts/search/?limit=999&offset=0`).subscribe(
      data => {
        console.log('accounts list', data);
        this.accountsList = data.data;
      }, error => {
        console.log('error', error);
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Error:</b> Something went wrong.`
        });
      }
    );
  }

  getAddedByAvatar(id) {
    const selectedId = this.accountsList.find(element => element._id === id);
    if (selectedId) {
      return selectedId.avatarUrl ? selectedId.avatarUrl : './assets/images/koala.jpg';
    } else {
      return './assets/images/koala.jpg';
    }
  }

  getAddedByName(id) {
    const selectedId = this.accountsList.find(element => element._id === id);
    if (selectedId) {
      return selectedId.name ? (selectedId.name ? selectedId.name : '—') : (selectedId.firstName ? (selectedId.firstName + ' ' + selectedId.lastName) : '—');
    } else {
      return '—';
    }
  }

  getModulesList() {
    this.httpAdminService.get$(`accounts/role-groups/available-modules`).subscribe(
      data => {
        console.log('available modules', data.data);
        this.modulesArray = data.data.map(element => {
          return element.fieldName;
        });
        this.modulesNameArray = data.data.map(element => {
          return element.label;
        });
        this.moduleList = data.data;
        this.restructureModulesData();
      }, error => {
        console.log('error', error);
      }
    );
  }

  restructureModulesData() {
    console.log('modulesDatamodulesDatamodulesDatamodulesData', this.modulesData);
    this.modifiedModulesData = this.modulesArray.map((element, index) => {
      if (Object.keys(this.modulesData).includes(element)) {
        return {
          key: this.modulesNameArray[index],
          value: this.moduleList[index].modules.filter(element2 => {
            return this.modulesData[element].includes(element2.value);
          })
        };
      }
    }).filter(element => {
      return element.value.length > 0;
    });
    const arr = this.modifiedModulesData.map(element => element.value);
    this.totalPermission = [].concat.apply([], arr);
    this.openPanel(this.modifiedModulesData[0].key);
    console.log('modified', this.modifiedModulesData);
    console.log('totalPermission', this.totalPermission);
  }

  openPanel(event) {
    console.log('collapsed');
    if (!this.collapsed.includes(event)) {
      console.log('should open');
      this.collapsed.push(event);
    } else {
      const i = this.collapsed.indexOf(event);
      this.collapsed.splice(i, 1);
    }
    console.log('openPanel', this.collapsed, event);
  }

  edit() {
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(UserRoleAddEditFormComponent, {
      centered: true
    });
    modalRef.componentInstance.modulesData = this.modulesData;
  }

  remove() {
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(ModalConfirmComponent, {
      centered: true
    });
    modalRef.componentInstance.modulesData = this.modulesData;
    modalRef.componentInstance.adminTitle = 'Remove User Role';
    modalRef.componentInstance.type = 'remove-user-role';
    modalRef.componentInstance.bodyText = `Are you sure you want to remove this user role?`;
    modalRef.componentInstance.primaryButton = 'Confirm';
  }

  ngOnInit() {
    console.log('modulesData', this.modulesData);
    this.getAccountsList();
    if (this.modulesData) {
      this.getModulesList();
    }
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { BusinessService } from 'src/app/core/services/business.service';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-info',
  templateUrl: './main-info.component.html',
  styleUrls: ['./main-info.component.scss']
})
export class MainInfoComponent implements OnInit {
  @Input() type: any;
  @Input() details: any;
  @Input() details2: any;
  @Input() distributorData: any;
  categoryList = [
    { label: 'Show on Customer Kiosk/App', caption: 'This category will be availble on the customer kiosk/app', tooltip: 'Status based on the toggle on/off in Category Settings'},
    { label: 'Enable queue tagging on this group', caption: 'Tag a queue after completion under this queue group', tooltip: 'Status based on the toggle on/off in Category Settings'}
  ];
  queueGroupList = [
    { label: 'Ask customers “How many people?” when queueing', caption: 'When OFF, capacity field will not be shown on Kiosk or App. Default number of people is 1.', tooltip: 'Control display of Capacity field in Add Queue'},
    { label: 'Require customers their MOBILE NUMBER when queueing', caption: 'When OFF, mobile number is not needed to queue.', tooltip: 'Set mobile number as either required or optional field based on Queue Group Settings'},
    { label: 'Require customers their NAME when queueing', caption: 'When OFF, a name is not needed to queue.', tooltip: 'Set name as either required or optional field based on Queue Group Settings'},
    { label: 'Enable queue tagging on this group', caption: 'Tag a queue after completion under this queue group', tooltip: 'Allow tagging a queue upon completion'},
    { label: 'Enable queue priority (PWD/Senior Citizen)', caption: 'Add a PWD / SC toggle when adding queue to tag as priority on the queue list', tooltip: 'Set availability of Toggle On/Off priority function in the Add Queue page'}
  ];
  tooltipDefault: string;
  lat = 0;
  lng = 0;
  addedByAvatar: string;
  addedByName: string;

  constructor(
    private businessService: BusinessService,
    private httpAdminService: HttpWebAdminService,
    private router: Router
  ) { }

  checkDisable(social) {
    const check = this.details.settings.socialLinks.find(element => {
      return element.type === social;
    });
    if (!check) {
      return true;
    } else {
      return false;
    }
  }

  goToFacebook() {
    const fb = this.details.settings.socialLinks.find(element => {
      return element.type === 'facebook';
    });
    console.log('fb', fb.url);
    const fbLink = fb.url.includes('http') ? fb.url : 'http://' + fb.url;
    window.open(fbLink, '_blank');
  }

  goToInstagram() {
    const ig = this.details.settings.socialLinks.find(element => {
      return element.type === 'instagram';
    });
    console.log('instagram', ig.url);
    const igLink = ig.url.includes('http') ? ig.url : 'http://' + ig.url;
    window.open(igLink, '_blank');
  }

  goToWebsite() {
    const website = this.details.settings.socialLinks.find(element => {
      return element.type === 'company';
    });
    console.log('website', website.url);
    const webLink = website.url.includes('http') ? website.url : 'http://' + website.url;
    window.open(webLink, '_blank');
  }

  getPlanType(val) {
    return this.businessService.getPlanType(val);
  }

  getAddedBy(accountId) {
    this.httpAdminService.get$(`accounts/${accountId}`).subscribe(
      data => {
        console.log('addedby data', data);
        this.addedByAvatar = data.avatarUrl;
        if (data.firstName) {
          this.addedByName = data.firstName + ' ' + data.lastName;
        } else {
          this.addedByName = '—';
        }
      }, error => {
        console.log('error', error);
      }
    );
  }

  resendVerification(accountId, branchId) {
    this.httpAdminService.post$(`business-portal/accounts/web-admin/${accountId}/verification/resend?branchId=${branchId}`, '').subscribe(
      data => {
        console.log('resend success', data);
        this.businessService.publishAlert$({
          type: 'green-dark-01',
          icon: 'fas fa-check',
          message: `<b>Sent!</b> — The email verification link has been sent.`
        });
      }, error => {
        console.log('verify error', error);
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Something went wrong.</b> Please try again.`
        });
      }
    )
  }

  ngOnInit() {
    this.tooltipDefault = 'insert tooltip here';
    if (this.details.settings) {
      this.lat = this.details.settings.location.coordinates[1];
      this.lng = this.details.settings.location.coordinates[0];
    }
    if (this.details) {
      console.log('details', this.details);
      if (this.details.addedBy._id) {
        this.getAddedBy(this.details.addedBy._id);
      } else {
        this.getAddedBy(this.details.addedBy.id);
      }
    }
  }

}

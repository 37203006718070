import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerAppAddFormComponent } from '../customer-app-add-form/customer-app-add-form.component';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-banner-view',
  templateUrl: './banner-view.component.html',
  styleUrls: ['./banner-view.component.scss']
})
export class BannerViewComponent implements OnInit {
  bannerData: any;
  index: number;
  linkTitle: string;
  linkUrl: string;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private httpAdminService: HttpWebAdminService,
    private router: Router
  ) { }

  removeBanner() {
    this.activeModal.dismiss();
    const modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
    modalRef.componentInstance.type = 'suspend-admin';
    modalRef.componentInstance.primaryButton = 'Confirm';
    modalRef.componentInstance.adminTitle = 'Remove Banner';
    modalRef.componentInstance.bodyText = `Are you sure you want to remove the banner?`;
    modalRef.componentInstance.bannerData = this.bannerData;
    modalRef.componentInstance.bannerIndex = this.index;
  }

  editBanner() {
    this.activeModal.dismiss();
    const modalRef = this.modalService.open(CustomerAppAddFormComponent, {
      centered: true
    });
    modalRef.componentInstance.bannerData = this.bannerData;
    modalRef.componentInstance.index2 = this.index;
  }

  getNewsList() {
    this.httpAdminService.get$(`notification/news/search?limit=999&offset=0`).subscribe(
      data => {
        console.log('data', data);
        if (data) {
          const newsTitle = data.data.find(element => element._id === this.bannerData.linkValue);
          this.linkTitle = newsTitle.title;
          this.linkUrl = `./announcements/news/view/${this.bannerData.linkValue}`;
        }
      }, error => {
        console.log('error', error);
        // this.errorMsg = `<b>Error:</b> Something went wrong.`;
      }
    );
  }

  getBusinessPartners() {
    this.httpAdminService.get$(`partners`).subscribe(
      data => {
        console.log('business partners list', data);
        if (data) {
          const partnerTitle = data.data.find(element => element._id === this.bannerData.linkValue);
          this.linkTitle = partnerTitle.name;
          this.linkUrl = `./business-partners/${partnerTitle._id}`;
        }
      }, error => {
        console.log('error', error);
        // this.errorMsg = `<b>Error:</b> Something went wrong.`;
      }
    );
  }

  getBranchList() {
    this.httpAdminService.get$(`branches?limitTo=999&startAt=0`).subscribe(
      data => {
        console.log('data', data);
        if (data) {
          const branchTitle = data.data.find(element => element._id === this.bannerData.linkValue);
          this.linkTitle = branchTitle.branchName;
          this.linkUrl = `./business-partners/branch/${branchTitle.partnerId}/${branchTitle._id}`;
        }
      }, error => {
        console.log('error', error);
        // this.errorMsg = `<b>Error:</b> Something went wrong.`;
      }
    );
  }

  goToLink(viewType) {
    this.modalService.dismissAll();
    if (viewType === 4) {
      window.open(this.linkUrl, '_blank');
    } else {
      this.router.navigate([this.linkUrl]);
    }
  }

  getLinkType(viewType) {
    switch (viewType) {
      case 0: {}
      break;
      case 1: {
        this.getNewsList();
      }
      break;
      case 2: {
        this.getBusinessPartners();
      }
      break;
      case 3: {
        this.getBranchList();
      }
      break;
      case 4: {
        this.linkTitle = this.bannerData.linkValue;
        this.linkUrl = this.bannerData.linkValue;
        this.linkUrl = this.bannerData.linkValue.includes('http') ? this.bannerData.linkValue : 'http://' + this.bannerData.linkValue;
      }
      break;
    }
  }

  ngOnInit() {
    console.log(this.bannerData);
    if (this.bannerData) {
      this.getLinkType(this.bannerData.viewType);
    }
  }

}

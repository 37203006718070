import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Additional Modules
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TopnavComponent } from './header/topnav/topnav.component';
import { SidenavComponent } from './header/sidenav/sidenav.component';

// Auth
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './auth/auth-interceptor.service';
import { ErrorInterceptorService } from './auth/error-interceptor.service';
import {TimeAgoPipe} from 'time-ago-pipe';

// Misc
import { IconsComponent } from '../core/misc/icons/icons.component';

@NgModule({
  declarations: [
    TopnavComponent,
    SidenavComponent,
    TimeAgoPipe,
    IconsComponent
  ],
  exports: [
    SidenavComponent,
    TopnavComponent,
    TimeAgoPipe,
    IconsComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true},
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true}
  ]
})
export class CoreModule { }

import { Component, OnInit, HostListener, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BusinessService } from 'src/app/core/services/business.service';
import { HttpService } from 'src/app/core/http/http.service';
import { QueueService } from 'src/app/core/services/queue.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Router } from '@angular/router';
import { tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { AccountsAddEditFormComponent } from 'src/app/modules/settings/components/accounts-add-edit-form/accounts-add-edit-form.component';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit, OnDestroy {
  title: string;
  bodyText: string;
  type: any;
  primaryButton: string;
  detailType: any;
  id: string;
  targetIndex: number;
  sourceIndex: number;
  item: any;
  removeString: any;
  removeText: string;
  disabled: boolean;
  form: any;
  branchId: string;
  categoryId: string;
  distributorId: string;
  queueGroupId: string;
  queueGroupData: any;
  queue: any;
  submitting: boolean;
  errorMsg: string;
  accountId: string;
  email: string;
  urlAPI = environment.urlAPI;
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private loggedUser: string;
  partnerId: string;
  adminTitle: string;
  successType: string;
  addAccountModal: any;
  accountData: any;
  distributorData: any;
  deviceData: any;
  industryData: any;
  messageId: string;
  bannerData: any;
  bannerIndex: number;
  newsId: string;
  customerData: any;
  statusCodeList: Array<any>;
  modulesData: any;
  templateData: any;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private businessService: BusinessService,
    private httpService: HttpService,
    private queueService: QueueService,
    private router: Router,
    private http: HttpClient,
    private httpAdminService: HttpWebAdminService
  ) { }

  leaveForm(choice) {
    console.log('leaveForm', choice);
    this.modalService.dismissAll();
    this.businessService.navigateAwaySelection$.next(choice);
  }

  revertForm() {
    this.businessService.publishResetForm$(true);
    this.activeModal.dismiss();
  }

  close(title, id, targetIndex, sourceIndex, item, successType) {
    console.log(title, id, targetIndex, sourceIndex, item, successType);
    this.businessService.navigateClose$.next(true);
    switch (title) {
      case 'Return Queue' : {
        // Return Queue when not confirmed
        this.activeModal.dismiss();
        this.businessService.publishReturnQueue$({
          queueId: id,
          tIndex: targetIndex,
          sIndex: sourceIndex,
          queueItem: item
        });
      }
      break;
      case 'Success Add Account' : {
        this.modalService.dismissAll();
      }
      break;
      default : {
        this.modalService.dismissAll();
      }
      break;
    }
    switch (successType) {
      case 'add-branch' : {
        this.addBranch();
      }
      break;
      case 'add-admin-account' : {
        this.addAdminAccount();
      }
      break;
      default : {
        this.modalService.dismissAll();
      }
      break;
    }
  }

  confirm(title, item) {
    switch (title) {
      case 'Return Queue' : {
        this.confirmReturnQueue(item);
      }
      break;
      case 'Skip Queue' : {
        this.confirmSkipQueue(item);
      }
      break;
    }
  }

  checkRemoveString(removeText) {
    this.removeString === removeText ? this.disabled = false : this.disabled = true;
  }

  removeManager() {
    this.activeModal.dismiss();
    const modalRef = this.modalService.open(ModalConfirmComponent, {
      centered: true,
      size: 'sm'
    });
    modalRef.componentInstance.title = 'Remove as Manager';
    modalRef.componentInstance.type = 'danger';
    modalRef.componentInstance.removeString = 'remove manager';
    modalRef.componentInstance.bodyText = `Removing a manager  will prevent them from accessing data from this branch. Managers will be notified when removed.`;
    modalRef.componentInstance.primaryButton = 'Confirm';
    modalRef.componentInstance.queueGroupData = this.queueGroupData;
  }

  confirmRemoveManager() {
    this.activeModal.dismiss();
    console.log('confirm remove manager');
    this.httpService.delete$(`accounts/${this.branchId}/managers/${this.queueGroupData.id}`).subscribe(
      data => {
        console.log('deleted', data);
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Removed!</b> — This manager has been removed.`
        });
      }, error => {
        console.log('error', error);
        this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
      }
    );
  }

  confirmSuspendAccount() {
    this.submitting = true;
    const body = { isSuspended: true };
    this.httpService.patch$(`/accounts/${this.branchId}/${this.accountId}/update-status`, body).subscribe(
      data => {
        console.log('suspended', data);
        this.activeModal.dismiss();
        this.businessService.publishDataChange$(data);
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Suspend!</b> — This account won’t be able to access kyoo until unsuspended.`
        });
        this.submitting = false;
      }, error => {
        console.log('error', error);
        this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
        this.submitting = false;
      }
    );
  }

  confirmSuspendCategory() {
    this.submitting = true;
    const body = { isActive: false };
    this.httpService.patch$(`/category/${this.branchId}/${this.categoryId}/is-active`, body).subscribe(
      data => {
        console.log('suspended', data);
        this.activeModal.dismiss();
        this.businessService.publishDataChange$(data);
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Suspend!</b> — This category won’t be able to access kyoo until unsuspended.`
        });
        this.submitting = false;
      }, error => {
        console.log('error', error);
        this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
        this.submitting = false;
      }
    );
  }

  confirmSuspendQueueGroup() {
    this.submitting = true;
    const body = { isSuspended: true };
    this.httpService.patch$(`/queue-groups/${this.branchId}/${this.queueGroupId}/update-status`, body).subscribe(
      data => {
        console.log('suspended', data);
        this.activeModal.dismiss();
        this.businessService.publishDataChange$(data);
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Suspend!</b> — This queue group won’t be able to access kyoo until unsuspended.`
        });
        this.submitting = false;
      }, error => {
        console.log('error', error);
        this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
        this.submitting = false;
      }
    );
  }

  confirmDiscardMessage() {
    this.httpAdminService.delete$(`notification/messages/${this.messageId}`).subscribe(
      data => {
        console.log('deleted', data);
        this.modalService.dismissAll();
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `“<b>${data.title}”</b> has been <b>deleted!</b>`
        });
        this.router.navigate([`/announcements/messages`]);
      }, error => {
        const errorsList = error.error.errors.map(element => {
          return element.param;
        });
        this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
      }
    );
  }

  confirmDeleteNews() {
    this.httpAdminService.delete$(`notification/news/${this.newsId}`).subscribe(
      data => {
        console.log('deleted', data);
        this.modalService.dismissAll();
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `“<b>${data.title}”</b> has been <b>deleted!</b>`
        });
        this.router.navigate([`/announcements/news`]);
      }, error => {
        const errorsList = error.error.errors.map(element => {
          return element.param;
        });
        this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
      }
    );
  }

  confirmResetQueue() {
    this.submitting = true;
    this.httpService.patch$(`queue-groups/${this.branchId}/${this.queueGroupId}/reset-counter`, '').subscribe(
      data => {
        console.log('reset', data);
        this.activeModal.dismiss();
        this.businessService.publishAlert$({
          type: 'secondary',
          icon: 'fas fa-sync-alt',
          message: `<b>Reset!</b> — This queue group's number has been reset.`
        });
        this.submitting = false;
      }, error => {
        console.log('error', error);
        this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
        this.submitting = false;
      }
    );
  }

  confirmRemoveQueue(current) {
    this.queueService.removeQueue(current);
    this.activeModal.dismiss();
  }

  confirmReturnQueue(current) {
    this.queueService.returnQueue(current);
    this.activeModal.dismiss();
  }

  confirmSkipQueue(current) {
    this.queueService.skipQueue(current);
    this.activeModal.dismiss();
  }

  confirmlogout() {
    console.log('confirm logout');
    this.logout().subscribe(res => {
      if (res.success === true) {
        this.modalService.dismissAll();
        this.router.navigate(['/login']);
        console.log('success logout');
      }
    });
  }

  logout() {
    const accountId = localStorage.getItem('accountId');
    console.log('auth logout', accountId);
    return this.http.post<any>(`${this.urlAPI}/auth/logout/${accountId}`, {
      refreshToken: this.getRefreshToken()
    }).pipe(
      tap(() => this.doLogoutUser()),
      map(user => {
        return user;
      }));
  }

  doLogoutUser() {
    console.log('logout user');
    this.loggedUser = null;
    this.removeTokens();
    localStorage.removeItem('accountId');
    localStorage.removeItem('lastEventTime');
  }

  removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
  }


  getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  back() {
    this.activeModal.dismiss();
    const modalRef = this.modalService.open(ModalConfirmComponent, {
      centered: true,
      size: 'sm'
    });
    modalRef.componentInstance.title = 'View Manager Details';
    modalRef.componentInstance.type = 'details';
    modalRef.componentInstance.detailType = 'branch-managers';
    modalRef.componentInstance.queueGroupData = this.queueGroupData;
  }

  disableConfirm() {
    switch (this.type) {
      case 'danger' : {
        this.disabled = true;
      }
      break;
    }
  }

  addBranch() {
    this.activeModal.dismiss();
    this.router.navigate([`/business-partners/branch/${this.partnerId}/add`]);
  }

  addAdminAccount() {
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(AccountsAddEditFormComponent, {
      centered: true
    });
    modalRef.componentInstance.title = 'Add Account';
    this.businessService.formDataModal$ = null;
  }

  confirmSuspendPartner() {
    console.log('suspend partner', this.partnerId);
    this.httpAdminService.patch$(`partners/${this.partnerId}/suspend-status`, {status: true}).subscribe(
      data => {
        console.log('suspended', data.data);
        this.activeModal.dismiss();
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>${data.data.name}</b> has been Suspended!`
        });
      }, error => {
        console.log('error');
        this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
      }
    );
  }

  confirmSuspendBranch() {
    console.log('suspend branch', this.branchId);
    this.httpAdminService.patch$(`branches/${this.branchId}/suspend-status`, {suspendStatus: true}).subscribe(
      data => {
        this.activeModal.dismiss();
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>${data.branchName}</b> has been Suspended!`
        });
      }, error => {
        console.log('error');
        this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
      }
    );
  }

  confirmSuspendAdmin() {
    console.log('suspend admin account', this.branchId);
    this.httpAdminService.patch$(`accounts/status/${this.accountData._id}`, {isSuspended: true}).subscribe(
      data => {
        this.activeModal.dismiss();
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>${this.accountData.firstName} ${this.accountData.lastName}</b> has been Suspended!`
        });
      }, error => {
        console.log('error');
        this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
      }
    );
  }

  confirmResetPassword() {
    const body = {identifier: this.accountData.email.value};
    this.httpAdminService.post$(`auth/forgot-password`, body).subscribe(
      data => {
        console.log('confirm reset', data);
        this.modalService.dismissAll();
        this.businessService.publishAlert$({
          type: 'green-dark-01',
            icon: 'fas fa-check',
            message: `<b>Sent!</b>Password reset link has been sent to ${this.accountData.email.value}`
        });
      }, error => {
        this.modalService.dismissAll();
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `Unable to resend reset password link to <b>${this.accountData.email.value}</b>. Click here to resend link`
        });
      }
    );
  }

  confirmResetPasswordCustomer() {
    const body = {identifier: this.customerData.email.value ? this.customerData.email.value : this.customerData.mobileNo.value};
    this.httpAdminService.post$(`customer/auth/forgot-password`, body).subscribe(
      data => {
        console.log('confirm reset', data);
        this.modalService.dismissAll();
        this.businessService.publishAlert$({
          type: 'green-dark-01',
            icon: 'fas fa-check',
            message: `<b>Sent!</b>Password reset link has been sent to ${this.customerData.email.value}`
        });
      }, error => {
        this.modalService.dismissAll();
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `Unable to resend reset password link to <b>${this.customerData.email.value}</b>. Click here to resend link`
        });
      }
    );
  }

  confirmRemoveIndustry() {
    this.httpAdminService.delete$(`/industry/${this.industryData._id}`).subscribe(
      data => {
        this.activeModal.dismiss();
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>${this.industryData.name}</b> has been removed!`
        });
        this.router.navigate(['/settings/industry-setting/industry']);
      }, error => {
        console.log('error');
        this.showError(error);
      }
    );
  }

  confirmRemoveUserRole() {
    this.httpAdminService.delete$(`accounts/role-groups/${this.modulesData._id}`).subscribe(
      data => {
        this.activeModal.dismiss();
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Success!</b> ${this.modulesData.name} user role has been removed.`
        });
        this.businessService.publishDataChange$(data);
      }, error => {
        console.log('error');
        this.showError(error);
      }
    );
  }

  confirmRemoveTemplate() {
    this.httpAdminService.delete$(`/custom-brand-templates/${this.templateData._id}`).subscribe(
      data => {
        this.activeModal.dismiss();
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Success!</b> ${this.templateData.name} template has been removed.`
        });
        this.businessService.publishDataChange$(data);
        this.router.navigate([`/settings/custom-brand-setting/custom-list`]);
      }, error => {
        console.log('error');
        this.showError(error);
      }
    );
  }

  confirmSuspendDistributor() {
    this.httpAdminService.patch$(`distributor/${this.distributorData._id}/status`, {isSuspended: true}).subscribe(
      data => {
        this.activeModal.dismiss();
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>${this.distributorData.name}</b> has been Suspended!`
        });
        this.businessService.publishDataChange$(data);
      }, error => {
        console.log('error');
        this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
      }
    );
  }

  confirmSuspendDevice() {
    this.httpAdminService.patch$(`devices/${this.deviceData._id}/suspend-status`, {status: true}).subscribe(
      data => {
        this.activeModal.dismiss();
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>${this.deviceData.refId}</b> has been Suspended!`
        });
        this.businessService.publishDataChange$(data);
      }, error => {
        console.log('error');
        this.showError(error);
      }
    );
  }

  confirmSuspendCustomer() {
    this.httpAdminService.patch$(`/customer/user/suspend`, {accountIds: [this.customerData._id]}).subscribe(
      data => {
        this.activeModal.dismiss();
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>${this.customerData.fullName}</b> has been Suspended!`
        });
        this.businessService.publishDataChange$(data);
      }, error => {
        console.log('error');
        this.showError(error);
      }
    );
  }

  confirmUnassignedDevice() {
    const body = {deviceId: this.deviceData._id};
    this.httpAdminService.patch$(`devices/${this.deviceData._id}/unassign`, body).subscribe(
      data => {
        this.activeModal.dismiss();
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Device has been unassigned!</b> ${this.deviceData.refId} has been pulled out to the list!`
        });
        this.businessService.publishDataChange$(data);
      }, error => {
        console.log('error', error);
        this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
      }
    );
  }

  confirmPulloutDevice() {
    const body = {deviceId: this.deviceData._id};
    this.httpAdminService.patch$(`devices/${this.deviceData._id}/pull-out`, body).subscribe(
      data => {
        this.activeModal.dismiss();
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Device has been pulled out!</b> ${this.deviceData.refId} has been pulled out to the list!`
        });
        this.businessService.publishDataChange$(data);
      }, error => {
        console.log('error', error);
        this.showError(error);
      }
    );
  }

  confirmRemoveBanner() {
    console.log('confirm remove banner', this.bannerIndex);
    if (this.bannerData._id) {
      this.httpAdminService.delete$(`partners/banner/${this.bannerData._id}`).subscribe(
        data => {
          console.log('deleted', data);
          this.activeModal.dismiss();
          this.businessService.publishAlert$({
            type: 'red-dark-01',
            icon: 'fas fa-ban',
            message: `<b>Banner</b> has been <b>Removed!</b>`
          });
        }, error => {
          console.log('error', error);
        }
      );
    } else {
      this.activeModal.dismiss();
      this.businessService.publishRemoveBanner$(this.bannerIndex);
    }
  }

  showError(error) {
    this.statusCodeList = error.error.errors.filter(element => element.msg.statusCode).map(element2 => element2.msg.statusCode);
    console.log('this.statusCodeList', this.statusCodeList);
    // Unable to delete industry. Category is associated with a Branch/Business Partner
     if (this.statusCodeList.includes(13530)) {
      this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
      // Suspend failed, device is still assigned to a Branch/Business Partner.
    } else if (this.statusCodeList.includes(20413)) {
      this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
    // No branch to pull out.
    } else if (this.statusCodeList.includes(20121)) {
      this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
    } else {
      this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
    }
  }

  primaryAction(title, id, primaryButton, adminTitle) {
    console.log('log', title, id, primaryButton, adminTitle);
    switch (title) {
      case 'Remove as Manager' : {
        this.confirmRemoveManager();
      }
      break;
      case 'Success Add Account' : {
        this.activeModal.dismiss();
        this.businessService.publishResetForm$(true);
      }
      break;
      case 'Suspend Account': {
        this.confirmSuspendAccount();
      }
      break;
      case 'Suspend Category' : {
        this.confirmSuspendCategory();
      }
      break;
      case 'Suspend Queue Group' : {
        this.confirmSuspendQueueGroup();
      }
      break;
      case 'Revert Changes' : {
        this.revertForm();
      }
      break;
      case 'Reset Queue Number' : {
        this.confirmResetQueue();
      }
      break;
      case 'Remove Queue' : {
        this.confirmRemoveQueue(this.queue);
      }
      break;
      case 'Account has been logged out' : {
        this.confirmlogout();
      }
      break;
      case 'Session Timeout' : {
        this.confirmlogout();
      }
      break;
    }
    switch (primaryButton) {
      case 'Add Branch Now' : {
        this.addBranch();
      }
      break;
      case 'Okay, got it' : {
        this.modalService.dismissAll();
      }
      break;
      case 'Back to Login' : {
        this.confirmlogout();
      }
      break;
      case 'Close' : {
        this.modalService.dismissAll();
      }
      break;
    }
    switch (adminTitle) {
      case 'Suspend Business Partner' : {
        this.confirmSuspendPartner();
      }
      break;
      case 'Suspend Branch' : {
        this.confirmSuspendBranch();
      }
      break;
      case 'Suspend Account' : {
        this.confirmSuspendAdmin();
      }
      break;
      case 'Suspend Distributor' : {
        this.confirmSuspendDistributor();
      }
      break;
      case 'Suspend Device' : {
        this.confirmSuspendDevice();
      }
      break;
      case 'Suspend Customer' : {
        this.confirmSuspendCustomer();
      }
      break;
      case 'Unassigned Device' : {
        this.confirmUnassignedDevice();
      }
      break;
      case 'Pull Out Device' : {
        this.confirmPulloutDevice();
      }
      break;
      case 'Remove Industry' : {
        this.confirmRemoveIndustry();
      }
      break;
      case 'Remove User Role' : {
        this.confirmRemoveUserRole();
      }
      break;
      case 'Remove Template' : {
        this.confirmRemoveTemplate();
      }
      break;
      case 'Reset Password' : {
          this.confirmResetPassword();
      }
      break;
      case 'Reset Password Customer' : {
          this.confirmResetPasswordCustomer();
      }
      break;
      case 'Discard Draft' : {
        this.confirmDiscardMessage();
      }
      break;
      case 'Delete News' : {
        this.confirmDeleteNews();
      }
      break;
      case 'Remove Banner' : {
        this.confirmRemoveBanner();
      }
      break;
    }
  }

  // delete this
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    console.log(event);
    event.preventDefault();
  }

  ngOnInit() {
    this.disableConfirm();
    // this.branchId = localStorage.getItem('branchId');
    console.log('current', this.queue);
    console.log('queueGroupData', this.queueGroupData);
    if (this.businessService.formDataModal$) {
      console.log(this.businessService.formDataModal$);
      const data = this.businessService.formDataModal$;
      this.type = data.type;
      this.successType = data.successType;
      this.bodyText = data.bodyText;
      this.primaryButton = data.primaryButton;
      this.adminTitle = data.adminTitle;
      this.accountData = data.accountData;
      this.distributorData = data.distributorData;
      this.deviceData = data.deviceData;
      this.industryData = data.industryData;
      this.customerData = data.customerData;
    }
    console.log('templateData', this.templateData);
  }

  ngOnDestroy() {
    this.businessService.formDataModal$ = null;
  }

}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { HttpService } from 'src/app/core/http/http.service';
import { BusinessService } from 'src/app/core/services/business.service';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  passwordType: any;
  show: boolean;
  errorMsg: string;
  errorUnverified: boolean;
  returnUrl: string;
  subscriptions = new Subscription();
  submitting: boolean;
  background: string;
  partnerId: string;
  branchName: string;
  partnerAvatarUrl: string;
  partnerData: any;
  inputFocus: any;
  statusCodeList: Array<any>;
  errorEmailMsg: string;
  errorPasswordMsg: string;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private modalService: NgbModal,
    private businessService: BusinessService
  ) {
    this.subscriptions.add(this.activatedRoute.queryParams.subscribe(params => {
      this.returnUrl = params.returnUrl;
    }));
  }

  loginForm = this.fb.group({
    username:  ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9\_\-\.]+\@[a-zA-Z0-9]{1,12}(\.[a-zA-Z0-9]{1,7}){1,3}$/)]],
    password: ['', Validators.required]
  });

  get f(): any { return this.loginForm.controls; }

  showHidePw() {
    this.show = !this.show;
    this.show ? this.passwordType = 'text' : this.passwordType = 'password';
  }

  submitForm(f: any, suspendedModal) {
    this.submitting = true;
    f.disable();
    console.log(f.value);
    const body = f.value;
    this.auth.login(body)
    .pipe(first())
      .subscribe(
        data => {
          console.log('login', data);
          localStorage.setItem('accountId', data._id);
          if (this.returnUrl) {
            this.router.navigate([this.returnUrl]);
          } else {
            this.router.navigate(['']);
          }
          this.submitting = false;
          f.enable();
        },
        error => {
          console.log('error', error);
          this.showError(error);
          this.submitting = false;
          f.enable();
        }
      );
  }

  showError(error) {
    this.statusCodeList = error.error.errors.filter(element => element.msg.statusCode).map(element2 => element2.msg.statusCode);
    console.log('this.statusCodeList', this.statusCodeList);
    // User not found
    if (this.statusCodeList.includes(40411)) {
      this.errorEmailMsg = `Sorry, this email is not registered to any account.`;
    // Wrong Password
    } else if (this.statusCodeList.includes(40412)) {
      this.errorEmailMsg = `Sorry, the email and password combination doesn't match.`;
      this.errorPasswordMsg = `Sorry, the email and password combination doesn't match.`;
    // Email not yet verified
    } else if (this.statusCodeList.includes(40413)) {
      this.errorEmailMsg = `Your email address is not yet verified. Please check your inbox`;
    // Suspended Account
    } else if (this.statusCodeList.includes(40414)) {
      const title = `Account Suspended`;
      const bodyText = `Sorry, your account has been suspended. Please ask your admin for assistance.`;
      this.openErrorModal(title, bodyText);
    } else {
      this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
    }
  }

  openErrorModal(title, bodyText) {
    const modalRef = this.modalService.open(ModalConfirmComponent, {
      centered: true
    });
    modalRef.componentInstance.type = 'generic-error';
    modalRef.componentInstance.title =  title;
    modalRef.componentInstance.bodyText =  bodyText;
    modalRef.componentInstance.primaryButton = 'Close';
  }

  clearBackendError(input) {
    if (input.dirty) {
      this.errorMsg = null;
      this.errorEmailMsg = null;
      this.errorPasswordMsg = null;
    }
  }

  ngOnInit() {
    this.passwordType = 'password';
    this.background = './assets/images/img_kyoo_food.png';
    this.partnerId = localStorage.getItem('partnerId');
    this.branchName = localStorage.getItem('branchName');
  }

}

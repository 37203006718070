import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpService } from 'src/app/core/http/http.service';
import { ConfirmPasswordValidator } from 'src/app/shared/validators/confirm-password.validator.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BusinessService } from 'src/app/core/services/business.service';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent implements OnInit, OnDestroy {
  accountId: string;
  branchId: string;
  errorMsg: string;
  passwordType: any;
  show: boolean;
  passwordType2: any;
  show2: boolean;
  passwordType3: any;
  show3: boolean;
  inputFocus: any;
  isMatch1: boolean;
  isMatch2: boolean;
  isMatch3: boolean;
  token: string;
  type: any;
  submitting: boolean;
  statusCodeList: Array<any>;
  errorPasswordMsg: string;
  subscriptions = new Subscription();

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private router: Router,
    public activeModal: NgbActiveModal,
    private businessService: BusinessService
  ) { }

  passwordForm = this.fb.group({
    oldPassword: ['', Validators.required],
    password: ['', [Validators.pattern(/(?=^.{6,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/)]],
    confirmPwd: ['', Validators.required]
  }, {
    validator: ConfirmPasswordValidator.MatchPassword
  });

  get f() { return this.passwordForm.controls; }

  showHidePw() {
    this.show = !this.show;
    this.show ? this.passwordType = 'text' : this.passwordType = 'password';
    console.log(this.passwordType);
  }

  showHidePw2() {
    this.show2 = !this.show2;
    this.show2 ? this.passwordType2 = 'text' : this.passwordType2 = 'password';
    console.log(this.passwordType);
  }

  showHidePw3() {
    this.show3 = !this.show3;
    this.show3 ? this.passwordType3 = 'text' : this.passwordType3 = 'password';
    console.log(this.passwordType);
  }

  checkPwPattern(newPwd) {
    console.log(newPwd);
    // At least 6 characters
    newPwd.match(/(?=^.{6,}$)/) ? this.isMatch1 = true : this.isMatch1 = false;
    newPwd.match(/(?=.*[A-Z])/) ? this.isMatch2 = true : this.isMatch2 = false;
    newPwd.match(/(?=.*[0-9])/) ? this.isMatch3 = true : this.isMatch3 = false;
  }

  getUrlParams() {
    this.subscriptions.add(this.activatedRoute.queryParams.subscribe(params => {
      this.token =  params['token'];
      this.type = params['type'];
    }));
  }

  submitForm(f) {
    console.log('type', this.type);
    f.disable();
    this.submitting = true;
    const {password, confirmPwd, ...form} = f.value;
    console.log(password);
    const body = { ...form, newPassword: password };
    console.log('form value', body);
    this.httpService.patch$(`accounts/reset-password/${this.accountId}`, body).subscribe(data => {
      f.enable();
      this.submitting = false;
      this.activeModal.dismiss();
      console.log('data', data);
      this.businessService.publishAlert$({
        type: 'green-dark-01',
        icon: 'fas fa-check',
        message: `<b>Success!</b> — Password has been changed.`
      });
    }, error => {
      f.enable();
      this.submitting = false;
      console.log('error', error);
      this.showError(error);
    });
  }

  showError(error) {
    this.statusCodeList = error.error.errors.filter(element => element.msg.statusCode).map(element2 => element2.msg.statusCode);
    console.log('this.statusCodeList', this.statusCodeList);
    // User not found
    if (this.statusCodeList.includes(88400)) {
      this.errorPasswordMsg = `You cannot use your old password.`;
    } else {
      this.errorPasswordMsg = null;
      this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
    }
  }

  clearBackendError(input) {
    if (input.dirty) {
      this.errorMsg = null;
      this.errorPasswordMsg = null;
    }
  }

  ngOnInit() {
    this.branchId = localStorage.getItem('branchId');
    this.passwordType = 'password';
    this.passwordType2 = 'password';
    this.passwordType3 = 'password';
    this.getUrlParams();
    console.log('account id', this.accountId);
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

import { Component, OnInit, HostListener, OnDestroy, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';
import { CropPhotoComponent } from 'src/app/indie/crop-photo/crop-photo.component';
import { Subscription } from 'rxjs';
import { BusinessService } from 'src/app/core/services/business.service';
import { HttpService } from 'src/app/core/http/http.service';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-distributor-add-edit-form',
  templateUrl: './distributor-add-edit-form.component.html',
  styleUrls: ['./distributor-add-edit-form.component.scss']
})
export class DistributorAddEditFormComponent implements OnInit, OnDestroy {
  title: string;
  avatarUrl: string;
  imgUrl: any;
  branchId: string;
  submitting: boolean;
  errorMsg: string;
  distributorData: any;
  selectedAvatar: File;
  enableSubmit: boolean;
  openLeaveModal: boolean;
  subscriptions = new Subscription();

  croppedImg: any;
  fileName: string;
  fileType: string;

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private businessService: BusinessService,
    private httpService: HttpService,
    private httpAdminService: HttpWebAdminService,
    private router: Router
  ) { }

  distributorForm = this.fb.group({
    name: ['', Validators.required],
    contactPerson: ['', Validators.required],
    email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9\_\-\.]+\@[a-zA-Z0-9]{1,12}(\.[a-zA-Z0-9]{1,7}){1,3}$/)]],
    mobileNo:  ['(+63)', Validators.required]
  });

  get f() {return this.distributorForm.controls; }

  onMobileKeyUp(contact) {
    const defaultVal = '(+63)';
    if (contact.value.length <= 3 ||
      contact.value.length === '' ||
      contact.value.substring(0, 5) !== defaultVal) {
      contact.setValue(defaultVal);
    }
  }

  patchMobile(contact) {
    const number1 = contact.substring(0, 3);
    const number2 = contact.substring(3, 6);
    const number3 = contact.substring(6, 11);
    return `(+63) ${number1} - ${number2} - ${number3}`;
  }

  transformMobile(contact) {
    return contact.slice(6, 22).replace(/\D/g, '');
  }

  onAvatarChange($event) {
    this.selectedAvatar = $event.target.files[0] as File;
    if ($event) {
      this.openCropModal($event, 'avatar-circle');
    }
  }

  checkCroppedAvatar() {
    this.subscriptions.add(this.businessService.croppedPhoto$.subscribe(res => {
      this.croppedImg = res.image;
      console.log('this.croppedImg', this.croppedImg);
      this.imgUrl = res.image;
      this.fileName = res.fileName;
      this.fileType = res.fileType;
      this.enableSubmit = true;
    }));
  }

  openCropModal(avatar, type) {
    const modalRef = this.modalService.open(CropPhotoComponent, {
      centered: true,
      windowClass: `modal-${type}`
    });
    modalRef.componentInstance.imageFile = avatar;
    modalRef.componentInstance.title = 'Select Avatar';
    modalRef.componentInstance.cropType = type;
  }

  @HostListener('body:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    console.log(event);
    event.preventDefault();
    if (!this.openLeaveModal) {
      this.closeModal();
    }
    this.subscriptions.add(
      this.businessService.navigateClose$.subscribe(res => {
        console.log('res', res);
        this.openLeaveModal = false;
      })
    );
  }

  submitForm(f){
    const body = new FormData();
    if (this.croppedImg) {
      body.append('avatar', new File([new Blob([this.businessService.dataURItoBlob(this.croppedImg)], {type: this.fileType})], this.fileName, {type: this.fileType}));
      console.log('avatar', new File([new Blob([this.businessService.dataURItoBlob(this.croppedImg)], {type: this.fileType})], this.fileName, {type: this.fileType}));
    }
    console.log('mobile', f.get('mobileNo').value);
    if (f.get('mobileNo').value !== '(+63)') {
      body.append('mobileNo', this.transformMobile(f.get('mobileNo').value));
    }
    for (const key in f.getRawValue()) {
      if (f.getRawValue().hasOwnProperty(key) && key !== 'mobileNo') {
        body.append(key, f.getRawValue()[key]);
        console.log(key, f.getRawValue()[key]);
      }
    }
    this.submitting = true;
    f.disable();

    if (this.distributorData) {
      // if edit
      this.httpService.patch$(`distributor/${this.distributorData._id}`, body).subscribe(
        data => {
          console.log('edited distributor', data);
          this.activeModal.dismiss();
          this.businessService.publishAlert$({
            type: 'green-dark-01',
            icon: 'fas fa-check',
            message: `<b>Success!</b> — Account has been updated.`
          });
          this.submitting = false;
          f.enable();
          this.businessService.publishDataChange$(data);
        }, error => {
          console.log('error', error);
          const errorsList = error.error.errors.map(element => {
            return element.param;
          });
          console.log('errorsList', errorsList);
          this.errorMsg = `Invalid ${errorsList.toString().split(',').join(', ')}`;
          this.submitting = false;
          f.enable();
        }
      );
    } else {
      // if new
      this.httpService.post$(`distributor`, body).subscribe(
        data => {
          console.log('added distributor', data);
          this.activeModal.dismiss();
          this.businessService.publishAlert$({
            type: 'green-dark-01',
            icon: 'fas fa-check',
            message: `<b>Success!</b> — ${data.name} has been added.`
          });
          this.businessService.publishDataChange$(data);
          this.submitting = false;
          f.enable();
        }, error => {
          console.log('error', error);
          const errorsList = error.error.errors.map(element => {
            return element.value;
          });
          this.errorMsg = `Invalid ${errorsList.toString().split(',').join(', ')}`;
          this.submitting = false;
          f.enable();
        }
      );
    }
  }

  closeModal() {
      this.activeModal.close();
  }

  resetForm() {
    this.subscriptions.add(this.businessService.resetForm$.subscribe(res => {
      if (res === true) {
        this.distributorForm.reset();
        this.distributorForm.get('contactNo').patchValue('(+63)');
      }
    }));
  }
  patchValue() {
    this.distributorForm.patchValue({
      name: this.distributorData.name,
      contactPerson: this.distributorData.contactPerson,
      email: this.distributorData.email,
      mobileNo: this.distributorData.mobileNo ? this.patchMobile(this.distributorData.mobileNo) : '(+63)',
    });
    this.avatarUrl = this.distributorData.avatarUrl ? this.distributorData.avatarUrl : './assets/images/koala.jpg';
  }

  ngOnInit() {
    this.checkCroppedAvatar();
    this.branchId = localStorage.getItem('branchId');
    this.resetForm();
    if (this.distributorData) {
      this.patchValue();
      this.imgUrl = this.distributorData.avatarUrl ? this.distributorData.avatarUrl : './assets/images/koala.jpg';
    } else {
      this.imgUrl = './assets/images/koala.jpg';
    }
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

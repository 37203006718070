import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { BusinessService } from 'src/app/core/services/business.service';

@Component({
  selector: 'app-password-success-page',
  templateUrl: './password-success-page.component.html',
  styleUrls: ['./password-success-page.component.scss']
})
export class PasswordSuccessPageComponent implements OnInit, OnDestroy {
  type: string;
  title: string;
  bodyText: string;
  token: string;
  accountId: string;
  subscriptions = new Subscription();

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpAdminService: HttpWebAdminService,
    private router: Router,
    private authService: AuthService,
    private businessService: BusinessService
  ) { }

  getUrlParams() {
    this.subscriptions.add(this.activatedRoute.params.subscribe(params => {
      this.type = params['type'];
      this.getType();
    }));
    this.subscriptions.add(this.activatedRoute.queryParams.subscribe(params => {
      this.token =  params['token'];
    }));
    console.log('token', this.type, this.token);
    if (this.type === 'VERIFIED') {
      this.checkTokenValid();
    }
  }

  checkTokenValid() {
    this.httpAdminService.get$(`auth/update-account/verify-email?token=${this.token}`).subscribe(data => {
      console.log('data', data);
    }, error => {
      console.log('error', error);
      this.router.navigate([`/password-failed/${this.type}`]);
    });
  }

  backToLogin() {
    console.log('confirm logout');
    this.authService.logout(this.accountId).subscribe(
      res => {
      if (res.success === true) {
        this.router.navigate(['/login']);
        this.businessService.accountData$ = null;
        console.log('success logout');
      }
    }, error => {
      console.log('error', error);
    });
  }

  getType() {
    switch (this.type) {
      case 'FORGOT_PASSWORD' : {
        console.log('forgot password');
        this.title = 'Password Reset Successful';
        this.bodyText = 'Your password has been successfully reset.';
      }
      break;
      case 'CUSTOMER_PASSWORD' : {
        console.log('customer password');
        this.title = 'Password Reset Successful';
        this.bodyText = 'Your password has been successfully reset.';
      }
      break;
      case 'VERIFICATION' : {
        console.log('verify');
        this.title = 'Email Verified';
        this.bodyText = 'Your email has been successfully verified.';
      }
      break;
      case 'VERIFIED' : {
        console.log('verify');
        this.title = 'Email Verified';
        this.bodyText = 'Your email has been successfully verified.';
      }
      break;
      case 'CUSTOMER_VERIFY' : {
        console.log('customer verify');
        this.title = 'Email Verified';
        this.bodyText = 'Your email has been successfully verified.';
      }
      break;
    }
  }

  ngOnInit() {
    this.getUrlParams();
    this.accountId = localStorage.getItem('accountId');
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { BusinessService } from 'src/app/core/services/business.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BusinessPartnersAddEditFormComponent } from '../../components/business-partners-add-edit-form/business-partners-add-edit-form.component';
import { Router } from '@angular/router';
import { UserRolePermissionsComponent } from 'src/app/shared/components/user-role-permissions/user-role-permissions.component';
@Component({
  selector: 'app-business-partners-page',
  templateUrl: './business-partners-page.component.html',
  styleUrls: ['./business-partners-page.component.scss']
})
export class BusinessPartnersPageComponent extends UserRolePermissionsComponent implements OnInit, OnDestroy {
  dataLists: Array<any>;

  constructor(
    businessService: BusinessService,
    private modalService: NgbModal,
    private router: Router
  ) {
    super(businessService)
  }

  getDataList() {
    this.subscriptions.add(this.businessService.dataLists$.subscribe(
      data => {
        console.log('partners list', data);
        if (data) {
          this.dataLists = data.data;
        }
      }, error => {
        console.log('error', error);
      }
    ));
  }

  addPartner() {
    const modalRef = this.modalService.open(BusinessPartnersAddEditFormComponent, {
      centered: true
    });
    modalRef.componentInstance.title = 'Add Business Partner';
  }

  viewDetails($event) {
    console.log($event);
    this.router.navigate([`/business-partners/${$event.id}`]);
  }

  getAccountData() {
    this.getAccountPromise();
    this.accountPromise
    .then((data) => {      
      console.log('promise account', data);
      this.setAccountItems(data);
      // Insert all functions related to Account Data
      // ----- End ----- //
    })
    .catch((error) => {
      console.log('promise err', error);
    });
  }

  ngOnInit() {
    // Needed in User Role
    this.getAccountData();
    // --------- //
    this.getDataList();    
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-main-branch-billing',
  templateUrl: './main-branch-billing.component.html',
  styleUrls: ['./main-branch-billing.component.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }]
})
export class MainBranchBillingComponent implements OnInit {
  @Input() branchData: any;
  planList = [
    {value: 1, name: 'Subscription'},
    {value: 2, name: 'One-time Payment'}
  ];
  periodList = [ 3, 6, 9, 12 ];
  isSubscription: boolean;

  constructor(
    private fb: FormBuilder,
    private parent: FormGroupDirective
  ) { }

  billing = this.fb.group({
    plan: [this.planList[0].value],
    period: [this.periodList[0]],
    subsRate: [1, [Validators.required, Validators.min(1)]],
    smsRate: [0.4, [Validators.required, Validators.min(0)]]
  });

  get f() { return this.billing.controls; }

  changePlan($event) {
    if ($event.toString() === '1') {
      // show both
      this.isSubscription = true;
    } else {
      // price rate only
      this.isSubscription = false;
    }
  }

  preventInput(event, input) {
    if (event === 0) {
      input.patchValue(1);
    } else if (event === '') {
      input.patchValue('');
    }
  }

  preventInputSMS(event, input) {
    if (event === null || event === undefined) {
      input.patchValue(0);
    } else if (event === '') {
      input.patchValue('');
    }
  }

  preventMouseOut(input) {
    if (input.value <= 0 || input.value === '') {
      input.patchValue(1);
    }
  }

  preventMouseOutSMS(input) {
    if (input.value < 0 || input.value === '') {
      input.patchValue(0.4);
    }
  }

  patchValue() {
    this.billing.patchValue({
      plan: this.branchData.billing.planType.toString(),
      period: this.branchData.billing.lockInPeriod.toString(),
      subsRate: this.branchData.billing.amountRate.toString(),
      smsRate: this.branchData.billing.SMSRate.toString()
    });
    this.changePlan(this.branchData.billing.planType);
  }

  ngOnInit() {
    if (this.branchData) {
      this.patchValue();
    } else {
      this.changePlan(this.planList[0].value);
    }
    this.parent.form.addControl(
      'billing', this.billing
    );
  }

}

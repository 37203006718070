import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription, BehaviorSubject, timer, Subject, Observable } from 'rxjs';
import { BusinessService } from '../../services/business.service';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { HttpService } from '../../http/http.service';
import { SocketService } from '../../http/socket.service';
import { map, takeUntil, take } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangePasswordModalComponent } from 'src/app/modules/others/components/change-password-modal/change-password-modal.component';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit, OnDestroy {
  branchId: string;
  avatarUrl: string;
  alertType: any;
  alertMessage: any;
  alertIcon: any;
  subscriptions = new Subscription();
  staticAlert: boolean;
  isCollapse: boolean;
  isPermanentCollapse: boolean;
  partnerId: string;
  partnerData: any;
  branchName: string;
  partnerName: string;
  accountId: string;
  accountData: any;
  hasNewNotif: boolean;
  notifData: any;
  newNotifLists: Array<any>;
  oldNotifLists: Array<any>;
  endTime = 60;
  isTimeReset: boolean;
  count = 3;
  unsubscribe$ = new Subject<any>();
  timerSubscription: Subscription;
  genericNotif: BehaviorSubject<any>;
  accountNotif: BehaviorSubject<any>;
  viewedNotif: BehaviorSubject<any>;
  markReadNotif: BehaviorSubject<any>;
  readNotif: BehaviorSubject<any>;
  int: any;
  lastEvent: number;

  constructor(
    private businessService: BusinessService,
    private authService: AuthService,
    private router: Router,
    private httpService: HttpService,
    private wsService: SocketService,
    private modalService: NgbModal
  ) { }

  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  resetTimer2() {
    this.authService.notifyUserAction();
  }

  resetTimer(endTime: number = this.endTime) {
    this.lastEvent = 0;
    localStorage.removeItem('lastEventTime');
    const interval = 1000;
    const duration = endTime * 60;
    this.subscriptions.add(
      this.timerSubscription = timer(0, interval).pipe(
        take(duration)
      ).subscribe(value => {
        this.lastEvent ++;
        if ((Number(localStorage.getItem('lastEventTime'))) === value) {
          localStorage.setItem('lastEventTime', this.lastEvent.toString());
        }
      },
        err => { },
        () => {
          if (Number(localStorage.getItem('lastEventTime')) === duration) {
            this.isTimeReset = true;
            this.viewModal();
          }
        })
    );

  }

  viewModal() {
    console.log('should have modal logout');
    this.authService.logout(this.accountId);
    localStorage.removeItem('JWT_TOKEN');
    localStorage.removeItem('REFRESH_TOKEN');
    localStorage.removeItem('lastEventTime');
    this.modalService.dismissAll();
    if (this.isTimeReset) {
      const modalRef = this.modalService.open(ModalConfirmComponent, { centered: true,
        backdrop: false
        });
      modalRef.componentInstance.type = 'modal-timeout';
      modalRef.componentInstance.title = 'Session Timeout';
      modalRef.componentInstance.bodyText = 'You’ve been logged out due to browser inactivity for the past <b>60</b> minutes. Please login again to access your branch.';
      modalRef.componentInstance.primaryButton = 'Back to Login';
    }
  }

  listenAlert() {
    this.subscriptions.add(
      this.businessService.alert$.subscribe(res => {
        console.log('alerttttt', res);
        this.checkIfAccountUpdate(res.message);
        let millis;
        if (res.type === 'red-dark-01') {
          millis = 10;
        } else {
          millis = 3;
        }
        if (this.int) {
          console.log('timeout', millis);
          clearInterval(this.int);
        }
        if (res === null) {
          this.staticAlert = false;
        } else {
          this.staticAlert = true;
          this.alertType = res.type;
          this.alertMessage = res.message;
          this.alertIcon = res.icon;
          if (res.type !== 'yellow-light') {
            console.log(res.type, millis);
            this.countdown(millis);
          }
        }
      })
    );
  }

  countdown(count) {
    this.count = count;
    this.int = setInterval(() => {
      count--;
      console.log(count);
      this.count = count;
      if (count <= 0) {
        this.staticAlert = false;
        clearInterval(this.int);
      }
    }, 1000);
  }

  checkIfAccountUpdate(res) {
    if (res.includes('Account has been updated.')) {
      this.getAccountData();
    }
  }

  getPartnerData() {
    if (!this.businessService.partnerData$) {
      this.httpService.get$(`partners/${this.partnerId}`).subscribe(
        data => {
          console.log('partner data in topnav', data.data);
          this.partnerData = data.data;
          this.businessService.partnerData$ = this.partnerData;
        }, error => {
          console.log('error', error);
        }
      );
    }
  }

  getAccountData() {
    this.httpService.get$(`accounts/${this.accountId}`).subscribe(
      data => {
        console.log('account details', data);
        this.accountData = data;
        this.businessService.accountData$ = this.accountData;
        this.avatarUrl = this.accountData.avatarUrl ? this.accountData.avatarUrl : './assets/images/koala.jpg';
        // Publish Logged-in Account to Other Components
        const accountObject = {
          accountId: this.accountId,
          accountData: this.accountData,
          permission: this.accountData.roleGroup,
          roleLevel: this.accountData.roleLevel
        }
        console.log('accountObject', accountObject);
        this.businessService.publishLoggedInAccount$(accountObject);
        this.businessService.accountData$ = accountObject;
      }, error => {
        console.log('error', error);
      }
    );
  }

  getNotif() {
    this.httpService.get$(`notification/${this.branchId}/generic-notification?limit=999`).subscribe(
      data => {
        console.log('notif lists', data);
        this.newNotifLists = data.data.filter(element => {
          return element.readBy.length === 0;
        });
        this.oldNotifLists = data.data.filter(element => {
          return element.readBy.length > 0;
        });
      }, error => {
        console.log('error', error);
      }
    );
  }

  checkGenericNotifUpdate() {
    this.genericNotif = this.wsService
    .connect('RECEIVE_GENERIC_NOTIFICATION_' + this.branchId)
    .pipe(
      map((response: any): any => {
        return response;
      })
    ) as BehaviorSubject<any>;
    this.subscriptions.add(this.genericNotif.subscribe(msg => {
      console.log('received generic notif', msg);
      // if new generic notif received get new lists
      this.getNotif();
    }));
  }

  checkAccountNotifUpdate() {
    this.accountNotif = this.wsService
    .connect('RECEIVE_ACCOUNT_NOTIFICATION_' + this.accountId)
    .pipe(
      map((response: any): any => {
        return response;
      })
    ) as BehaviorSubject<any>;
    this.subscriptions.add(this.accountNotif.subscribe(msg => {
      console.log('received account notif', msg);
      // if new account notif received get new lists
      this.getNotif();
    }));
  }

  checkViewedNotifUpdate() {
    this.viewedNotif = this.wsService
    .connect('UPDATE_VIEWED_BY_GENERIC_NOTIFICATION_' + this.branchId)
    .pipe(
      map((response: any): any => {
        return response;
      })
    ) as BehaviorSubject<any>;
    this.subscriptions.add(this.viewedNotif.subscribe(msg => {
      console.log('received viewed notif', msg);
      // if new viewed notif received get new lists
      this.getNotif();
    }));
  }

  checkReadNotifUpdate() {
    this.readNotif = this.wsService
    .connect('UPDATE_READ_BY_GENERIC_NOTIFICATION_' + this.branchId)
    .pipe(
      map((response: any): any => {
        return response;
      })
    ) as BehaviorSubject<any>;
    this.subscriptions.add(this.readNotif.subscribe(msg => {
      console.log('received read notif', msg);
      // if new read notif received get new lists
      this.getNotif();
    }));
  }

  checkMarkAllAsReadNotifUpdate() {
    this.markReadNotif = this.wsService
    .connect('MARK_ALL_AS_READ_GENERIC_NOTIFICATION_' + this.branchId)
    .pipe(
      map((response: any): any => {
        return response;
      })
    ) as BehaviorSubject<any>;
    this.subscriptions.add(this.markReadNotif.subscribe(msg => {
      console.log('received mark as read notif', msg);
      // if new mark as read notif received get new lists
      this.getNotif();
    }));
  }

  viewNotif(notifId) {
    this.httpService.patch$(`notification/${this.branchId}/generic-notification/${notifId}/mark-as-read/`, '').subscribe(
      data => {
        console.log('read', data);
      }, error => {
        console.log('error', error);
      }
    );
  }

  markReadAll() {
    this.httpService.patch$(`notification/${this.branchId}/generic-notification/mark-all-as-read/`, '').subscribe(
      data => {
        console.log('viewed', data);
      }, error => {
        console.log('error', error);
      }
    );
  }

  toggleSideNav() {
    this.isPermanentCollapse = !this.isPermanentCollapse;
    this.isCollapse = !this.isCollapse;
    this.businessService.publishPermanentSideNav$(this.isPermanentCollapse);
    this.businessService.publishSideNav$(this.isCollapse);
  }

  viewProfile() {
    this.router.navigate([`/settings/admin-account-setting/${this.accountId}`]);
  }

  changePassword() {
    const modalRef = this.modalService.open(ChangePasswordModalComponent, {
      centered: true,
      size: 'sm'
    });
    modalRef.componentInstance.accountId = this.accountId;
  }

  logout() {
    console.log('confirm logout');
    this.authService.logout(this.accountId).subscribe(
      res => {
      if (res.success === true) {
        this.router.navigate(['/login']);
        this.businessService.accountData$ = null;
        console.log('success logout');
      }
    }, error => {
      console.log('error', error);
    });
  }

  ngOnInit() {
    console.log('topnav');
    this.listenAlert();
    this.branchId = localStorage.getItem('branchId');
    this.accountId = localStorage.getItem('accountId');
    if (this.businessService.accountData$) {
      this.accountData = this.businessService.accountData$.accountData;
      if (this.accountData) {
        this.avatarUrl = this.accountData.avatarUrl ? this.accountData.avatarUrl : './assets/images/koala.jpg';
      }
      console.log('accountData', this.accountData);
    } else {
      this.getAccountData();
    } 
    this.getNotif();
    this.checkGenericNotifUpdate();
    this.checkAccountNotifUpdate();
    this.checkViewedNotifUpdate();
    this.checkReadNotifUpdate();
    this.checkMarkAllAsReadNotifUpdate();
    this.resetTimer();
    this.authService.userActionOccured.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
      }
      this.resetTimer();
    });
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

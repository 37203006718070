import { Component, OnInit, OnDestroy, ViewChild, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Subscription, of } from 'rxjs';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { BusinessService } from 'src/app/core/services/business.service';
import { DateTimeValidatorService } from 'src/app/shared/validators/date-time.validator.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { CropPhotoComponent } from 'src/app/indie/crop-photo/crop-photo.component';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';

const states = ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado',
  'Connecticut', 'Delaware', 'District Of Columbia', 'Federated States Of Micronesia', 'Florida', 'Georgia',
  'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine',
  'Marshall Islands', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana',
  'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota',
  'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico', 'Rhode Island',
  'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virgin Islands', 'Virginia',
  'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];

@Component({
  selector: 'app-news-add-edit-form',
  templateUrl: './news-add-edit-form.component.html',
  styleUrls: ['./news-add-edit-form.component.scss']
})
export class NewsAddEditFormComponent implements OnInit, OnDestroy {
  title: string;
  subTitle: string;
  newsData: any;
  newsId: string;
  newsLinkList = [ 'No Link', 'External Link', 'Link to Business'];
  time: any;
  meridian = true;
  timeIndex: number;
  timeType: string;
  showDateTime: boolean;
  hoursDirty: boolean;
  defaultTime: number;
  newsForm: FormGroup;
  selectedBanner: any;
  bannerFileName: string;
  imgUrl: any;
  selectedRecipient: Array<number>;
  submitting: boolean;
  enableSubmit: boolean;
  minDate: any;
  timeValid: boolean;
  config: AngularEditorConfig;
  setting = {
    element: {
      dynamicDownload: null as HTMLElement
    }
  };
  htmlFile: File;
  businessPartnersList: Array<any>;
  croppedImg: any;
  fileName: string;
  fileType: string;
  avatarError: boolean;
  paramList: Array<any>;
  errorMsg: string;
  scheduledAtErrorMsg: string;
  imageDirty: boolean;
  subscriptions = new Subscription();

  constructor(
    private httpAdminService: HttpWebAdminService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private businessService: BusinessService,
    private calendar: NgbCalendar
  ) { }

  createForm() {
    this.newsForm = this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(120)]],
      isScheduled: [false],
      newsOption: [0, Validators.required],
      newsLink: [''],
      rawContent: ['', Validators.required],
      dateSchedule: [],
      timeSchedule: []
    });
  }

  get f() { return this.newsForm.controls; }
  get recipients() { return this.newsForm.get('recipients') as FormArray; }

  getUrlParams() {
    this.subscriptions.add(this.activatedRoute.params.subscribe(params => {
      this.newsId = params['newsId'];
      this.getNewsData();
    }));
  }

  getNewsData() {
    this.httpAdminService.get$(`notification/news/${this.newsId}`).subscribe(
      data => {
        console.log('news data', data);
        if (data) {
          this.newsData = data;
          if (this.newsData) {
            this.patchValue();
          }
        }
      }, error => {
        console.log('error', error);
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Error:</b> Something went wrong.`
        });
      }
    );
  }

  goToNewsPage() {
    this.router.navigate([`/announcements/news/view/${this.newsData._id}`]);
  }

  getBusinessPartners() {
    this.httpAdminService.get$(`partners`).subscribe(
      data => {
        console.log('business partners list', data);
        if (data) {
          this.businessPartnersList = data.data;
        }
      }, error => {
        console.log('error', error);
      }
    );
  }

  changeNewsLink($event) {
    console.log($event);
    this.f['newsLink'].patchValue('');
    if ($event === 1 || $event === 2) {
      this.f['newsLink'].setValidators([Validators.required]);
    } else {
      this.f['newsLink'].clearValidators();
      this.f['newsLink'].updateValueAndValidity();
    }
  }

  patchValue() {
    this.newsForm.patchValue({
      title: this.newsData.title,
      rawContent: this.newsData.rawContent,
      newsOption: this.newsData.newsLink.linkType,
      newsLink: this.newsData.newsLink.url,
      isScheduled: this.newsData.scheduledAt > 0 ? true : false,
      dateSchedule: this.newsData.scheduledAt > 0 ? this.businessService.jsonDate(this.newsData.scheduledAt) : undefined,
      timeSchedule: this.newsData.scheduledAt > 0 ? this.newsData.scheduledAt : undefined
    });
    this.imgUrl = this.newsData.bannerUrl;
    this.bannerFileName = this.newsData.bannerUrl;
  }

  onAvatarChange($event) {
    console.log($event.target.files[0]);
    this.bannerFileName = $event.target.files[0].name;
    this.selectedBanner = $event.target.files[0] as File;
    if (['image/png', 'image/jpeg'].includes($event.target.files[0].type)) {
      this.openCropModal($event, 'cover-news');
    } else {
      this.avatarError = true;
    }
  }

  checkCroppedBanner() {
    this.subscriptions.add(this.businessService.croppedBanner$.subscribe(res => {
      this.croppedImg = res.image;
      console.log('this.croppedImg2', this.croppedImg);
      this.imgUrl = res.image;
      this.fileName = res.fileName;
      this.fileType = res.fileType;
      this.enableSubmit = true;
      this.avatarError = false;
      this.imageDirty = false;
    }));
  }

  openCropModal(avatar, type) {
    const modalRef = this.modalService.open(CropPhotoComponent, {
      centered: true,
      windowClass: `modal-${type}`
    });
    modalRef.componentInstance.imageFile = avatar;
    modalRef.componentInstance.title = 'Select News Image';
    modalRef.componentInstance.cropType = type;
  }

  checkIfDirty() {
    this.imageDirty = true;
  }

  changeTime($event, timePicker, timeValue, type) {
    $event.srcElement.blur();
    this.modalService.open(timePicker, {
      centered: true,
      size: 'sm',
      windowClass: 'width-200'
    });
    this.time = this.businessService.jsonTime(timeValue);
    this.timeType = type;
    this.hoursDirty = true;
  }

  setTime(time, type) {
    const transformedTime = this.businessService.parseTime(time);
    this.f[type].setValue(transformedTime);
  }

  toggleMeridian() {
    this.meridian = !this.meridian;
  }

  checkIsScheduled($event) {
    console.log($event);
    if ($event) {
      this.checkTimeValid();
      this.showDateTime = true;
      this.f['dateSchedule'].setValidators([Validators.required]);
      this.f['timeSchedule'].setValidators([Validators.required]);
      this.f['dateSchedule'].updateValueAndValidity();
      this.f['timeSchedule'].updateValueAndValidity();
    } else {
      this.showDateTime = false;
      this.timeValid = true;
      this.f['dateSchedule'].clearValidators();
      this.f['timeSchedule'].clearValidators();
      this.f['dateSchedule'].updateValueAndValidity();
      this.f['timeSchedule'].updateValueAndValidity();
    }
  }

  convertTimeDate(date, time) {
    const convertTime = this.businessService.jsonTime(time);
    if (date) {
      const convertMillis = (new Date(`${date.month}/${date.day}/${date.year} ${convertTime.hour}:${convertTime.minute}:${convertTime.second}`).getTime());
      console.log('convertMillis', `${date.month}/${date.day}/${date.year} ${convertTime.hour}:${convertTime.minute}:${convertTime.second}`, convertMillis);
      return convertMillis;
    }
  }

  checkTimeValid() {
    const time = this.convertTimeDate((this.f['dateSchedule'].value), this.f['timeSchedule'].value);
    const date = new Date();
    console.log('time - todayTime', time - date.getTime());
    if (time > date.getTime()) {
      console.log('is valid time');
      this.timeValid = true;
    } else {
      console.log('not valid time');
      this.timeValid = false;
    }
    console.log(this.f['dateSchedule']);
    console.log(this.f['timeSchedule']);
  }

  fakeValidateUserData() {
    return of(this.f['rawContent'].value);
  }

  dynamicDownloadTxt() {
    this.fakeValidateUserData().subscribe((res) => {
      this.dyanmicDownloadByHtmlTag({
        fileName: this.f['title'].value,
        text: res
      });
    });
  }

  dyanmicDownloadByHtmlTag(arg: {
    fileName: string,
    text: string
  }) {
    const myblob = new Blob([arg.text], {
        type: 'text/html'
    });
    console.log('blob', myblob);
    console.log(this.blobToFile(myblob, 'title'));
    this.htmlFile = this.blobToFile(myblob, arg.fileName);
  }

  blobToFile(theBlob: Blob, fileName: string): File {
    const b: any = theBlob;
    b.lastModified = this.businessService.parseDate(new Date());
    b.lastModifiedDate = new Date();
    b.name = fileName + '.html';
    return theBlob as File;
  }

  initEditorConfig() {
    this.config = {
      editable: true,
      spellcheck: true,
      height: '15rem',
      minHeight: '5rem',
      placeholder: 'Enter text here...',
      translate: 'no',
      toolbarHiddenButtons: [
        ['strikeThrough', 'subscript', 'superscript'],
        ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
        ['insertVideo', 'insertHorizontalRule', 'insertImage']
      ]
    };
  }

  cancel() {
    if (this.newsData) {
      this.router.navigate([`/announcements/news/view/${this.newsId}`]);
    } else {
      this.router.navigate([`/announcements/news`]);
    }
  }

  canDeactivate() {
    console.log('canDeactivate');
    if (this.newsForm.dirty || this.enableSubmit) {
      const modalRef = this.modalService.open(ModalConfirmComponent, {
        centered: true
      });
      modalRef.componentInstance.title = 'Leave Page';
      modalRef.componentInstance.type = 'leave-page';
      return this.businessService.navigateAwaySelection$;
    } else {
      return true;
    }
  }

  submitForm(f, stat) {
    this.dynamicDownloadTxt();
    console.log(this.config);
    console.log(f.value);
    this.submitting = true;
    f.disable();
    const {dateSchedule, timeSchedule, isScheduled, newsOption, ...form} = f.value;
    const newForm = {...form, status: stat, scheduledAt: this.f['isScheduled'].value === true ? this.convertTimeDate(this.f['dateSchedule'].value, this.f['timeSchedule'].value) : 0};
    const body = new FormData();
    const fileName = (f.value.title.toLowerCase().replace(/ /g, '-') + '.html');
    console.log('fileName', fileName);
    if (this.croppedImg) {
      body.append('banner', new File([new Blob([this.businessService.dataURItoBlob(this.croppedImg)], {type: this.fileType})], this.fileName, {type: this.fileType}));
    console.log('banner', new File([new Blob([this.businessService.dataURItoBlob(this.croppedImg)], {type: this.fileType})], this.fileName, {type: this.fileType}));
    }
    body.append('content', new File([this.htmlFile], fileName));
    for (const key in newForm) {
      if (newForm.hasOwnProperty(key)) {
        body.append(key, newForm[key]);
        console.log(key, newForm[key]);
      }
    }
    console.log('body', body);
    if (this.newsData) {
      // if edit
      this.httpAdminService.patch$(`notification/news/${this.newsId}`, body).subscribe(
        data => {
          console.log('edited', data);
          // if Saved as Draft
          if (stat === 0) {
            this.businessService.publishAlert$({
              type: 'green-dark-01',
              icon: 'fas fa-check',
              message: `<b>Draft Saved!</b> Your draft has been saved.`
            });
          // if Send
          } else {
            this.businessService.publishAlert$({
              type: 'green-dark-01',
              icon: 'fas fa-check',
              message: `<b>News Posted!</b> News has been posted.`
            });
          }
          this.submitting = false;
          f.enable();
          f.reset();
          this.enableSubmit = false;
          this.router.navigate([`/announcements/news/view/${data._id}`]);
        }, error => {
          console.log('error', error);
          this.showError(error);
          this.submitting = false;
          f.enable();
        }
      );
    } else {
      // if new
      this.httpAdminService.post$(`notification/news`, body).subscribe(
        data => {
          console.log('submitted', data);
          // if Saved as Draft
          if (stat === 0) {
            this.businessService.publishAlert$({
              type: 'green-dark-01',
              icon: 'fas fa-check',
              message: `<b>Draft Saved!</b> Your draft has been saved.`
            });
          // if Send
          } else {
            this.businessService.publishAlert$({
              type: 'green-dark-01',
              icon: 'fas fa-check',
              message: `<b>News Posted!</b> News has been posted.`
            });
          }
          this.submitting = false;
          f.enable();
          f.reset();
          this.enableSubmit = false;
          this.router.navigate([`/announcements/news/view/${data._id}`]);
        }, error => {
          console.log('error', error);
          this.showError(error);
          this.submitting = false;
          f.enable();
        }
      );
    }
  }

  showError(error) {
    this.paramList = error.error.errors.filter(element => element.param).map(element2 => element2.param);
    console.log('this.paramList', this.paramList);
    if (this.paramList) {
      if (this.paramList.includes('scheduledAt')) {
        this.scheduledAtErrorMsg = 'Entered time is less than the current time.';
      } else {
        this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
      }
    }
    this.businessService.publishAlert$({
      type: 'red-dark-01',
      icon: 'fas fa-ban',
      message: this.errorMsg
    });
  }

  clearBackendError(input) {
    if (input.dirty) {
      this.scheduledAtErrorMsg = null;
      this.errorMsg = null;
    }
  }

  ngOnInit() {
    this.checkCroppedBanner();
    this.getBusinessPartners();
    this.bannerFileName = '';
    this.initEditorConfig();
    if (this.router.url.includes('edit')) {
      this.getUrlParams();
      console.log('should edit');
      this.title = 'Edit News';
      this.subTitle = 'Edit and schedule posting of new articles for customers';
    } else {
      this.title = 'Add News';
      this.subTitle = 'Add and schedule posting of new articles for customers';
    }
    const date = new Date();
    const m = date.getMonth();
    const d = date.getDay();
    const y = date.getFullYear();
    this.defaultTime = date.setTime(date.getTime() + (60 * 60 * 1000));
    this.minDate = this.calendar.getToday();
    console.log('date today', this.calendar.getToday());
    this.createForm();
    this.timeValid = true;
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { BusinessService } from 'src/app/core/services/business.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/core/http/http.service';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';

@Component({
  selector: 'app-search-filter-web-admin',
  templateUrl: './search-filter-web-admin.component.html',
  styleUrls: ['./search-filter-web-admin.component.scss']
})
export class SearchFilterWebAdminComponent implements OnInit, OnDestroy {
  @Input() searchFilterType: any;
  @Input() placeholder: any;
  @Input() filterList: any;
  @Input() listType: any;
  @Input() feedType: string;
  @Output() selectFilter = new EventEmitter();
  dateTypes = ['Show All', 'Today', 'Yesterday', 'Last 7 Days', 'Last 30 Days', 'This Month', 'Last Month', 'This Year', 'Custom'];
  selected: number;
  dateFrom: any;
  dateTo: any;
  dateDisplay: boolean;
  inputFrom: any;
  inputTo: any;
  selectedLimit: number;
  limits: Array<number>;
  searchString: string;
  searchTerm$ = new Subject<string>();
  branchId: string;
  categoryId: string;
  queueGroupId: string;
  partnerId: string;
  startItem: number;
  selectedFilter: string;
  filterCategory: any;
  selectedFilterLists: Array<any>;
  selectedFilterName: string;
  curr = new Date();
  month = this.curr.getMonth() - 1;
  httpGet: any;
  subscriptions = new Subscription();
  distributorId: any;
  customerId: any;
  deviceId: string;
  accountId: string;
  @Input() logsType: any;
  eventList: Array<any> = [];
  modulesArray: Array<any>;

  constructor(
    private businessService: BusinessService,
    private activatedRoute: ActivatedRoute,
    private httpAdminService: HttpWebAdminService,
    private router: Router
  ) {
    this.subscriptions.add(this.search(this.searchTerm$).subscribe(() => {
      console.log('searchTerm');
      this.getSearchResult();
    }));
  }

  search(terms: Observable<string>) {
    return terms
    .pipe(
      debounceTime(500),
      distinctUntilChanged()
    );
  }

  getUrlParams() {
    this.subscriptions.add(this.activatedRoute.params.subscribe(params => {
      this.categoryId = params['categoryId'];
      this.queueGroupId = params['queueGroupId'];
      this.partnerId = params['partnerId'];
      this.distributorId = params['distributorId'];
      this.customerId = params['customerId'];
      this.deviceId = params['deviceId'];
      this.accountId = params['accountId'];
      this.branchId = params['branchId'];
    }));
  }

  getSelectedCategory() {
    this.subscriptions.add(this.businessService.selectedCategory$.subscribe(
      data => {
        this.categoryId = data;
        this.getSearchResult();
      }
    ));
  }

  getSearchResult() {
    console.log('getSearchResult', this.feedType);
    this.startItem = this.startItem ? this.startItem : 0;
    this.selectedLimit = this.selectedLimit ? this.selectedLimit : 10;
    let param = null;
    switch (this.feedType) {
      case 'partners' : {
        param = `partners/?limitTo=${this.selectedLimit}&startAt=${this.startItem}`;
      }
      break;
      case 'branches' : {
        param = `partners/${this.partnerId}/branches/?limitTo=${this.selectedLimit}&startAt=${this.startItem}`;
      }
      break;
      case 'industry' : {
        param = `industry/?limitTo=${this.selectedLimit}&startAt=${this.startItem}`;
      }
      break;
      case 'distributor' : {
        param = `distributor/?limit=${this.selectedLimit}&offset=${this.startItem}`;
      }
      break;
      case 'distributor-device' : {
        param = `distributor/${this.distributorId}/devices/?limitTo=${this.selectedLimit}&startAt=${this.startItem}`;
      }
      break;
      case 'distributor-accounts' : {
        param = `distributor/${this.distributorId}/accounts/search/?limit=${this.selectedLimit}&offset=${this.startItem}`;
      }
      break;
      case 'distributor-branches' : {
        param = `distributor/${this.distributorId}/branch/?limit=${this.selectedLimit}&offset=${this.startItem}`;
      }
      break;
      case 'device-monitoring' : {
        param = `devices/?limitTo=${this.selectedLimit}&startAt=${this.startItem}`;
      }
      break;
      case 'customer-history' : {
        param = `queues/customers/${this.customerId}/history/search/?limitTo=${this.selectedLimit}&startAt=${this.startItem}`;
      }
      break;
      case 'admin-accounts' : {
        param = `accounts/search/?limit=${this.selectedLimit}&offset=${this.startItem}`;
      }
      break;
      case 'user-admin-role' : {
        param = `accounts/role-groups/?limitTo=${this.selectedLimit}&startAt=${this.startItem}`;
      }
      break;
      case 'news' : {
        param = `notification/news/search?limit=${this.selectedLimit}&offset=${this.startItem}`;
      }
      break;
      case 'messages' : {
        param = `notification/messages/search?limit=${this.selectedLimit}&offset=${this.startItem}`;
      }
      break;
      case 'queues-count' : {
        param = `queues/${this.branchId}/dashboard/info/`;
        console.log('param', param);
      }
      break;
      case 'dashboard-queues-count' : {
        param = `queues/admin-dashboard/`;
      }
      break;
      case 'discover-business-list' : {
        param = `partners/customer-app?limitTo=999&startAt=${this.startItem}`;
      }
      break;
      case 'activity-logs' : {
        // News
        if (this.router.url.includes('news')) {
          param = `logger/general-logs?classification=news&limit=${this.selectedLimit}&offset=${this.startItem}`;
        // Messages
        } else if (this.router.url.includes('messages')) {
          param = `logger/general-logs?classification=messages&limit=${this.selectedLimit}&offset=${this.startItem}`;
        // Business Partners list
        } else if (this.router.url.includes('/business-partners/activity-logs')) {
          param = `logger/general-logs/partners?limitTo=${this.selectedLimit}&startAt=${this.startItem}`;
        // Branch List
        } else if (this.router.url.includes(`/business-partners/${this.partnerId}/activity-logs`)) {
          param = `logger/general-logs/branches?limitTo=${this.selectedLimit}&startAt=${this.startItem}`;
        } else if (this.router.url.includes('distributors')) {
          param = `logger/general-logs/distributors?limitTo=${this.selectedLimit}&startAt=${this.startItem}`;
        } else if (this.router.url.includes('customers')) {
          param = `logger/general-logs?classification=customer&limitTo=${this.selectedLimit}&startAt=${this.startItem}`;
        } else if (this.router.url.includes('customer-app-setting')) {
          param = `logger/general-logs/customer-app-settings?limitTo=${this.selectedLimit}&startAt=${this.startItem}`;
        } else if (this.router.url.includes('device-monitoring/activity-logs')) {
          param = `logger/general-logs/devices?limitTo=${this.selectedLimit}&startAt=${this.startItem}`;
        } else if (this.router.url.includes(`device-monitoring/${this.deviceId}`)) {
          param = `logger/general-logs/devices?referenceId=${this.deviceId}&limitTo=${this.selectedLimit}&startAt=${this.startItem}`;
        } else if (this.router.url.includes('admin-account-setting/activity-logs')) {
          param = `logger/general-logs/accounts?limitTo=${this.selectedLimit}&startAt=${this.startItem}`;
        } else if (this.router.url.includes('industry-setting')) {
          param = `logger/general-logs/industries?limitTo=${this.selectedLimit}&startAt=${this.startItem}`;
        } else if (this.router.url.includes(`admin-account-setting/${this.accountId}`)) {
          param = `logger/general-logs?classification=general-logs&accountId=${this.accountId}&limitTo=${this.selectedLimit}&startAt=${this.startItem}`;
        } else if (this.router.url.includes('user-admin-role-setting/activity-logs')) {
          param = `logger/general-logs/accounts?limitTo=${this.selectedLimit}&startAt=${this.startItem}`;
        }
      }
      break;
    }
    if (this.searchString) {
      param = param.concat(`&searchText=${this.searchString}`);
    }
    if (this.listType) {
      param = param.concat(`&searchText=${this.searchString}`);
    }
    if (this.filterList === 'lineChart' || this.filterList === 'dashboardLineChart') {
      param = param.concat(this.selectedFilterName);
      if (this.feedType === 'queues-count-queue-group') {
        param = param.concat(`?queueGroupId=${this.queueGroupId}`);
      }
    } else {
      if (this.selectedFilterName) {
        this.dateFrom = null;
        this.dateTo = null;
        param = param.concat(`&${this.selectedFilter}=${this.selectedFilterName}`);
      }
      if (this.selectedFilterName === '') {
        param = param.replace(`&${this.selectedFilter}=${this.selectedFilterName}`, '');
      }
    }
    if (this.dateFrom) {
      param = param.concat(`&dateFrom=${this.parseDate(this.dateFrom)}`);
    } else {
      if (param) {
        param = param.replace(`&dateFrom=${this.parseDate(this.dateFrom)}`, '');
      }
    }
    if (this.dateTo) {
      param = param.concat(`&dateTo=${this.parseDate(this.dateTo)}`);
    } else {
      if (param) {
        param = param.replace(`&dateTo=${this.parseDate(this.dateTo)}`, '');
      }
    }
    if (param !== null) {
      this.httpGet = this.httpAdminService.get$(param).subscribe(
        data => {
          console.log('param', param);
          console.log('result', data);
          if (this.filterList === 'lineChart' || this.filterList === 'dashboardLineChart') {
            this.businessService.publishChartLists$(data);
          } else {
            this.businessService.publishDataLists$(data);
          }
          if (this.feedType === 'accounts-management') {
            const total = data.data.filter(element => {
              return element.roleLevel > 0;
            });
            this.businessService.publishTotalItem$(total.length);
          // } if (this.feedType === 'activity-logs') {
          //     if (this.router.url.includes(`device-monitoring/${this.deviceId}`)) {
          //       const total = data.data.filter(element => element.actionType === 4 || element.actionType === 5 || ((element.actionType === 2 && element.eventSummary.includes('pulled-out') || (element.actionType === 2 && element.eventSummary.includes('updated')))));
          //       this.businessService.publishTotalItem$(total.length);
          //     } else {
          //       const total = data.data;
          //       this.businessService.publishTotalItem$(total.length);
          //     }
          } else {
            this.businessService.publishTotalItem$(data.totalCounts);
          }
          this.businessService.publishMaxNumberPages$(data.totalPages);
          this.businessService.publishSearchString$(this.searchString);
        }, error => {
          console.log('errorrrr', error);
          if (error.status !== 404) {
            if (this.filterList === 'lineChart' || this.filterList === 'dashboardLineChart') {
              this.businessService.publishChartLists$(error);
            } else {
              this.businessService.publishDataLists$(error);
            }
          }
        }
      );
    }
    this.selectFilter.emit(this.selectedFilterName);
  }

  getFilterList() {
    switch (this.filterList) {
      case 'lineChart': {
        this.filterCategory = [{
          fieldName: 'queueType', name: 'Show',
          value: [
            { value: 'weekly-queue-counts', name: 'This Week'},
            { value: 'month-queue-counts', name: 'This Month'}
          ]
        }];
        this.selectedFilterName = this.filterCategory[0].value[0].value;
        console.log('selectedFilterName', this.selectedFilterName);
      }
      break;
      case 'dashboardLineChart': {
        this.filterCategory = [{
          fieldName: 'queueType', name: 'Show',
          value: [
            { value: 'weekly-queue-counts?filterBy=this-week', name: 'This Week'},
            { value: 'weekly-queue-counts?filterBy=last-week', name: 'Last Week'},
            { value: 'month-queue-counts?filterBy=this-month', name: 'This Month'},
            { value: `month-queue-counts?filterBy=last-month`, name: 'Last Month'}
          ]
        }];
        this.selectedFilterName = this.filterCategory[0].value[0].value;
        console.log('selectedFilterName', this.selectedFilterName);
      }
      break;
      case 'news' : {
        this.filterCategory =  [{
          fieldName: 'businessPartner', name: 'Business Partner'
        },
        {
          fieldName: 'calendar', name: 'Publish Date'
        },
        {
          fieldName: 'status', name: 'Status',
          value: [
            { value: '0', name: 'Draft'},
            { value: '1', name: 'Posted'}
          ]
        }
      ];
        this.selectedFilter = this.filterCategory[0].fieldName;
        this.selectedFilterName = '';
        this.filterNameChange(this.filterCategory[0].fieldName);
      }
      break;
      case 'messages' : {
        this.filterCategory =  [{
          fieldName: 'calendar', name: 'Sent Date'
        },
        {
          fieldName: 'status', name: 'Status',
          value: [
            { value: 0, name: 'Draft'},
            { value: 1, name: 'Pending'},
            { value: 2, name: 'Sent'}
          ]
        }];
        this.selectedFilter = this.filterCategory[0].fieldName;
        this.selectedFilterName = '';
        this.filterNameChange(this.filterCategory[0].fieldName);
      }
      break;
      case 'device-monitoring' : {
        this.filterCategory =  [{
          fieldName: 'manufacturer', name: 'Manufacturer',
          value: [
            { value: 'Sunmi', name: 'Sunmi'},
            { value: 'Telpo', name: 'Telpo'},
            { value: 'Samsung', name: 'Samsung'}
          ]
        },
        {
          fieldName: 'status', name: 'Status',
          value: [
            { value: 1, name: 'Active'},
            { value: 2, name: 'In-stock'},
            { value: 3, name: 'Suspended'}
          ]
        }];
        this.selectedFilter = this.filterCategory[0].fieldName;
        this.selectedFilterName = '';
        this.filterNameChange(this.filterCategory[0].fieldName);
      }
      break;
      case 'distributor-device' : {
        this.filterCategory =  [{
          fieldName: 'manufacturer', name: 'Manufacturer',
          value: [
            { value: 'Sunmi', name: 'Sunmi'},
            { value: 'Samsung', name: 'Samsung'},
            { value: 'Telpo', name: 'Telpo'}
          ]
        },
        {
          fieldName: 'status', name: 'Status',
          value: [
            { value: 1, name: 'Active'},
            { value: 2, name: 'In-stock'},
            { value: 3, name: 'Suspended'}
          ]
        }];
        this.selectedFilter = this.filterCategory[0].fieldName;
        this.selectedFilterName = '';
        this.filterNameChange(this.filterCategory[0].fieldName);
      }
      break;
      case 'distributor-branches' : {
        this.filterCategory =  [{
          fieldName: 'industryId', name: 'Industry'
        },
        {
          fieldName: 'status', name: 'Status',
          value: [
            { value: true, name: 'Active'},
            { value: false, name: 'Suspended'}
          ]
        }];
        this.selectedFilter = this.filterCategory[0].fieldName;
        this.selectedFilterName = '';
        this.filterNameChange(this.filterCategory[0].fieldName);
      }
      break;
      case 'distributor' : {
        this.filterCategory =  [{
          fieldName: 'status', name: 'Status',
          value: [
            { value: true, name: 'Active'},
            { value: false, name: 'Suspended'}
          ]
        }];
        this.selectedFilter = this.filterCategory[0].fieldName;
        this.selectedFilterName = '';
        this.filterNameChange(this.filterCategory[0].fieldName);
      }
      break;
      case 'partners' : {
        this.filterCategory =  [{
          fieldName: 'calendar', name: 'Date Added'
        },
        {
          fieldName: 'industryId', name: 'Industry'
        }];
        this.selectedFilter = this.filterCategory[0].fieldName;
        this.selectedFilterName = '';
        this.filterNameChange(this.filterCategory[0].fieldName);
      }
      break;
      case 'branches' : {
        this.filterCategory =  [{
          fieldName: 'calendar', name: 'Date Added'
        },
        {
          fieldName: 'status', name: 'Status',
          value: [
            { value: '1', name: 'Active'},
            { value: '2', name: 'Suspended'}
          ]
        }];
        this.selectedFilter = this.filterCategory[0].fieldName;
        this.selectedFilterName = '';
        this.filterNameChange(this.filterCategory[0].fieldName);
      }
      break;
      case 'customer-history' : {
        this.filterCategory =  [
        {
          fieldName: 'status', name: 'Status',
          value: [
            { value: 3, name: 'Cancelled'},
            { value: 1, name: 'Completed'},
            { value: 4, name: 'Removed'}
          ]
        }];
        this.selectedFilter = this.filterCategory[0].fieldName;
        this.selectedFilterName = '';
        this.filterNameChange(this.filterCategory[0].fieldName);
      }
      break;
      case 'admin-accounts' : {
        this.filterCategory =  [{
          fieldName: 'status', name: 'Status',
          value: [
            { value: 'true', name: 'Active'},
            { value: 'false', name: 'Suspended'},
            { value: 'unverified', name: 'Unverified'}
          ]
        }];
        this.selectedFilter = this.filterCategory[0].fieldName;
        this.selectedFilterName = '';
        this.filterNameChange(this.filterCategory[0].fieldName);
      }
      break;
      case 'activity-logs' : {
        this.filterCategory =  [{
          fieldName: 'calendar', name: 'Date'
        },
        {
          fieldName: 'event', name: 'Event',
          value: [
            { value: '1', name: 'Add'},
            { value: '2', name: 'Edit'},
            { value: '3', name: 'Delete'},
            { value: '4', name: 'Suspend'},
            { value: '5', name: 'Unsuspend'},
            { value: '6', name: 'Sign in'},
            { value: '7', name: 'Sign out'},
            { value: '8', name: 'Reset'},
            { value: '9', name: 'Post'}
          ]
        }];
        this.selectedFilter = this.filterCategory[0].fieldName;
        this.selectedFilterName = '';
        this.filterNameChange(this.filterCategory[0].fieldName);
        this.getLogsType();
      }
      break;
    }
  }

  getFilterListModule() {
    this.filterCategory =  [{
      fieldName: 'module', name: 'Module',
      value: this.modulesArray
    }];
    console.log('filter module', this.modulesArray);
    this.selectedFilter = this.filterCategory[0].fieldName;
    this.selectedFilterName = '';
    this.filterNameChange(this.filterCategory[0].fieldName);
  }

  getModulesList() {
    this.httpAdminService.get$(`accounts/role-groups/available-modules`).subscribe(
      data => {
        this.modulesArray = data.data.map(element => {
          return {
            name: element.label,
            value: element.fieldName
          };
        });
        this.getFilterListModule();
        console.log('modulesArray', this.modulesArray);
      }, error => {
        console.log('error', error);
      }
    );
  }

  getLogsType() {
    switch (this.logsType) {
      case 'partners' : {
        this.eventList = ['Add', 'Suspend', 'Unsuspend', 'Edit'];
      }
      break;
      case 'partners-single' : {
        this.eventList = ['Add', 'Suspend', 'Unsuspend', 'Edit'];
      }
      break;
      case 'distributor' : {
        this.eventList = ['Add', 'Suspend', 'Unsuspend', 'Edit'];
      }
      break;
      case 'customer' : {
        this.eventList = ['Suspend', 'Unsuspend', 'Reset'];
      }
      break;
      case 'device-monitoring' : {
        this.eventList = ['Add'];
      }
      break;
      case 'device-single' : {
        this.eventList = ['Suspend', 'Unsuspend', 'Edit'];
      }
      break;
      case 'industry-setting' : {
        this.eventList = ['Add', 'Edit', 'Delete'];
      }
      break;
      case 'customer-app-setting' : {
        this.eventList = ['Add', 'Edit', 'Delete'];
      }
      break;
      case 'news' : {
        this.eventList = ['Delete', 'Post'];
      }
      break;
      case 'messages' : {
        this.eventList = ['Delete', 'Post'];
      }
      break;
     case 'admin-account-setting' : {
      this.eventList = ['Add', 'Edit', 'Delete', 'Suspend', 'Unsuspend', 'Sign in', 'Sign out', 'Reset', 'Post'];
     }
     break;
    }
  }

  filterNameChange(selectedFilter) {
    this.filterCategory.forEach(element => {
      if (element.fieldName === selectedFilter) {
        this.selectedFilterLists = element.value;
      }
    });
    this.selectedFilterName = '';
    if (selectedFilter === 'businessPartner') {
      this.getPartnerList();
    } else if (selectedFilter === 'industryId') {
      this.getIndustryList();
    } else if  (selectedFilter === 'addedBy') {
      this.getUserList();
    }

    this.getSearchResult();
  }

  getPartnerList() {
    this.httpAdminService.get$(`partners`).subscribe(
      data => {
        console.log('partner listzz', data);
        this.selectedFilterLists = data.data
        .map(element => {
          return {
            value: element._id,
            name: element.name
          };
        }).sort((a, b) => {
          return (a.name.localeCompare(b.name));
        });
      }, error => {
        console.log('error', error);
      }
    );
  }

  getIndustryList() {
    this.httpAdminService.get$(`industry`).subscribe(
      data => {
        console.log('industry listzzz', data);
        this.selectedFilterLists = data.data
        .map(element => {
          return {
            value: element._id,
            name: element.name
          };
        }).sort((a, b) => {
          return (a.name.localeCompare(b.name));
        });
      }, error => {
        console.log('error', error);
      }
    );
  }
  getUserList() {
    this.httpAdminService.get$(`accounts/search`).subscribe(
      data => {
        console.log('accounts listzzz', data);
        this.selectedFilterLists = data.data
        .map(element => {
          return {
            value: element._id,
            name: element.name
          };
        }).sort((a, b) => {
          return (a.name.localeCompare(b.name));
        });
      }, error => {
        console.log('error', error);
      }
    );
  }

  changeDateFilter(i) {
    const today = new Date();
    this.selected = i;
    console.log(this.selected);
    switch (i) {
      // Show All
      case 0: {
        this.dateFrom = null;
        this.dateTo = null;
      }
      break;
      // Today
     case 1: {
      this.dateFrom = this.jsonDate(today);
      this.dateTo = this.jsonDate(today);
     }
     break;
     // Yesterday
      case 2: {
        this.dateFrom = this.jsonDate(today.setDate(today.getDate() - 1));
        this.dateTo = this.dateFrom;
      }
      break;
      // Last 7 Days
      case 3: {
        this.dateTo = this.jsonDate(today);
        this.dateFrom = this.jsonDate(today.setDate(today.getDate() - 6));
      }
      break;
      // Last 30 Days
      case 4: {
        this.dateTo = this.jsonDate(today);
        this.dateFrom = this.jsonDate(today.setDate(today.getDate() - 29));
      }
      break;
      // This Month
      case 5: {
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        this.dateTo = this.jsonDate(lastDay);
        this.dateFrom = this.jsonDate(firstDay);
      }
      break;
      // Last Month
      case 6: {
        const lastMonthFirstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const lastMonthLastDay = new Date(today.getFullYear(), today.getMonth(), 0);
        this.dateTo = this.jsonDate(lastMonthLastDay);
        this.dateFrom = this.jsonDate(lastMonthFirstDay);
      }
      break;
       // This Year
       case 7: {
        const thisYearFirstDay = new Date(today.getFullYear(), 0);
        const thisYearLastDay = new Date(today.getFullYear(), 11, 31);
        this.dateTo = this.jsonDate(thisYearLastDay);
        this.dateFrom = this.jsonDate(thisYearFirstDay);
      }
      break;
    }
  }

  checkDateFromChange($event) {
    console.log('change', $event);
    this.selected = 8;
    const day = String($event.day).padStart(2, '0');
    const month = String($event.month).padStart(2, '0');
    const year = $event.year;
    this.inputFrom = `${month}/${day}/${year}`;
  }

  checkDateToChange($event) {
    console.log('change', $event);
    this.selected = 8;
    const day = String($event.day).padStart(2, '0');
    const month = String($event.month).padStart(2, '0');
    const year = $event.year;
    this.inputTo = `${month}/${day}/${year}`;
  }

  jsonDate(date) {
    return this.businessService.jsonDate(date);
  }

  parseDate(date) {
    return this.businessService.parseDate(date);
  }

  applyDateFilter() {
    console.log(this.parseDate(this.dateFrom), this.parseDate(this.dateTo));
    this.dateDisplay = false;
    this.getSearchResult();
  }

  limitChanged() {
    this.businessService.publishSelectedLimit$(this.selectedLimit);
  }

  getSelectedLimit() {
    this.subscriptions.add(this.businessService.selectedLimit$.subscribe(
      res => {
        this.selectedLimit = res;
        console.log('getSelectedLimit');
        this.getSearchResult();
      }
    ));
  }

  getStartItem() {
    this.subscriptions.add(this.businessService.startItem$.subscribe(res => this.startItem = res));
    console.log('getStartItem');
    this.getSearchResult();
  }

  checkAlert() {
    this.subscriptions.add(this.businessService.alert$.subscribe(res => {
      if (res !== null) {
        if (res.message.includes('completed')) {
          setTimeout(() => {
            this.getSearchResult();
          }, 1000);
        } else {
          if (!res.message.includes(`<b>Error:</b> Something went wrong.`)) {
            this.getSearchResult();
          }
        }
        console.log('data checkAlert', res);
      }
     }));
  }

  checkDataChange() {
    this.subscriptions.add(this.businessService.dataChange$.subscribe(res => {
      console.log('checkDataChange res', res);
      if (res !== null) {
        if (typeof res === 'string') {
          if (res.includes('change status')) {
            setTimeout(() => {
              this.getSearchResult();
            }, 1000);
          }
        } else {
          this.getSearchResult();
        }
        console.log('data changedddd', res);
      }
     }));
  }

  ngOnInit() {
    this.getUrlParams();
    this.checkDataChange();
    this.checkAlert();
    this.selected = 0;
    this.getSelectedCategory();
    // default values
    this.searchString = '';
    this.selectedLimit = 10;
    this.limits = [ 10, 20, 50, 100];
    this.startItem = 0;
    this.selectedFilter = '';
    this.getFilterList();
    if (!this.modulesArray && this.feedType === 'user-admin-role') {
      this.getModulesList();
    }
    this.getSelectedLimit();
    this.getStartItem();
    console.log('filterList', this.filterList);
    // this.filterNameChange(this.filterCategory[0].fieldName);
  }

  ngOnDestroy() {
    console.log('should destroy');
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    if (this.httpGet) {
      this.httpGet.unsubscribe();
    }
  }

}

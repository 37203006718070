import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { BusinessService } from 'src/app/core/services/business.service';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { FormBuilder, FormArray } from '@angular/forms';
import { SuspendUsersModalComponent } from '../../components/suspend-users-modal/suspend-users-modal.component';
import { UserRolePermissionsComponent } from 'src/app/shared/components/user-role-permissions/user-role-permissions.component';

@Component({
  selector: 'app-customer-list-page',
  templateUrl: './customer-list-page.component.html',
  styleUrls: ['./customer-list-page.component.scss']
})
export class CustomerListPageComponent extends UserRolePermissionsComponent implements OnInit, OnDestroy {
  query: any;
  customers: any;
  selectedOne: boolean;
  selectedCustomers: Array<string>;
  totalItem: number;
  selectedLimit: number;
  maxNumberPages: number;
  limits: Array<number>;
  startItem: number;
  selectedPage: number;
  pages: Array<number>;
  itemShow: number;
  initPages: any;
  allCustomers: any;
  getCustomer: any;
  selectedFilter: string;
  selectedFilterName: string;
  checks: boolean;
  showList: boolean;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    businessService: BusinessService,
    private httpAdminService: HttpWebAdminService,
    private fb: FormBuilder
  ) {
    super(businessService)
  }

  customerForm = this.fb.group({
    searchString: [''],
    selectedFilterName: [''],
    selectedAll: [false],
    customer: this.fb.array([]),
  });

  get f() { return this.customerForm.controls; }
  get customer(): any { return this.customerForm.get('customer') as FormArray; }


  getCustomers() {
    this.showList = false;
    this.startItem = this.startItem ? this.startItem : 0;
    this.httpAdminService.get$(`customer/user/?limit=${this.selectedLimit}&offset=${this.startItem}`).subscribe(
      data => {
        console.log('customers', data);
        this.showList = true;
        this.customers = data.data;
        this.businessService.publishTotalItem$(data.totalCounts);
        this.businessService.publishMaxNumberPages$(data.totalPages);
        if (this.businessService.pages$) {
        this.initPages = (this.businessService.pages$ * this.selectedLimit) - this.selectedLimit;
        console.log('this.initPages', this.initPages);
        } else {
          this.initPages = 0;
        }
      }, error => {
        this.showList = false;
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Error:</b> Something went wrong.`
        });
      }
    );
  }

  getAllCustomers() {
   this.getCustomer = this.httpAdminService.get$(`customer/user/`).subscribe(
      data => {
        console.log('customers', data);
        this.allCustomers = data.data;
        data.data.forEach((element) => {
          (this.customerForm.get('customer') as FormArray).push(
            this.fb.group({
              id: element._id,
              lastLogin: element.lastSignedIn,
              fullName: element.fullName,
              value: false
            })
          );
        });
        this.getSelectedLimit();
        this.getStartItem();
        console.log('customer.controls', this.customer.controls);
        console.log('this.customer.getRawValue()', this.customer.getRawValue());
      }, error => {
        this.showList = false;
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Error:</b> Something went wrong.`
        });
      }
    );
  }

  selectAll(selectedAll) {
    console.log('selectedAll', this.customer.controls);
    if (selectedAll === true) {
      this.customer.controls.forEach(element1 => {
        element1.controls['value'].setValue(true);
      });
      this.addSelected();
      this.selectedOne = false;
    } else {
      this.customer.controls.forEach(element2 => {
        element2.controls['value'].setValue(false);
      });
      this.addSelected();
      this.selectedOne = false;
    }
  }

  checkIfSelected() {
    console.log('this.customers', this.customers);
    console.log('lenght', this.customer.controls.length);
    console.log('controls', this.customer.controls);
    const selected = this.customer.controls.find(element1 => {
      return element1.value.value === true;
    });
    console.log('selected', selected);
    if (selected) {
      const checking = this.customer.controls.filter(element1 => {
        return element1.value.value === true;
      });
      if (checking.length === this.customer.controls.length) {
        console.log('true');
        this.checks = true;
        this.selectedOne = false;
      } else {
        console.log('false');
        this.checks = false;
        this.selectedOne = true;
      }
      this.addSelected();
    } else {
      this.selectedOne = false;
      this.addSelected();
    }
  }

  addSelected() {
    console.log('this.customer.getRawValue()', this.customer.getRawValue());
    this.selectedCustomers = this.customer.getRawValue()
      .map((element4) => element4.value === true ? {id: element4.id, lastLogin: element4.lastLogin, fullName: element4.fullName} : null)
      .filter(element5 => element5 !== null);
    console.log('selectedCustomers', this.selectedCustomers);
  }

  search(searchString) {
    this.query = searchString;
    console.log(searchString);
  }

  viewDetails(id) {
    console.log(id);
    this.router.navigate([`/customers/${id}`]);
  }

  submitForm() {
    console.log(this.selectedCustomers);
      const modalRef = this.modalService.open(SuspendUsersModalComponent, {
        centered: true,
        windowClass: 'my-class'
      });
      modalRef.componentInstance.title = 'Suspend Accounts';
      modalRef.componentInstance.customers = this.selectedCustomers;
  }

  limitChanged() {
    this.businessService.publishSelectedLimit$(this.selectedLimit);
  }

  getSelectedLimit() {
    this.subscriptions.add(this.businessService.selectedLimit$.subscribe(
      res => {
        this.selectedLimit = res;
        console.log('getSelectedLimit', this.selectedLimit);
        this.getCustomers();
      }
    ));
  }

  getStartItem() {
    this.subscriptions.add(this.businessService.startItem$.subscribe(res => this.startItem = res));
    console.log('getStartItem', this.startItem);
    this.getCustomers();
  }

  getTotalItem() {
    this.subscriptions.add(this.businessService.totalItem$.subscribe(res => {
      this.totalItem = res;
      console.log('this.totalItem', this.totalItem);
    }));
  }

  checkDataChange() {
    this.subscriptions.add(this.businessService.dataChange$.subscribe(res => {
      if (res) {
        this.getCustomers();
        this.customer.controls.forEach(element2 => {
          element2.controls['value'].setValue(false);
        });
        this.selectedOne = false;
      }
    }));
  }

  getAccountData() {
    this.getAccountPromise();
    this.accountPromise
    .then((data) => {      
      console.log('promise account', data);
      this.setAccountItems(data);
      // Insert all functions related to Account Data
      // ----- End ----- //
    })
    .catch((error) => {
      console.log('promise err', error);
    });
  }

  ngOnInit() {
    // Needed in User Role
    this.getAccountData();
    // --------- //
    this.limits = [ 10, 20, 50, 100];
    this.selectedLimit = 10;
    this.selectedPage = 1;
    this.startItem = 0;
    this.initPages = 0;
    this.getAllCustomers();
    this.getTotalItem();
    this.checkDataChange();
  }

  ngOnDestroy() {
    console.log('destroy');
    this.selectedOne = false;
    this.businessService.pages$ = 0;
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}

import { Component, OnInit, HostListener, OnDestroy, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';
import { CropPhotoComponent } from 'src/app/indie/crop-photo/crop-photo.component';
import { Subscription } from 'rxjs';
import { BusinessService } from 'src/app/core/services/business.service';
import { HttpService } from 'src/app/core/http/http.service';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-device-add-edit-form',
  templateUrl: './device-add-edit-form.component.html',
  styleUrls: ['./device-add-edit-form.component.scss']
})
export class DeviceAddEditFormComponent implements OnInit, OnDestroy {
  title: string;
  avatarUrl: string;
  imgUrl: any;
  branchId: string;
  submitting: boolean;
  errorMsg: string;
  accountData: any;
  selectedAvatar: File;
  enableSubmit: boolean;
  openLeaveModal: boolean;
  subscriptions = new Subscription();
  distributors: any;
  manufacturers: Array<any>;
  modelTypes: Array<any>;
  randomCode: any;
  deviceData: any;
  branchData: any;
  selectedBranchId: any;

  croppedImg: any;
  fileName: string;
  fileType: string;
  formDirty: boolean;
  avatarError: boolean;
  statusCodeList: Array<any>;
  refIdErrorMsg: string;
  serialNoErrorMsg: string;
  imeiErrorMsg: string;
  wDateErrorMsg: string;
  today = new Date();
  minDate: any;

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private businessService: BusinessService,
    private httpService: HttpService,
    private httpAdminService: HttpWebAdminService,
    private router: Router,
    private calendar: NgbCalendar
  ) { }

  deviceForm = this.fb.group({
    refId: [{value: '', disabled: true}, Validators.required],
    serialNo: ['', [Validators.pattern(/^[a-zA-Z0-9]+$/i)]],
    manufacturer: ['Sunmi'],
    modelType: ['V1'],
    imei: ['', [Validators.pattern(/^[a-zA-Z0-9]+$/i)]],
    warrantyDate: ['', Validators.required],
    branchId: [''],
    distributorId: [''],
  });

  get f() {return this.deviceForm.controls; }

  parseDate() {
    console.log('parsed date');
    let date = [];
    date = [
      this.deviceForm.controls.warrantyDate.value.month,
      this.deviceForm.controls.warrantyDate.value.day,
      this.deviceForm.controls.warrantyDate.value.year,
    ];
    console.log(Date.parse(date.toString()));
    return Date.parse(date.toString());
  }

  parsePatchedDate() {
    let date = [];
    if (this.deviceData) {
      const datejson = this.jsonDate(this.deviceData.warrantyDate);
      date = [
        datejson.month,
        datejson.day,
        datejson.year,
      ];
      return Date.parse(date.toString());
    }
  }

  jsonDate(dateFromTo) {
    const m = new Date(dateFromTo).getMonth();
    const d = new Date(dateFromTo).getDate();
    const y = new Date(dateFromTo).getFullYear();
    return {
      day: d,
      month: m + 1,
      year: y
    };
  }

  onAvatarChange($event) {
    this.formDirty = true;
    this.selectedAvatar = $event.target.files[0] as File;
    if (['image/png', 'image/jpeg'].includes($event.target.files[0].type)) {
      this.openCropModal($event, 'avatar-circle', 'Select Device Photo');
    } else {
      this.avatarError = true;
    }
  }

  checkCroppedAvatar() {
    this.subscriptions.add(this.businessService.croppedPhoto$.subscribe(res => {
      this.croppedImg = res.image;
      console.log('this.croppedImg', this.croppedImg);
      this.imgUrl = res.image;
      this.fileName = res.fileName;
      this.fileType = res.fileType;
      this.enableSubmit = true;
      this.avatarError = false;
    }));
  }

  openCropModal(avatar, type, title) {
    const modalRef = this.modalService.open(CropPhotoComponent, {
      centered: true,
      windowClass: `modal-${type}`
    });
    modalRef.componentInstance.imageFile = avatar;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.cropType = type;
    console.log('image type', avatar.target.files[0].type);
  }

  @HostListener('body:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    console.log(event);
    event.preventDefault();
    if (!this.openLeaveModal) {
      this.closeModal();
    }
    this.subscriptions.add(
      this.businessService.navigateClose$.subscribe(res => {
        console.log('res', res);
        this.openLeaveModal = false;
      })
    );
  }

  submitForm(f) {
    // console.log('warranty date', this.parseDate().toString());
    const body = new FormData();
    if (this.croppedImg) {
      body.append('avatar', new File([new Blob([this.businessService.dataURItoBlob(this.croppedImg)], {type: this.fileType})], this.fileName, {type: this.fileType}));
      console.log('avatar', new File([new Blob([this.businessService.dataURItoBlob(this.croppedImg)], {type: this.fileType})], this.fileName, {type: this.fileType}));
    }
    body.append('refId', f.getRawValue().refId);
    body.append('serialNo', f.getRawValue().serialNo);
    body.append('manufacturer', f.getRawValue().manufacturer);
    body.append('modelType', f.getRawValue().modelType);
    body.append('branchId', f.getRawValue().branchId !== 'null' ? f.getRawValue().branchId : '');
    body.append('imei', f.getRawValue().imei);
    body.append('distributorId', f.getRawValue().distributorId !== 'null' ? f.getRawValue().distributorId : '');
    body.append('warrantyDate', this.parseDate().toString());
    console.log('avatar', this.selectedAvatar);
    this.submitting = true;
    f.disable();

    if (this.deviceData) {
      // if edit
      this.httpService.patch$(`/devices/${this.deviceData._id}`, body).subscribe(
        data => {
          console.log('edited devices', data);
          this.activeModal.dismiss();
          this.businessService.publishAlert$({
            type: 'green-dark-01',
            icon: 'fas fa-check',
            message: `<b>Success!</b> — Device has been updated.`
          });
          this.submitting = false;
          this.businessService.publishDataChange$(data);
          f.enable();
        }, error => {
          console.log('error', error);
          this.showError(error);
          this.submitting = false;
          f.enable();
        }
      );
    } else {
      // if new
      this.httpService.post$(`devices`, body).subscribe(
        data => {
          console.log('added devices', data);
          this.activeModal.dismiss();
          this.businessService.publishAlert$({
            type: 'green-dark-01',
            icon: 'fas fa-check',
            message: `<b>Success!</b> — ${data.refId} has been added.`
          });
          this.businessService.publishDataChange$(data);
          this.submitting = false;
          f.enable();
        }, error => {
          console.log('error', error);
          this.showError(error);
          this.submitting = false;
          f.enable();
          f.controls.refId.disable();
        }
      );
    }
  }

  showError(error) {
    this.statusCodeList = error.error.errors.filter(element => element.msg.statusCode).map(element2 => element2.msg.statusCode);
    console.log('this.statusCodeList', this.statusCodeList);
    if (this.statusCodeList.includes(20411)) {
      this.refIdErrorMsg = `<b>Something went wrong.</b> Please try again.`;
    } else if (this.statusCodeList.includes(20412)) {
      this.serialNoErrorMsg = 'Serial Number already exist.';
    } else if (this.statusCodeList.includes(20414 )) {
      this.imeiErrorMsg = 'IMEI already exist.';
    } else if (this.statusCodeList.includes(20401)) {
      this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
    } else {
      this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
    }
  }

  clearBackendError(input) {
    if (input.dirty) {
      this.serialNoErrorMsg = null;
      // this.wDateErrorMsg = null;
      this.imeiErrorMsg = null;
      this.errorMsg = null;
    }
  }

  closeModal() {
      this.activeModal.close();
  }

  patchValue() {
    this.deviceForm.patchValue({
      refId: this.deviceData.refId,
      serialNo: this.deviceData.serialNo,
      manufacturer: this.deviceData.manufacturer,
      modelType: this.deviceData.modelType,
      imei: this.deviceData.imei,
      warrantyDate: this.jsonDate(this.deviceData.warrantyDate),
      branchId: this.deviceData.branchId,
      distributorId: this.deviceData.distributorId,
    });
    this.avatarUrl = this.deviceData.avatarUrl ? this.deviceData.avatarUrl : './assets/images/koala.jpg';
  }

  getDistributorsData() {
    this.httpAdminService.get$(`/distributor`).subscribe(
      data => {
        console.log('distributors data', data);
        this.distributors = data.data.filter(element1 => element1.status === 'ACTIVE');
      }, error => {
        this.errorMsg = `<b>Error:</b> Something went wrong.`;
      }
    );
  }

  getBranchesData() {
    this.httpAdminService.get$(`/branches`).subscribe(
      data => {
        console.log('branches data', data);
        this.branchData = data.data;
      }, error => {
        this.errorMsg = `<b>Error:</b> Something went wrong.`;
      }
    );
  }

  checkIfExpired() {
    console.log('expired date now', this.minDate.day);
    console.log('device data', this.jsonDate(this.deviceData.warrantyDate).day);

    if(this.minDate.day > this.jsonDate(this.deviceData.warrantyDate).day && this.minDate.month >= this.jsonDate(this.deviceData.warrantyDate).month && this.minDate.year >= this.jsonDate(this.deviceData.warrantyDate).year){
      console.log('true');
      this.wDateErrorMsg = `The warranty for this device has expired.`;
    } else {
      console.log('false');
    }
  }

  clearExpired(input){
    if (input.dirty) {
      this.wDateErrorMsg = null;
    }
  }

  selectEvent(item) {
    console.log('item', item._id);
    this.selectedBranchId = item._id;
  }


  changeDistributorlevel($event) {
    console.log($event);
  }

  changeModellevel($event) {
    console.log($event);
  }

  changeManufacturerlevel($event) {
    console.log($event);
    if ($event === 'Sunmi') {
      this.randomCode = 'SN-';
      this.getRandomCode();
    } else if ($event === 'Telpo') {
      this.randomCode = 'TP-';
      this.getRandomCode();
    }
    this.deviceForm.get('refId').setValue(this.randomCode);
  }

  getManufacturerList() {
    this.manufacturers = [{
      id: 0,
      name: 'Sunmi'
    }, {
      id: 1,
      name: 'Telpo'
    }];
  }

  getModelList() {
    this.modelTypes = [{
      id: 0,
      name: 'V1'
    }, {
      id: 1,
      name: 'V1S'
    }, {
      id: 2,
      name: 'K1'
    }, {
      id: 3,
      name: 'T2'
    }];
  }

  getRandomCode() {
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
    const lengthOfCode = 10;
    for (let i = 0; i < lengthOfCode; i++) {
      this.randomCode += possible.charAt(Math.floor(Math.random() * possible.length));
    }
      return this.randomCode;
  }

  ngOnInit() {
    this.checkCroppedAvatar();
    if (this.deviceData) {
      this.patchValue();
      this.imgUrl = this.deviceData.avatarUrl ? this.deviceData.avatarUrl : './assets/images/koala.jpg';
    } else {
      this.imgUrl = './assets/images/koala.jpg';
    }
    this.minDate = {year: this.calendar.getToday().year, month: this.calendar.getToday().month, day: this.calendar.getToday().day + 1};
    console.log('this.mindatessss', this.minDate);
    this.getDistributorsData();
    this.getBranchesData();
    this.getManufacturerList();
    this.getModelList();
    this.changeManufacturerlevel('Sunmi');
    this.checkIfExpired();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BusinessService } from 'src/app/core/services/business.service';
import { UserRolePermissionsComponent } from 'src/app/shared/components/user-role-permissions/user-role-permissions.component';

@Component({
  selector: 'app-industry-listing',
  templateUrl: './industry-listing.component.html',
  styleUrls: ['./industry-listing.component.scss']
})
export class IndustryListingComponent extends UserRolePermissionsComponent implements OnInit, OnDestroy  {
  dataLists: Array<any>;

  constructor(
    private router: Router,
    businessService: BusinessService
  ) {
    super(businessService)
  }

  getDataList() {
    this.subscriptions.add(this.businessService.dataLists$.subscribe(
      data => {
        console.log('industry list', data.data);
        if (data) {
          this.dataLists = data.data;
        }
      }, error => {
        console.log('error', error);
      }
    ));
  }

  addIndustry() {
    this.router.navigate(['/settings/industry-setting/industry/add']);
  }

  viewDetails($event) {
    console.log($event);
    this.router.navigate([`/settings/industry-setting/${$event.id}`]);
  }

  getAccountData() {
    this.getAccountPromise();
    this.accountPromise
    .then((data) => {      
      console.log('promise account', data);
      this.setAccountItems(data);
      // Insert all functions related to Account Data
      // ----- End ----- //
    })
    .catch((error) => {
      console.log('promise err', error);
    });
  }

  ngOnInit() {
    // Needed in User Role
    this.getAccountData();
    // --------- //
    this.getDataList();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}


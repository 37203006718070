import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountsAddEditFormComponent } from '../../components/accounts-add-edit-form/accounts-add-edit-form.component';
import { Router } from '@angular/router';
import { BusinessService } from 'src/app/core/services/business.service';
import { UserRolePermissionsComponent } from 'src/app/shared/components/user-role-permissions/user-role-permissions.component';
@Component({
  selector: 'app-accounts-listing',
  templateUrl: './accounts-listing.component.html',
  styleUrls: ['./accounts-listing.component.scss']
})
export class AccountsListingComponent extends UserRolePermissionsComponent implements OnInit, OnDestroy {
  accountsList: Array<any>;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    businessService: BusinessService
  ) {
    super(businessService)
  }

  getAccountsList() {
    this.subscriptions.add(this.businessService.dataLists$.subscribe(
      data => {
        console.log('accounts list', data.data);
        if (data) {
          this.accountsList = data.data;
        }
      }, error => {
        console.log('error', error);
      }
    ));
  }

  addAccount() {
    const modalRef = this.modalService.open(AccountsAddEditFormComponent, {
      centered: true
    });
    modalRef.componentInstance.title = 'Add Account';
  }

  getUserRole(role) {
    switch (role) {
      case 0: {
        return 'Super Admin';
      }
      case 1: {
        return 'Admin';
      }
      case 5: {
        return 'User';
      }
    }
  }

  getStatus(isVerified, isSuspended) {
    if (isVerified) {
      if (!isSuspended) {
        return 'Active';
      } else {
        return 'Suspended';
      }
    } else {
      return 'Unverified';
    }
  }


  viewDetails($event) {
    console.log($event);
    this.router.navigate([`/settings/admin-account-setting/${$event._id}`]);
  }

  checkDataChange() {
    this.subscriptions.add(this.businessService.dataChange$.subscribe(res => {
      if (res) {
        this.getAccountsList();
      }
    }));
  }

  getAccountData() {
    this.getAccountPromise();
    this.accountPromise
    .then((data) => {      
      console.log('promise account', data);
      this.setAccountItems(data);
      // Insert all functions related to Account Data
      // ----- End ----- //
    })
    .catch((error) => {
      console.log('promise err', error);
    });
  }

  ngOnInit() {
    // Needed in User Role
    this.getAccountData();
    // --------- //
    this.getAccountsList();
    this.checkDataChange();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }


}

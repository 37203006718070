import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BusinessService } from 'src/app/core/services/business.service';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { FormBuilder, FormArray } from '@angular/forms';
import { ManagerAddEditFormComponent } from '../../components/manager-add-edit-form/manager-add-edit-form.component';

@Component({
  selector: 'app-manager-list-page',
  templateUrl: './manager-list-page.component.html',
  styleUrls: ['./manager-list-page.component.scss']
})
export class ManagerListPageComponent implements OnInit, OnDestroy {
  query: any;
  managers: any;
  selectedOne: boolean;
  subscriptions = new Subscription();
  selectedManagers: Array<string>;

  totalItem: number;
  selectedLimit: number;
  maxNumberPages: number;
  limits: Array<number>;
  startItem: number;
  selectedPage: number;
  pages: Array<number>;
  itemShow: number;
  initPages: any;
  allManagers: any;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private businessService: BusinessService,
    private httpAdminService: HttpWebAdminService,
    private fb: FormBuilder
  ) { }

  managerForm = this.fb.group({
    searchString: [''],
    selectedAll: [false],
    manager: this.fb.array([]),
  });

  get f() { return this.managerForm.controls; }
  get manager(): any { return this.managerForm.get('manager') as FormArray; }


  getManagers() {
    this.startItem = this.startItem ? this.startItem : 0;
    this.httpAdminService.get$(`managers/?limitTo=${this.selectedLimit}&startAt=${this.startItem}`).subscribe(
      data => {
        console.log('managers', data);
        this.managers = data.data;
        this.businessService.publishTotalItem$(data.totalCounts);
        this.businessService.publishMaxNumberPages$(data.totalPages);
        if (this.businessService.pages$) {
        this.initPages = (this.businessService.pages$ * this.selectedLimit) - this.selectedLimit;
        console.log('this.initPages', this.initPages);
        } else {
          this.initPages = 0;
        }
      }, error => {
        console.log('error', error);
      }
    );
  }

  getAllManagers() {
    this.httpAdminService.get$(`managers`).subscribe(
      data => {
        console.log('managers', data);
        this.allManagers = data.data;
        data.data.forEach((element) => {
          (this.managerForm.get('manager') as FormArray).push(
            this.fb.group({
              id: element._id,
              lastLogin: element.lastSignedIn,
              fullName: element.fullName,
              value: false
            })
          );
        });
        this.getSelectedLimit();
        this.getStartItem();
        console.log('manager.controls', this.manager.controls);
      }, error => {
        console.log('error', error);
      }
    );
  }

  selectAll(selectedAll) {
    console.log('selectedAll', selectedAll);
    if (selectedAll === true) {
      this.manager.controls.forEach(element1 => {
        element1.controls['value'].setValue(true);
      });
      this.addSelected();
      this.selectedOne = false;
    } else {
      this.manager.controls.forEach(element2 => {
        element2.controls['value'].setValue(false);
      });
      this.selectedOne = false;
    }
  }

  checkIfSelected() {
    const selected = this.manager.controls.find(element1 => {
      return element1.value.value === true;
    });
    if (selected) {
      selected.value ? this.selectedOne = true : this.selectedOne = false;
      this.addSelected();
    } else {
      this.manager.controls.length === 0 ? this.selectedOne = false : this.selectedOne = true;
    }
  }

  addSelected() {
    console.log('this.manager.getRawValue()', this.manager.getRawValue());
    this.selectedManagers = this.manager.getRawValue()
      .map((element4) => element4.value === true ? {id: element4.id, lastLogin: element4.lastLogin, fullName: element4.fullName} : null)
      .filter(element5 => element5 !== null);
    console.log('selectedManagers', this.selectedManagers);
  }

  search(searchString) {
    this.query = searchString;
    console.log(searchString);
  }

  viewDetails(id) {
    console.log(id);
    this.router.navigate([`/managers/${id}`]);
  }

  submitForm() {
    console.log(this.selectedManagers);
  }

  limitChanged() {
    this.businessService.publishSelectedLimit$(this.selectedLimit);
  }

  getSelectedLimit() {
    this.subscriptions.add(this.businessService.selectedLimit$.subscribe(
      res => {
        this.selectedLimit = res;
        console.log('getSelectedLimit', this.selectedLimit);
        this.getManagers();
      }
    ));
  }

  getStartItem() {
    this.subscriptions.add(this.businessService.startItem$.subscribe(res => this.startItem = res));
    console.log('getStartItem', this.startItem);
    this.getManagers();
  }

  getTotalItem() {
    this.subscriptions.add(this.businessService.totalItem$.subscribe(res => {
      this.totalItem = res;
      console.log('this.totalItem', this.totalItem);
    }));
  }

  checkDataChange() {
    this.subscriptions.add(this.businessService.dataChange$.subscribe(res => {
      if (res) {
        this.getManagers();
        this.manager.controls.forEach(element2 => {
          element2.controls['value'].setValue(false);
        });
        this.selectedOne = false;
      }
    }));
  }

  addManager() {
    const modalRef = this.modalService.open(ManagerAddEditFormComponent, {
      centered: true
    });
    modalRef.componentInstance.title = 'Add Manager';
  }


  ngOnInit() {
  this.limits = [ 10, 20, 50, 100];
  this.selectedLimit = 10;
  this.selectedPage = 1;
  this.startItem = 0;
  this.initPages = 0;
  this.getAllManagers();
  this.getTotalItem();
  this.checkDataChange();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { map, tap, mapTo, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  urlAPI = environment.urlAPI;
  public branchId = localStorage.getItem('branchId');
  public partnerId = localStorage.getItem('partnerId');
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private loggedUser: string;
  tokenExpired: boolean;

  public userActionOccured = new Subject<any>();
  userActionOccured$ = this.userActionOccured.asObservable();

  notifyUserAction() {
    this.userActionOccured.next();
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private modalService: NgbModal
  ) {}

  loginBranch(data) {
    return this.http.get<any>(`${this.urlAPI}/authenticate/branch?branchId=${data}`)
    .pipe(
      map(branch => {
        if (branch) {
          console.log('branch', branch._id);
          localStorage.setItem('branchId', branch._id);
          localStorage.setItem('branchName', branch.branchName);
          localStorage.setItem('partnerId', branch.partnerId);
          localStorage.setItem('partnerAvatarUrl', branch.partnerAvatarUrl);
          this.branchId = branch._id;
          this.partnerId = branch.partnerId;
        }
        return branch;
      })
    );
  }

  login(user: { username: string, password: string }): Observable<any> {
    return this.http.post<any>(`${this.urlAPI}/auth/sign-in`, user)
      .pipe(
        tap(tokens => this.doLoginUser(user.username, tokens)),
        map(user2 => {
          return user2;
        }));
  }

  logout(accountId) {
    console.log('auth logout');
    return this.http.post<any>(`${this.urlAPI}/auth/logout/${accountId}`, '').pipe(
      tap(() => this.doLogoutUser()),
      map(user => {
        return user;
      }));
  }

  isLoggedIn() {
    if (this.getJwtToken()) {
      return true;
    }
  }

  refreshToken() {
    return this.http.patch<any>(`${this.urlAPI}/auth/refresh-token`, {
      refreshToken: this.getRefreshToken()
    }).pipe(tap((tokens) => {
      console.log('refresh-token', tokens);
      if (tokens.accessToken) {
        this.storeJwtToken(tokens.accessToken);
      }
    }));
  }

  viewModal() {
    this.modalService.dismissAll();
    localStorage.removeItem('JWT_TOKEN');
    localStorage.removeItem('REFRESH_TOKEN');
    const modalRef = this.modalService.open(ModalConfirmComponent, {
      centered: true,
      backdrop: 'static',
      keyboard: false
      });
    modalRef.componentInstance.type = 'modal-logout';
    modalRef.componentInstance.title = 'Account has been logged out';
    modalRef.componentInstance.bodyText = 'You have been logged out of the system.';
    modalRef.componentInstance.primaryButton = 'Back to Login';
  }

  viewAccountSuspendedModal() {
    this.modalService.dismissAll();
    localStorage.removeItem('JWT_TOKEN');
    localStorage.removeItem('REFRESH_TOKEN');
    const modalRef = this.modalService.open(ModalConfirmComponent, {
      centered: true,
      backdrop: 'static',
      keyboard: false
    });
    modalRef.componentInstance.type = 'account-suspended-login';
    modalRef.componentInstance.title =  `Account Suspended`;
    modalRef.componentInstance.bodyText =  `Sorry, your account has been suspended. Feel free to contact us for assistance. Thank you`;
    modalRef.componentInstance.primaryButton = 'Back to Login';
  }

  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  private doLoginUser(username: string, tokens) {
    this.loggedUser = username;
    this.storeTokens(tokens);
  }

  private doLogoutUser() {
    console.log('logout user');
    this.loggedUser = null;
    this.removeTokens();
    localStorage.removeItem('accountId');
    localStorage.removeItem('lastEventTime');
  }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem(this.JWT_TOKEN, jwt);
  }

  private storeTokens(tokens) {
    localStorage.setItem(this.JWT_TOKEN, tokens.accessToken);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
  }

  private removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
  }
}

export class User {
  branchId?: string;
  id?: string;
  branchName?: string;
  partnerName?: string;
  partnerAvatarUrl?: string;
  accountAvatarUrl?: number;
  email?: {
    value?: string;
  };
}

export class Branch {

}

import { Component, OnInit, ViewChild, Inject, Renderer2, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { LyTheme2, ThemeVariables, Platform } from '@alyle/ui';
import { LyResizingCroppingImages, ImgCropperConfig, ImgCropperEvent } from '@alyle/ui/resizing-cropping-images';

import { styles } from './crop-photo.styles';
import { BusinessService } from 'src/app/core/services/business.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-crop-photo',
  templateUrl: './crop-photo.component.html',
  styleUrls: ['./crop-photo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CropPhotoComponent implements OnInit {
  title: string;
  cropType: string;
  selectedAvatar: any;
  imageFile: any;
  imgUrl: any;
  classes = this.theme.addStyleSheet(styles);
  croppedImg: string;
  @ViewChild(LyResizingCroppingImages, {static: true}) imgCropper: LyResizingCroppingImages;
  scale: number;
  myConfig: ImgCropperConfig;
  cropping: any;
  mySrc: any;
  fileName: string;

  constructor(
    private theme: LyTheme2,
    private businessService: BusinessService,
    public activeModal: NgbActiveModal
  ) { }

  /** on event */
  onCrop(e: ImgCropperEvent) {
    this.croppedImg = e.dataURL;
    if (this.cropType === 'avatar-circle') {
      this.businessService.publishCroppedPhoto$({
        image: this.croppedImg,
        fileName: this.imageFile.target.files[0].name,
        fileType: this.imageFile.target.files[0].type
      });
    } else if (this.cropType === 'account-avatar') {
      this.businessService.publishCroppedAccountPhoto$({
        image: this.croppedImg,
        fileName: this.imageFile.target.files[0].name,
        fileType: this.imageFile.target.files[0].type
      });
    } else if (this.cropType === 'cover-branch') {
      this.businessService.publishCroppedBranch$({
        image: this.croppedImg,
        fileName: this.imageFile.target.files[0].name,
        fileType: this.imageFile.target.files[0].type
      });
    } else if (this.cropType === 'cover-mascot') {
      this.businessService.publishCroppedMascot$({
        image: this.croppedImg,
        fileName: this.imageFile.target.files[0].name,
        fileType: this.imageFile.target.files[0].type
      });
    } else if (this.cropType === 'login-image') {
      this.businessService.publishCroppedLoginImage$({
        image: this.croppedImg,
        fileName: this.imageFile.target.files[0].name,
        fileType: this.imageFile.target.files[0].type
      });
    } else if (this.cropType === 'sidenav-logo') {
      this.businessService.publishCroppedSidenavLogo$({
        image: this.croppedImg,
        fileName: this.imageFile.target.files[0].name,
        fileType: this.imageFile.target.files[0].type
      });
    } else {
      this.businessService.publishCroppedBanner$({
        image: this.croppedImg,
        fileName: this.imageFile.target.files[0].name,
        fileType: this.imageFile.target.files[0].type
      });
    }
    this.activeModal.dismiss();
    console.log('cropped', this.croppedImg);
  }
  /** manual crop */
  getCroppedImg() {
    const img = this.imgCropper.crop();
    console.log(img);
    return img.dataURL;
  }

  onloaded(e: ImgCropperEvent) {
    console.log('img loaded', e);
  }

  initConfig() {
    switch (this.cropType) {
      case 'avatar-circle' : {
        this.myConfig = {
          width: 280,
          height: 280
        };
      }
      break;
      case 'account-avatar' : {
        this.myConfig = {
          width: 280,
          height: 280
        };
      }
      break;
      case 'cover-mascot' : {
        this.myConfig = {
          width: 1200,
          height: 1200
        };
      }
      break;
      case 'logo-rectangle' : {
        this.myConfig = {
          width: 600,
          height: 300
        };
      }
      break;
      case 'cover-banner' : {
        this.myConfig = {
          width: 600,
          height: 300
        };
      }
      break;
      case 'cover-news' : {
        this.myConfig = {
          width: 1200,
          height: 600
        };
      }
      break;
      case 'login-image' : {
        this.myConfig = {
          width: 1200,
          height: 1800
        };
      }
      break;
      case 'sidenav-logo' : {
        this.myConfig = {
          width: 200,
          height: 50
        };
      }
      break;
      default: {
        console.log('default');
        this.myConfig = {
          width: 1200,
          height: 600
        };
      }
      break;
    }
  }

  ngOnInit() {
    console.log('imageFile', this.imageFile);
    console.log('imgCropper', this.imgCropper);
    this.imgCropper.selectInputEvent(this.imageFile);
    this.initConfig();
  }

}

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ControlContainer, FormGroupDirective, FormBuilder } from '@angular/forms';
import { CropPhotoComponent } from 'src/app/indie/crop-photo/crop-photo.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BusinessService } from 'src/app/core/services/business.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-template-sidenav-logo-form',
  templateUrl: './template-sidenav-logo-form.component.html',
  styleUrls: ['./template-sidenav-logo-form.component.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }]
})
export class TemplateSidenavLogoFormComponent implements OnInit, OnDestroy {
  @Input() templateData: any;
  bannerFileName: any;
  selectedBanner: any;
  bannerError: boolean;
  imgBanner: any;
  formDirty: boolean;
  submitting: boolean;
  subscriptions = new Subscription();

  constructor(
    private fb: FormBuilder,
    private parent: FormGroupDirective,
    private modalService: NgbModal,
    private businessService: BusinessService
  ) { }

  sidenavLogoForm = this.fb.group({});

  onBannerChange($event) {
    console.log('banner');
    this.bannerFileName = $event.target.files[0].name;
    this.selectedBanner = $event.target.files[0] as File;
    if (['image/png', 'image/PNG', 'image/jpeg'].includes($event.target.files[0].type)) {
      this.openCropModal($event, 'sidenav-logo', 'Select Side Navigation Logo');
    } else {
      this.bannerError = true;
    }
  }

  clickBrowse($event) {
    $event.target.value = null;
    this.bannerFileName = null;
    console.log('fileInput', $event.target.value);
  }

  openCropModal(avatar, type, title) {
    const modalRef = this.modalService.open(CropPhotoComponent, {
      centered: true,
      windowClass: `modal-${type}`
    });
    modalRef.componentInstance.imageFile = avatar;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.cropType = type;
    console.log('image type', avatar.target.files[0].type);
  }

  checkCroppedBanner() {
    this.subscriptions.add(this.businessService.croppedSideNavLogo$.subscribe(res => {
      console.log('selectedSidenavLogo', this.businessService.selectedSidenavLogo$);
      this.imgBanner = res.image;
      this.businessService.selectedSidenavLogo$ = res;
      this.formDirty = true;
      this.businessService.publishEnableEdit$(true);
      this.bannerError = false;
    }));
  }

  patchValue() {
    this.imgBanner = this.templateData.sideNavigationImage;
    this.selectedBanner = this.imgBanner;
  }

  ngOnInit() {
    this.parent.form.addControl(
      'sidenavLogo', this.sidenavLogoForm
    );
    this.checkCroppedBanner();
    if (this.templateData) {
      this.patchValue();
      this.bannerFileName = this.templateData.sideNavigationImage;
    } else {
      this.bannerFileName = '';
    }
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

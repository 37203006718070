import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { BusinessService } from 'src/app/core/services/business.service';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { CropPhotoComponent } from 'src/app/indie/crop-photo/crop-photo.component';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';

@Component({
  selector: 'app-customer-app-add-form',
  templateUrl: './customer-app-add-form.component.html',
  styleUrls: ['./customer-app-add-form.component.scss']
})
export class CustomerAppAddFormComponent implements OnInit, OnDestroy  {
  bannerData: any;
  title: string;
  selectedBanner: any;
  bannerFileName: string;
  imgUrl: any;
  thumb01: string;
  formOldValue: any;
  branchId: string;
  submitting: boolean;
  errorMsg: string;
  accountData: any;
  selectedAvatar: File;
  enableSubmit: boolean;
  openLeaveModal: boolean;
  index2: number;
  businessPartnersList: Array<any>;
  subscriptions = new Subscription();
  minDate: any;
  today = new Date();
  linkTypes = ['No Link', 'Posted News', 'Business Partner', 'Specific Branch', 'External Link'];
  initialValue: any;
  isLinkChanged: boolean;
  externalLinkList: Array<any>;
  externalPlaceholder: string;
  avatarError: boolean;
  formDirty: boolean;
  fileType: string;
  imageDirty: boolean;

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private businessService: BusinessService,
    private httpAdminService: HttpWebAdminService
  ) { }

  bannerForm = this.fb.group({
    _id: [''],
    title: ['', Validators.required],
    businessName: [''],
    content: ['', Validators.required],
    notifyStatus: [false],
    notifyAt: [this.businessService.jsonDate(this.today.setDate(this.today.getDate() + 1)), Validators.required],
    viewType: ['0', Validators.required],
    linkValue: [null]
  });

  get f() {return this.bannerForm.controls; }

  getBusinessPartners() {
    this.httpAdminService.get$(`partners`).subscribe(
      data => {
        console.log('business partners list', data);
        if (data) {
          this.businessPartnersList = data.data;
        }
      }, error => {
        console.log('error', error);
        this.errorMsg = `<b>Error:</b> Something went wrong.`;
      }
    );
  }

  onAvatarChange($event) {
    console.log($event);
    if (['image/png', 'image/jpeg'].includes($event.target.files[0].type)) {
      this.openCropModal($event, 'cover-banner', 'Select Banner');
    } else {
      this.avatarError = true;
    }
    this.enableSubmit = true;
  }

  openCropModal(avatar, type, title) {
    const modalRef = this.modalService.open(CropPhotoComponent, {
      centered: true,
      windowClass: `modal-${type}`
    });
    modalRef.componentInstance.imageFile = avatar;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.cropType = type;
  }

  checkCroppedAvatar() {
    this.subscriptions.add(this.businessService.croppedBanner$.subscribe(res => {
      this.businessService.selectedCoverPhoto$ = res.image;
      console.log('this.croppedImg2', this.businessService.selectedCoverPhoto$);
      this.imgUrl = res.image;
      this.selectedBanner = res.image;
      this.bannerFileName = res.fileName;
      this.fileType = res.fileType;
      this.formDirty = true;
      this.businessService.publishEnableEdit$(true);
      this.avatarError = false;
      this.imageDirty = false;
    }));
  }

  checkIfDirty() {
    this.imageDirty = true;
  }

  checkIsNotified($event) {
    console.log($event);
    if ($event === true) {
      this.f['notifyAt'].enable();
      this.f['notifyAt'].patchValue(this.businessService.jsonDate(this.today.setDate(this.today.getDate())));
    } else {
      this.f['notifyAt'].disable();
    }
  }

  selectDate($event) {
    this.checkTimeValid();
  }

  convertTimeDate(date) {
    const convertMillis = (new Date(`${date.month}/${date.day}/${date.year}`).getTime());
    console.log('convertMillis', `${date.month}/${date.day}/${date.year}`, convertMillis);
    return convertMillis;
  }

  checkTimeValid() {
    const time = this.convertTimeDate(this.f['notifyAt'].value);
    const date = new Date();
    if (time > date.getTime()) {
      // console.log('is valid time');
    } else {
      // console.log('not valid time');
    }
    console.log(this.f['notifyAt']);
  }

  @HostListener('body:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    console.log(event);
    event.preventDefault();
    if (!this.openLeaveModal) {
      this.closeModal();
    }
    this.subscriptions.add(
      this.businessService.navigateClose$.subscribe(res => {
        console.log('res', res);
        this.openLeaveModal = false;
      })
    );
  }

  closeModal() {
      this.activeModal.close();
  }

  resetForm() {
    this.subscriptions.add(this.businessService.resetForm$.subscribe(res => {
      if (res === true) {
        this.bannerForm.reset();
        this.bannerForm.get('contactNo').patchValue('(+63)');
      }
    }));
    this.checkIsNotified(false);
  }

  getNewsList() {
    this.httpAdminService.get$(`notification/news/search?limit=999&offset=0`).subscribe(
      data => {
        console.log('data', data);
        this.externalLinkList = data.data.map(element => {
          return {
            name: element._id + ' - ' + element.title,
            id: element._id
          };
        }).sort((a, b) => a.name.localeCompare(b.name));
        console.log('bannerData', this.bannerData);
        this.patchNewsBranch();
      }, error => {
        console.log('error', error);
        this.errorMsg = `<b>Error:</b> Something went wrong.`;
      }
    );
  }

  getPartnersList() {
    this.httpAdminService.get$(`partners?limitTo=999&startAt=0`).subscribe(
      data => {
        console.log('data', data);
        this.externalLinkList = data.data.map(element => {
          return {
            name: element.name,
            id: element._id
          };
        }).sort((a, b) => a.name.localeCompare(b.name));
        this.patchNewsBranch();
      }, error => {
        console.log('error', error);
        this.errorMsg = `<b>Error:</b> Something went wrong.`;
      }
    );
  }

  getBranchList() {
    this.httpAdminService.get$(`branches?limitTo=999&startAt=0`).subscribe(
      data => {
        console.log('data', data);
        this.externalLinkList = data.data.map(element => {
          return {
            name: element.branchName,
            id: element._id
          };
        }).sort((a, b) => a.name.localeCompare(b.name));
        this.patchNewsBranch();
      }, error => {
        console.log('error', error);
        this.errorMsg = `<b>Error:</b> Something went wrong.`;
      }
    );
  }

  selectEvent($event) {
    this.enableSubmit = true;
    console.log($event);
    switch (this.f['viewType'].value) {
      case '1' : {
        this.f['linkValue'].patchValue($event.id);
      }
      break;
      case '3' : {
        this.f['linkValue'].patchValue($event.id);
      }
      break;
    }
  }

  onChangeSearch($event) {
    console.log('onChangeSearch', this.f['linkValue'].value, '$event', $event);
  }

  onInputCleared($event) {
    console.log('$event', $event);
    if (!$event) {
      this.f['linkValue'].patchValue(null);
    }
  }

  onInputFocused($event) {
    console.log($event);
    this.f['linkValue'].markAsTouched();
  }

  changeLinkType($event) {
    console.log($event);
    switch ($event) {
      // No link
      case '0': {
        this.f['linkValue'].patchValue('');
        this.f['linkValue'].disable();
        this.f['linkValue'].clearValidators();
        this.f['linkValue'].updateValueAndValidity();
      }
      break;
      // News
      case '1': {
        this.getNewsList();
        this.f['linkValue'].setValidators([Validators.required]);
        this.f['linkValue'].enable();
        this.externalPlaceholder = 'Enter News ID / Title';
      }
      break;
      // PartnerId
      case '2': {
        this.getPartnersList();
        this.f['linkValue'].setValidators([Validators.required]);
        this.f['linkValue'].enable();
        if (this.bannerData) {
          this.f['linkValue'].patchValue(this.bannerData.linkValue);
        }
      }
      break;
      // BranchId
      case '3': {
        this.getBranchList();
        this.f['linkValue'].setValidators([Validators.required]);
        this.f['linkValue'].enable();
        this.externalPlaceholder = 'Enter Branch Name';
      }
      break;
      // Extenal Link
      case '4': {
        this.f['linkValue'].patchValue(null);
        this.patchNewsBranch();
        this.f['linkValue'].setValidators([Validators.required, Validators.pattern(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)]);
        this.f['linkValue'].enable();
      }
      break;
    }
  }

  disableRoleType() {
    if (this.accountData.roleLevel === 0) {
      this.f['roleLevel'].disable();
    }
  }

  patchValue() {
    this.imgUrl = this.bannerData.imageUrl ? this.bannerData.imageUrl : this.bannerData.imagePreview;
    this.bannerFileName = this.bannerData.imageUrl;
    this.bannerForm.patchValue({
      _id: this.bannerData._id,
      title: this.bannerData.title,
      businessName: this.bannerData.businessName ? this.bannerData.businessName : '',
      content: this.bannerData.content,
      notifyStatus: this.bannerData.notifyStatus,
      notifyAt: this.businessService.jsonDate(this.bannerData.notifyAt),
      viewType: this.bannerData.viewType.toString(),
      linkValue: this.bannerData.linkValue
    });
    this.changeLinkType(this.bannerData.viewType.toString());
  }

  patchNewsBranch() {
    console.log('viewType', this.f['viewType'].value);
    // if (['2', '4'].includes(this.f['viewType'].value)) {
    if (this.f['viewType'].value === '2') {
      if (this.isLinkChanged) {
        this.f['linkValue'].patchValue(this.externalLinkList[0].id);
        this.initialValue = null;
      } else {
        this.f['linkValue'].patchValue(this.bannerData.linkValue);
      }
    } else if (this.f['viewType'].value === '4') {
      this.isLinkChanged ? this.f['linkValue'].patchValue(null) : this.f['linkValue'].patchValue(this.bannerData.linkValue);
      this.initialValue = null;
    } else {
      const linkValue = this.externalLinkList.find(element => element.id === this.bannerData.linkValue);
      console.log('linkValue', linkValue);
      if (linkValue && !this.isLinkChanged) {
        this.initialValue = linkValue.name;
        setTimeout(() => {
          this.f['linkValue'].patchValue(linkValue.id);
        }, 500);
      } else {
        this.f['linkValue'].patchValue(null);
        this.initialValue = null;
      }
    }
  }

  checkLinkChange($event) {
    console.log('link changed');
    if ($event) {
      this.isLinkChanged = true;
    }
  }

  submitForm(f) {
    const {  notifyAt, viewType, ...form } = f.value;
    const body = {
      ...form,
      viewType: Number(this.f['viewType'].value),
      notifyAt: this.f['notifyStatus'].value ? this.businessService.parseDate(this.f['notifyAt'].value) : 0,
      bannerImage: this.selectedBanner ? this.selectedBanner : this.bannerData.bannerImage,
      fileName:  this.bannerFileName ? this.bannerFileName : '',
      fileType: this.selectedBanner ? this.fileType : '',
      imagePreview: this.imgUrl};
    this.submitting = true;
    f.disable();
    console.log('body', body);
    this.businessService.publishBannersList$({banner: body, i: this.index2});
    this.activeModal.dismiss();
  }

  ngOnInit() {
    this.getBusinessPartners();
    this.minDate = this.businessService.jsonDate(this.today.setDate(this.today.getDate()));
    this.bannerFileName = '';
    this.branchId = localStorage.getItem('branchId');
    this.resetForm();
    this.formOldValue = this.bannerForm.getRawValue();
    if (this.bannerData) {
      console.log('should edit');
      this.title = 'Edit Banner';
      this.patchValue();
    } else {
      this.title = 'Add Banner';
      this.changeLinkType('0');
    }
    this.checkCroppedAvatar();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }


}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { BusinessService } from 'src/app/core/services/business.service';
import { Router } from '@angular/router';
import { UserRolePermissionsComponent } from 'src/app/shared/components/user-role-permissions/user-role-permissions.component';

@Component({
  selector: 'app-distributor-page',
  templateUrl: './distributor-page.component.html',
  styleUrls: ['./distributor-page.component.scss']
})
export class DistributorPageComponent extends UserRolePermissionsComponent implements OnInit, OnDestroy {
  dataLists: Array<any>;

  constructor(
    businessService: BusinessService,
    private router: Router
  ) {
    super(businessService)
  }

  getDataList() {
    this.subscriptions.add(this.businessService.dataLists$.subscribe(
      data => {
        console.log('distributor list', data);
        if (data) {
          this.dataLists = data.data;
        }
      }, error => {
        console.log('error', error);
      }
    ));
  }

  addDistributor() {
    this.router.navigate([`/distributors/dist/add`]);
  }

  viewDetails($event) {
    console.log($event);
    this.router.navigate([`/distributors/${$event._id}`]);
  }

  checkDataChange() {
    this.subscriptions.add(this.businessService.dataChange$.subscribe(res => {
      if (res) {
        this.getDataList();
      }
    }));
  }

  getAccountData() {
    this.getAccountPromise();
    this.accountPromise
    .then((data) => {      
      console.log('promise account', data);
      this.setAccountItems(data);
      // Insert all functions related to Account Data
      // ----- End ----- //
    })
    .catch((error) => {
      console.log('promise err', error);
    });
  }

  ngOnInit() {
    // Needed in User Role
    this.getAccountData();
    // --------- //
    this.getDataList();
    this.checkDataChange();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { BusinessService } from 'src/app/core/services/business.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnDestroy {
  totalItem: number;
  selectedLimit: number;
  maxNumberPages: number;
  limits: Array<number>;
  limits2: Array<number>;
  startItem: number;
  selectedPage: number;
  pages: Array<number>;
  itemShow: number;
  @Input() type: any;
  @Input() lastTransaction: any;
  subscriptions = new Subscription();

  constructor(
    private businessService: BusinessService,
    public router: Router
  ) { }

  limitChanged() {
    this.businessService.publishSelectedLimit$(this.selectedLimit);
  }

  pageChanged($event) {
    this.startItem = ($event * this.selectedLimit) - this.selectedLimit;
    this.businessService.publishStartItem$(this.startItem);
    this.businessService.publishSelectedPage$($event);
    this.businessService.pages$ = $event;
    console.log('event', $event);
    this.limitChanged();
    window.scrollTo(0, 0);
  }

  getTotalItem() {
    this.subscriptions.add(this.businessService.totalItem$.subscribe(res => this.totalItem = res));
  }

  getMaxNumberPages() {
    this.subscriptions.add(this.businessService.maxNumberPages$.subscribe(
      res => {
        this.maxNumberPages = res;
        this.getPages();
      }));
  }

  getStartItem() {
    this.subscriptions.add(this.businessService.startItem$.subscribe(res => this.startItem = res));
  }

  getPages() {
    this.pages = [];
    for (let i = 0; i < this.maxNumberPages; i++) {
      this.pages.push(i + 1);
    }
  }

  getItemShowing() {
    this.subscriptions.add(this.businessService.itemShowing$.subscribe(res => this.itemShow = res));
  }

  ngOnInit() {
    this.limits = [ 10, 20, 50, 100];
    this.limits2 = [ 10, 20, 50];
    this.selectedLimit = 10;
    this.selectedPage = 1;
    this.getTotalItem();
    this.getMaxNumberPages();
    this.getItemShowing();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

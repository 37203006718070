import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-main-branch-about',
  templateUrl: './main-branch-about.component.html',
  styleUrls: ['./main-branch-about.component.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }]
})
export class MainBranchAboutComponent implements OnInit {
  @Input() branchData: any;

  constructor(
    private fb: FormBuilder,
    private parent: FormGroupDirective
  ) { }

  about = this.fb.group({
    desc: [null, Validators.required],
    facebook: [null],
    instagram: [null],
    company: [null]
  });

  get f() {return this.about.controls; }

  patchValue() {
    const fb = this.branchData.settings.socialLinks.find(element => {
      return element.type === 'facebook';
    });
    const ig = this.branchData.settings.socialLinks.find(element => {
      return element.type === 'instagram';
    });
    const cy = this.branchData.settings.socialLinks.find(element => {
      return element.type === 'company';
    });
    this.about.patchValue({
      desc: this.branchData.about,
      facebook: fb ? fb.url : null,
      instagram: ig ? ig.url : null,
      company: cy ? cy.url : null
    });
  }

  ngOnInit() {
    this.parent.form.addControl(
      'about', this.about
    );
    if (this.branchData) {
      this.patchValue();
    }
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { BusinessService } from 'src/app/core/services/business.service';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DragulaService } from 'ng2-dragula';
import { DiscoverAddEditFormComponent } from '../discover-add-edit-form/discover-add-edit-form.component';
import { Subscription } from 'rxjs';
import { UserRolePermissionsComponent } from 'src/app/shared/components/user-role-permissions/user-role-permissions.component';

@Component({
  selector: 'app-customer-discover',
  templateUrl: './customer-discover.component.html',
  styleUrls: ['./customer-discover.component.scss']
})
export class CustomerDiscoverComponent extends UserRolePermissionsComponent implements OnInit, OnDestroy {
  discoverList: Array<any>;
  originalList: Array<any>;
  addBusinessList: Array<any>;
  formDirty: boolean;
  submitting: boolean;
  isAutoSort: boolean;

  constructor(
    businessService: BusinessService,
    private httpAdminService: HttpWebAdminService,
    private modalService: NgbModal,
    private dragulaService: DragulaService
  ) {
    super(businessService)
  }

  getDiscoverList() {
    this.httpAdminService.get$(`partners/discover`).subscribe(
      data => {
        console.log('customer app discover', data);
        this.originalList = data.data;
        this.discoverList = data.data;
        this.isAutoSort = data.autoSortStatus;
        this.addBusinessList = [];
        for ( let i = 0; i < (7 - this.discoverList.length); i++) {
          this.addBusinessList.push('test');
          console.log('addBusinessList', this.addBusinessList);
        }
      }, error => {
        console.log('error', error);
      }
    );
  }

  autoSort($event) {
    this.formDirty = true;
    this.businessService.publishBannerDiscover$(true);
    if ($event) {
      this.isAutoSort = true;
      // this.httpAdminService.get$(`partners/discover/calibrate`).subscribe(
      //   data => {
      //     console.log('auto sort list', data);
      //     this.originalList = data.data;
      //     this.discoverList = [];
      //     this.addBusinessList = [];
      //     data.data.forEach(element => {
      //       this.discoverList.push(element);
      //     });
      //     for ( let i = 0; i < (7 - this.discoverList.length); i++) {
      //       this.addBusinessList.push('test');
      //       console.log('addBusinessList', this.addBusinessList);
      //     }
      //   }, error => {
      //     console.log('error', error);
      //   }
      // );
    } else {
      this.isAutoSort = false;
    }
  }

  addBusinessPartner() {
    const modalRef = this.modalService.open(DiscoverAddEditFormComponent, {
      centered: true
    });
    modalRef.componentInstance.title = 'Add Business Partner';
    modalRef.componentInstance.discoverList = this.discoverList;
    this.formDirty = true;
    this.businessService.publishBannerDiscover$(true);
  }

  checkIfDiscoverAdd() {
    this.subscriptions.add(this.businessService.discoverList$.subscribe(res => {
      console.log('checkIfDiscoverAdd', res);
      if (res.i !== undefined) {
        this.discoverList.splice(res.i, 1, res.business);
      } else {
        this.discoverList.push(res.business);
        this.addBusinessList.splice(0, 1);
      }
    }));
    console.log('discover list', this.discoverList);
  }

  checkDrag() {
    console.log('check drag');
    this.subscriptions.add(this.dragulaService.dropModel('discoverModel')
      .subscribe(({ sourceIndex, targetIndex, sourceModel, targetModel, target, item }) => {
        this.formDirty = true;
        this.businessService.publishBannerDiscover$(true);
        this.discoverList = targetModel;
        console.log('array', targetModel);
        })
    );
  }

  checkDataChange() {
    this.subscriptions.add(this.businessService.alert$.subscribe(res => {
      if (res) {
        if (!res.message.includes(`<b>Error:</b> Something went wrong.`)) {
          this.getDiscoverList();
          this.checkDrag();
        }
      }
    }));
  }

  replaceBusiness(business, index) {
    const modalRef = this.modalService.open(DiscoverAddEditFormComponent, {
      centered: true
    });
    modalRef.componentInstance.title = 'Replace Business Partner';
    modalRef.componentInstance.index = index;
    modalRef.componentInstance.businessData = JSON.stringify(business);
    modalRef.componentInstance.discoverList = this.discoverList;
    this.formDirty = true;
    this.businessService.publishBannerDiscover$(true);
  }

  removeBusiness(i) {
    this.discoverList.splice(i, 1);
    this.addBusinessList.push('test');
    this.formDirty = true;
    this.businessService.publishBannerDiscover$(true);
  }

  saveBusiness() {
    console.log('originalListdiscoverList', this.originalList, this.discoverList);
    this.submitting = true;
    const newList = this.discoverList.map((element, index) => {
      return {
        partnerId: element._id,
        sortNo: index,
        isModified: this.originalList[index] ? (this.originalList[index]._id === element._id ? false : true) : false
      };
    });
    console.log({data: newList, autoSortStatus: this.isAutoSort });
    this.httpAdminService.patch$(`partners/discover`, { data: newList, autoSortStatus: this.isAutoSort }).subscribe(
      data => {
        console.log('submitted', data);
        this.businessService.publishAlert$({
          type: 'green-dark-01',
          icon: 'fas fa-check',
          message: `<b>Success!</b> Discover list has been updated.`
        });
        this.getDiscoverList();
        this.formDirty = false;
        this.businessService.publishBannerDiscover$(false);
        this.submitting = false;
      }, error => {
        console.log('error', error);
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Something went wrong.</b> Please try again.`
        });
        this.formDirty = false;
        this.businessService.publishBannerDiscover$(false);
        this.submitting = false;
      }
    );
  }

  getAccountData() {
    this.getAccountPromise();
    this.accountPromise
    .then((data) => {      
      console.log('promise account', data);
      this.setAccountItems(data);
      // Insert all functions related to Account Data
      // ----- End ----- //
    })
    .catch((error) => {
      console.log('promise err', error);
    });
  }

  ngOnInit() {
    // Needed in User Role
    this.getAccountData();
    // --------- //
    this.getDiscoverList();
    this.checkIfDiscoverAdd();
    this.checkDataChange();
    this.checkDrag();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, ControlContainer, FormGroupDirective, FormArray } from '@angular/forms';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { BusinessService } from 'src/app/core/services/business.service';

@Component({
  selector: 'app-main-branch-distributor',
  templateUrl: './main-branch-distributor.component.html',
  styleUrls: ['./main-branch-distributor.component.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }]
})
export class MainBranchDistributorComponent implements OnInit {
  @Input() branchData: any;
  distributorList: Array<any>;
  manufacturerList = [ 'Sunmi', 'Telpo' ];
  devicesList: Array<any>;
  devicesListFiltered: Array<any>;
  disabledRemove: boolean;
  disabledAdd: boolean;
  devicesNameList: Array<any>;

  constructor(
    private fb: FormBuilder,
    private parent: FormGroupDirective,
    private httpAdminService: HttpWebAdminService,
    private businessService: BusinessService
  ) { }

  distributor = this.fb.group({
    assignedDistributor: [null],
    devices: this.fb.array([
      this.fb.group({
        manufactureId: [this.manufacturerList[0]],
        deviceId: [null],
        deviceName: [null]
      })
    ])
  });

  get f() { return this.distributor.controls; }
  get devices() { return this.distributor.get('devices') as FormArray; }

  createDevice() {
    return this.fb.array([
      this.fb.group({
        manufactureId: [this.manufacturerList[0]],
        deviceId: [null],
        deviceName: [null]
      })
    ]);
  }

  getDistributorList() {
    this.httpAdminService.get$(`distributor?limit=999`).subscribe(
      data => {
        console.log('distributor list', data);
        this.distributorList = data.data;
      }
    );
  }

  getDevices(distributorId) {
    console.log('getDevices', distributorId);
    this.devicesList = [];
    const id = (distributorId === 'null' || distributorId === null) ? '' : distributorId;
    this.getInStockDevices(id);
    if (this.branchData) {
      this.getAssignedDevices(id);
    }
  }

  getAssignedDevices(id) {
    const param = `&branchId=${this.branchData._id}&distributorId=${id}`;
    this.httpAdminService.get$(`devices?limit=999&${param}`).subscribe(
      data => {
        console.log('getAssignedDevices', id, data);
        this.devicesList = [...this.devicesList, ...data.data];
        this.changeManufacturer(this.manufacturerList[0], 0);
      }
    );
  }

  getInStockDevices(id) {
    const param = `&status=2&distributorId=${id}`;
    this.httpAdminService.get$(`devices?limit=999&${param}`).subscribe(
      data => {
        console.log('getInStockDevices', id, data);
        this.devicesList = [...this.devicesList, ...data.data];
        this.changeManufacturer(this.manufacturerList[0], 0);
      }
    );
  }

  initDistributor($event) {
    this.getDevices($event);
  }

  changeDistributor($event) {
    this.resetFormArray();
    this.initDistributor($event);
  }

  changeManufacturer($event, index) {
    console.log('changeManufacturer', $event, index);
    if (!this.branchData) {
      this.devices.controls[index].get('deviceId').patchValue(null);
      this.devicesListFiltered = this.devices.value.map((element, i) => {
        return this.devicesList.filter(element2 => {
          return element.manufactureId === element2.manufacturer;
        });
      });
    } else {
      this.devicesListFiltered = this.devices.value.map((element, i) => {
        return this.devicesList.filter(element2 => {
          return element.manufactureId === element2.manufacturer && (!element2.branchId || (element2.branch !== this.branchData._id));
        });
      });
    }
    if (this.devices.length > 0) {
      console.log('devices', index, this.devices.value[index].deviceId);
      console.log('devicesListFiltered', this.devicesListFiltered);
    }
    this.disableAdd();
  }

  changeDevice($event, index) {
    console.log($event, index, this.devicesListFiltered[index]);
    const deviceName = this.devicesListFiltered[index].find(element => $event === element._id);
    this.devices.controls[index].get('deviceName').patchValue(deviceName ? deviceName.modelType : null);
    this.disableAdd();
  }

  resetFormArray() {
    console.log('reset', this.devices.value.length);
    while (this.devices.value.length > 0) {
      this.devices.removeAt(0);
    }
    this.initDevice();
  }

  addDevice() {
    this.initDevice();
    const lastIndex = this.devices.value.length - 1;
    this.changeManufacturer(this.manufacturerList[0], lastIndex);
    this.disableAdd();
  }

  initDevice() {
    this.devices.push(
      this.fb.group({
        manufactureId: [this.manufacturerList[0]],
        deviceId: [null],
        deviceName: [null]
      })
    );
    this.disableRemove();
  }

  disableRemove() {
    console.log('devices.length', this.devices.length);
    if (this.devices.length <= 1) {
      this.disabledRemove = true;
    } else {
      this.disabledRemove = false;
    }
  }

  disableAdd() {
    const index = this.devices.length - 1;
    if (this.devices.length > 0) {
      if (this.devices.value[index].deviceId === null || this.devices.value[index].deviceName ===  null) {
        this.disabledAdd = true;
      } else {
        this.disabledAdd = false;
      }
    }
  }

  deleteDevice(i) {
    this.devices.removeAt(i);
    this.disableRemove();
    console.log('devices deleted', this.devices);
    this.businessService.publishEnableEdit$(true);
  }

  patchValue() {
    console.log('patchValue');
    this.f['assignedDistributor'].patchValue(this.branchData.distributorId ? this.branchData.distributorId : null);
    this.initDistributor(this.branchData.distributorId ? this.branchData.distributorId : 'null');
    this.branchData.assignedDevices.forEach((element, index) => {
      this.devices.push(
        this.fb.group({
          manufactureId: element.manufactureId,
          deviceId: element.deviceId,
          deviceName: element.modelType
        })
      );
    });
    this.devices.removeAt(0);
    if (this.branchData.assignedDevices.length <= 0) {
      this.initDevice();
    }
    console.log('devices form', this.devices);
  }

  ngOnInit() {
    this.getDistributorList();
    if (this.branchData) {
      this.patchValue();
    } else {
      this.initDistributor('null');
    }
    this.parent.form.addControl(
      'distributor', this.distributor
    );
    this.disableRemove();
    this.disableAdd();
  }

}

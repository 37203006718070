import { Component, OnInit, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators, FormArray, FormGroup } from '@angular/forms';
import { HttpService } from 'src/app/core/http/http.service';
import { Subscription, BehaviorSubject, pipe } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessService } from 'src/app/core/services/business.service';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';

@Component({
  selector: 'app-user-role-add-edit-form',
  templateUrl: './user-role-add-edit-form.component.html',
  styleUrls: ['./user-role-add-edit-form.component.scss']
})
export class UserRoleAddEditFormComponent implements OnInit {

  errorMsg: string;
  modulesData: any;
  title: string;
  partnerName = localStorage.getItem('partnerName');
  branchName = localStorage.getItem('branchName');
  submitting: boolean;
  type: any;
  branchId: any;
  totalQueues: any;
  filteredModuleList: Array<any>;
  moduleList: Array<any>;
  userRoleForm: FormGroup;
  collapsed: any = [];
  selectedOne: boolean;
  checkAll: boolean;
  newForm: any;
  subscriptions = new Subscription();
  totalModules = [];
  totalPermission = [];
  modulesWithMinus = [];
  filtModule: any;
  modulesArray: Array<string>;
  // modulesArray = [
  //   'dashboardModulePermissions',
  //   'partnerModulePermissions',
  //   'distributorModulePermissions',
  //   'customerModulePermissions',
  //   'deviceMonitoringModulePermissions',
  //   'announcementModulePermissions',
  //   'reportsModulePermissions',
  //   'settingsModulePermissions'
  // ];


  constructor(
    private businessService: BusinessService,
    public modalService: NgbModal,
    public fb: FormBuilder,
    private router: Router,
    public httpService: HttpService,
    private httpAdminService: HttpWebAdminService,
    public activeModal: NgbActiveModal
  ) { }

  get f() { return this.userRoleForm.controls; }
  get module(): any { return this.userRoleForm.get('modulez') as FormArray; }

  initFormGroup() {
    this.userRoleForm = this.fb.group({
      name: ['', [Validators.pattern(/[^\s]+/), Validators.required]],
      modulez: this.fb.array([]),
      selectedAll: [false]
    });
  }

  getModulesList() {
    this.httpAdminService.get$(`accounts/role-groups/available-modules`).subscribe(
      data => {
        console.log('available modules', data);
        this.modulesArray = data.data.map(element => {
          return element.fieldName;
        });
        this.filtModule = data.data.filter(elementz => elementz.modules.length > 0);
        this.moduleList = this.filtModule.map((e, ind) => {
          return { ...e, i: ind, modules: e.modules.map((elem, ind2) => {
            return { ...elem, i: ind2};
          }) };
        });
        this.filteredModuleList = this.moduleList;
        if (this.filtModule) {
          this.getPermission(0);
          this.filtModule.forEach((element, index) => {
            (this.userRoleForm.get('modulez') as FormArray).push(
              this.fb.group({
                i: index,
                value: false,
                modulesId: this.fb.array(this.getPermission(index))
              })
            );
          });
          this.checkModulesMinus();
        }
        if (this.modulesData && this.filtModule) {
          this.patchModule();
        }
      }, error => {
        console.log('error', error);
      }
    );
  }

  getPermission(ind) {
    const form = [];
    this.moduleList[ind].modules.forEach((element, index) => {
      form.push(this.fb.group({
        i : index,
        id: element.value,
        value: false,
        name: element.name
      }));
    });
    return form;
  }

  openPanel(event) {
    if (!this.collapsed.includes(event)) {
      console.log('should open');
      this.collapsed.push(event);
    } else {
      const i = this.collapsed.indexOf(event);
      this.collapsed.splice(i, 1);
    }
    console.log(this.collapsed, event);
  }

  selectAll($event) {
    console.log('selectedAll', this.module.controls);
    if ($event) {
      this.module.controls.forEach(element => {
        element.controls.value.patchValue(true);
      });
    } else {
      this.module.controls.forEach(element => {
        element.controls.value.patchValue(false);
      });
    }
    this.checkModuleSelected();
    this.checkPermissionSelected();
  }

  changeModule($event, index) {
    console.log('changeModule', $event, this.module.controls[index].get('modulesId').controls);
    if ($event) {
      this.module.controls[index].get('modulesId').controls.forEach(element => {
        console.log(element.controls.value.value);
        element.controls.value.patchValue(true);
      });
    } else {
      this.module.controls[index].get('modulesId').controls.forEach(element => {
        element.controls.value.patchValue(false);
      });
    }
    console.log('form', this.userRoleForm.value.modulez);
    this.checkPermissionSelected();
  }

  changePermission(event, mod, permission) {
    this.checkPermissionSelected();
    console.log('changePermission', event, mod, permission);
  }

  checkModuleSelected() {
    const filtered = this.modulesWithMinus.filter((element, i) => element.minus > 0);
    console.log('filtered', filtered.length);
    this.totalModules = filtered;
    if (filtered.length <= 0) {
      this.selectedOne = false;
      this.checkAll = false;
    } else if (this.moduleList.length === filtered.length) {
      this.selectedOne = false;
      this.checkAll = true;
    } else if (filtered.length < this.moduleList.length) {
      this.selectedOne = true;
      this.checkAll = false;
    }
  }

  checkPermissionSelected() {
    this.totalPermission = this.userRoleForm.value.modulez.reduce((arr, cats: any) => {
      const {modulesId} = cats;
      for (const x in modulesId) {
        if (modulesId[x].value === true) {
          arr.push(modulesId[x].id);
        }
      }
      return arr;
    }, []);
    console.log('totalPermission', this.totalPermission);
    this.checkModulesMinus();
  }

  checkModulesMinus() {
    this.modulesWithMinus = this.userRoleForm.value.modulez.map((element, index) => {
      return {
        i: index,
        minus: element.modulesId.filter(element2 => element2.value).map(element3 => element3.value).length,
        total: element.modulesId.length,
        permissions: element.modulesId.filter(element2 => element2.value).map(element3 => {
          if (element3.value) {
            return element3.id;
          }
        })
      };
    });
    console.log('modulesWithMinus', this.modulesWithMinus);
    this.checkModuleSelected();
  }

  filterModule() {
    const modulesArr = this.modulesArray.map((element, index) => {
      return { key: element, value: this.modulesWithMinus[index].permissions };
    });
    const result = {};
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < modulesArr.length; i++) {
      result[modulesArr[i].key] = modulesArr[i].value;
    }
    const {modulez, selectedAll, ...form} = this.userRoleForm.value;
    this.newForm = {
      ...form,
      ...result
    };
    console.log('newForm', this.newForm);
  }

  submitForm() {
    this.filterModule();
    this.submitting = true;
    // edit
    if (this.modulesData) {
      this.httpService.patch$(`accounts/role-groups/${this.modulesData._id}`, this.newForm).subscribe(
        data => {
          console.log('edited user role', data);
          this.businessService.publishAlert$({
            type: 'green-dark-01',
            icon: 'fas fa-check',
            message: `<b>Success!</b> — ${this.modulesData.name} user role has been updated.`
          });
          this.modalService.dismissAll();
          this.businessService.publishDataChange$(data);
          this.submitting = true;
        },
        error => {
          console.log('error', error);
          this.submitting = false;
          this.businessService.publishAlert$({
            type: 'red-dark-01',
            icon: 'fas fa-ban',
            message: `<b>Oops Something went wrong!</b> — Server error.`
          });
        }
      );
      // new
    } else {
      this.httpService.post$(`accounts/role-groups/`, this.newForm).subscribe(
        data => {
          console.log('added user role', data);
          this.businessService.publishAlert$({
            type: 'green-dark-01',
            icon: 'fas fa-check',
            message: `<b>Success!</b> —  ${data.name} user role has been added.`
          });
          this.modalService.dismissAll();
          this.businessService.publishDataChange$(data);
          this.submitting = true;
        },
        error => {
          console.log('error', error);
          this.submitting = false;
          this.businessService.publishAlert$({
            type: 'red-dark-01',
            icon: 'fas fa-ban',
            message: `<b>Oops Something went wrong!</b> — Server error.`
          });
        }
      );
    }
  }

  patchValue() {
    console.log('template data', this.modulesData);
    this.userRoleForm.patchValue({
      name: this.modulesData.name
    });
  }

  patchModule() {
    console.log('categorycategorycategorycategory', this.module.controls);
    this.modulesArray.forEach((element, index) => {
      this.module.controls[index].controls.modulesId.controls.forEach((element2) => {
        if (this.modulesData[element].includes(element2.value.id)) {
          element2.controls.value.patchValue(true);
        }
      });
    });
    this.checkPermissionSelected();
  }

  ngOnInit() {
    this.modulesData ? this.title = 'Edit User Role' : this.title = 'Add User Role';
    this.getModulesList();
    this.initFormGroup();
    if (this.modulesData) {
      this.patchValue();
    }
  }

}

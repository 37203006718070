import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BusinessService } from 'src/app/core/services/business.service';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BusinessPartnersAddEditFormComponent } from '../../components/business-partners-add-edit-form/business-partners-add-edit-form.component';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';
import { UserRolePermissionsComponent } from 'src/app/shared/components/user-role-permissions/user-role-permissions.component';

@Component({
  selector: 'app-business-partners-single-page',
  templateUrl: './business-partners-single-page.component.html',
  styleUrls: ['./business-partners-single-page.component.scss']
})
export class BusinessPartnersSinglePageComponent extends UserRolePermissionsComponent implements OnInit, OnDestroy {
  partnerData: any;
  imgUrl: any;
  dataLists: Array<any>;
  partnerId: string;

  constructor(
    businessService: BusinessService,
    private httpAdminService: HttpWebAdminService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
  ) {
    super(businessService)
  }

  getUrlParams() {
    this.subscriptions.add(this.activatedRoute.params.subscribe(params => {
      this.partnerId = params['partnerId'];
      this.getPartnerData();
    }));
  }

  getPartnerData() {
    this.httpAdminService.get$(`partners/${this.partnerId}`).subscribe(
      data => {
        console.log('partner data', data);
        this.partnerData = data.data;
        this.imgUrl = this.partnerData.avatarUrl ? this.partnerData.avatarUrl : './assets/images/koala.jpg';
      }, error => {
        console.log('error', error);
        // this.businessService.publishAlert$({
        //   type: 'red-dark-01',
        //   icon: 'fas fa-ban',
        //   message: `<b>Error:</b> Something went wrong.`
        // });
      }
    );
  }

  getDataList() {
    this.subscriptions.add(this.businessService.dataLists$.subscribe(
      data => {
        console.log('branches list', data);
        if (data) {
          this.dataLists = data.data;
        }
      }, error => {
        console.log('error', error);
      }
    ));
  }

  viewLogs() {
    this.router.navigate([`/business-partners/${this.partnerId}/activity-logs`]);
  }

  checkDataChange() {
    this.subscriptions.add(this.businessService.dataChange$.subscribe(
      res => {
        if (res) {
          this.getPartnerData();
          this.getDataList();
        }
      }
    ));
  }

  checkAlert() {
    this.subscriptions.add(this.businessService.alert$.subscribe(
      res => {
        if (res) {
          if (!res.message.includes(`<b>Error:</b> Something went wrong.`)) {
            this.getPartnerData();
            this.getDataList();
          }
        }
      }
    ));
  }

  addBranch() {
    this.router.navigate([`/business-partners/branch/${this.partnerId}/add`]);
  }

  editPartner() {
    const modalRef = this.modalService.open(BusinessPartnersAddEditFormComponent, {
      centered: true
    });
    modalRef.componentInstance.title = 'Edit Business Partner';
    modalRef.componentInstance.partnerData = this.partnerData;
  }

  viewDetails($event) {
    console.log($event);
    this.router.navigate([`/business-partners/branch/${$event.partnerId}/${$event._id}`]);
  }

  suspendPartner() {
    const modalRef = this.modalService.open(ModalConfirmComponent, {
      centered: true
    });
    modalRef.componentInstance.type = 'suspend-admin';
    modalRef.componentInstance.adminTitle = 'Suspend Business Partner';
    modalRef.componentInstance.bodyText = `Are you sure you want to suspend <br>
    ${this.partnerData.name}?`;
    modalRef.componentInstance.primaryButton = 'Confirm';
    modalRef.componentInstance.partnerId = this.partnerId;
  }

  unsuspendPartner() {
    this.httpAdminService.patch$(`partners/${this.partnerId}/suspend-status`, {status: false}).subscribe(
      data => {
        this.businessService.publishAlert$({
          type: 'green-dark-01',
          icon: 'fas fa-check',
          message: `<b>${data.data.name}</b> has been Unsuspended!`
        });
        this.getPartnerData();
      }, error => {
        console.log('error');
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: error.error.source
        });
      }
    );
  }

  getAccountData() {
    this.getAccountPromise();
    this.accountPromise
    .then((data) => {      
      console.log('promise account', data);
      this.setAccountItems(data);
      // Insert all functions related to Account Data
      // ----- End ----- //
    })
    .catch((error) => {
      console.log('promise err', error);
    });
  }

  ngOnInit() {
    // Needed in User Role
    this.getAccountData();
    // --------- //
    this.getUrlParams();
    this.getDataList();
    this.getDataList();
    this.checkAlert();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

import { Component, OnInit, HostListener, OnDestroy, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CropPhotoComponent } from 'src/app/indie/crop-photo/crop-photo.component';
import { Subscription } from 'rxjs';
import { BusinessService } from 'src/app/core/services/business.service';
import { HttpService } from 'src/app/core/http/http.service';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
@Component({
  selector: 'app-accounts-add-edit-form',
  templateUrl: './accounts-add-edit-form.component.html',
  styleUrls: ['./accounts-add-edit-form.component.scss']
})
export class AccountsAddEditFormComponent implements OnInit, OnDestroy {
  title: string;
  @Input() title2: string;
  avatarUrl: string;
  imgUrl: any;
  thumb01: string;
  formOldValue: any;
  branchId: string;
  submitting: boolean;
  errorMsg: string;
  accountData: any;
  selectedAvatar: File;
  enableSubmit: boolean;
  openLeaveModal: boolean;
  subscriptions = new Subscription();
  roleTypes: Array<any>;
  enabledEmail: boolean;
  verificationSent: boolean;
  verificationFailed: boolean;
  type: any;
  distributorData: any;

  formDirty: boolean;
  avatarError: boolean;
  emailErrorMsg: string;
  statusCodeList: Array<any>;

  croppedImg: any;
  fileName: string;
  fileType: string;


  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private businessService: BusinessService,
    private httpService: HttpService,
    private httpAdminService: HttpWebAdminService
  ) { }

  accountForm = this.fb.group({
    firstName: ['', [Validators.required, Validators.pattern(/^[A-Za-z- ñÑ.]*$/)]],
    lastName: ['', [Validators.required, Validators.pattern(/^[A-Za-z- ñÑ.]*$/)]],
    email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9\_\-\.]+\@[a-zA-Z0-9]{1,12}(\.[a-zA-Z0-9]{1,7}){1,3}$/)]],
    mobileNo:  ['(+63) 9', [Validators.required, Validators.minLength(7)]],
    roleGroupId: [null, Validators.required]
  });

  get f() {return this.accountForm.controls; }

  onMobileKeyUp(contact) {
    const defaultVal = '(+63) 9';
    if (contact.value.length <= 3 ||
      contact.value.length === '' ||
      contact.value.substring(0, 7) !== defaultVal) {
      contact.setValue(defaultVal);
    }
  }

  patchMobile(contact) {
    const number1 = contact.substring(1, 3);
    const number2 = contact.substring(3, 6);
    const number3 = contact.substring(6, 11);
    return `(+63) 9${number1} - ${number2} - ${number3}`;
  }

  transformMobile(contact) {
    return contact.slice(6, 22).replace(/\D/g, '');
  }

  onAvatarChange($event) {
    this.formDirty = true;
    this.selectedAvatar = $event.target.files[0] as File;
    if (['image/png', 'image/jpeg'].includes($event.target.files[0].type)) {
      this.openCropModal($event, 'avatar-circle', 'Select Admin Photo');
    } else {
      this.avatarError = true;
    }
  }

  checkCroppedAvatar() {
    this.subscriptions.add(this.businessService.croppedPhoto$.subscribe(res => {
      this.croppedImg = res.image;
      console.log('this.croppedImg', this.croppedImg);
      this.imgUrl = res.image;
      this.fileName = res.fileName;
      this.fileType = res.fileType;
      this.enableSubmit = true;
      this.avatarError = false;
    }));
  }

  openCropModal(avatar, type, title) {
    const modalRef = this.modalService.open(CropPhotoComponent, {
      centered: true,
      windowClass: `modal-${type}`
    });
    modalRef.componentInstance.imageFile = avatar;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.cropType = type;
    console.log('image type', avatar.target.files[0].type);
  }

  submitForm(f, confirmModal) {
    const body = new FormData();
    if (this.croppedImg) {
      body.append('avatar', new File([new Blob([this.businessService.dataURItoBlob(this.croppedImg)], {type: this.fileType})], this.fileName, {type: this.fileType}));
    console.log('avatar', new File([new Blob([this.businessService.dataURItoBlob(this.croppedImg)], {type: this.fileType})], this.fileName, {type: this.fileType}));
    }
    console.log('mobile', f.get('mobileNo').value);
    if (f.get('mobileNo').value !== '(+63) 9') {
      body.append('mobileNo', this.transformMobile(f.get('mobileNo').value));
    }
    for (const key in f.getRawValue()) {
      if (f.getRawValue().hasOwnProperty(key) && key !== 'mobileNo' && key !== 'firstName' && key !== 'lastName') {
        body.append(key, f.getRawValue()[key]);
        console.log(key, f.getRawValue()[key]);
      }
    }
    body.append('firstName', this.capitalize(f.getRawValue().firstName));
    body.append('lastName', this.capitalize(f.getRawValue().lastName));
    this.submitting = true;
    f.disable();
    if (this.accountData) {
      // if edit
      this.httpService.patch$(`/accounts/${this.accountData._id}`, body).subscribe(
        data => {
          console.log('added account', data);
          this.activeModal.dismiss();
          this.businessService.publishAlert$({
            type: 'green-dark-01',
            icon: 'fas fa-check',
            message: `<b>Success!</b> — Account has been updated.`
          });
          this.submitting = false;
          f.enable();
        }, error => {
          console.log('error', error);
          this.showError(error);
          this.submitting = false;
          f.enable();
        }
      );
    } else {
      // if new
      if (this.type === 'distributor-add-account') {
        this.httpService.post$(`distributor/${this.distributorData._id}/accounts/sign-up`, body).subscribe(
          data => {
            console.log('added account', data);
            this.activeModal.dismiss();
            this.businessService.publishAlert$({
              type: 'green-dark-01',
              icon: 'fas fa-check',
              message: `<b>Success!</b> — ${data.firstName} ${data.lastName} has been added.`
            });
            this.submitting = false;
            this.businessService.publishDataChange$(data);
            f.enable();
          }, error => {
            console.log('error', error);
            this.showError(error);
            this.submitting = false;
            f.enable();
          }
        );
      } else {
        this.httpService.post$(`accounts/sign-up`, body).subscribe(
          data => {
            console.log('added account', data);
            this.activeModal.dismiss();
            this.modalService.open(confirmModal, {
              centered: true
            });
            this.businessService.formDataModal$ = {
              type: 'success-admin',
              successType: 'add-admin-account',
              bodyText: `To activate the account, a verification link will be sent to<br>
              <b>${data.email.value}</b>`,
              primaryButton: 'Okay, got it'
            };
            this.businessService.publishDataChange$(data);
            this.submitting = false;
            f.enable();
          }, error => {
            console.log('error', error);
            this.showError(error);
            this.submitting = false;
            f.enable();
          }
        );
      }
    }
  }

  showError(error) {
    this.statusCodeList = error.error.errors.filter(element => element.msg.statusCode).map(element2 => element2.msg.statusCode);
    console.log('this.statusCodeList', this.statusCodeList);
    if (this.statusCodeList.includes(40474)) {
      this.emailErrorMsg = 'Email already exist.';
    } else {
      this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
    }
  }

  clearBackendError(input) {
    if (input.dirty) {
      this.emailErrorMsg = null;
      this.errorMsg = null;
    }
  }

  @HostListener('body:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    console.log(event);
    event.preventDefault();
    if (!this.openLeaveModal) {
      this.closeModal();
    }
    this.subscriptions.add(
      this.businessService.navigateClose$.subscribe(res => {
        console.log('res', res);
        this.openLeaveModal = false;
      })
    );
  }

  closeModal() {
      this.activeModal.close();
  }

  resetForm() {
    this.subscriptions.add(this.businessService.resetForm$.subscribe(res => {
      if (res === true) {
        this.accountForm.reset();
        this.accountForm.get('contactNo').patchValue('(+63) 9');
      }
    }));
  }

  changeRolelevel($event) {
    console.log($event);
  }

  capitalize(name) {
    return name.toLowerCase().replace(/(^|\s)\S/g,  (firstLetter) => firstLetter.toUpperCase());
  }

  patchValue() {
    // const roleId = this.roleTypes.find(element => element.name === this.accountData.userRole)._id;
    this.accountForm.patchValue({
      firstName: this.accountData.firstName,
      lastName: this.accountData.lastName,
      email: this.accountData.email.value,
      mobileNo: this.accountData.mobileNo ? this.patchMobile(this.accountData.mobileNo) : '(+63) 9',
      roleGroupId: this.accountData.roleGroupId
    });
    this.avatarUrl = this.accountData.avatarUrl ? this.accountData.avatarUrl : './assets/images/koala.jpg';
    this.f['email'].disable();
    this.enabledEmail = true;
  }

  getRoleList() {
    this.httpAdminService.get$(`accounts/role-groups/`).subscribe(
      data => {
        console.log('role list', data);
        this.roleTypes = data.data;
        if (this.accountData) {
          this.patchValue();
          this.imgUrl = this.accountData.avatarUrl ? this.accountData.avatarUrl : './assets/images/koala.jpg';
        } else {
          this.imgUrl = './assets/images/koala.jpg';
        }
      }, error => {
        console.log('error', error);
      }
    );
  }

  resetPassword(confirmModal) {
    this.activeModal.dismiss();
    this.modalService.open(confirmModal, {
      centered: true
    });
    this.businessService.formDataModal$ = {
      type: 'reset-password',
      bodyText: `A reset password link will be sent to <br>
      <b>${this.accountData.email.value}</b>`,
      primaryButton: 'Confirm',
      adminTitle: 'Reset Password',
      accountData: this.accountData
    };
  }

  enableEmail($event) {
    console.log(this.enabledEmail);
    this.enabledEmail = !this.enabledEmail;
    this.enabledEmail ? this.f['email'].disable() : this.f['email'].enable();
  }

  verify() {
    console.log('verify', this.accountData._id); //
    let param;
    if (this.accountData.isVerified) {
      param = `auth/update-account/email-verification/${this.accountData._id}`;
    } else {
      param = `auth/sign-up/email-verification/${this.accountData._id}`;
    }
    this.httpAdminService.post$(param, '').subscribe(
      data => {
        console.log('verified', data);
        this.verificationSent = true;
      }, error => {
        console.log('error', error);
        this.verificationFailed = true;
      }
    );
  }

  ngOnInit() {
    this.checkCroppedAvatar();
    this.branchId = localStorage.getItem('branchId');
    this.resetForm();
    this.formOldValue = this.accountForm.getRawValue();
    this.getRoleList();
    console.log('accountData', this.accountData);
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { BusinessService } from 'src/app/core/services/business.service';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { UserRolePermissionsComponent } from '../user-role-permissions/user-role-permissions.component';

@Component({
  selector: 'app-table-web-admin',
  templateUrl: './table-web-admin.component.html',
  styleUrls: ['./table-web-admin.component.scss']
})
export class TableWebAdminComponent extends UserRolePermissionsComponent implements OnInit, OnDestroy {
  @Input() title: any;
  @Input() subtitle: any;
  @Input() type: any;
  @Input() searchFilterType: any;
  @Input() feedType: any;
  @Input() lists: any;
  @Input() placeholder: any;
  @Input() filterList: any;
  @Input() totalOnGoingQueues: any;
  listEmpty: any;
  hasData: any;
  employees = [
    { avatarUrl: './assets/images/avatar.jpg'},
    { avatarUrl: './assets/images/avatar.jpg'},
    { avatarUrl: './assets/images/avatar.jpg'},
    { avatarUrl: './assets/images/avatar.jpg'}
  ];
  @Output() viewItem = new EventEmitter();
  @Output() addEmpty = new EventEmitter();
  queueAvailability: boolean;
  branchId: string;
  employeesList: Array<any>;
  feedName: string;
  shortName: string;
  titleEmpty: string;
  bodyEmpty: string;
  controls: any;
  isAvailable: boolean;
  categoryList: Array<any>;
  featuredAccountId: Array<string>;
  myAccountId: string;
  searchString: boolean;
  partnersList: Array<any>;
  showItem: any;
  industryLists: Array<any>;
  totalCategoryLists: Array<any>;
  distributorLists: Array<any>;
  deviceId: string;
  isDeviceSingle: boolean;
  totalBranchSMSList: Array<any>;
  partnerId: string;
  days = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  devicesList: Array<any>;
  @Input() logsType: any;
  showList: boolean;
  accountsList: Array<any>;
  modulesFieldNameArray: Array<string>;
  modulesLabelArray: Array<string>;

  constructor(
    private httpAdminService: HttpWebAdminService,
    businessService: BusinessService,
    public router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super(businessService)
  }

  getUrlParams() {
    this.subscriptions.add(this.activatedRoute.params.subscribe(params => {
      this.deviceId = params['deviceId'];
      this.partnerId = params['partnerId'];
    }));
  }

  getIndustryList() {
    this.httpAdminService.get$(`industry`).subscribe(
      data => {
        this.industryLists = data.data;
        this.totalCategoryLists = [];
        this.industryLists.forEach(element => {
          element.categoryList.forEach(element2 => {
            this.totalCategoryLists.push(element2);
          });
        });
        console.log('industry list', this.industryLists);
        console.log('category list', this.totalCategoryLists);
      }, error => {
        console.log('error', error);
      }
    );
  }

  getDistributorList() {
    this.httpAdminService.get$(`distributor`).subscribe(
      data => {
        this.distributorLists = data.data;
        console.log('distributor list', this.distributorLists);
      }, error => {
        console.log('error', error);
      }
    );
  }

  getIndustry(id) {
    const industryId = this.industryLists.find(element => element._id === id);
    return industryId.name;
  }

  getCategory(id) {
    const categoryId = this.totalCategoryLists.find(element => element._id === id);
    return categoryId.name;
  }

  getDistributor(id) {
    const distributorId = this.distributorLists.find(element => element._id === id);
    return distributorId.name;
  }

  getDayBranch(value) {
    const r = /\d+/;
    const int = value.match(r);
    const word = value.split(int).join(this.days[int]);
    const newWord = word.replace('operationHours', 'Operation Hours').split('->').join('/');
    if (newWord.includes('enabled')) {
      return newWord.replace('enabled', 'Enabled');
    } else if (newWord.includes('openingTime')) {
      return newWord.replace('openingTime', 'Opening Time');
    } else if (newWord.includes('closingTime')) {
      return newWord.replace('closingTime', 'Closing Time');
    } else if (newWord.includes('isWholeDay')) {
      return newWord.replace('isWholeDay', '24 Hours');
    } else {
      return newWord;
    }
  }

  view($event, index) {
    console.log($event);
    this.viewItem.emit($event);
    if (this.feedType === 'activity-logs' && ($event.actionType === 2 && !$event.eventSummary.includes('pulled-out'))) {
      this.viewLog(index);
    }
  }

  add() {
    console.log('add empty');
    this.addEmpty.emit();
  }

  toggle(idVal, isAvailableVal) {
    this.viewItem.emit({
      id: idVal,
      isAvailable: isAvailableVal
    });
  }

  viewLog(i) {
    console.log(this.showItem);
    if (this.showItem !== i) {
      this.showItem = i;
    } else {
      this.showItem = null;
    }
  }

  roundOff(time) {
    if (time <= 60000) {
      return Math.round(time) + 'secs';
    } else {
      return Math.round(time) + 'mins';
    }
  }

  getEmployeeList() {
    this.httpAdminService.get$(`accounts/${this.branchId}`).subscribe(
      data => {
        this.employeesList = data.data;
        console.log('employeesList', this.employeesList);
      }, error => {
        console.log('error', error);
      }
    );
  }

  getDevicesList() {
    this.httpAdminService.get$(`devices?limit=999`).subscribe(
      data => {
        console.log('devices list', data.data);
        this.devicesList = data.data;
      }, error => {
        console.log('error', error);
      }
    );
  }

  getDevices(deviceId) {
    const device = this.devicesList.find(element => element._id === deviceId);
    return device.refId;
  }

  getBranchSMS(id) {
    this.httpAdminService.get$(`/mailer/${id}/sms/sms-count-today`).subscribe(
      data => {
        if (data) {
          console.log('SMS data', data);
          this.totalBranchSMSList.push({totalValue: data.totalValue});
        }
      }, error => {
        console.log('error', error);
      }
    );
  }

  getAvatar(id) {
    let avatar;
    if (this.employeesList) {
      this.employeesList.forEach(element1 => {
        if (element1.id === id) {
          avatar = element1.avatarUrl;
        }
      });
    }
    return avatar;
  }

  getName(id) {
    let name;
    if (this.employeesList) {
      this.employeesList.forEach(element2 => {
        if (element2.id === id) {
          name = element2.firstName + element2.lastName;
        }
      });
    }
    return name;
  }

  getPartnersList() {
    this.httpAdminService.get$(`partners`).subscribe(
      data => {
        console.log('partners list', data);
        if (data) {
          this.partnersList = data.data;
        }
      }, error => {
        console.log('error', error);
      }
    );
  }

  getPartnerName(id) {
    let name;
    if (this.partnersList) {
      this.partnersList.forEach(element => {
        if (element.id === id) {
          name = element.name;
        }
      });
    }
    return name;
  }

  getPartnerAvatar(id) {
    let avatar;
    if (this.partnersList) {
      this.partnersList.forEach(element => {
        if (element.id === id) {
          avatar = element.avatarUrl ? element.avatarUrl : './assets/images/koala.jpg';
        }
      });
    }
    return avatar;
  }

  getPlanType(val) {
    return this.businessService.getPlanType(val);
  }

  getBannerViewType(val) {
    return this.businessService.getBannerViewType(val);
  }

  convertNumber(val) {
    return Number(val);
  }

  modifyWord(val) {
    const word = val.split('');
    const newWord = word.map(element => {
      if (element.match(/[^a-z]/g)) {
        return ' ' + element;
      } else {
        return element;
      }
    });
    return newWord.toString().split(',').join('').toLowerCase();
  }

  filterModifiedFields(list) {
    return list.filter(element => !['__v', 'updatedAt', 'branchId', 'settings -> __v'].includes(element.fieldName) && !element.fieldName.includes('createdAt'));
  }

  checkEmpty() {
    console.log('check emptyyyyy');
    this.subscriptions.add(this.businessService.dataLists$.pipe(first())
      .subscribe(
      data => {
        console.log('check emptyyyyy data', data);
        this.showList = true;
        if (data.error) {
          this.showList = false;
        }
        let filteredData;
        // Device Single Page
        // if (this.router.url.includes(`device-monitoring/${this.deviceId}`)) {
        //   filteredData = data.data.filter(element => element.actionType === 4 || element.actionType === 5 || ((element.actionType === 2 && element.eventSummary.includes('pulled-out') || (element.actionType === 2 && element.eventSummary.includes('updated')))));
        // // Other Pages
        // } else {
        //   filteredData = data.data;
        // }
        filteredData = data.data;
        if (data) {
          console.log('check empty', data);
          this.totalBranchSMSList = [];
          if (this.router.url.includes(`business-partners/${this.partnerId}`) && !this.router.url.includes(`business-partners/${this.partnerId}/activity-logs`)) {
            filteredData.forEach(element => {
              console.log('id', element._id);
              this.getBranchSMS(element._id);
            });
          }
          console.log('totalBranchSMSList', this.totalBranchSMSList);
          if (filteredData) {
            if (filteredData.length <= 0) {
              console.log('filteredData.length <= 0');
              this.listEmpty = true;
            } else {
              this.listEmpty = false;
            }
          } else if (data.totalCounts <= 0) {
            console.log('data.totalCounts <= 0');
            this.listEmpty = true;
          } else {
            console.log('else');
            this.hasData = true;
            this.listEmpty = false;
          }
        }
      }, error => {
        this.showList = false;
      }
    ));
  }

  checkFeedType() {
    switch (this.feedType) {
      case 'branches': {
        this.feedName = 'Branch';
        this.shortName = 'Branch';
        this.titleEmpty = 'No Branches Yet';
        this.bodyEmpty = 'This business will not appear on any platform unless a branch has been added.';
      }
      break;
      case 'partners': {
        this.feedName = 'Partner';
        this.shortName = 'Partner';
        this.titleEmpty = 'No Partners Yet';
        this.bodyEmpty = 'This business will not appear on any platform unless a partner has been added.';
      }
      break;
      case 'industry': {
        this.feedName = 'Industry';
        this.shortName = 'Industry';
        this.titleEmpty = 'No Industry Yet';
        this.bodyEmpty = 'Add an industry classification to categorize new business partners';
      }
      break;
      case 'distributor': {
        this.feedName = 'Distributor';
        this.shortName = 'Distributor';
        this.titleEmpty = 'No Distributor Yet';
        this.bodyEmpty = 'Add Kyoo Partner merchants and brand re-sellers';
      }
      break;
      case 'device-monitoring': {
        this.feedName = 'Devices';
        this.shortName = 'Devices';
        this.titleEmpty = 'No Devices Yet';
        this.bodyEmpty = 'Add a device to be used for distribution of Kyoo Queueing Management Solution';
      }
      break;
      case 'admin-accounts': {
        this.feedName = 'Admin Accounts';
        this.shortName = 'Accounts';
        this.titleEmpty = 'No Accounts Yet';
        this.bodyEmpty = 'Add an administrator account to manage and setup the whole Kyoo solution ';
      }
      break;
      case 'distributor-accounts': {
        this.feedName = 'Accounts';
        this.shortName = 'Accounts';
        this.titleEmpty = 'No Accounts Yet';
        this.bodyEmpty = '';
      }
      break;
      case 'distributor-device': {
        this.feedName = 'Devices';
        this.shortName = 'Devices';
        this.titleEmpty = 'No Devices Yet';
        this.bodyEmpty = 'Add a device to be used for distribution of Kyoo Queueing Management Solution';
      }
      break;
      case 'distributor-branches': {
        this.feedName = 'Branches';
        this.shortName = 'Branches';
        this.titleEmpty = 'No Branches Yet';
        this.bodyEmpty = `Add and setup Kyoo client's account for Business Portal and Application access`;
      }
      break;
      case 'customer-history': {
        this.feedName = 'Queue History';
        this.shortName = 'History';
        this.titleEmpty = 'No Queue History Yet';
        this.bodyEmpty = 'View list of customer queue activities via Kyoo customer application';
      }
      break;
      case 'activity-logs': {
        this.feedName = 'Activity Logs';
        this.shortName = 'Log';
        this.titleEmpty = 'No Activity Logs Yet';
        this.bodyEmpty = 'Virtually monitor series of Kyoo actions and information updates';
      }
      break;
      case 'news': {
        this.feedName = 'News';
        this.shortName = 'News';
        this.titleEmpty = 'No News Yet';
        this.bodyEmpty = 'Add new information and news articles to be posted in Kyoo customer application';
      }
      break;
      case 'messages': {
        this.feedName = 'Messages';
        this.shortName = 'Message';
        this.titleEmpty = 'No Messages Yet';
        this.bodyEmpty = 'Add new messages for business and consumers';
      }
      break;
      case 'user-admin-role': {
        this.feedName = 'User Roles';
        this.shortName = 'User Role';
        this.titleEmpty = 'No User Roles Yet';
        this.bodyEmpty = 'Add new user role';
      }
      break;
    }
  }

  onScroll() {
    console.log('scrolling');
    // add pagination code here (page +1 startsAt +1)
    let page = 1;
    page++;
    console.log('page', page);
  }

  checkDataChange() {
    this.subscriptions.add(this.businessService.dataChange$.subscribe(res => {
      if (res) {
        console.log('test empty');
        this.checkEmpty();
      }
    }));
  }

  getAccountsList() {
    this.httpAdminService.get$(`accounts/search/?limit=999&offset=0`).subscribe(
      data => {
        console.log('accounts list', data);
        this.accountsList = data.data;
      }, error => {
        console.log('error', error);
      }
    );
  }

  getAddedByAvatar(id) {
    const selectedId = this.accountsList.find(element => element._id === id);
    if (selectedId) {
      return selectedId.avatarUrl ? selectedId.avatarUrl : './assets/images/koala.jpg';
    } else {
      return './assets/images/koala.jpg';
    }
  }

  getAddedByName(id) {
    const selectedId = this.accountsList.find(element => element._id === id);
    if (selectedId) {
      return selectedId.name ? (selectedId.name ? selectedId.name : '—') : (selectedId.firstName ? (selectedId.firstName + ' ' + selectedId.lastName) : '—');
    } else {
      return '—';
    }
  }

  getModulesList() {
    this.httpAdminService.get$(`accounts/role-groups/available-modules`).subscribe(
      data => {
        console.log('available modules in table', data);
        this.modulesFieldNameArray = data.data.map(element => {
          return element.fieldName;
        });
        this.modulesLabelArray = data.data.map(element => {
          return element.label;
        });
      }, error => {
        console.log('error', error);
      }
    );
  }

  checkModuleAccess(list) {
    let arr = [];
    this.modulesFieldNameArray.forEach((element, i) => {
      if (list[element]) {
        if (list[element].length > 0) {
          arr.push(this.modulesLabelArray[i]);
        }
      } else {
        arr = [];
      }
    });
    const arrString = arr.length > 0 ? arr.toString().replace(/,/g, ', ') : '—';
    return arrString;
  }

  getAccountData() {
    this.getAccountPromise();
    this.accountPromise
    .then((data) => {      
      console.log('promise account', data);
      this.setAccountItems(data);
      // Insert all functions related to Account Data
      // ----- End ----- //
    })
    .catch((error) => {
      console.log('promise err', error);
    });
  }

  ngOnInit() {
     // Needed in User Role
     this.getAccountData();
     // --------- //
    this.getUrlParams();
    this.myAccountId = localStorage.getItem('accountId');
    this.listEmpty = false;
    if (this.router.url.includes('business-partners')) {
      this.getIndustryList();
    }
    this.checkEmpty();
    this.checkDataChange();
    this.checkFeedType();
    this.getPartnersList();
    this.getDistributorList();
    this.router.url.includes(`device-monitoring/${this.deviceId}`) ? this.isDeviceSingle = true : this.isDeviceSingle = false;
    if (this.router.url.includes('business-partners/branch')) {
      this.getDevicesList();
    }
    // Get Latest for Added by
    if (['partners', 'branches', 'industry', 'admin-accounts', 'user-admin-role', 'device-monitoring'].includes(this.feedType)) {
      this.getAccountsList();
    }
    this.getModulesList();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BusinessService } from 'src/app/core/services/business.service';
import { UserRoleAddEditFormComponent } from '../user-role-add-edit-form/user-role-add-edit-form.component';
import { UserRoleViewModalComponent } from '../user-role-view-modal/user-role-view-modal.component';

@Component({
  selector: 'app-user-role-listing',
  templateUrl: './user-role-listing.component.html',
  styleUrls: ['./user-role-listing.component.scss']
})
export class UserRoleListingComponent implements OnInit, OnDestroy {

  userRoleList: Array<any>;
  subscriptions = new Subscription();

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private businessService: BusinessService
  ) { }

  getUserRoleList() {
    this.subscriptions.add(this.businessService.dataLists$.subscribe(
      data => {
        console.log('userRoleList', data.data);
        if (data) {
          this.userRoleList = data.data;
        }
      }, error => {
        console.log('error', error);
      }
    ));
  }

  addUserRole() {
    this.modalService.open(UserRoleAddEditFormComponent, {
      centered: true
    });
  }

  viewDetails($event) {
    console.log('viewDetails', $event);
    const modalRef = this.modalService.open(UserRoleViewModalComponent, {
      centered: true
    });
    modalRef.componentInstance.modulesData = $event;
  }

  ngOnInit() {
    this.getUserRoleList();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }


}

import { Component, OnInit, Input } from '@angular/core';
import { BusinessService } from 'src/app/core/services/business.service';

@Component({
  selector: 'app-category-sidenav',
  templateUrl: './category-sidenav.component.html',
  styleUrls: ['./category-sidenav.component.scss']
})
export class CategorySidenavComponent implements OnInit {
  @Input() categoryList: any;
  selected: number;

  constructor(
    private businessService: BusinessService
  ) { }

  selectCategory(id) {
    this.businessService.publishSelectedCategory$(id);
  }

  ngOnInit() {
    this.selected = 0;
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { BusinessService } from 'src/app/core/services/business.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';

@Component({
  selector: 'app-logs-page',
  templateUrl: './logs-page.component.html',
  styleUrls: ['./logs-page.component.scss']
})
export class LogsPageComponent implements OnInit, OnDestroy {
  dataLists: Array<any>;
  backTitle: string;
  backTitle2: string;
  logsType: string;
  categoryId: string;
  queueGroupId: string;
  partnerId: string;
  distributorId: string;
  customerId: string;
  partnerData: any;
  deviceId: string;
  subTitle: string;
  subscriptions = new Subscription();

  constructor(
    private businessService: BusinessService,
    private modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private httpAdminService: HttpWebAdminService
  ) { }

  getUrlParams() {
    this.subscriptions.add(this.activatedRoute.params.subscribe(params => {
      this.categoryId = params['categoryId'];
      this.queueGroupId = params['queueGroupId'];
      this.partnerId = params['partnerId'];
      this.distributorId = params['distributorId'];
      this.customerId = params['customerId'];
      this.deviceId = params['deviceId'];
      this.getRootPage();
      if (['partners-single'].includes(this.logsType)) {
        this.getPartnerData();
      }
    }));
  }

  getPartnerData() {
    this.httpAdminService.get$(`partners/${this.partnerId}`).subscribe(
      data => {
        console.log('partner data', data);
        this.partnerData = data.data;
        this.backTitle2 = this.partnerData.name;
      }, error => {
        console.log('error', error);
      }
    );
  }

  getDataList() {
    this.subscriptions.add(this.businessService.dataLists$.subscribe(
      data => {
        console.log('activity logs', data);
        if (data) {
          if (this.router.url.includes('device-monitoring/activity-logs')) {
            this.dataLists = data.data.filter(element => element.actionType === 1);
            console.log('results for device monitoring activity logs', this.dataLists);
          } else {
            this.dataLists = data.data;
            console.log('results for activity logs', this.dataLists);
          }
        }
      }, error => {
        console.log('error', error);
      }
    ));
  }

  backToRootPage() {
    let url;
    switch (this.logsType) {
      case 'messages' : {
        url = '/announcements/messages';
      }
      break;
      case 'news' : {
        url = '/announcements/news';
      }
      break;
      case 'partners' : {
        url = '/business-partners/all';
      }
      break;
      case 'partners-single' : {
        url = `/business-partners/all`;
      }
      break;
      case 'distributor' : {
        url = `/distributors/all`;
      }
      break;
      case 'customer' : {
        url = `/customers/customer-listing`;
      }
      break;
      case 'customer-app-setting' : {
        url = `/settings/customer-app-setting`;
      }
      break;
      case 'industry-setting' : {
        url = `/settings/industry-setting/industry`;
      }
      break;
      case 'device-monitoring' : {
        url = `/device-monitoring/all`;
      }
      break;
      case 'admin-account-setting' : {
        url = `/settings/admin-account-setting/admin-account`;
      }
      break;
      case 'user-role-setting' : {
        url = `/settings/user-admin-role-setting/user-list`;
      }
      break;
    }
    console.log('url', url);
    this.router.navigate([url]);
  }

  backToRootPage2() {
    let url;
    switch (this.logsType) {
      case 'partners-single' : {
        url = `/business-partners/${this.partnerId}`;
      }
      break;
    }
    console.log('url', url);
    this.router.navigate([url]);
  }

  getRootPage() {
    if (this.router.url.includes('messages')) {
      this.logsType = 'messages';
      this.backTitle = 'Message List';
      this.subTitle = 'Events  and messages tracker';
    } else if (this.router.url.includes('news')) {
      this.logsType = 'news';
      this.backTitle = 'News List';
      this.subTitle = 'News posting and activity tracker';
    } else if (this.router.url.includes('/business-partners/activity-logs')) {
      this.logsType = 'partners';
      this.backTitle = 'Business Partners';
      this.subTitle = 'Partner merchants activity tracker';
    } else if (this.router.url.includes(`/business-partners/${this.partnerId}/activity-logs`)) {
      this.logsType = 'partners-single';
      this.backTitle = 'Business Partners';
    } else if (this.router.url.includes('distributors')) {
      this.logsType = 'distributor';
      this.backTitle = 'Distributor List';
      this.subTitle = 'Distributor activity tracker';
    } else if (this.router.url.includes('customers')) {
      this.logsType = 'customer';
      this.backTitle = 'Customers List';
    } else if (this.router.url.includes('customer-app-setting')) {
      this.logsType = 'customer-app-setting';
      this.backTitle = 'Customer App Settings';
    } else if (this.router.url.includes('industry-setting')) {
      this.logsType = 'industry-setting';
      this.backTitle = 'Industry Settings';
    } else if (this.router.url.includes('device-monitoring/activity-logs')) {
      this.logsType = 'device-monitoring';
      this.backTitle = 'Device List';
      this.subTitle = 'Device information and activity tracker';
    } else if (this.router.url.includes('admin-account-setting')) {
      this.logsType = 'admin-account-setting';
      this.backTitle = 'Admin Accounts';
    } else if (this.router.url.includes('user-admin-role-setting')) {
      this.logsType = 'user-role-setting';
      this.backTitle = 'User Role Settings';
    }
  }

  ngOnInit() {
    this.getUrlParams();
    this.getDataList();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

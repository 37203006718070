import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective, FormBuilder, Validators } from '@angular/forms';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { BusinessService } from 'src/app/core/services/business.service';

@Component({
  selector: 'app-main-branch-custom-branding',
  templateUrl: './main-branch-custom-branding.component.html',
  styleUrls: ['./main-branch-custom-branding.component.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }]
})
export class MainBranchCustomBrandingComponent implements OnInit {
  @Input() branchData: any;
  templateList: any[];

  constructor(
    private fb: FormBuilder,
    private parent: FormGroupDirective,
    private httpAdminService: HttpWebAdminService,
    private businessService: BusinessService
  ) { }

  branding = this.fb.group({
    status: [false],
    templateId: [null]
  });

  get f() { return this.branding.controls; }

  getTemplateList() {
    this.httpAdminService.get$(`custom-brand-templates`).subscribe(
      data => {
        console.log('template list', data);
        this.templateList = data.result.data;
        console.log('templateList', this.templateList);
        if (this.branchData) {
          this.changeHasTemplate(this.branchData.settings.customBrandTemplate.status);
        }
      }, error => {
        console.log('error', error);
      }
    );
  }

  changeHasTemplate($event) {
    console.log($event);
    const templateIds = this.templateList.length > 0 ? this.templateList.map(element => element._id) : [];
    if (templateIds.length > 0 && !this.branchData.settings.customBrandTemplate.templateId) {
      this.f['templateId'].patchValue(this.templateList[0]._id);
    } else if (templateIds.length === 0) {
      this.f['templateId'].patchValue(null);
    } else {
      this.f['templateId'].patchValue(this.branchData.settings.customBrandTemplate.templateId);
    }

    if ($event) {
      this.f['templateId'].setValidators([Validators.required]);
      this.f['templateId'].updateValueAndValidity();
    } else {
      this.f['templateId'].clearValidators();
      this.f['templateId'].updateValueAndValidity();
    }
  }

  enableEdit() {
    this.businessService.publishEnableEdit$(true);
  }

  patchValue() {
    this.branding.patchValue({
      status: this.branchData.settings.customBrandTemplate.status,
      templateId: null
    });
  }

  ngOnInit() {
    this.getTemplateList();
    if (this.branchData) {
      this.patchValue();
    }
    this.parent.form.addControl(
      'customBrandTemplate', this.branding
    );
  }

}

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BusinessService } from '../../services/business.service';
import { Subscription } from 'rxjs';
import { HttpService } from '../../http/http.service';
import { UserRolePermissionsComponent } from 'src/app/shared/components/user-role-permissions/user-role-permissions.component';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent extends UserRolePermissionsComponent implements OnInit, OnDestroy {
  collapsed: any;
  @Input() isCollapse: boolean;
  isPermanentCollapse: boolean;
  partnerId: string;
  branchId: string;

  constructor(
    public router: Router,
    businessService: BusinessService
  ) {
    super(businessService)
  }

  toggle(event) {
    event !== this.collapsed ? this.collapsed = event : this.collapsed = undefined;
  }

  listenSideNav() {
    this.subscriptions.add(this.businessService.permanentSideNav$.subscribe(res => {
      console.log('permanent sidenav', res);
      this.isPermanentCollapse = res;
    }));
  }

  mouseOverSideNav() {
    console.log(this.isPermanentCollapse);
    if (this.isPermanentCollapse === true) {
      this.isCollapse = false;
      this.businessService.publishSideNav$(false);
    }
  }

  mouseOutSideNav() {
    if (this.isPermanentCollapse === true) {
      this.isCollapse = true;
      this.businessService.publishSideNav$(true);
    }
  }

  getAccountData() {
    this.getAccountPromise();
    this.accountPromise
    .then((data) => {      
      console.log('promise account', data);
      this.setAccountItems(data);
      // Insert all functions related to Account Data
      // ----- End ----- //
    })
    .catch((error) => {
      console.log('promise err', error);
    });
  }

  ngOnInit() {
    // Needed in User Role
    this.getAccountData();
    // --------- //
    this.listenSideNav();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

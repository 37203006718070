import { Component, OnInit, HostListener, OnDestroy, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';
import { Subscription } from 'rxjs';
import { BusinessService } from 'src/app/core/services/business.service';
import { HttpService } from 'src/app/core/http/http.service';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';

@Component({
  selector: 'app-manager-add-edit-form',
  templateUrl: './manager-add-edit-form.component.html',
  styleUrls: ['./manager-add-edit-form.component.scss']
})
export class ManagerAddEditFormComponent implements OnInit, OnDestroy {
  title: string;
  avatarUrl: string;
  imgUrl: any;
  thumb01: string;
  branchId: string;
  submitting: boolean;
  errorMsg: string;
  managerData: any;
  selectedAvatar: File;
  enableSubmit: boolean;
  openLeaveModal: boolean;
  subscriptions = new Subscription();
  roleTypes: Array<any>;
  enabledEmail: boolean;
  verificationSent: boolean;
  verificationFailed: boolean;
  type: any;
  distributorData: any;

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private businessService: BusinessService,
    private httpService: HttpService,
    private httpAdminService: HttpWebAdminService
  ) { }

  managerForm = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9\_\-\.]+\@[a-zA-Z0-9]{1,12}(\.[a-zA-Z0-9]{1,7}){1,3}$/)]],
    mobileNo:  ['(+63)']
  });

  get f() {return this.managerForm.controls; }

  onMobileKeyUp(contact) {
    const defaultVal = '(+63)';
    if (contact.value.length <= 3 ||
      contact.value.length === '' ||
      contact.value.substring(0, 5) !== defaultVal) {
      contact.setValue(defaultVal);
    }
  }

  patchMobile(contact) {
    const number1 = contact.substring(0, 3);
    const number2 = contact.substring(3, 6);
    const number3 = contact.substring(6, 11);
    return `(+63) ${number1} - ${number2} - ${number3}`;
  }

  transformMobile(contact) {
    return contact.slice(6, 22).replace(/\D/g, '');
  }

  onAvatarChange($event) {
    this.selectedAvatar = $event.target.files[0] as File;
    if ($event.target.files && $event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = e => this.imgUrl = reader.result;
      reader.readAsDataURL(this.selectedAvatar);
    }
    this.enableSubmit = true;
  }

  @HostListener('body:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    console.log(event);
    event.preventDefault();
    if (!this.openLeaveModal) {
      this.closeModal();
    }
    this.subscriptions.add(
      this.businessService.navigateClose$.subscribe(res => {
        console.log('res', res);
        this.openLeaveModal = false;
      })
    );
  }

  closeModal() {
    this.activeModal.close();
  }

  enableEmail($event) {
    console.log(this.enabledEmail);
    this.enabledEmail = !this.enabledEmail;
    this.enabledEmail ? this.f['email'].disable() : this.f['email'].enable();
  }

  resetForm() {
    this.subscriptions.add(this.businessService.resetForm$.subscribe(res => {
      if (res === true) {
        this.managerForm.reset();
        this.managerForm.get('contactNo').patchValue('(+63)');
      }
    }));
  }

  ngOnInit() {
    this.branchId = localStorage.getItem('branchId');
    this.resetForm();
    if (this.managerData) {
      // this.patchValue();
      this.imgUrl = this.managerData.avatarUrl ? this.managerData.avatarUrl : './assets/images/koala.jpg';
    } else {
      this.imgUrl = './assets/images/koala.jpg';
    }
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

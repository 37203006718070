import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BusinessService } from 'src/app/core/services/business.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';

@Component({
  selector: 'app-discover-add-edit-form',
  templateUrl: './discover-add-edit-form.component.html',
  styleUrls: ['./discover-add-edit-form.component.scss']
})
export class DiscoverAddEditFormComponent implements OnInit {
  title: string;
  internalBusinessList: Array<any>;
  businessList: Array<any>;
  selectedPage: number;
  startItem: number;
  selectedLimit: number;
  totalItem: number;
  index: number;
  businessId: string;
  businessData: any;
  errorMsg: string;
  discoverList: Array<any>;
  showList: boolean;
  subscriptions = new Subscription();

  constructor(
    private businessService: BusinessService,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private httpAdminService: HttpWebAdminService
  ) { }

  businessForm = this.fb.group({
    business: [''],
  });

  getBusinessList() {
    console.log('discover list', this.discoverList);
    let existingIds;
    if (this.discoverList) {
      existingIds = this.discoverList.map(element => element._id);
    }
    this.businessService.dataLists$.subscribe(
      data => {
        console.log('data', data);
        if (data) {
          this.showList = true;
          if (existingIds) {
            this.internalBusinessList = data.data.filter(element => !existingIds.includes(element._id));
          } else {
            this.internalBusinessList = data.data;
          }
        }
      }, error => {
        console.log('error', error);
        this.showList = true;
        this.errorMsg = `<b>Error:</b> Something went wrong.`;
      }
    );
  }

  patchValue() {
    this.businessForm.patchValue({
      business: this.businessData
    });
    console.log('stringify', this.businessData);
  }

  stringify(json) {
    return JSON.stringify(json);
  }

  submitForm(f) {
    this.activeModal.dismiss();
    console.log('form', f.getRawValue());
    const parsedForm = JSON.parse(f.value.business);
    console.log('parsedForm', parsedForm);
    this.businessService.publishDiscoverList$({
      business: parsedForm,
      i: this.index
    });
  }

  ngOnInit() {
    this.internalBusinessList = [];
    this.getBusinessList();
    if (this.businessData) {
      this.patchValue();
    }
  }

}

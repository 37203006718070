import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-chart-donut',
  templateUrl: './chart-donut.component.html',
  styleUrls: ['./chart-donut.component.scss']
})
export class ChartDonutComponent implements OnInit, OnChanges {
  donutChartData: any;
  donutChartLabels: any;
  donutChartColors: any;
  donutChartOptions: any;
  donutCenterText: any;
  donutChartText: any;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() info1: any;
  @Input() info2: any;
  @Input() info3: any;
  @Input() color1: any;
  @Input() color2: any;
  @Input() color3: any;
  @Input() label1: any;
  @Input() label2: any;
  @Input() label3: any;
  @Input() sub1: any;
  @Input() sub2: any;
  @Input() sub3: any;

  constructor() { }

  getPercentage(num) {
    return (Math.round(num / (this.info1 + this.info2)) * 100);
  }

  getChartValue() {
    console.log('info', this.info1, this.info2);
    this.donutChartLabels = ['', ''];
    this.donutChartData = [this.info1, this.info2, this.info3];
    if ((this.info1 === 0 && this.info2 === 0) || (this.info1 === 0 && this.info2 === 0 && this.info3 === 0)) {
      this.donutChartData = [1, 0, 0];
    }
    if (this.info1 > 0 && this.info2 <= 0 && this.info3 <= 0) {
      // if info1 has the only one has value
      this.donutChartColors = [{ backgroundColor: [this.color1, this.color1, this.color1] }];
    } else if (this.info1 <= 0 && this.info2 > 0 && this.info3 <= 0) {
      // if info2 has the only one has value
      this.donutChartColors = [{ backgroundColor: [this.color2, this.color2, this.color2] }];
    } else if (this.info1 <= 0 && this.info2 <= 0 && this.info3 > 0) {
      // if info3 has the only one has value
      this.donutChartColors = [{ backgroundColor: [this.color3, this.color3, this.color3] }];
    } else if (this.info1 > 0 || this.info2 > 0 || this.info3 > 0) {
      // if all has value
      this.donutChartColors = [{ backgroundColor: [this.color1, this.color2, this.color3] }];
    } else if ((this.info1 <= 0 && this.info2 <= 0) || (this.info1 <= 0 && this.info2 <= 0 && this.info3 <= 0)) {
      this.donutChartColors = [{ backgroundColor: ['#D1D0DA', '#D1D0DA', '#D1D0DA']}];
    }

    this.donutChartOptions = {
      legend: {position: 'bottom'},
      tooltips: {
        enabled: false
      }
    };
  }

  mathRound(numberr) {
    return Math.round(numberr);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('changes', changes);
    if (changes.info1.currentValue !== undefined) {
      this.getChartValue();
    }
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { BusinessService } from 'src/app/core/services/business.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { SocketService } from 'src/app/core/http/socket.service';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-main',
  templateUrl: './dashboard-main.component.html',
  styleUrls: ['./dashboard-main.component.scss']
})
export class DashboardMainComponent implements OnInit {
  queuesToday: any;
  smsToday: any;
  queueRate: any;
  monthlyRevenue: any;
  customerAppUsers: any;
  topBranches: any;
  lineTitle: string;
  lineSubtitle: string;
  serviceType: number;
  queueLineChart: any;
  queueWalkin = 0;
  queueOnline = 0;
  donutTitle: string;
  donutSubtitle: string;
  avatarUrl: any;
  errorMsg: any;
  smsProvidersList: Array<any>;
  newUpdate: BehaviorSubject<any>;
  subscriptions = new Subscription();

  constructor(
    private httpAdminService: HttpWebAdminService,
    private businessService: BusinessService,
    public wsService: SocketService,
    private authService: AuthService
  ) { }

  getQueuesToday() {
    this.httpAdminService.get$(`/queues/admin-dashboard/queue-counts-today`).subscribe(data => {
      console.log('queues today', data);
      this.queuesToday = data;
    }, error => {
      // this.businessService.publishAlert$({
      //   type: 'red-dark-01',
      //   icon: 'fas fa-ban',
      //   message: `<b>Error:</b> Something went wrong.`
      // });
    });
  }

  getSmsCounts() {
    this.httpAdminService.get$(`/mailer/admin-dashboard/sms-count-today`).subscribe(data => {
      console.log('sms counts', data);
      this.smsToday = data;
    }, error => {
      // this.businessService.publishAlert$({
      //   type: 'red-dark-01',
      //   icon: 'fas fa-ban',
      //   message: `<b>Error:</b> Something went wrong.`
      // });
    });
  }

  getSMSProviders() {
    this.smsProvidersList = [];
  }

  getQueueRate() {
    this.httpAdminService.get$(`/queues/admin-dashboard/queue-rate`).subscribe(data => {
      console.log('queue Rate', data);
      this.queueRate = data;
    }, error => {
      // this.businessService.publishAlert$({
      //   type: 'red-dark-01',
      //   icon: 'fas fa-ban',
      //   message: `<b>Error:</b> Something went wrong.`
      // });
    });
  }

  getMonthlyRevenue() {
    this.httpAdminService.get$(`/customer/user/admin-dashboard`).subscribe(data => {
      console.log('queue Rate', data);
      this.monthlyRevenue = data;
    }, error => {
      // this.businessService.publishAlert$({
      //   type: 'red-dark-01',
      //   icon: 'fas fa-ban',
      //   message: `<b>Error:</b> Something went wrong.`
      // });
    });
  }

  getCustomerAppUsers() {
    this.httpAdminService.get$(`/customer/user/admin-dashboard`).subscribe(data => {
      console.log('customer app user', data);
      this.customerAppUsers = data;
    }, error => {
      // this.businessService.publishAlert$({
      //   type: 'red-dark-01',
      //   icon: 'fas fa-ban',
      //   message: `<b>Error:</b> Something went wrong.`
      // });
    });
  }

  getTopBranches() {
    this.httpAdminService.get$(`/queues/admin-dashboard/branch/top`).subscribe(data => {
      console.log('top branches', data);
      this.topBranches = data;
    }, error => {
      // this.businessService.publishAlert$({
      //   type: 'red-dark-01',
      //   icon: 'fas fa-ban',
      //   message: `<b>Error:</b> Something went wrong.`
      // });
      console.log('error', error);
    });
  }

  getServicesType($event) {
    console.log($event);
    this.serviceType = $event;
    this.getLineChart(this.serviceType);
  }

  getLineChart(filter) {
    console.log('filter', filter);
    let param;
    if (filter === 1) {
      param = `/queues/admin-dashboard/weekly-queue-counts?week=0`;
    } else if (filter === 2) {
      param = `/queues/admin-dashboard/weekly-queue-counts?week=-1`;
    } else if (filter === 3) {
      param = `/queues/admin-dashboard/month-queue-counts`;
    } else {
      param = `/queues/admin-dashboard/month-queue-counts?month=${filter}`;
    }
    this.httpAdminService.get$(param).subscribe(data => {
      console.log('Line chart', data);
      this.queueLineChart = data;
    }, error => {
      // this.businessService.publishAlert$({
      //   type: 'red-dark-01',
      //   icon: 'fas fa-ban',
      //   message: `<b>Error:</b> Something went wrong.`
      // });
    });
  }

  removeNegative(rate) {
    return Math.abs(rate);
  }

  checkNewUpdate() {
    this.newUpdate = this.wsService
    .connect('UPDATE_DASHBOARD_QUEUE_COUNTS')
    .pipe(
      map((response: any): any => {
        return response;
      })
    ) as BehaviorSubject<any>;
    this.subscriptions.add(this.newUpdate.subscribe(msg => {
      console.log('newUpdate dashboard', msg);
      // call get functions
      this.getQueuesToday();
      this.getSmsCounts();
      this.getQueueRate();
      this.getCustomerAppUsers();
      this.getTopBranches();
    }));
  }

  ngOnInit() {
    this.getQueuesToday();
    this.getSmsCounts();
    this.getQueueRate();
    this.getCustomerAppUsers();
    this.getTopBranches();
    this.checkNewUpdate();
    this.lineSubtitle = 'this will be changed to page visits soon of we have SEO';
    this.donutTitle = 'Subscription Payment Status';
    this.donutSubtitle = 'Subscribers';
    this.avatarUrl = './assets/images/koala.jpg';
  }

}

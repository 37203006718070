import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BusinessService } from 'src/app/core/services/business.service';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-distributor-account-page',
  templateUrl: './distributor-account-page.component.html',
  styleUrls: ['./distributor-account-page.component.scss']
})
export class DistributorAccountPageComponent implements OnInit, OnDestroy {
  accountData: any;
  distributorData: any;
  imgUrl: any;
  imgAvatar: any;
  imgBanner: any;
  imgBranch: any;
  imgMascot: any;
  dataLists: Array<any>;
  accountId: string;
  distributorId: string;
  subscriptions = new Subscription();

  constructor(
    private businessService: BusinessService,
    private httpAdminService: HttpWebAdminService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private location: Location
  ) { }

  getUrlParams() {
    this.subscriptions.add(this.activatedRoute.params.subscribe(params => {
      this.accountId = params['accountId'];
      this.distributorId = params['distributorId'];
      console.log('this.accountId', this.accountId);
      console.log('this.distributorId', this.distributorId);
      this.getAccountData();
      this.getDistributorData();
    }));
  }

  getAccountData() {
    this.httpAdminService.get$(`distributor/${this.distributorId}/accounts/${this.accountId}`).subscribe(
      data => {
        console.log('accounts data', data);
        this.accountData = data;
        this.imgUrl = this.accountData.avatarUrl ? this.accountData.avatarUrl : './assets/images/koala.jpg';
      }, error => {
        console.log('error', error);
      }
    );
  }

  getDistributorData() {
    this.httpAdminService.get$(`distributor/${this.distributorId}`).subscribe(
      data => {
        console.log('distributor data', data);
        this.distributorData = data;
      }, error => {
        console.log('error', error);
      }
    );
  }

  checkDataChange() {
    this.subscriptions.add(this.businessService.dataChange$.subscribe(res => {
      if (res) {
        this.getDistributorData();
        this.getAccountData();
      }
    }));
  }

  goBack() {
    this.location.back();
  }

  ngOnInit() {
    this.checkDataChange();
    this.getUrlParams();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

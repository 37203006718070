import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective, FormBuilder, Validators } from '@angular/forms';
import { BusinessService } from 'src/app/core/services/business.service';

@Component({
  selector: 'app-template-name-color-form',
  templateUrl: './template-name-color-form.component.html',
  styleUrls: ['./template-name-color-form.component.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }]
})
export class TemplateNameColorFormComponent implements OnInit {
  @Input() templateData: any;
  primaryColor: string;
  secondaryColor: string;
  primaryText: string;
  secondaryText: string;

  constructor(
    private fb: FormBuilder,
    private parent: FormGroupDirective,
    private businessService: BusinessService
  ) { }

  nameColorForm = this.fb.group({
    name: [null, Validators.required],
    primary: ['#FC9612', Validators.required],
    secondary: ['#262A30', Validators.required],
    primaryText: ['#FFFFFF', Validators.required],
    secondaryText: ['#FFFFFF', Validators.required]
  });

  get f() { return this.nameColorForm.controls; }

  changePrimaryColor($event) {
    console.log($event);
    this.primaryColor = $event;
    this.nameColorForm.controls.primary.patchValue(this.primaryColor);
    this.businessService.publishPrimaryColor$(this.primaryColor);
    this.businessService.publishEnableEdit$(true);
  }

  changeSecondaryColor($event) {
    console.log($event);
    this.secondaryColor = $event;
    this.nameColorForm.controls.secondary.patchValue(this.secondaryColor);
    this.businessService.publishSecondaryColor$(this.secondaryColor);
    this.businessService.publishEnableEdit$(true);
  }

  changePrimaryText($event) {
    console.log($event);
    this.primaryText = $event;
    this.nameColorForm.controls.primaryText.patchValue(this.primaryText);
    this.businessService.publishPrimaryText$(this.primaryText);
    this.businessService.publishEnableEdit$(true);
  }

  changeSecondaryText($event) {
    console.log($event);
    this.secondaryText = $event;
    this.nameColorForm.controls.secondaryText.patchValue(this.secondaryText);
    this.businessService.publishSecondaryText$(this.secondaryText);
    this.businessService.publishEnableEdit$(true);
  }

  patchValue() {
    const primaryColor = this.templateData.colorTheme.primary;
    const secondaryColor = this.templateData.colorTheme.secondary;
    const primaryTextr = this.templateData.colorTheme.primaryText;
    const secondaryTextr = this.templateData.colorTheme.secondaryText;
    this.nameColorForm.patchValue({
      name: this.templateData.name,
      primary: primaryColor,
      secondary: secondaryColor,
      primaryText: primaryTextr,
      secondaryText: secondaryTextr
    });
    this.primaryColor = primaryColor;
    this.secondaryColor = secondaryColor;
    this.primaryText = primaryTextr;
    this.secondaryText = secondaryTextr;
    this.nameColorForm.controls.primary.patchValue(primaryColor);
    this.nameColorForm.controls.secondary.patchValue(secondaryColor);
    this.nameColorForm.controls.primaryText.patchValue(primaryTextr);
    this.nameColorForm.controls.secondaryText.patchValue(secondaryTextr);
    this.businessService.publishPrimaryColor$(primaryColor);
    this.businessService.publishSecondaryColor$(secondaryColor);
    this.businessService.publishPrimaryText$(primaryTextr);
    this.businessService.publishSecondaryText$(secondaryTextr);
  }

  ngOnInit() {
    this.primaryColor = '#FC9612';
    this.secondaryColor = '#262A30';
    this.primaryText = '#FFFFFF';
    this.secondaryText = '#FFFFFF';
    this.parent.form.addControl(
      'nameColor', this.nameColorForm
    );
    if (this.templateData) {
      console.log('patchvalueeeeeee', this.templateData);
      this.patchValue();
    }
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { BusinessService } from 'src/app/core/services/business.service';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';
import { DeviceAddEditFormComponent } from '../../components/device-add-edit-form/device-add-edit-form.component';
import { UserRolePermissionsComponent } from 'src/app/shared/components/user-role-permissions/user-role-permissions.component';

@Component({
  selector: 'app-device-single-page',
  templateUrl: './device-single-page.component.html',
  styleUrls: ['./device-single-page.component.scss']
})
export class DeviceSinglePageComponent extends UserRolePermissionsComponent implements OnInit, OnDestroy {
  deviceData: any;
  imgUrl: any;
  imgAvatar: any;
  imgBanner: any;
  imgBranch: any;
  imgMascot: any;
  dataLists: Array<any>;
  deviceId: string;
  selected: number;
  selectedList: any;

  constructor(
    businessService: BusinessService,
    private httpAdminService: HttpWebAdminService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal
  ) {
    super(businessService)
  }

  getUrlParams() {
    this.subscriptions.add(this.activatedRoute.params.subscribe(params => {
      this.deviceId = params['deviceId'];
      console.log('this.deviceId', this.deviceId);
      this.getDeviceData();
    }));
  }

  getDeviceData() {
    this.httpAdminService.get$(`devices/${this.deviceId}`).subscribe(
      data => {
        console.log('devices data', data);
        this.deviceData = data;
        this.imgUrl = this.deviceData.avatarUrl ? this.deviceData.avatarUrl : './assets/images/koala.jpg';
      }, error => {
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Error:</b> Something went wrong.`
        });
      }
    );
  }

  getDataList() {
    this.subscriptions.add(this.businessService.dataLists$.subscribe(
      data => {
        if (data) {
          // this.dataLists = data.data.filter(element => element.actionType === 4 || element.actionType === 5 || ((element.actionType === 2 && element.eventSummary.includes('pulled-out') || element.actionType === 2 && element.eventSummary.includes('updated'))));
          // console.log('activity logs', this.dataLists);
          this.dataLists = data.data;
        }
      }, error => {
        console.log('error', error);
      }
    ));
  }

  pullOutDevice() {
    const modalRef = this.modalService.open(ModalConfirmComponent, {
      centered: true
    });
    modalRef.componentInstance.type = 'pullout-device';
    modalRef.componentInstance.adminTitle = 'Pull Out Device';
    modalRef.componentInstance.bodyText = `Do you want to pull out ${this.deviceData.refId}?`;
    modalRef.componentInstance.primaryButton = 'Confirm';
    modalRef.componentInstance.deviceData = this.deviceData;
  }

  suspendDevice() {
    const modalRef = this.modalService.open(ModalConfirmComponent, {
      centered: true
    });
    modalRef.componentInstance.type = 'suspend-device';
    modalRef.componentInstance.adminTitle = 'Suspend Device';
    modalRef.componentInstance.bodyText = `Are you sure you want to suspend ${this.deviceData.refId}?`;
    modalRef.componentInstance.primaryButton = 'Confirm';
    modalRef.componentInstance.deviceData = this.deviceData;
  }

  unsuspendDevice() {
    this.httpAdminService.patch$(`/devices/${this.deviceData._id}/suspend-status`, {status: false}).subscribe(
      data => {
        this.businessService.publishAlert$({
          type: 'green-dark-01',
          icon: 'fas fa-check',
          message: `<b>${this.deviceData.refId}</b> has been Unsuspended!`
        });
        this.getDeviceData();
      }, error => {
        console.log('error');
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: error.error.source
        });
      }
    );
  }

  checkDataChange() {
    this.subscriptions.add(this.businessService.dataChange$.subscribe(res => {
      if (res) {
        this.getDeviceData();
      }
    }));
  }

  checkAlert() {
    this.subscriptions.add(this.businessService.alert$.subscribe(
      res => {
        if (res) {
          if (!res.message.includes(`<b>Error:</b> Something went wrong.`)) {
            this.getDeviceData();
          }
        }
      }
    ));
  }

  editDevice() {
    const modalRef = this.modalService.open(DeviceAddEditFormComponent, {
      centered: true
    });
    modalRef.componentInstance.title = 'Edit Device';
    modalRef.componentInstance.deviceData = this.deviceData;
  }

  getAccountData() {
    this.getAccountPromise();
    this.accountPromise
    .then((data) => {      
      console.log('promise account', data);
      this.setAccountItems(data);
      // Insert all functions related to Account Data
      // ----- End ----- //
    })
    .catch((error) => {
      console.log('promise err', error);
    });
  }

  ngOnInit() {
    // Needed in User Role
    this.getAccountData();
    // --------- //
    this.checkDataChange();
    this.getUrlParams();
    this.getDataList();
    this.checkAlert();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

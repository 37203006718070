import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ConfirmPasswordValidator {

  static MatchPassword(control: AbstractControl) {
    const password = control.get('password').value;
    const confirmPassword = control.get('confirmPwd').value;
    if (password !== confirmPassword) {
    control.get('confirmPwd').setErrors( {ConfirmPassword: true} );
    }
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BusinessPartnersPageComponent } from './modules/business-partners/pages/business-partners-page/business-partners-page.component';
import { LoginPageComponent } from './modules/others/pages/login-page/login-page.component';
import { ForgotPasswordPageComponent } from './modules/others/pages/forgot-password-page/forgot-password-page.component';
import { PasswordPageComponent } from './modules/others/pages/password-page/password-page.component';
import { PasswordFailedPageComponent } from './modules/others/pages/password-failed-page/password-failed-page.component';
import { PasswordSuccessPageComponent } from './modules/others/pages/password-success-page/password-success-page.component';
import { NotFoundPageComponent } from './modules/others/pages/not-found-page/not-found-page.component';
import { DefaultPageComponent } from './shared/components/default-page/default-page.component';
import { BusinessPartnersSinglePageComponent } from './modules/business-partners/pages/business-partners-single-page/business-partners-single-page.component';
import { BranchesAddEditFormComponent } from './modules/business-partners/components/branches-add-edit-form/branches-add-edit-form.component';
import { BranchesSinglePageComponent } from './modules/business-partners/pages/branches-single-page/branches-single-page.component';
import { AccountsPageComponent } from './modules/settings/pages/accounts-page/accounts-page.component';
import { AccountsSinglePageComponent } from './modules/settings/pages/accounts-single-page/accounts-single-page.component';
import { AccountsListingComponent } from './modules/settings/components/accounts-listing/accounts-listing.component';
import { IndustryAddEditFormComponent } from './modules/settings/components/industry-add-edit-form/industry-add-edit-form.component';
import { IndustryListingComponent } from './modules/settings/components/industry-listing/industry-listing.component';
import { IndustryPageComponent } from './modules/settings/pages/industry-page/industry-page.component';
import { IndustySinglePageComponent } from './modules/settings/pages/industy-single-page/industy-single-page.component';
import { CustomerAppPageComponent } from './modules/settings/pages/customer-app-page/customer-app-page.component';
import { CustomerAppSingleComponent } from './modules/settings/pages/customer-app-single/customer-app-single.component';
import { CustomerAppAddFormComponent } from './modules/settings/components/customer-app-add-form/customer-app-add-form.component';
import { CustomerListPageComponent } from './modules/customers/pages/customer-list-page/customer-list-page.component';
import { CustomerSinglePageComponent } from './modules/customers/pages/customer-single-page/customer-single-page.component';
import { DistributorSinglePageComponent } from './modules/distributor/pages/distributor-single-page/distributor-single-page.component';
import { DistributorAddEditFormComponent } from './modules/distributor/components/distributor-add-edit-form/distributor-add-edit-form.component';
import { DistributorPageComponent } from './modules/distributor/pages/distributor-page/distributor-page.component';
import { DistributorAccountPageComponent } from './modules/distributor/pages/distributor-account-page/distributor-account-page.component';
import { DeviceMonitoringPageComponent } from './modules/device-monitoring/pages/device-monitoring-page/device-monitoring-page.component';
import { DeviceAddEditFormComponent } from './modules/device-monitoring/components/device-add-edit-form/device-add-edit-form.component';
import { DeviceSinglePageComponent } from './modules/device-monitoring/pages/device-single-page/device-single-page.component';
import { NewsPageComponent } from './modules/announcement/pages/news-page/news-page.component';
import { NewsSinglePageComponent } from './modules/announcement/pages/news-single-page/news-single-page.component';
import { MessagesPageComponent } from './modules/announcement/pages/messages-page/messages-page.component';
import { MessagesSinglePageComponent } from './modules/announcement/pages/messages-single-page/messages-single-page.component';
import { MessagesAddEditFormComponent } from './modules/announcement/components/messages-add-edit-form/messages-add-edit-form.component';
import { NewsAddEditFormComponent } from './modules/announcement/components/news-add-edit-form/news-add-edit-form.component';
import { ManagerListPageComponent } from './modules/managers/pages/manager-list-page/manager-list-page.component';
import { ManagerSinglePageComponent } from './modules/managers/pages/manager-single-page/manager-single-page.component';
import { ManagerAddEditFormComponent } from './modules/managers/components/manager-add-edit-form/manager-add-edit-form.component';
import { AssignManagerModalComponent } from './modules/managers/components/assign-manager-modal/assign-manager-modal.component';
import { DashboardMainComponent } from './modules/dashboard/components/dashboard-main/dashboard-main.component';
import { DashboardPageComponent } from './modules/dashboard/pages/dashboard-page/dashboard-page.component';
import { CustomerPasswordPageComponent } from './modules/others/pages/customer-password-page/customer-password-page.component';
import { ReportsPageComponent } from './modules/reports/pages/reports-page/reports-page.component';

// Guards
import { CanDeactivateGuard } from './core/guards/can-deactivate.guard';
import { IsLoggedInService } from './core/auth/is-logged-in.service';
import { AuthGuardService } from './core/guards/auth-guard.service';
import { LogsPageComponent } from './modules/activity-logs/pages/logs-page/logs-page.component';
import { LandingPageComponent } from './modules/business-portal/pages/landing-page/landing-page.component';
import { LogoutPageComponent } from './modules/business-portal/pages/logout-page/logout-page.component';
import { DistributorAddEditFormV2Component } from './modules/distributor/components/distributor-add-edit-form-v2/distributor-add-edit-form-v2.component';
import { UserRolePageComponent } from './modules/settings/pages/user-role-page/user-role-page.component';
import { UserRoleListingComponent } from './modules/settings/components/user-role-listing/user-role-listing.component';
import { CustomBrandPageComponent } from './modules/settings/pages/custom-brand-page/custom-brand-page.component';
import { CustomBrandListingComponent } from './modules/settings/components/custom-brand-listing/custom-brand-listing.component';
import { TemplateSinglePageComponent } from './modules/settings/pages/template-single-page/template-single-page.component';
import { TemplateAddPageComponent } from './modules/settings/pages/template-add-page/template-add-page.component';

const routes: Routes = [
  // Main Web Admin
  { path: '', redirectTo: 'webadmin-dashboard', pathMatch: 'full'},
  { path: 'webadmin-dashboard', component: DashboardPageComponent, canActivate: [AuthGuardService]},
  { path: 'business-partners', component: DefaultPageComponent, canActivate: [AuthGuardService],
    children: [
    { path: '', redirectTo: 'all', pathMatch: 'full'},
    { path: 'all', component: BusinessPartnersPageComponent, canActivate: [AuthGuardService]},
    { path: 'activity-logs', component: LogsPageComponent},
    { path: ':partnerId', component: BusinessPartnersSinglePageComponent, canActivate: [AuthGuardService]},
    { path: ':partnerId/activity-logs', component: LogsPageComponent},
    { path: 'branch/:partnerId/add', component: BranchesAddEditFormComponent, canDeactivate: [CanDeactivateGuard], canActivate: [AuthGuardService]},
    { path: 'branch/:partnerId/:branchId/edit', component: BranchesAddEditFormComponent, canDeactivate: [CanDeactivateGuard], canActivate: [AuthGuardService]},
    { path: 'branch/:partnerId/:branchId', component: BranchesSinglePageComponent, canActivate: [AuthGuardService]}
    ]
  },
  { path: 'distributors', component: DefaultPageComponent, canActivate: [AuthGuardService],
    children: [
      { path: '', redirectTo: 'all', pathMatch: 'full'},
      { path: 'all', component: DistributorPageComponent, canActivate: [AuthGuardService]},
      { path: 'activity-logs', component: LogsPageComponent},
      { path: ':distributorId', component: DistributorSinglePageComponent},
      { path: 'dist/add', component: DistributorAddEditFormV2Component, canDeactivate: [CanDeactivateGuard], canActivate: [AuthGuardService]},
      { path: 'dist/:distributorId/edit', component: DistributorAddEditFormV2Component, canDeactivate: [CanDeactivateGuard], canActivate: [AuthGuardService]},
      { path: 'dist/:distributorId/accounts/:accountId', component: DistributorAccountPageComponent}
    ]
  },
  { path: 'device-monitoring', component: DefaultPageComponent, canActivate: [AuthGuardService],
    children: [
      { path: '', redirectTo: 'all', pathMatch: 'full'},
      { path: 'all', component: DeviceMonitoringPageComponent, canActivate: [AuthGuardService]},
      { path: 'activity-logs', component: LogsPageComponent},
      { path: ':deviceId', component: DeviceSinglePageComponent},
    ]
  },
  { path: 'customers', component: DefaultPageComponent,
    children: [
    { path: '', redirectTo: 'customer-listing', pathMatch: 'full'},
    { path: 'activity-logs', component: LogsPageComponent},
    { path: 'customer-listing', component: CustomerListPageComponent},
    { path: ':customerId', component: CustomerSinglePageComponent}
    ]
  },
  { path: 'managers', component: DefaultPageComponent,
    children: [
    { path: '', redirectTo: 'managers-listing', pathMatch: 'full'},
    { path: 'managers-listing', component: ManagerListPageComponent},
    { path: ':managerId', component: ManagerSinglePageComponent}
    ]
  },
  { path: 'announcements', component: DefaultPageComponent,
    children: [
    { path: '', redirectTo: 'news', pathMatch: 'full'},
    { path: 'news', component: NewsPageComponent},
    { path: 'news/view/:newsId', component: NewsSinglePageComponent},
    { path: 'news/add', component: NewsAddEditFormComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'news/:newsId/edit', component: NewsAddEditFormComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'news/activity-logs', component: LogsPageComponent},
    { path: 'messages', component: MessagesPageComponent},
    { path: 'messages/view/:messagesId', component: MessagesSinglePageComponent},
    { path: 'messages/add', component: MessagesAddEditFormComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'messages/:messagesId/edit', component: MessagesAddEditFormComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'messages/activity-logs', component: LogsPageComponent}
    ]
  },
  { path: 'reports', component: ReportsPageComponent, canActivate: [AuthGuardService]},
  { path: 'settings', component: DefaultPageComponent,
  children: [
    { path: 'admin-account-setting', component: AccountsPageComponent,
    children: [
      { path: '', redirectTo: 'admin-account', pathMatch: 'full'},
      { path: 'admin-account', component: AccountsListingComponent },
      { path: 'activity-logs', component: LogsPageComponent},
      { path: ':accountId', component: AccountsSinglePageComponent },
    ]},
    { path: 'customer-app-setting', component: CustomerAppPageComponent, canDeactivate: [CanDeactivateGuard]},
    { path: 'customer-app-setting/activity-logs', component: LogsPageComponent},
    { path: 'industry-setting', component: IndustryPageComponent,
    children: [
      { path: '', redirectTo: 'industry', pathMatch: 'full'},
      { path: 'industry', component: IndustryListingComponent },
      { path: 'activity-logs', component: LogsPageComponent},
      { path: ':industryId', component: IndustySinglePageComponent },
      { path: 'industry/add', component: IndustryAddEditFormComponent, canDeactivate: [CanDeactivateGuard] },
      { path: 'industry/:industryId/edit', component: IndustryAddEditFormComponent, canDeactivate: [CanDeactivateGuard] }
    ]},
    { path: 'custom-brand-setting', component: CustomBrandPageComponent,
    children: [
      { path: '', redirectTo: 'custom-list', pathMatch: 'full'},
      { path: 'custom-list', component: CustomBrandListingComponent },
      { path: 'add', component: TemplateAddPageComponent, canDeactivate: [CanDeactivateGuard] },
      { path: 'view/:templateId', component: TemplateSinglePageComponent },
      { path: 'edit/:templateId', component: TemplateAddPageComponent, canDeactivate: [CanDeactivateGuard] },
      { path: 'activity-logs', component: LogsPageComponent}
    ]},
    { path: 'user-admin-role-setting', component: UserRolePageComponent,
    children: [
      { path: '', redirectTo: 'user-list', pathMatch: 'full'},
      { path: 'user-list', component: UserRoleListingComponent },
      { path: 'activity-logs', component: LogsPageComponent}
    ]}
  ]
  },

  // Other Pages
  { path: 'business-portal', component: LandingPageComponent},
  { path: 'business-portal-logout', component: LogoutPageComponent},
  { path: 'login', component: LoginPageComponent, resolve: [IsLoggedInService] },
  { path: 'forgot-password', component: ForgotPasswordPageComponent, resolve: [IsLoggedInService] },
  { path: 'password-page', component: PasswordPageComponent },
  { path: 'customer-verify-page', component: CustomerPasswordPageComponent },
  { path: 'verify-page', component: PasswordPageComponent },
  { path: 'password-failed/:type', component: PasswordFailedPageComponent },
  { path: 'password-success/:type', component: PasswordSuccessPageComponent },
  { path: 'not-found', component: NotFoundPageComponent },
  { path: '**', redirectTo: 'not-found'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [CanDeactivateGuard]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { BusinessService } from 'src/app/core/services/business.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-suspend-users-modal',
  templateUrl: './suspend-users-modal.component.html',
  styleUrls: ['./suspend-users-modal.component.scss']
})
export class SuspendUsersModalComponent implements OnInit {
  title: any;
  customers: any;
  errorMsg: string;
  submitting: boolean;
  selectedCustomers = [];
  customerLength: any;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private businessService: BusinessService,
    private httpAdminService: HttpWebAdminService,
    ) { }

  ngOnInit() {
    console.log('customers', this.customers);
    this.customers.forEach((element) => {
      this.selectedCustomers.push(element.id);
    });
    console.log('this.selectedCustomers', this.selectedCustomers);
  }

  submitSuspended() {
    this.submitting = true;
    this.httpAdminService.patch$(`customer/user/suspend`, {accountIds: this.selectedCustomers}).subscribe(
      data => {
        this.activeModal.dismiss();
        this.businessService.publishDataChange$(data);
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>${this.customers.length}</b> has been Suspended!`
        });
        this.submitting = false;
      }, error => {
        console.log('error', error);
        this.errorMsg = error.error.message;
        this.submitting = false;
      }
    );
  }

}

import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';
import { ReportGenerateModalComponent } from '../report-generate-modal/report-generate-modal.component';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-reports-listing',
  templateUrl: './reports-listing.component.html',
  styleUrls: ['./reports-listing.component.scss']
})
export class ReportsListingComponent implements OnInit {
  query: any;
  reports = [{reportName: 'SMS Report', filter: 'filter-single-date', type: 'sms-report', desc: 'Record list of  successfully sent SMS'}, {reportName: 'Accounts Report', filter: 'filter-single', type: 'accounts-report', desc: 'Download list of accounts added in the web admin'}];

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
  ) { }

  searchForm = this.fb.group({
    searchString: ['']
  });

  get f() { return this.searchForm.controls; }

  filterReport(title, filter, type) {
    const modalRef = this.modalService.open(ReportGenerateModalComponent, {
      centered: true,
      size: 'sm'
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.type = 'report';
    modalRef.componentInstance.filter = filter;
    modalRef.componentInstance.type = type;

  }

  search(searchString) {
    this.query = searchString;
    console.log(searchString);
  }

  ngOnInit() {
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, ControlContainer, FormGroupDirective, Validators } from '@angular/forms';
import { HttpService } from 'src/app/core/http/http.service';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';

@Component({
  selector: 'app-main-branch-feature-access',
  templateUrl: './main-branch-feature-access.component.html',
  styleUrls: ['./main-branch-feature-access.component.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }]
})
export class MainBranchFeatureAccessComponent implements OnInit {
  @Input() branchData: any;
  queueSettingsData: any;

  constructor(
    private fb: FormBuilder,
    private parent: FormGroupDirective,
    private httpAdminService: HttpWebAdminService
  ) { }

  feature = this.fb.group({
    maxAccount: [false],
    maxAccountNo: [1, [Validators.required]],
    maxQueueGroup: [false],
    maxQueueGroupNo: [1, [Validators.required]],
    enableSMS: [false],
    enableBatchIssuanceOfQueue: [false],
    enableMultipleDoneRemove: [false],
    enableUserRole: [false],
    enableProcedure: [false],
    enableFeedback: [false],
    enableAppointment: [false],
    enableKyooID: [false]
  });

  get f() { return this.feature.controls; }

  getQueueSettings() {
    this.httpAdminService.get$(`/branches/${this.branchData._id}/queue-settings`).subscribe(
      data => {
        console.log('queue settings', data);
        this.queueSettingsData = data;
        this.patchValue2();
      }
    );
  }

  preventInput(event, input) {
    if (event === 0) {
      input.patchValue(1);
    } else if (event === '') {
      input.patchValue('');
    }
  }

  preventMouseOut(input) {
    if (input.value <= 0 || input.value === '') {
      input.patchValue(1);
    }
  }

  patchValue() {
    this.feature.patchValue({
      maxAccount: this.branchData.settings.featuredAccess.account.status,
      maxAccountNo: this.branchData.settings.featuredAccess.account.max,
      maxQueueGroup: this.branchData.settings.featuredAccess.queueGroup.status,
      maxQueueGroupNo: this.branchData.settings.featuredAccess.queueGroup.max,
      enableSMS: this.branchData.settings.featuredAccess.smsModule.status,
      enableUserRole: this.branchData.settings.userRoleStatus,
      enableProcedure : this.branchData.settings.customizationClientFeatures.includes(1) ? true : false,
      enableKyooID : this.branchData.settings.customizationClientFeatures.includes(5) ? true : false,
      enableFeedback : this.branchData.settings.customizationClientFeatures.includes(6) ? true : false,
      enableAppointment : this.branchData.settings.customizationClientFeatures.includes(7) ? true : false,
    });
  }

  patchValue2() {
    this.feature.patchValue({
      enableBatchIssuanceOfQueue: this.queueSettingsData.features.includes(5),
      enableMultipleDoneRemove: this.queueSettingsData.features.includes(4)
    });
  }

  ngOnInit() {
    this.parent.form.addControl(
      'feature', this.feature
    );
    if (this.branchData) {
      this.getQueueSettings();
      this.patchValue();
    }
  }

}

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ControlContainer, FormGroupDirective, FormBuilder } from '@angular/forms';
import { CropPhotoComponent } from 'src/app/indie/crop-photo/crop-photo.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BusinessService } from 'src/app/core/services/business.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-template-favicon-form',
  templateUrl: './template-favicon-form.component.html',
  styleUrls: ['./template-favicon-form.component.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }]
})
export class TemplateFaviconFormComponent implements OnInit, OnDestroy {
  @Input() templateData: any;
  bannerFileName: any;
  selectedBanner: any;
  bannerError: boolean;
  imgBanner: any;
  formDirty: boolean;
  submitting: boolean;
  subscriptions = new Subscription();

  constructor(
    private fb: FormBuilder,
    private parent: FormGroupDirective,
    private modalService: NgbModal,
    private businessService: BusinessService
  ) { }

  faviconForm = this.fb.group({});

  onBannerChange($event) {
    console.log('banner', $event);
    this.bannerFileName = $event.target.files[0].name;
    this.selectedBanner = $event.target.files[0] as File;
    if (this.selectedBanner) {
      const reader = new FileReader();
      reader.onload = e => {
        this.businessService.publishCroppedFavicon$(reader.result);
        return this.imgBanner = reader.result;
      };
      reader.readAsDataURL(this.selectedBanner);
    }
    this.imgBanner = this.selectedBanner;
    this.businessService.selectedFavicon$ = this.selectedBanner;
    this.businessService.publishEnableEdit$(true);
  }

  clickBrowse($event) {
    $event.target.value = null;
    this.bannerFileName = null;
    console.log('fileInput', $event.target.value);
  }

  patchValue() {
    this.imgBanner = this.templateData.faviconImage;
    this.selectedBanner = this.imgBanner;
  }

  ngOnInit() {
    this.parent.form.addControl(
      'favicon', this.faviconForm
    );
    if (this.templateData) {
      this.patchValue();
      this.bannerFileName = this.templateData.faviconImage;
    } else {
      this.bannerFileName = '';
    }
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

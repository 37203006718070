import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  public pages$: any;

  public sideNav = new BehaviorSubject<boolean>(false);
  sideNav$ = this.sideNav.asObservable();
  public permanentSideNav = new BehaviorSubject<boolean>(false);
  permanentSideNav$ = this.permanentSideNav.asObservable();
  public query = new Subject<string>();
  query$ = this.query.asObservable();
  navigateAwaySelection$: Subject<boolean> = new Subject<boolean>();
  navigateClose$: Subject<boolean> = new Subject<boolean>();
  // Return Queue
  public returnQueue = new Subject<any>();
  returnQueue$ = this.returnQueue.asObservable();
  // Confirm Return Queue
  public confirmReturnQueue = new Subject<any>();
  confirmReturnQueue$ = this.confirmReturnQueue.asObservable();
  // Alert
  public alert = new Subject<any>();
  alert$ = this.alert.asObservable();
  // Reset Form
  public resetForm = new Subject<any>();
  resetForm$ = this.resetForm.asObservable();
  // Tags
  public selectedTags = new Subject<any>();
  selectedTags$ = this.selectedTags.asObservable();
  // PartnerData
  public partnerData$: any;
  // AccountData
  public accountData$: any;
  // Industrydata
  public industryData$: any;
  // Selected Category
  public selectedCategory = new Subject<any>();
  selectedCategory$ = this.selectedCategory.asObservable();
  // Search and Filter
  // Data
  public dataLists = new Subject<any>();
  dataLists$ = this.dataLists.asObservable();
  // Chart
  public chartLists = new Subject<any>();
  chartLists$ = this.chartLists.asObservable();
  public totalItem = new Subject<any>();
  totalItem$ = this.totalItem.asObservable();
  public selectedPage = new Subject<any>();
  selectedPage$ = this.selectedPage.asObservable();
  public maxNumberPages = new Subject<any>();
  maxNumberPages$ = this.maxNumberPages.asObservable();
  public startItem = new Subject<any>();
  startItem$ = this.startItem.asObservable();
  public selectedLimit = new Subject<any>();
  selectedLimit$ = this.selectedLimit.asObservable();
  public itemShowing = new Subject<number>();
  itemShowing$ = this.itemShowing.asObservable();
  public searchString = new ReplaySubject<string>();
  searchString$ = this.searchString.asObservable();
  // Check data change
  public dataChange = new ReplaySubject<any>();
  dataChange$ = this.dataChange.asObservable();
  // Branch Settings
  public branchSettings = new Subject<any>();
  branchSettings$ = this.branchSettings.asObservable();
  // Selected Avatar and Cover Photo
  public selectedAvatar$: any;
  public fileType$: any;
  public fileName$: any;
  public fileType2$: any;
  public fileName2$: any;
  public fileType3$: any;
  public fileName3$: any;
  public selectedAccountAvatar$: any;
  public selectedCoverPhoto$: any;
  // EnableEdit
  public enableEdit = new BehaviorSubject<boolean>(false);
  enableEdit$ = this.enableEdit.asObservable();
  // formDataModal
  public formDataModal$: any;
  // Banner Customer App Settings
  public bannersList = new Subject<any>();
  bannersList$ = this.bannersList.asObservable();
  // Remove Banner
  public removeBanner = new Subject<any>();
  removeBanner$ = this.removeBanner.asObservable();
  // Discover Customer App Settings
  public discoverList = new Subject<any>();
  discoverList$ = this.discoverList.asObservable();
  // CroppedPhoto
  public croppedPhoto = new Subject<any>();
  croppedPhoto$ = this.croppedPhoto.asObservable();
  public croppedAccountPhoto = new Subject<any>();
  croppedAccountPhoto$ = this.croppedAccountPhoto.asObservable();
  // CroppedPhoto
  public croppedBanner = new Subject<any>();
  croppedBanner$ = this.croppedBanner.asObservable();
  public croppedBranch = new Subject<any>();
  croppedBranch$ = this.croppedBranch.asObservable();
  public croppedMascot = new Subject<any>();
  croppedMascot$ = this.croppedMascot.asObservable();
  // Errors in Forms
  public statusCodeList = new Subject<any>();
  statusCodeList$ = this.statusCodeList.asObservable();
  // Check Banner and Discover Dirty
  public bannerDiscover = new Subject<any>();
  bannerDiscover$ = this.bannerDiscover.asObservable();
  // Custom Branding images
  public selectedLoginImage$: any;
  public selectedSidenavLogo$: any;
  public selectedFavicon$: any;
  public croppedLoginImage = new Subject<any>();
  croppedLoginImage$ = this.croppedLoginImage.asObservable();
  public croppedSideNavLogo = new Subject<any>();
  croppedSideNavLogo$ = this.croppedSideNavLogo.asObservable();
  public croppedFavicon = new Subject<any>();
  croppedFavicon$ = this.croppedFavicon.asObservable();
  // Custom Branding Color Theme
  public primaryColor = new Subject<any>();
  primaryColor$ = this.primaryColor.asObservable();
  public secondaryColor = new Subject<any>();
  secondaryColor$ = this.secondaryColor.asObservable();
  public primaryText = new Subject<any>();
  primaryText$ = this.primaryText.asObservable();
  public secondaryText = new Subject<any>();
  secondaryText$ = this.secondaryText.asObservable();
  public distributorData = new Subject<any>();
  distributorData$ = this.distributorData.asObservable();
  public distributorLogin = new Subject<any>();
  distributorLogin$ = this.distributorLogin.asObservable();
  public distributorTV = new Subject<any>();
  distributorTV$ = this.distributorTV.asObservable();
  // User Roles
  public loggedInAccount = new Subject<any>();
  loggedInAccount$ = this.loggedInAccount.asObservable();

  publishSideNav$(booleann) {
    this.sideNav.next(booleann);
  }

  publishPermanentSideNav$(booleann) {
    this.permanentSideNav.next(booleann);
  }

  publishQuery$(query) {
    this.query.next(query);
  }

  parseDate(dateFromTo) {
    let date = [];
    if (dateFromTo) {
      date  = [
        dateFromTo.month,
        dateFromTo.day,
        dateFromTo.year,
      ];
    }
    return Date.parse(date.toString());
  }

  jsonDate(dateFromTo) {
    const m = new Date(dateFromTo).getMonth();
    const d = new Date(dateFromTo).getDate();
    const y = new Date(dateFromTo).getFullYear();
    return {
      day: d,
      month: m + 1,
      year: y
    };
  }

  parseTime(time) {
    const hour = time.hour;
    const minute = time.minute;
    const second = time.second;
    return new Date(1970, 0, 1, hour, minute, second).getTime();
  }

  jsonTime(time) {
    const h = new Date(time).getHours();
    const m = new Date(time).getMinutes();
    const s = new Date(time).getSeconds();
    return {hour: h, minute: m, second: s};
  }

  dataURItoBlob(dataURI) {
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], {type: mimeString});
  }

  setBusinessPortal(branchData) {
    localStorage.setItem('branchId', branchData._id);
    localStorage.setItem('branchName', branchData.branchName);
    localStorage.setItem('partnerName', branchData.partnerName);
    localStorage.setItem('partnerId', branchData.partnerId);
    localStorage.setItem('partnerAvatarUrl', branchData.partnerAvatarUrl);
  }

  getPlanType(val) {
    switch (val) {
      case 1: {
        return 'Subscription';
      }
      case 2: {
        return 'One-time Payment';
      }
    }
  }

  getBannerViewType(val) {
    switch (val) {
      case 1: {
        return 'External Link';
      }
      case 2: {
        return 'Facebook';
      }
      case 3: {
        return 'Instagram';
      }
    }
  }

  // Return Queue
  publishReturnQueue$(queue) {
    this.returnQueue.next(queue);
  }

  // Confirm Return Queue
  publishConfirmReturnQueue$(queue) {
    this.confirmReturnQueue.next(queue);
  }

  // Alert
  publishAlert$(alert) {
    this.alert.next(alert);
  }

  // Reset Form
  publishResetForm$(reset) {
    this.resetForm.next(reset);
  }

  // Tags
  publishSelectedTags$(tags) {
    this.selectedTags.next(tags);
  }

  // Selected Category
  publishSelectedCategory$(id) {
    this.selectedCategory.next(id);
  }

  // Search and Filter
  // Data
  publishDataLists$(object) {
    this.dataLists.next(object);
  }

  // Chart
  publishChartLists$(object) {
    this.chartLists.next(object);
  }

  publishTotalItem$(item) {
    this.totalItem.next(item);
  }

  publishSelectedPage$(page) {
    this.selectedPage.next(page);
  }

  publishMaxNumberPages$(page) {
    this.maxNumberPages.next(page);
  }

  publishStartItem$(item) {
    this.startItem.next(item);
  }

  publishSelectedLimit$(limit) {
    this.selectedLimit.next(limit);
  }

  publishItemShowing$(itemShow) {
    this.itemShowing.next(itemShow);
  }

  publishSearchString$(text) {
    this.searchString.next(text);
  }

  // Check data change
  publishDataChange$(data) {
    this.dataChange.next(data);
  }

  // Branch Settings
  publishBranchSettings$(data) {
    this.branchSettings.next(data);
  }

  // Enable Edit
  publishEnableEdit$(data) {
    this.enableEdit.next(data);
  }

  // Banner Customer App Settings
  publishBannersList$(data) {
    this.bannersList.next(data);
  }

  // Remove Banner
  publishRemoveBanner$(data) {
    this.removeBanner.next(data);
  }

  // Discover Customer App Settings
  publishDiscoverList$(data) {
    this.discoverList.next(data);
  }
  // CroppedPhoto
  publishCroppedPhoto$(data) {
    this.croppedPhoto.next(data);
  }
  // CroppedPhoto
  publishCroppedAccountPhoto$(data) {
    this.croppedAccountPhoto.next(data);
  }
  // CroppedPBanner
  publishCroppedBanner$(data) {
    this.croppedBanner.next(data);
  }

  // CroppedPBanner
  publishCroppedBranch$(data) {
    this.croppedBranch.next(data);
  }

  // CroppedPBanner
  publishCroppedMascot$(data) {
    this.croppedMascot.next(data);
  }

  // Errors in Forms
  publishStatusCodeList$(data) {
    this.statusCodeList.next(data);
  }

  // Check Banner and Discover Dirty
  publishBannerDiscover$(data) {
    this.bannerDiscover.next(data);
  }

   // Cropped Custom Branding Images
  publishCroppedLoginImage$(data) {
    this.croppedLoginImage.next(data);
  }

  publishCroppedSidenavLogo$(data) {
    this.croppedSideNavLogo.next(data);
  }

  publishCroppedFavicon$(data) {
    this.croppedFavicon.next(data);
  }

  // Custom Branding Color Theme
  publishPrimaryColor$(data) {
    this.primaryColor.next(data);
  }
  publishSecondaryColor$(data) {
    this.secondaryColor.next(data);
  }
  publishPrimaryText$(data) {
    this.primaryText.next(data);
  }
  publishSecondaryText$(data) {
    this.secondaryText.next(data);
  }
  publishDistributorData$(data) {
    this.distributorData.next(data);
  }
  publishDistributorLogin$(data) {
    this.distributorLogin.next(data);
  }
  publishDistributorTV$(data) {
    this.distributorTV.next(data);
  }
  
  // User Roles
  publishLoggedInAccount$(data) {
    this.loggedInAccount.next(data);
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BusinessService } from 'src/app/core/services/business.service';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';

@Component({
  selector: 'app-manager-single-page',
  templateUrl: './manager-single-page.component.html',
  styleUrls: ['./manager-single-page.component.scss']
})
export class ManagerSinglePageComponent implements OnInit {

  managerData: any;
  imgUrl: any;
  imgAvatar: any;
  imgBanner: any;
  imgBranch: any;
  imgMascot: any;
  dataLists: Array<any>;
  managerId: string;
  subscriptions = new Subscription();
  selected: number;
  selectedList: any;

  constructor() { }

  ngOnInit() {
  }

}

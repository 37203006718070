import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mobileTransform'
})
export class MobileTransformPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      const number1 = value.substring(0, 3);
      const number2 = value.substring(3, 6);
      const number3 = value.substring(6, 11);
      return `(+63) ${number1} - ${number2} - ${number3}`;
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/auth/auth.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-branch-page',
  templateUrl: './login-branch-page.component.html',
  styleUrls: ['./login-branch-page.component.scss']
})
export class LoginBranchPageComponent implements OnInit {
  background: string;
  errorMsg: string;
  submitting: boolean;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router
  ) { }

  branchLoginForm = this.fb.group({
    branchId: ['', Validators.required]
  });

  get f() { return this.branchLoginForm.controls; }

  submitForm(f) {
    this.submitting = true;
    f.disable();
    console.log(f.value.branchId);
    this.auth.loginBranch(f.value.branchId)
      .pipe(first())
      .subscribe(data => {
        console.log('login branch', data);
        this.router.navigate(['/login']);
        this.submitting = false;
        f.enable();
      }, error => {
        console.log(error.error.source);
        this.errorMsg = error.error.source;
        this.submitting = false;
        f.enable();
      });
  }

  ngOnInit() {
    this.background = './assets/images/img_kyoo_bg.png';
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';
import { BusinessService } from 'src/app/core/services/business.service';
import { Router } from '@angular/router';
import { UserRolePermissionsComponent } from 'src/app/shared/components/user-role-permissions/user-role-permissions.component';
@Component({
  selector: 'app-custom-brand-listing',
  templateUrl: './custom-brand-listing.component.html',
  styleUrls: ['./custom-brand-listing.component.scss']
})
export class CustomBrandListingComponent extends UserRolePermissionsComponent implements OnInit {
  query: any;
  templateLists: any;
  accountsList: Array<any>;

  constructor(
    private fb: FormBuilder,
    private httpAdminService: HttpWebAdminService,
    businessService: BusinessService,
    private router: Router
  ) {
    super(businessService)
  }

  searchForm = this.fb.group({
    searchString: ['']
  });

  get f() { return this.searchForm.controls; }

  search(searchString) {
    this.query = searchString;
    console.log(searchString);
  }

  getTemplateList() {
    this.httpAdminService.get$(`custom-brand-templates`).subscribe(data => {
      console.log('custom-brand-templates', data);
      this.templateLists = data.result.data;
    });
    this.getAccountsList();
  }

  viewTemplated(tempId) {
    this.router.navigate([`/settings/custom-brand-setting/view/${tempId}`]);
  }

  getAccountsList() {
    this.httpAdminService.get$(`accounts/search/?limit=999&offset=0`).subscribe(
      data => {
        console.log('accounts list', data);
        this.accountsList = data.data;
      }, error => {
        console.log('error', error);
        this.businessService.publishAlert$({
          type: 'red-dark-01',
          icon: 'fas fa-ban',
          message: `<b>Error:</b> Something went wrong.`
        });
      }
    );
  }

  getAddedByAvatar(id) {
    const selectedId = this.accountsList.find(element => element._id === id);
    if (selectedId) {
      return selectedId.avatarUrl ? selectedId.avatarUrl : './assets/images/koala.jpg';
    } else {
      return './assets/images/koala.jpg';
    }
  }

  getAddedByName(id) {
    const selectedId = this.accountsList.find(element => element._id === id);
    if (selectedId) {
      return selectedId.name ? (selectedId.name ? selectedId.name : '—') : (selectedId.firstName ? (selectedId.firstName + ' ' + selectedId.lastName) : '—');
    } else {
      return '—';
    }
  }

  addTemplate() {
    this.router.navigate([`/settings/custom-brand-setting/add`]);
  }

  getAccountData() {
    this.getAccountPromise();
    this.accountPromise
    .then((data) => {      
      console.log('promise account', data);
      this.setAccountItems(data);
      // Insert all functions related to Account Data
      // ----- End ----- //
    })
    .catch((error) => {
      console.log('promise err', error);
    });
  }

  ngOnInit() {
    // Needed in User Role
    this.getAccountData();
    // --------- //
    this.getTemplateList();
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Additional Modules
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts-x';
import { AgmCoreModule } from '@agm/core';

import { ChartDonutComponent } from './components/chart-donut/chart-donut.component';
import { ChartLineComponent } from './components/chart-line/chart-line.component';
import { DefaultPageComponent } from './components/default-page/default-page.component';
import { InputActiveDirective } from './directives/input-active.directive';
import { MainInfoComponent } from './components/main-info/main-info.component';
import { FilterDataPipe } from './pipes/filter-data.pipe';
import { ModalConfirmComponent } from './components/modal-confirm/modal-confirm.component';
import { CategorySidenavComponent } from './components/category-sidenav/category-sidenav.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { MobileFormatDirective } from './directives/mobile-format.directive';
import { MobileTransformPipe } from './pipes/mobile-transform.pipe';
import { MobileFormatShortDirective } from './directives/mobile-format-short';
import { LandlineFormatDirective } from './directives/landline-format.directive';
import { SearchFilterWebAdminComponent } from './components/search-filter-web-admin/search-filter-web-admin.component';
import { TableWebAdminComponent } from './components/table-web-admin/table-web-admin.component';
import { AccountsAddEditFormComponent } from '../modules/settings/components/accounts-add-edit-form/accounts-add-edit-form.component';
import { SafePipe } from './pipes/safe.pipe';
import { IframeResizerDirective } from './directives/iframe-resizer.directive';
import { UserRolePermissionsComponent } from './components/user-role-permissions/user-role-permissions.component';

@NgModule({
  declarations: [
    ChartDonutComponent,
    ChartLineComponent,
    DefaultPageComponent,
    InputActiveDirective,
    MainInfoComponent,
    FilterDataPipe,
    ModalConfirmComponent,
    CategorySidenavComponent,
    PaginationComponent,
    MobileFormatDirective,
    MobileTransformPipe,
    MobileFormatShortDirective,
    LandlineFormatDirective,
    SearchFilterWebAdminComponent,
    TableWebAdminComponent,
    SafePipe,
    IframeResizerDirective,
    UserRolePermissionsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    FormsModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCEcYYZ5FPrsIiD42WN5wXBzts_CNUj_OA'
    }),
  ],
  exports: [
    ChartDonutComponent,
    ChartLineComponent,
    InputActiveDirective,
    MainInfoComponent,
    FilterDataPipe,
    CategorySidenavComponent,
    PaginationComponent,
    ModalConfirmComponent,
    MobileFormatDirective,
    MobileTransformPipe,
    MobileFormatShortDirective,
    LandlineFormatDirective,
    SearchFilterWebAdminComponent,
    TableWebAdminComponent,
    SafePipe,
    IframeResizerDirective
  ],
  entryComponents: [
    ModalConfirmComponent
  ]
})
export class SharedModule {
  static forRoot() {
    return {
        ngModule: SharedModule,
        providers: [],
    };
  }
}

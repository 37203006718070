import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BusinessService } from 'src/app/core/services/business.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from 'src/app/shared/components/modal-confirm/modal-confirm.component';

@Component({
  selector: 'app-customer-app-page',
  templateUrl: './customer-app-page.component.html',
  styleUrls: ['./customer-app-page.component.scss']
})
export class CustomerAppPageComponent implements OnInit, OnDestroy {
  isBannerDiscoverDirty: boolean;
  subscriptions = new Subscription();

  constructor(
    private businessService: BusinessService,
    private modalService: NgbModal
  ) { }

  canDeactivate() {
    console.log('canDeactivate');
    if (this.isBannerDiscoverDirty) {
      const modalRef = this.modalService.open(ModalConfirmComponent, {
        centered: true
      });
      modalRef.componentInstance.title = 'Leave Page';
      modalRef.componentInstance.type = 'leave-page';
      return this.businessService.navigateAwaySelection$;
    } else {
      return true;
    }
  }

  checkBannerDiscoverDirty() {
    console.log('checkBannerDiscoverDirty');
    this.subscriptions.add(this.businessService.bannerDiscover$.subscribe(res => {
      console.log('checkBannerDiscoverDirty is true');
      this.isBannerDiscoverDirty = res;
    }));
  }

  ngOnInit() {
    this.checkBannerDiscoverDirty();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpService } from 'src/app/core/http/http.service';
import { ConfirmPasswordValidator } from 'src/app/shared/validators/confirm-password.validator.service';
import { HttpWebAdminService } from 'src/app/core/http/http-web-admin.service';

@Component({
  selector: 'app-password-page',
  templateUrl: './password-page.component.html',
  styleUrls: ['./password-page.component.scss']
})
export class PasswordPageComponent implements OnInit, OnDestroy {
  branchId: string;
  errorMsg: string;
  passwordType: any;
  show: boolean;
  passwordType2: any;
  show2: boolean;
  inputFocus: any;
  isMatch1: boolean;
  isMatch2: boolean;
  isMatch3: boolean;
  token: string;
  type: any;
  submitting: boolean;
  setPasswordToken: string;
  statusCodeList: Array<any>;
  errorPasswordMsg: string;
  subscriptions = new Subscription();

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private httpAdminService: HttpWebAdminService,
    private router: Router
  ) { }

  passwordForm = this.fb.group({
    setPasswordToken: [''],
    password: ['', [Validators.pattern(/(?=^.{6,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/)]],
    confirmPwd: ['', Validators.required]
  }, {
    validator: ConfirmPasswordValidator.MatchPassword
  });

  get f() { return this.passwordForm.controls; }

  showHidePw() {
    this.show = !this.show;
    this.show ? this.passwordType = 'text' : this.passwordType = 'password';
    console.log(this.passwordType);
  }

  showHidePw2() {
    this.show2 = !this.show2;
    this.show2 ? this.passwordType2 = 'text' : this.passwordType2 = 'password';
    console.log(this.passwordType);
  }

  checkPwPattern(newPwd) {
    console.log(newPwd);
    // At least 6 characters
    newPwd.match(/(?=^.{6,}$)/) ? this.isMatch1 = true : this.isMatch1 = false;
    newPwd.match(/(?=.*[A-Z])/) ? this.isMatch2 = true : this.isMatch2 = false;
    newPwd.match(/(?=.*[0-9])/) ? this.isMatch3 = true : this.isMatch3 = false;
  }

  getUrlParams() {
    this.subscriptions.add(this.activatedRoute.queryParams.subscribe(params => {
      this.token =  params['token'];
      this.type = params['type'];
      this.branchId = params['branchId'];
    }));
  }

  patchToken() {
    this.f['setPasswordToken'].patchValue(this.setPasswordToken);
  }

  validateToken() {
    switch (this.type) {
      // if password
      case 'FORGOT_PASSWORD' : {
        this.httpAdminService.get$(`auth/forgot-password/${this.token}`).subscribe(data => {
          console.log('data', data);
          this.setPasswordToken = data.token;
          this.patchToken();
        }, error => {
          console.log('error', error);
          this.router.navigate([`/password-failed/${this.type}`]);
        });
      }
      break;
      // if verify
      case 'VERIFICATION' : {
        this.httpAdminService.get$(`auth/sign-up/verify-email?token=${this.token}`).subscribe(data => {
          console.log('data', data);
          this.setPasswordToken = data.setPasswordToken;
          this.patchToken();
        }, error => {
          console.log('error', error);
          this.router.navigate([`/password-failed/${this.type}`]);
        });
      }
      break;
    }
  }

  submitForm(f) {
    f.disable();
    this.submitting = true;
    switch (this.type) {
      case 'FORGOT_PASSWORD' : {
        console.log('forgot password');
        const {password} = f.value;
        const body = { newPassword: password};
        console.log('type and form', this.type, body);
        this.httpAdminService.post$(`auth/forgot-password/change-password/${this.setPasswordToken}`, body).subscribe(data => {
          f.enable();
          console.log('data', data);
          this.router.navigate([`password-success/FORGOT_PASSWORD`]);
          this.submitting = false;
        }, error => {
          console.log('error', error);
          this.showError(error);
          this.submitting = false;
          f.enable();
        });
      }
      break;
      case 'VERIFICATION' : {
        console.log('verify');
        const {setPasswordToken, password} = f.value;
        const body = { setPasswordToken, password };
        console.log('type and form', this.type, body);
        this.httpAdminService.patch$(`auth/sign-up/set-password`, body).subscribe(data => {
          f.enable();
          this.submitting = false;
          console.log('data', data);
          this.router.navigate([`/password-success/VERIFICATION`]);
          this.submitting = false;
        }, error => {
          console.log('error', error);
          this.showError(error);
          this.submitting = false;
          f.enable();
        });
      }
      break;
    }
  }

  showError(error) {
    this.statusCodeList = error.error.errors.filter(element => element.msg.statusCode).map(element2 => element2.msg.statusCode);
    console.log('this.statusCodeList', this.statusCodeList);
    // User not found
    if (this.statusCodeList.includes(88400)) {
      this.errorPasswordMsg = `You cannot use your old password.`;
    } else {
      this.errorPasswordMsg = null;
      this.errorMsg = `<b>Something went wrong.</b> Please try again.`;
    }
  }

  clearBackendError(input) {
    if (input.dirty) {
      this.errorMsg = null;
      this.errorPasswordMsg = null;
    }
  }

  ngOnInit() {
    this.passwordType = 'password';
    this.passwordType2 = 'password';
    this.getUrlParams();
    this.validateToken();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent implements OnInit {

  constructor(
    private authService: AuthService
  ) { }

  loggedIn() {
    if (this.authService.isLoggedIn()) {
      return true;
    }
  }

  ngOnInit() {
  }

}
